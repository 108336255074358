const userPages = {
    Products: 'PRODUCTS',
    ProductDetail: 'PRODUCT_DETAIL',
    Customers: 'CUSTOMERS',
    OrderHistory: 'ORDER_HISTORY',
    Settings: 'SETTINGS',
    AuthorizedUsers: 'AUTHORIZED_USERS',
    Reports: 'REPORTS',
    Emails: 'EMAILS',
    CreateAccount: 'CREATE_ACCOUNT',
    AccessAccount: 'ACCESS_ACCOUNT',
    ProductCategories: 'PRODUCT_CATEGORIES',
    Profile: 'PROFILE',
    Coupons: 'COUPONS',
    CouponDetail: 'COUPON_DETAIL',
    CustomerDetail: 'CUSTOMER_DETAIL',
    OrderDetail: 'ORDER_DETAIL', 
    Ingredients: 'INGREDIENTS',
    Combo: 'COMBO',
    DailySnapshot: 'DAILY_SNAPSHOT',
    SMSCampaigns: 'SMS_CAMPAIGNS',
    SalesAI: 'SALES_AI',
    Help: 'HELP'
}

export default userPages