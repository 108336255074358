const orderStatus = {
    Unknown: 0,
    SentToClub: 1,
    AcknowledgedByClub: 2,
    DeclinedByClub: 3,
    ReadyForPickup: 4,
    AwaitingPayment: 5,
    CanceledByClub: 6,
    Completed: 7,
    FullyRefunded: 8,
    Voided: 9
}

export default orderStatus

export const orderStatusLookup = {
    0: 'Unknown',
    1: 'Sent To Club',
    2: 'Acknowledged By Club',
    3: 'Declined By Club',
    4: 'Ready For Pickup',
    5: 'Awaiting Payment',
    6: 'Canceled By Club',
    7: 'Completed',
    8: 'Fully Refunded',
    9: 'Voided',
}