import { baseURL, baseWeb } from '../base';

const controller = `${baseURL}/auth/v1`

export async function userSignInAPI(email, password, userType, isKiosk, storeId){
    const response = await fetch(`${controller}/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            Password: password,
            UserType: userType,
            IsKiosk: isKiosk || false,
            StoreAccountId: storeId || 0
        })
    });
    return response;
}

export async function refreshAPI(refreshToken){
    const response = await fetch(`${controller}/refresh`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Refresh': refreshToken
        }
    });
    return response;
}

export async function emailExistsAPI(email, userType) {
    const response = await fetch(`${controller}/emailexists`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            UserType: userType,
        })
    });
    return response;
}

export async function registerUserAPI(email, password, firstName, lastName, phone){
    const response = await fetch(`${controller}/registeruser`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            Password: password,
            FirstName: firstName,
            Lastname: lastName,
            Phone: phone
        })
    })
    return response;
}

export async function accountCodeCheckAPI(hostName, accountCode) {
    const response = await fetch(`${controller}/checkstorecode`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            HostName: hostName,
            AccountCode: accountCode,
        })
    });
    return response;
}

export async function registerCustomerAPI(email, password, firstName, lastName, phone, homeId, appCreated){
    const response = await fetch(`${controller}/registercustomer`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            Password: password,
            FirstName: firstName,
            LastName: lastName,
            Phone: phone,
            HomeId: homeId,
            AppCreated: appCreated
        })
    })
    return response;
}

export async function ssoSignInAPI(code, storeId){
    const response = await fetch(`${controller}/customersso`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Domain: `${baseWeb}/fblogin`,
            Code: code,
            StoreAccountId: storeId
        })
    });
    return response;
}

export async function qrSignInAPI(qrData, storeId){
    const response = await fetch(`${controller}/qrlogin`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            StoreAccountId: storeId,
            QRData: qrData
        })
    });
    return response;
}

export async function requestPWRecoveryAPI(email, userType) {
    const response = await fetch(`${controller}/forgotpw`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email, 
            UserType: userType
        })
    })
    return response
}

export async function passwordRecoveryAPI(email, newPassword, code, userType){
    const response = await fetch(`${controller}/confirmforgotpw`,{
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
            Email: email,
            NewPassword: newPassword,
            Code: code,
            UserType: userType
        })
    })
    return response
}
