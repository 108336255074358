import { baseURL, baseWeb } from '../base';

const controller = `${baseURL}/quickpay/v1`

export async function testQuickPayAPI(request){
    const response = await fetch(`${controller}/testpay`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(request)
    });
    return response;
}

export async function quickPayAPI(request){
    const response = await fetch(`${controller}/pay`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(request)
    });
    return response;
}

export async function sendReceiptAPI(request, orderNo){
    const response = await fetch(`${controller}/receipt/${orderNo}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(request)
    });
    return response;
}