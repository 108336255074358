import React, { useState } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Fragment } from 'react'
import { CircularProgress } from '@material-ui/core'
import { createAccount } from '../../api/sockets/user/userRequests'
import { setHomeAccountId } from '../../api/auth/session'
import StateDropdown  from '../utility/stateDropdown'

export const CreateAccount = ({socket, notify, setSelectedPage}) => {

    const defaultAccount = {
        Address2: '',
        TimeDataJSON: ''
    }

    const [newAccount, setNewAccount] = useState(defaultAccount)
    const [loading, setLoading] = useState(false)
    const isSmall = useMediaQuery('(max-width:525px)')

    const updateProp = (x, propName) => {
        const clone = Object.assign({}, newAccount)
        clone[propName] = x
        setNewAccount(clone)
    }

    const validateAndCreate = (e) => {
        e.preventDefault()

        if (isNaN(Number(newAccount.HousePercentage))){
            notify('House Percentage Required')
            return
        }

        console.log(newAccount)
        setLoading(true)
        socket.props.setLoading = setLoading
        socket.props.notify = notify
        socket.props.createAccountSuccess = (response) => {
            setHomeAccountId(response.AccountId)
            setSelectedPage('')
            window.location.reload() // Causing an invalid socket error without this
        }

        // send request via websocket
        createAccount(newAccount, socket)
    }

    return (
        <Fragment>
            {loading
            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
            : <Form onSubmit={validateAndCreate}>
                <Row>
                    <Col xs={0} md={1}></Col>
                    <Col>
                        <h2>
                            Create Store Account
                        </h2>
                    </Col>
                </Row>
                <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end', marginTop: '30px'}}>
                    <Col xs={0} md={1}></Col>
                    <Form.Label column xs={4} sm={2}>
                        Store Name
                    </Form.Label>
                    <Col xs={7} sm={3}>
                        <Form.Control value={newAccount.Name || ''} onChange={(e) => updateProp(e.target.value, 'Name')} required maxLength={200} />
                    </Col>
                </Form.Group>
                <Row style={{marginTop: '30px'}}>
                    <Col xs={0} md={2}></Col>
                    <Col>
                        <h4>Address Information</h4>
                    </Col>
                </Row>
                <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end'}}>
                    <Col xs={0} md={1}></Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        Address
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newAccount.Address1 || ''} onChange={(e) => updateProp(e.target.value, 'Address1')} required maxLength={200} />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        Address 2
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newAccount.Address2 || ''} onChange={(e) => updateProp(e.target.value, 'Address2')} maxLength={200} />
                    </Col>
                    <Col xs={0} md={1}></Col>
                    <Col xs={0} md={1}></Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        City
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newAccount.City || ''} onChange={(e) => updateProp(e.target.value, 'City')} required maxLength={200} />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        State
                    </Form.Label>
                    <Col xs={3} md={2} lg={1}>
                        <StateDropdown value={newAccount.State} onChange={updateProp} propName='State'></StateDropdown>
                    </Col>
                    <Col xs={0} md={2} lg={3}></Col>
                    <Col xs={0} md={1}></Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        Zip Code
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newAccount.ZipCode || ''} onChange={(e) => updateProp(e.target.value, 'ZipCode')} required maxLength={20} type="number" />
                    </Col>
                </Form.Group>   
                <Row style={{marginTop: '30px'}}>
                    <Col xs={0} md={2}></Col>
                    <Col>
                        <h4>Payment Information</h4>
                    </Col>
                </Row>
                <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end'}}>
                    <Col xs={0} md={1}></Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        Payment Type
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control as="select" value={newAccount.PaymentType || 0} onChange={(e) => updateProp(e.target.value, 'PaymentType')}>
                            <option value={0}>-- Payment Type --</option>
                            <option value={1}>CardConnect</option>
                            {/* 
                            <option value={2}>Square</option>
                            <option value={3}>Stripe</option>
                            <option value={4}>PayPal</option>
                             */}
                        </Form.Control>
                    </Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        House Percentage
                    </Form.Label>
                    <Col xs={5} md={2}>
                        <Form.Control value={newAccount.HousePercentage || ''} onChange={(e) => updateProp(e.target.value, 'HousePercentage')} type="number" maxLength={8} />
                    </Col>         
                    <Col xs={0} md={2}></Col>
                    <Col xs={0} md={1}></Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        Payment Public Key
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newAccount.PaymentPublicKey || ''} onChange={(e) => updateProp(e.target.value, 'PaymentPublicKey')} />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                        Payment Private Key
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newAccount.PaymentPrivateKey || ''} onChange={(e) => updateProp(e.target.value, 'PaymentPrivateKey')} />
                    </Col>
                </Form.Group>

                <Row style={{justifyContent: 'center', marginTop: isSmall ? '0px' : '30px', marginBottom: '10px'}}>
                    <Button type="submit">Create Account</Button>
                </Row>
            </Form>
            }
        </Fragment>
    )
}