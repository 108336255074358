const orderItemStatus = {
    Unknown: 0,
    Paid: 1,
    Voided: 2,
    Refunded: 3,
    AwaitingPayment: 4
}

export default orderItemStatus

export const orderItemStatusLookup = {
    0: 'Unknown',
    1: 'Paid',
    2: 'Voided',
    3: 'Refunded',
    4: 'Awaiting Payment',
}