import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, Button, Form, Card } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { listCombos } from '../../api/sockets/combos/comboRequests'
import activeFilter from '../../enums/activeFilter'
import { ComboCard } from '../combos/comboCard'
import { loadProductCategories } from '../../api/sockets/account/accountRequests' 



export const ComboList = ({ socket, notify, setCreateCombo, setComboId, productCategories, setProductCategories }) => {
    const isSmall = useMediaQuery('(max-width:650px)')
    const isBig = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    // ComboList
    const [comboList, setComboList] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(activeFilter.Active)
    const [currentKey, setCurrentKey]  = useState('')


    useEffect(() => {
        if (socket) {
            if (!comboList) {
                reloadCombos()
            }
            if (!productCategories){
                reloadProductCategories()
            }
        }
    }, [socket])

    const reloadCombos = (activeFilter) => {
        if (!activeFilter) {
            activeFilter = filter
        }

        setLoading(true)
        socket.props.setLoading = setLoading
        socket.props.onListCombosFailure = notify
        socket.props.onListCombosSuccess = (response) => {
            // TODO: Categorize them by product Category
            setComboList(response.Combos)
        }

        const request = {
            ActiveFilter: activeFilter
        }
        listCombos(request, socket.socket)
    }

    const reloadProductCategories = () => {
        setLoading(true)
        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onListCategorySuccess = (results) => {
            setProductCategories(results)
        }

        const request = {
            ActiveFilter: activeFilter.Active
        }
        loadProductCategories(request, socket)
    }


    return (
        <Fragment>
            <Row style={{ marginBottom: '10px' }}>
                <Col lg={1} />
                <Col>
                    <h4>Combos</h4>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'flex-end' }}>
                <Col xs={10} sm lg={3} style={{ textAlign: 'end', paddingRight: isSmall ? '0' : '1rem' }}>
                    <Button style={{ marginBottom: '10px' }} onClick={() => setCreateCombo(true)}>Create New</Button>
                </Col>
                <Col xs={10} sm lg={3} style={{ paddingRight: isBig ? '4rem' : isSmall ? '0' : '1rem' }}>
                    <Form.Control as="select" value={filter} onChange={(e) => { setFilter(e.target.value); reloadCombos(e.target.value) }}>
                        <option value={activeFilter.Active}>Active</option>
                        <option value={activeFilter.Inactive}>Inactive</option>
                        <option value={activeFilter.Both}>All</option>
                    </Form.Control>
                </Col>
            </Row>
            <hr />
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : (
                    comboList && Boolean(comboList.length) &&
                    <Fragment>
                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <div style={{
                                    display: 'grid',
                                    gridAutoRows: '1fr',
                                    gridTemplateColumns: `repeat(${isXLarge ? '4' :
                                        isSmall ? '1' :
                                            isBig ? '3' : '2'
                                        }, 1fr)`,
                                    gridColumnGap: '5px',
                                    gridRowGap: '5px'
                                }}>
                                    {Boolean(comboList?.length) && comboList.map(item => {
                                        return (
                                            <ComboCard
                                                key={item.ComboId}
                                                combo={{ ComboId: item.ComboId, ImageLocation: item.ImageLocation, Name: item.Name, Enabled: item.Enabled }}
                                                isSmall={isSmall}
                                                setComboId={setComboId}
                                            />)
                                    })}
                                </div>
                            </Card.Body>
                        </Card>
                    </Fragment>
                )}
        </Fragment>
    )
}