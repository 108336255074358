import React, { useState, Fragment } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { ScrollTabPanel } from '../utility/scrollTabPanel'
import { Product } from './product'
import { CombosHome } from '../combos/combosHome'


export const ProductHome = ({ socket, notify, setSelectedPage, selectedPage }) => {
    const isSmall = useMediaQuery('(max-width:525px)')

    const [tab, setTab] = useState(0)

    const getTabProps = (i) => {
        return {
            id: `scrollable-auto-tab-${i}`,
            'aria-controls': `scrollable-auto-tabpanel-${i}`
        }
    }
    
    return (
        <Fragment>
            <AppBar position="static" color="default"
                style={{
                    marginLeft: isSmall ? '-4vw' : '',
                    width: isSmall ? '87vw' : '',
                    background: 'linear-gradient(transparent, #f5f5f5)',
                    boxShadow: '0px 6px 6px 0px rgb(0 0 0 / 8%)'
                }}
            >
                <Tabs
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ outline: 'none' }}
                >
                    <Tab label="Products" {...getTabProps(0)} />
                    <Tab label="Combos" {...getTabProps(1)} />
                </Tabs>
            </AppBar>
            <ScrollTabPanel value={tab} index={0}>
                <Product 
                    socket={socket} 
                    notify={notify}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage} 
                />
            </ScrollTabPanel>
            <ScrollTabPanel value={tab} index={1}>
                <CombosHome
                    socket={socket}
                    notify={notify}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                />  
            </ScrollTabPanel>
        </Fragment>
    )
}