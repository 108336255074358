import React, { Fragment, useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Row, Table } from 'react-bootstrap'
import { customerHistoryDetail as customerHistoryDetail } from '../../api/sockets/order/orderRequests'
import { orderItemStatusLookup } from '../../enums/orderItemStatus'

export const CustomerHistoryDetail = ({ props }) => {
    const { orderId, socket, notify, storeId } = props
    const [loading, setLoading] = useState(false)
    const [orderDetail, setOrderDetail] = useState(null)


    useEffect(() => {
        if (socket && socket.socket.readyState === WebSocket.OPEN && !orderDetail) {
            reloadDetail()
        }
    }, [orderDetail, socket])

    const reloadDetail = () => {
        setLoading(true)

        socket.props.notify = notify
        socket.props.setLoading = setLoading

        socket.props.onSuccess = (results) => {
            setOrderDetail(results)
        }

        const request = {
            OrderId: orderId,
            StoreAccountId: storeId
        }

        customerHistoryDetail(request, socket.socket)
    }

    return (
        <Fragment>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                :
                <Row style={{ marginBottom: '30px' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Quantity</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Price</th>
                                <th>Tax</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetail && orderDetail.OrderItems && orderDetail.OrderItems.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{x.Quantity}</td>
                                        <td>{x.Name}</td>
                                        <td>{x.Status && orderItemStatusLookup[x.Status]}</td>
                                        <td>{x.Price && `$${x.Price.toFixed(2)}`}</td>
                                        <td>{x.Tax && `$${x.Tax.toFixed(2)}`}</td>
                                        <td>{x.Total && `$${x.Total.toFixed(2)}`}</td>
                                    </tr>
                                )
                            }
                            )}
                        </tbody>
                    </Table>
                </Row>
                        }
        </Fragment>
    )


}