import React, { useState, Fragment, useEffect, useRef } from 'react'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { updateAccount, viewAccountDetail } from '../../api/sockets/account/accountRequests'
import { StoreLogoUploader } from '../account/utility/storeLogoUploader'
import StateDropdown from '../utility/stateDropdown'

export const AccountSettings = ({ props, socket, notify }) => {
    const [loading, setLoading] = useState(true)
    const [accountDetails, setAccountDetails] = useState(null)
    const [priorState, setPriorState] = useState(null)
    const mainForm = useRef(null)
    const isSmall = useMediaQuery('(max-width:768px)')

    const requiredFields = ["Name", "HousePercentage", "Address1", "City", "State", "ZipCode"]

    useEffect(() => {
        if (accountDetails === null && socket !== null) {
            reloadAccountDetails()
        }
        if (socket === null) {
            notify('Error', 'Network Error. Please try again later.') // Reconnect?
        }
    }, [accountDetails])

    const reloadAccountDetails = () => {
        setLoading(true)
        socket.props.notify = notify
        socket.props.onAccountDetailsSuccess = (response) => {
            setAccountDetails(response)
            setPriorState(response)

        }
        socket.props.setLoading = setLoading
        viewAccountDetail({}, socket)
    }

    const validateAndUpdate = (e) => {
        e.preventDefault()

        const request = {
            Name: accountDetails.Name,
            HousePercentage: accountDetails.HousePercentage,
            Address1: accountDetails.Address1,
            Address2: accountDetails.Address2,
            State: accountDetails.State,
            City: accountDetails.City,
            ZipCode: accountDetails.ZipCode,
            Timezone: accountDetails.Timezone,
            ObservesDST: accountDetails.ObservesDST,
            CurrencyCode: accountDetails.CurrencyCode,
            TimeDataJSON: accountDetails.TimeDataJSON,
            ActiveAccountKeyId: accountDetails.ActiveAccountKeyId
        }
        socket.props.onUpdateSuccess = () => {
            setPriorState(accountDetails)
        }
        socket.props.notify = notify
        
        updateAccount(request, socket)
    }

    const updateProp = (val, field) => {
        const clone = Object.assign({}, accountDetails)
        clone[field] = val
        setAccountDetails(clone)
    }

    const saveAccountDetails = (val, field) => {
        //TODO: Checks and X's next to input for input validation (See Sign Up)
        if (requiredFields.includes(field) && val === '') {
            notify('Error', `${field.replace(/[0-9]/g, '').match(/[A-Z][a-z]+/g).join(" ")} is required.`) // Regex replace Address1, join to break up words
            return
        }
        if (priorState[field] !== accountDetails[field]) {
            mainForm.current.click()
        }
    }

    return <Fragment>
        {loading
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
            : accountDetails &&
            <div>
                <Row>
                    <Col lg={1}></Col>
                    <Col>
                        <h4>Settings</h4>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={validateAndUpdate} onKeyDown={(e) => e.key === 'Enter' && e.target.type !== 'textarea' ? e.preventDefault() : '' }>
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Account Name</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control value={accountDetails.Name} maxLength={200} onChange={(e) => updateProp(e.target.value, 'Name')} onBlur={(e) => saveAccountDetails(e.target.value, 'Name')}></Form.Control>
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>House Percentage</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            {/* Todo: Validate input or put a mask on here, and format to 1.00 */}
                            <Form.Control type="number" required value={accountDetails.HousePercentage} max="100" maxLength={200} onChange={(e) => updateProp(e.target.value, 'HousePercentage')} onBlur={(e) => { saveAccountDetails(e.target.value, 'HousePercentage') }}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Address</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control value={accountDetails.Address1} maxLength={200} onChange={(e) => updateProp(e.target.value, 'Address1')} onBlur={(e) => { saveAccountDetails(e.target.value, 'Address1') }}></Form.Control>
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Payment Key</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control as="select" value={accountDetails.ActiveAccountKeyId || 0} maxLength={1} onChange={(e) => updateProp(e.target.value, 'ActiveAccountKeyId')} onBlur={(e) => { saveAccountDetails(e.target.value, 'ActiveAccountKeyId') }}>
                                {accountDetails && accountDetails.AccountKeys && accountDetails.AccountKeys.map(x => {
                                    return <option value={x.AccountKeyId} key={x.AccountKeyId}>{x.Name}</option>
                                })}
                                {/* <option value={0}>-- Payment Type --</option>
                                <option value={1}>CardConnect</option> */}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Apt/Suite</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control value={accountDetails.Address2 || ''} maxLength={200} onChange={(e) => updateProp(e.target.value, 'Address2')} onBlur={(e) => { saveAccountDetails(e.target.value, 'Address2') }}></Form.Control>
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>City</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control value={accountDetails.City || ''} maxLength={200} onChange={(e) => updateProp(e.target.value, 'City')} onBlur={(e) => { saveAccountDetails(e.target.value, 'City') }}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>State</Form.Label>
                        {/* TODO: Dropdown all 50 states.*/}
                        <Col xl={2} lg={3} md={3}>
                            <StateDropdown value={accountDetails.State} onChange={updateProp} onBlur={saveAccountDetails} propName='State'></StateDropdown>
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Zip Code</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                            <Form.Control value={accountDetails.ZipCode || ''} maxLength={20} onChange={(e) => updateProp(e.target.value, 'ZipCode')} onBlur={(e) => { saveAccountDetails(e.target.value, 'ZipCode') }}></Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Time Zone</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                        <Form.Control as="select" value={accountDetails.Timezone} onChange={(e) => updateProp(e.target.value, 'Timezone')} onBlur={(e) => { saveAccountDetails(e.target.value, 'Timezone') }}>
                                <option value={0}>UTC</option>
                                <option value={-5}>Eastern</option>
                                <option value={-6}>Central</option>
                                <option value={-7}>Mountain</option>
                                <option value={-8}>Pacific</option>
                            </Form.Control>
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Currency Code</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                        <Form.Control as="select" value={accountDetails.CurrencyCode} onChange={(e) => updateProp(e.target.value, 'CurrencyCode')} onBlur={(e) => { saveAccountDetails(e.target.value, 'CurrencyCode') }}>
                                <option value={'USD'}>USD</option>
                                <option value={'CAD'}>CAD</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Observes Day Light Savings?</Form.Label>
                        <Col xl={2} lg={3} md={3}>
                        <Form.Control as="select" value={accountDetails.ObservesDST} onChange={(e) => updateProp(e.target.value, 'ObservesDST')} onBlur={(e) => { saveAccountDetails(e.target.value, 'ObservesDST') }}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Form.Control>
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Store Logo</Form.Label>
                            <StoreLogoUploader socket={socket} existingPath={accountDetails.LogoPath || null}/>
                    </Form.Group>
                    {/* TODO: Store hours using TimeDataJSON */}
                    <Button style={{ display: 'none' }} ref={mainForm} type="submit"></Button>
                </Form>
            </div>}
    </Fragment>
}



