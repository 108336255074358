import React, { useState, useEffect, Fragment } from 'react'
import { Form, Row, Col, Button, Table } from 'react-bootstrap'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Tooltip from '@material-ui/core/Tooltip'
import { getOperatorOrderDetail, acknowledgeOrder } from '../../../../api/sockets/operator/operatorRequests'
import orderStatus, { orderStatusLookup } from '../../../../enums/orderStatus'
import orderItemStatus, { orderItemStatusLookup } from '../../../../enums/orderItemStatus'
import { altUtcStringToLocal } from '../../../../utility/dateHelper'
import { refundOrderItem, refundOrder } from '../../../../api/sockets/account/accountRequests'
import CopyIcon from '@material-ui/icons/Assignment'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import { OperatorUpdateOrder } from '../operatorUpdateOrder'
import { OperatorReceiveCash } from '../operatorReceiveCash'
import { OperatorUseTerminal } from '../operatorUseTerminal'
import { OperatorRecipe } from './operatorRecipe'
import { OperatorAddDiscount } from '../operatorAddDiscount'
import operatorPages from '../../../../enums/operatorPages';

const useStyles = makeStyles({
    clipBoard: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    },
})

export const OperatorOrderDetail = ({ props }) => {
    const { socket, notify, orderId, customerId, setSelectedPage, setOrderList } = props
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:700px)')
    const [loading, setLoading] = useState(true)
    const [orderDetail, setOrderDetail] = useState(null)
    // Popup info
    const [showUpdate, setShowUpdate] = useState(null)
    const [showCash, setShowCash] = useState(false)
    const [showReader, setShowReader] = useState(false)
    const [showManualReader, setShowManualReader] = useState(false)
    const [info, setInfo] = useState(null)
    const [showRecipe, setShowRecipe] = useState(false)
    const [recipeInfo, setRecipeInfo] = useState(null)
    const [showDiscount, setShowDiscount] = useState(false)
    const updateStatuses = [orderStatus.AcknowledgedByClub, orderStatus.ReadyForPickup, orderStatus.AwaitingPayment, orderStatus.CanceledByClub]
    const statusCantRefund = [orderStatus.Unknown, orderStatus.AwaitingPayment, orderStatus.FullyRefunded, orderStatus.Voided]
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    useEffect(() => {
        if (socket !== null && orderDetail === null) {
            reloadDetail()
        }
    }, [socket, orderDetail])


    const reloadDetail = (successCallback) => {
        setLoading(true)

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onDetailSuccess = (results) => {
            console.log("DETAILS", results)
            const statusChanged = orderDetail && orderDetail.Status !== results.Status
            setOrderDetail(results)
            let infoData = {
                CustomerId: customerId,
                OrderId: orderId,
                Amount: results.ChargedAmount,
                ...results
            }
            setInfo(infoData)

            const orders = JSON.parse(sessionStorage.getItem('OPERATOR_ORDERS')) || []
            const existingOrder = orders.filter(x => x.OrderId === orderId)[0]
            if (existingOrder) {
                existingOrder.Status = results.Status
                setOrderList(orders)
            }
            statusChanged && successCallback && successCallback()
        }
        const requestData = {
            OrderId: orderId,
            CustomerId: customerId
        }
        getOperatorOrderDetail(requestData, socket.socket)
    }

    const refundItem = (itemId) => {
        const matchedItem = orderDetail && orderDetail.OrderItems.filter(x => x.OrderItemId === itemId)[0]
        if (!matchedItem) {
            return
        }

        const message = `Are you sure you want to refund the ${matchedItem.Name} for $${matchedItem.Total.toFixed(2)}?`
        // Lazy - should be using a custom modal but timing. I broke out into a callback for easy migration later
        if (window.confirm(message)) {
            refundItemConfirmed(itemId)
        }
    }

    const refundItemConfirmed = (itemId) => {
        const requestData = {
            CustomerId: customerId,
            OrderId: orderId,
            OrderItemId: itemId
        }

        socket.props.onRefundFailure = (msg) => {
            notify('Refund Error', msg)
        }
        socket.props.onRefundSuccess = (data) => {
            // Lazy we have the data to update without reloading
            reloadDetail()
        }

        refundOrderItem(requestData, socket)
    }

    const refundOrderHandler = () => {
        if (!orderDetail) {
            return
        }
        const message = `Are you sure you want to refund the full order?`
        // Lazy - should be using a custom modal but timing. I broke out into a callback for easy migration later
        if (window.confirm(message)) {
            refundOrderConfirmed()
        }
    }

    const refundOrderConfirmed = () => {
        if (!orderDetail) {
            return
        }
        
        const requestData = {
            CustomerId: customerId,
            OrderId: orderId,
            CustomAmount: null
        }

        socket.props.onRefundFailure = (msg) => {
            notify('Refund Error', msg)
        }
        socket.props.onRefundSuccess = (data) => {
            // Lazy we have the data to update without reloading
            reloadDetail()
        }

        refundOrder(requestData, socket)
    }

    const handleAcknowledge = (e) => {
        if (!orderDetail) {
            console.log("order Detail is null")
            return
        }

        e.stopPropagation()

        console.log(`order Detail OrderId ${orderId} customerId ${customerId}`)

        const requestData = {
            OrderId: orderId,
            CustomerId: customerId
        }

        socket.props.onUpdateFailure = (msg) => {
            notify('Acknowledge Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            reloadDetail()
        }

        acknowledgeOrder(requestData, socket.socket)
    }


    const handleRecipeClick = (orderItem) => {
        if (orderItem.Recipe) {
            const recipe = {
                Name: orderItem.Name,
                ImageLocation: orderItem.ImageLocation,
                Recipe: orderItem.Recipe,
            }
            setRecipeInfo(recipe)
            setShowRecipe(true)
        }
    }

    const copyToClipBoard = (field) => {
        navigator.clipboard.writeText(orderDetail[field])
        notify('Success', 'Copied to clipboard')
    }


    return (
        <Fragment>
            {showUpdate && <OperatorUpdateOrder socket={socket} notify={notify} show={showUpdate} setShow={() => { setShowUpdate(null); reloadDetail(() => setSelectedPage(operatorPages.orders)) }} setShowCash={setShowCash} setShowReader={setShowReader} setShowDiscount={setShowDiscount} setShowManualReader={setShowManualReader} />}
            {showUpdate && showCash && <OperatorReceiveCash socket={socket} notify={notify} show={showCash && showUpdate} setShow={setShowCash} setSelectedPage={setSelectedPage} />}
            {showUpdate && showReader && <OperatorUseTerminal socket={socket} notify={notify} show={showReader && showUpdate} setShow={setShowReader} setSelectedPage={setSelectedPage} manualEnter={false} />}
            {showUpdate && showManualReader && <OperatorUseTerminal socket={socket} notify={notify} show={showManualReader && showUpdate} setShow={setShowManualReader} setSelectedPage={setSelectedPage} manualEnter={true} />}
            {showRecipe && recipeInfo && <OperatorRecipe show={showRecipe && recipeInfo} recipeInfo={recipeInfo} setShow={() => { setShowRecipe(null); setRecipeInfo(null) }} />}
            {showUpdate && showDiscount && <OperatorAddDiscount socket={socket} notify={notify} show={showDiscount && showUpdate} setShow={setShowDiscount} setSelectedPage={setSelectedPage} />}
            <Row style={{ marginBottom: '30px', marginTop: '1rem' }}>
                <Col xs={0} lg={1}></Col>
                <Col lg={6} md={5}>
                    <h2>
                        Order Detail
                    </h2>
                </Col>
                <Col xs={5} md={3} lg={2} style={{ textAlign: 'right' }}>
                    {orderDetail
                        ? orderDetail.Status === orderStatus.SentToClub
                            ? <Button variant="success" onClick={(e) => handleAcknowledge(e)}>Acknowledge</Button>
                            : updateStatuses.includes(orderDetail.Status)
                                ? <Button onClick={(e) => { e.stopPropagation(); setShowUpdate(info) }}>Update</Button>
                                : ''
                        : ''
                    }
                </Col>
                <Col xs={5} md={4} lg={3}>
                    {orderDetail 
                    && !statusCantRefund.includes(orderDetail.Status) 
                    && <Button variant="danger" onClick={refundOrderHandler}>Refund Total Order</Button> }
                </Col>
            </Row>
            <Form.Group as={Row} style={{ alignItems: 'center', textAlign: 'end' }}>
                <Form.Label column xs={4} md={2}>
                    First Name
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.FirstName)} />
                </Col>
                <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("FirstName")} color="primary" />
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Last Name
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.LastName) || ''} />
                </Col>
                <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("LastName")} color="primary" />
                <Col xs={0} md={1}></Col>
                <Form.Label column xs={4} md={2}>
                    Charged Amount
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && (moneyFormat(orderDetail.ChargedAmount?.toFixed(2)))) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                    Phone
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.Phone) || ''} />
                </Col>
                <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("Phone")} color="primary" />
                <Col xs={0} md={1}></Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Customer Email
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.Email) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                    Order Time
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && altUtcStringToLocal(orderDetail.OrderDate)) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Order Number
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.OrderNo) || ''} />
                </Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                    Pickup Time
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && altUtcStringToLocal(orderDetail.PickupTime)) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Status
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderStatusLookup[orderDetail.Status]) || ''} />
                </Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                    Tip Amount
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && moneyFormat(orderDetail.TipAmount)) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Refunded Amount
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && moneyFormat(orderDetail.RefundedAmount)) || ''} />
                </Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                    Acknowledged By
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.AcknowledgedBy) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Comments
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control as="textarea" rows={3} readOnly value={(orderDetail && orderDetail.Comments) || ''} style={{ resize: 'none' }} />
                </Col>
                <Col xs={0} md={1}></Col>
            </Form.Group>

            <Row style={{ marginBottom: '30px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Order Items
                    </h2>
                </Col>
            </Row>

            <Row style={{ marginBottom: '30px' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Tax</th>
                            <th>Total</th>
                            <th>Refund Item</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetail && orderDetail.OrderItems && orderDetail.OrderItems.map((x, i) => {
                            return (
                                <Fragment key={i}>
                                    <tr>
                                        <td rowSpan="2" style={{ verticalAlign: 'middle', borderBottom: '1px solid #949494' }}>{x.Name}
                                            <Tooltip title={x.Recipe ? "See the recipe" : "Recipe Unavaliable"} arrow>
                                                <MenuBookIcon color={x.Recipe ? "primary" : "error"} onClick={() => { handleRecipeClick(x) }} />
                                            </Tooltip>
                                        </td>
                                        <td>{x.Status && orderItemStatusLookup[x.Status]}</td>
                                        <td>{x.Price && `$${x.Price.toFixed(2)}`}</td>
                                        <td>{x.Quantity}</td>
                                        <td>{x.Tax && `$${x.Tax.toFixed(2)}`}</td>
                                        <td>{x.Total && `$${x.Total.toFixed(2)}`}</td>
                                        <td>
                                            {x.Status !== orderItemStatus.Refunded 
                                            && !statusCantRefund.includes(orderDetail.Status) 
                                            && <Button variant="danger" onClick={() => refundItem(x.OrderItemId)}>Refund</Button>}
                                        </td>
                                    </tr>
                                    <tr style={{ marginBottom: '5px' }}>
                                        <td colSpan="3" style={{ borderRight: '1px solid #949494', borderBottom: '1px solid #949494' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span>Addons:</span>
                                                <ul style={{ listStyleType: 'circle' }}>
                                                    {x.Addons.map(y => {
                                                        const match = orderDetail.Ingredients.filter(z => z.IngredientId === y.AddonIngredientId)[0]
                                                        if (!match) {
                                                            return ''
                                                        }
                                                        return <li key={y.AddonIngredientId}>{match.Name}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </td>
                                        <td colSpan="3" style={{ borderBottom: '1px solid #949494' }}>
                                            <div style={{ display: 'flex' }}>
                                                <span>Without:</span>
                                                <ul style={{ listStyleType: 'circle' }}>
                                                    {x.WithoutIngredientIds.map(y => {
                                                        const match = orderDetail.Ingredients.filter(z => z.IngredientId === y)[0]
                                                        if (!match) {
                                                            return ''
                                                        }
                                                        return <li key={y}>{match.Name}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        }
                        )}
                    </tbody>
                </Table>
            </Row>
        </Fragment>
    )
}