import React from 'react'
import { Ballot, PeopleAlt, LocalAtm } from '@material-ui/icons'
import { LeftNavTab } from './leftNavTab'
import operatorPages from '../../enums/operatorPages'

export const LeftOperatorNav = ({props}) => {
    
    return <div style={{display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        <LeftNavTab
            Icon={PeopleAlt}
            text="Customers"
            selectKey={operatorPages.customers}
            selector={props.setSelectedPage}
            selected={props.selectedPage}
            count={(props.newCustomers && props.newCustomers.length) || 0}
        />
        <LeftNavTab
            Icon={Ballot}
            text="Orders"
            selectKey={operatorPages.orders}
            selector={props.setSelectedPage}
            selected={props.selectedPage}
            count={(props.newOrders && props.newOrders.length) || 0}
        />
        <LeftNavTab
            Icon={LocalAtm}
            text="Register"
            selectKey={operatorPages.register}
            selector={props.setSelectedPage}
            selected={props.selectedPage}
        />

    </div>
}