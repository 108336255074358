import React from 'react'
import { Modal, Container, Card } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core';
import { productImageBase } from '../../../../api/base'

const useStyles = makeStyles({
    backdrop: {
        backgroundColor: 'transparent',
    }
})


export const OperatorRecipe = ({show, setShow, recipeInfo }) => {
    const classes = useStyles()
    const defaultImageLocation = 'missingimage.jpg'
    let modifiedLocation = recipeInfo.ImageLocation
    if (modifiedLocation != null){
        modifiedLocation = `${productImageBase}${modifiedLocation}`
    }


    return (
        <Container>
            <Modal show={Boolean(show)} backdropClassName={classes.backdrop} centered onHide={() => setShow(false)}>
                <Modal.Header>
                    <Modal.Title>{recipeInfo.Name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Img variant="top" style={{width: '150px', height: 'auto'}}  src={modifiedLocation || defaultImageLocation} />
                        <Card.Body>
                            <Card.Text className="recipe" style={{content: '\a', whiteSpace: 'pre'}}>
                                    {recipeInfo.Recipe}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </Container>
    )
}