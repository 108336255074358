import React, { useState, useEffect, Fragment } from 'react'
import { Form, Row, Col, Button, Table, InputGroup } from 'react-bootstrap'

import { AccountOrderDetail } from '../../api/sockets/order/orderRequests'
import orderStatus, { orderStatusLookup } from '../../enums/orderStatus'
import orderItemStatus, { orderItemStatusLookup } from '../../enums/orderItemStatus'
import { altUtcStringToLocal } from '../../utility/dateHelper'
import { refundOrderItem, refundOrder } from '../../api/sockets/account/accountRequests'
import userPages from '../../enums/userPages'
import { ArrowBackIos, People } from '@material-ui/icons'

export const OrderHistoryDetail = ({ props }) => {
    const { socket, notify, orderId, showOrderId, customerId, back, reloadOrderHistory, setSelectedPage } = props

    const [loading, setLoading] = useState(true)
    const [orderDetail, setOrderDetail] = useState(null)

    useEffect(() => {
        if (socket !== null && orderDetail === null) {
            reloadDetail()
        }
    }, [socket, orderDetail])


    const reloadDetail = () => {
        setLoading(true)

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onDetailSuccess = (results) => {
            console.log("Order Detail set")
            setOrderDetail(results)
        }
        const requestData = {
            OrderId: orderId,
            CustomerId: customerId
        }
        AccountOrderDetail(requestData, socket)
    }

    const refundItem = (itemId) => {
        const matchedItem = orderDetail && orderDetail.OrderItems.filter(x => x.OrderItemId === itemId)[0]
        if (!matchedItem){
            return
        }

        const message = `Are you sure you want to refund the ${matchedItem.Name} for $${matchedItem.Total.toFixed(2)}?`
        // Lazy - should be using a custom modal but timing. I broke out into a callback for easy migration later
        if (window.confirm(message)){
            refundItemConfirmed(itemId)
        }
    }

    const refundItemConfirmed = (itemId) => {
        const requestData = {
            CustomerId: orderDetail.CustomerId,
            OrderId: orderDetail.OrderId,
            OrderItemId: itemId
        }

        socket.props.onRefundFailure = (msg) => {
            notify('Refund Error', msg)
        }
        socket.props.onRefundSuccess = (data) => {
            // Lazy we have the data to update without reloading
            reloadDetail()
        }

        refundOrderItem(requestData, socket)
    }

    const refundOrderHandler = () => {
        if (!orderDetail){
            return
        }
        const message = `Are you sure you want to refund the full order?`
        // Lazy - should be using a custom modal but timing. I broke out into a callback for easy migration later
        if (window.confirm(message)){
            refundOrderConfirmed()
        }
    }

    const refundOrderConfirmed = () => {
        if (!orderDetail){
            return
        }

        const requestData = {
            CustomerId: orderDetail.CustomerId,
            OrderId: orderDetail.OrderId,
            CustomAmount: null
        }

        socket.props.onRefundFailure = (msg) => {
            notify('Refund Error', msg)
        }
        socket.props.onRefundSuccess = (data) => {
            // Lazy we have the data to update without reloading
            reloadDetail()
        }

        refundOrder(requestData, socket)
    }

    return (
        <Fragment>
            <Row style={{ marginBottom: '30px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        <ArrowBackIos style={{fontSize: '2rem', cursor: 'pointer'}} onClick={back} />
                        Order Detail
                    </h2>
                </Col>
                {orderDetail && orderDetail.Status !== orderStatus.Refunded && orderDetail.Status !== orderStatus.Voided && (
                    <Col xs={4} lg={2}>
                        <Button variant="danger" onClick={refundOrderHandler}>Refund Total Order</Button>
                    </Col>
                )}
            </Row>

            <Form.Group as={Row} style={{ alignItems: 'center', textAlign: 'end' }}>
                <Form.Label column xs={4} sm={2}>
                    Charged Amount
                </Form.Label>
                <Col xs={7} sm={3}>
                    <Form.Control readOnly value={(orderDetail && `$${orderDetail.ChargedAmount?.toFixed(2)}`) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Comments
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control as="textarea" rows={3} readOnly value={(orderDetail && orderDetail.Comments) || ''} style={{resize: 'none'}} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Customer Email
                </Form.Label>
                <Col xs={7} md={3}>
                    <InputGroup>
                        <Form.Control readOnly value={(orderDetail && orderDetail.Email) || ''} />
                        <InputGroup.Append style={{cursor: 'pointer'}} onClick={() => setSelectedPage(`${userPages.CustomerDetail}${orderDetail.CustomerId}`)}>
                            <InputGroup.Text>
                                <People style={{color: '#3f51b5'}} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Order Time
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && altUtcStringToLocal(orderDetail.OrderDate)) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Account Order Time
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.OrderDateLocal) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Order Number
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.OrderNo) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Pickup Time
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.PickupTime) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Status
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderStatusLookup[orderDetail.Status]) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Tip Amount
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && `$${orderDetail.TipAmount?.toFixed(2)}`) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Refunded Amount
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && `$${orderDetail.RefundedAmount?.toFixed(2)}`) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Acknowledged By
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(orderDetail && orderDetail.AcknowledgedBy) || ''} />
                </Col>
            </Form.Group>

            <Row style={{ marginBottom: '30px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Order Items
                    </h2>
                </Col>
            </Row>

            <Row style={{ marginBottom: '30px' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Tax</th>
                            <th>Total</th>
                            <th>Refund Item</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetail && orderDetail.OrderItems && orderDetail.OrderItems.map((x, i) => {
                            return (
                                <Fragment key={i}>
                                    <tr>
                                        <td rowSpan="2" style={{verticalAlign: 'middle'}}>{x.Name}</td>
                                        <td>{x.Status && orderItemStatusLookup[x.Status]}</td>
                                        <td>{x.Price && `$${x.Price.toFixed(2)}`}</td>
                                        <td>{x.Quantity}</td>
                                        <td>{x.Tax && `$${x.Tax.toFixed(2)}`}</td>
                                        <td>{x.Total && `$${x.Total.toFixed(2)}`}</td>
                                        <td>
                                            {x.Status !== orderItemStatus.Refunded && x.Status !== orderItemStatus.Voided && <Button variant="danger" onClick={() => refundItem(x.OrderItemId)}>Refund</Button>}
                                        </td>
                                    </tr>
                                    <tr style={{marginBottom: '5px'}}>
                                        <td colSpan="3" style={{borderRight: '1px solid #949494'}}>
                                            <div style={{display: 'flex'}}>
                                                <span>Addons:</span>
                                                <ul style={{listStyleType: 'circle'}}>
                                                    {x.Addons.map(y => {
                                                        const match = orderDetail.Ingredients.filter(z => z.IngredientId === y.AddonIngredientId)[0]
                                                        if (!match){
                                                            return ''
                                                        }
                                                        return <li key={y.AddonIngredientId}>{match.Name}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </td>
                                        <td colSpan="3">
                                            <div style={{display: 'flex'}}>
                                                <span>Without:</span>
                                                <ul style={{listStyleType: 'circle'}}>
                                                    {x.WithoutIngredientIds.map(y => {
                                                        const match = orderDetail.Ingredients.filter(z => z.IngredientId === y)[0]
                                                        if (!match){
                                                            return ''
                                                        }
                                                        return <li key={y}>{match.Name}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            )}
                        )}
                    </tbody>
                </Table>
            </Row>
        </Fragment>
    )
}