import React, { Fragment, useState } from "react"
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Typography } from '@material-ui/core'
import CurrencyInput from '../../../utility/currencyInput'
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core'


// Probably rename to OperatorCart
export const OperatorCart = ({ props }) => {
    const { orderItems, calculateTotal, setOrderItems, cart, setCart, products } = props
    const [loading, setLoading] = useState(true)
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format


    const handleProductQuantityChange = (cartItemId, val) => {
        const orderItemsClone = [...orderItems]
        const products = orderItemsClone.map(x => {
            if (x.CartItemId === cartItemId) {
                x.Quantity = Number(val) || ''
            }
            return x
        })
        setOrderItems(products)
    }

    const updateProductQuantity = (cartItemId, val) => {
        calculateTotal(orderItems)
    }

    const handleRemoveProduct = (cartItemId) => {
        const newOrderItems = [...orderItems].filter(x => x.CartItemId !== cartItemId)
        setOrderItems(newOrderItems)
        calculateTotal(newOrderItems, cart.Combos)
    }

    const handleComboQuantityChange = (cartItemId, val) => {
        const cartClone = Object.assign({}, cart)
        const combos = cart.Combos.map(x => {
            if (x.CartItemId === cartItemId) {
                x.Quantity = Number(val) || ''
            }
            return x
        })
        cartClone.Combos = combos
        setCart(cartClone)
    }

    const updateComboQuantity = (cartItemId, val) => {
        calculateTotal()
    }

    const handleRemoveCombo = (cartItemId) => {
        const newCombos = [...cart.Combos].filter(x => x.CartItemId != cartItemId)
        setCart(prevState => {
            return {
                ...prevState,
                Combos: newCombos
            }
        })
        calculateTotal(orderItems, newCombos)
    }

    const calculateComboTotal = (combo) => {
        let price = 0;
        combo.Products.forEach(x => {
            price += (Number(x.Price) * Number(x.Quantity))
        })
        price -= combo.Discount
        return price;

    }

    const getMoneySpan = (size, price, quantity) => {
        if (size) {
            size = size + "-"
        }
        else {
            size = ""
        }
        if(!quantity){
            quantity = 0
        }
        return <span style={{ fontSize: '1rem', fontStyle: 'italic' }}>{size}{moneyFormat(price)} x {quantity} = {moneyFormat(price * quantity || 0)}</span>
    }

    return (
        <Fragment>
            <Row style={{ paddingLeft: '1.5rem' }}>
                <Col>
                    <h4>Cart</h4>
                </Col>
            </Row>
            <div style={{ maxHeight: '26rem', overflowY: 'auto' }}>
                <Row>
                    <Col>
                        <Row style={{ justifyContent: 'center' }}>
                            <Col xs={8} lg={8}>
                                <Row>
                                    <h5>Item Details</h5>
                                </Row>
                                <hr />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {Boolean(orderItems?.length) && orderItems.map((x, i) => {
                    return <Fragment key={x.CartItemId}>
                        <Row>
                            <Col md={{ offset: 2, span: 4 }}>
                                <Row>
                                    <h5 style={{fontSize: '1.2rem', marginRight: '1rem'}}>{x.Name}</h5>
                                    {getMoneySpan(x.Size, x.Price, x.Quantity)}
                                </Row>
                                <Row>
                                    <span style={{fontSize: '.9rem'}}>Base Price: {moneyFormat(x.BasePrice)}</span>
                                </Row>
                                {Boolean(x.SelectedAddons.length) && (
                                    <div style={{fontSize: '.9rem'}}>
                                        <Row>
                                            <span>Addons:</span>
                                        </Row>
                                        <ul style={{ listStyleType: 'circle' }}>
                                            {x.SelectedAddons.map((y,i) => {
                                                const match = x.Addons.filter(z => z.IngredientId === y)[0]
                                                if (!match) {
                                                    return ''
                                                }
                                                return <li key={i}>{`${match.Name} +`} <span style={{fontStyle: 'italic'}}>{moneyFormat(match.Price)}</span></li>
                                            })}
                                        </ul>
                                    </div>
                                )}
                                {Boolean(x.WithoutIngredients.length) && (
                                    <div style={{fontSize: '.9rem'}}>
                                        <Row>
                                            <span>Without:</span>
                                        </Row>
                                        <ul style={{ listStyleType: 'circle' }}>
                                            {x.WithoutIngredients.map(y => {
                                                const match = x.Ingredients.filter(z => z.IngredientId === y)[0]
                                                if (!match) {
                                                    return ''
                                                }
                                                return <li>{match.Name}</li>
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </Col>
                            <Col xs={10} sm={4} lg={4}>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Form.Label column style={{ textAlign: 'end' }}>
                                        Quantity
                                </Form.Label>
                                    <Col>
                                        <Form.Control type="number" min="1" value={x.Quantity}
                                            onChange={(e) => handleProductQuantityChange(x.CartItemId, e.target.value)}
                                            onBlur={(e) => updateProductQuantity(x.CartItemId, e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    <Button variant="danger" onClick={() => handleRemoveProduct(x.CartItemId)}>Remove Item</Button>
                                </Row>
                            </Col>
                        </Row>
                        <hr />
                    </Fragment>
                })}
                {cart && cart.Combos && cart.Combos.map((x, i) => {
                    const matchItems = x.Products
                    const comboTotal = calculateComboTotal(x)
                    const totalNoDiscount = comboTotal + x.Discount
                    return <Fragment key={x.CartItemId}>
                        <Row>
                            <Col md={{ offset: 2, span: 4 }}>
                                <Row>
                                    <h5 style={{marginRight: '1rem'}}>{x.Name} (Combo) </h5>
                                    <span style={{textDecoration: 'line-through', marginRight: '.5rem'}}>{moneyFormat(totalNoDiscount)}</span> {getMoneySpan(null, comboTotal, x.Quantity)}
                                </Row>
                            </Col>
                            <Col xs={10} sm={4} lg={4}>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Form.Label column style={{ textAlign: 'end' }}>
                                        Quantity
                                        </Form.Label>
                                    <Col>
                                        <Form.Control type="number" min="1" value={x.Quantity}
                                            onChange={(e) => handleComboQuantityChange(x.CartItemId, e.target.value)}
                                            onBlur={(e) => updateComboQuantity(x.CartItemId, e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    <Button variant="danger" onClick={() => handleRemoveCombo(x.CartItemId)}>Remove Combo</Button>
                                </Row>
                            </Col>
                        </Row>
                        {matchItems.map((y, j) => {
                            return <Fragment key={j}>
                                <Row>
                                    <Col md={{ offset: 3, span: 4 }}>
                                        <Row>
                                            <h5 style={{marginRight: '1rem'}}>{y.Name}</h5>
                                            {getMoneySpan(y.Size, y.Price, y.Quantity)}
                                        </Row>
                                        <Row>
                                            <span style={{fontSize: '.9rem'}}>Base Price: {moneyFormat(y.BasePrice)}</span>
                                        </Row>
                                        {Boolean(y.SelectedAddons.length) && (
                                            <Fragment>
                                                <Row>
                                                    <span>Addons:</span>
                                                </Row>
                                                <ul style={{ listStyleType: 'circle' }}>
                                                    {y.SelectedAddons.map(a => {
                                                        const match = y.Addons.filter(z => z.IngredientId === a)[0]
                                                        if (!match) {
                                                            return ''
                                                        }
                                                        return <li>{match.Name}+<span style={{fontStyle: 'italic'}}>{moneyFormat(match.Price)}</span></li>
                                                    })}
                                                </ul>
                                            </Fragment>
                                        )}
                                        {Boolean(y.WithoutIngredients.length) && (
                                            <Fragment>
                                                <Row>
                                                    <span>Without:</span>
                                                </Row>
                                                <ul style={{ listStyleType: 'circle' }}>
                                                    {y.WithoutIngredients.map(a => {
                                                        const match = y.Ingredients.filter(z => z.IngredientId === a)[0]
                                                        if (!match) {
                                                            return ''
                                                        }
                                                        return <li>{match.Name}</li>
                                                    })}
                                                </ul>
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                            </Fragment>
                        })}
                        <hr />
                    </Fragment>
                })}
            </div>
        </Fragment>
    )
}