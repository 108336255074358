const requestType = {
        // Max: 131
        // Misc
        HeartBeat: 0,

        // User
        Auth: 1,
        UpdateUser: 2,
        UserInviteReaction: 30,
        RequestAccountAccess: 18,
        ViewAccountAccess: 31,
        CancelAccessRequest: 36,
        ViewUser: 67,
        ApplySystemCoupon: 74,
        PurchaseAccess: 75,
        UpdatePaymentMethod: 76,
        UpdateUpline: 77,
        CancelAccess: 78,
        UpdatePassword: 93,

        // Customer
        CreateCustomer: 3,
        UpdateCustomer: 4,
        ViewStoreCategories: 32,
        ViewStoreProducts: 33,
        ViewStoreInformation: 34,
        ReloadQRCode: 35,
        ViewStoreProductDetail: 37,
        ViewCustomer: 68,
        CustomerOrderUpdated: 83,
        UpdateCustomerEmail: 94,
        CustomerSetDefaultPayment: 106,

        // Cart
        AddToCart: 38,
        RemoveFromCart: 39,
        UpdateCartQuantity: 40,
        ClearCart: 41,
        Checkout: 42,
        CartPaymentMethod: 43,
        CartSubmission: 44,
        GetCart: 45,
        RestoreCart: 47,
        ApplyCoupon: 72,
        ApplyReward: 73,
        RemoveCoupon: 84,
        RemoveReward: 85,
        TerminalCancel: 95,
        TerminalAbortKey: 97,
        SubmitCashOrder: 101,
        AddCartCombo: 122,
        UpdateCartCombo: 124,
        RemoveCartCombo: 125,

        // Order
        OperatorEditOrder: 6,
        AcknowledgeOrder: 7,
        DeclineOrder: 8,
        RefundOrder: 9,
        RefundOrderItem: 10,
        AccountOrderList: 48,
        AccountOrderDetail: 49,
        CustomerOrderList: 51,
        CustomerOrderDetail: 52,

        // Products
        AddProduct: 11,
        UpdateProduct: 12,
        UpdateProductTags: 13,
        UpdateProductAddons: 14,
        UpdateProductIngredients: 15,
        ProductDetails: 23,
        DeleteProduct: 24,
        ListProducts: 21,
        UpdateProductItemCategories: 46,
        ProductTagSearch: 50,
        ListIngredients: 90,
        CloneProduct: 100,
        OrderProductCategory: 107,
        ProductClearImage: 109,

        // Combos
        ViewComboDetail: 127,
        CreateCombo: 116,
        UpdateCombo: 117,
        AddComboCategory: 118,
        UpdateComboCategory: 129,
        DeleteComboCategory: 119,
        UpdateComboItems: 120,
        ListCombos: 123,
        ViewStoreCombo: 126,
        UpdateComboProductCategories: 128,

        // Account
        UpdateAccount: 16,
        CreateAccount: 17,
        InviteUserToAccount: 19,
        UpdateCustomerNote: 20,
        AccountDetails: 28,
        UpdateAccountUser: 29,
        UpdateAccountRewards: 69,
        ViewCoupons: 70,
        UpdateCoupon: 71,
        AccountCustomerList: 80,
        AccountCustomerDetail: 81,
        CreateCoupon: 86,
        ViewCouponDetails: 87,
        CustomerScannedQR: 88,
        ViewAccountRewards: 92,
        CreateIngredientRename: 112,
        UpdateIngredientRename: 113,
        DeleteIngredientRename: 114,
        ViewIngredientRenames: 115,
        DailySnapshot: 121,
        AccountHelp: 130,
        ViewAccountKeys: 132,
        EditAccountKey: 133,
        DeleteAccountKey: 134,
        CreateAccountKey: 135,

        // ProductCategories
        ListProductCategories: 22,
        AddProductCategory: 25,
        UpdateProductCategory: 26,
        DeleteProductCategory: 27,

        // Email
        CreateEmailTemplate: 53,
        UpdateEmailTemplate: 54,
        CreateCampaign: 55,
        UpdateCampaign: 56,
        CreateSegment: 57,
        EditSegment: 58,
        DeleteSegment: 59,
        GetEmailToken: 79,

        // Operator
        OperatorCustomerList: 60,
        OperatorCustomerDetail: 61,
        OperatorOrderList: 62,
        OperatorOrderDetail: 63,
        OperatorOrderReceived: 82,
        OperatorCustomerLoggedIn: 89,
        OperatorCreateOrder: 5,
        OperatorProductList: 91,
        OperatorTerminalOverride: 96,
        OperatorCashOut: 102,
        OperatorSendToTerminal: 103,
        OperatorCreateCustomer: 104,
        OperatorCustomerLookup: 105,
        OperatorProductRecipe: 108,
        OperatorCashRecords: 110,
        OperatorAddDiscount: 111,
        OperatorHelp: 131,

        // Integration
        GetIntegration: 64,
        UpdateCustomDomain: 65,
        CustomDomainStatus: 66,

        // Reporting
        GetReportDashboards: 98,
        GetDashboardURL: 99
}

export default requestType