import React, { useEffect, useState} from 'react'
import { OperatorCustomerList } from './operatorCustomerList'
import { OperatorCustomerDetail } from './operatorCustomerDetail'
import { getOperatorCustomerList } from '../../../api/sockets/operator/operatorRequests'

export const OperatorCustomer = ({ socket, notify, newCustomers, setNewCustomers }) => {
    const [loading, setLoading] = useState(false)
    // Customer Detail
    const [customerId, setCustomerId] = useState(null)

    // Customer List
    const [dateFilterDDL, setDateFilterDDL] = useState(30)
    const [customerList, setCustomerList] = useState(null)

    useEffect(() => {
        if (socket !== null && customerList === null) {
            reloadOperatorCustomers()
        }
    }, [customerList, socket])

    const reloadOperatorCustomers = (numberOfDays) => {
        if(!numberOfDays){
            numberOfDays = dateFilterDDL
        }
        
        setLoading(true)
        socket.props.onFailure = notify
        socket.props.setLoading = setLoading
        socket.props.onSuccess = (response) => {
            setCustomerList(response.Customers)
        }
        
        const request = {
            WithinDays: numberOfDays
        }
        
        // may need socket.socket
        getOperatorCustomerList(request, socket.socket)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            { 
                customerId
                    ? <OperatorCustomerDetail props={{
                        socket, notify,
                        customerId: customerId,
                        back: () => setCustomerId(null),
                        reloadOperatorCustomers
                    }} />
                    : <OperatorCustomerList props={{
                        customerList, reloadOperatorCustomers,
                        dateFilterDDL, setDateFilterDDL,
                        setCustomerId, newCustomers, setNewCustomers
                    }} />
            }
        </div>
    )
}