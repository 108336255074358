import { Delete } from '@material-ui/icons'
import React, { Fragment } from 'react'
import { Form, Row, Col, Table } from 'react-bootstrap'
import { altUtcStringToLocal } from '../../../utility/dateHelper'



export const OperatorCustomerList = ({props}) => {
    const { customerList, reloadOperatorCustomers, dateFilterDDL, setDateFilterDDL, setCustomerId, newCustomers, setNewCustomers } = props

    const removeNewCustomer = (e, id) => {
        e.stopPropagation()

        const newList = newCustomers.filter(x => x.CustomerId !== id)
        setNewCustomers(newList)
    }

    return (
        <Fragment>
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Recent Kiosk Customers
                    </h2>
                </Col>
                <Col>

                </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px'}}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>First</th>
                                <th>Last</th>
                                <th>Last Order</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {newCustomers && newCustomers.map(x => {
                                return <tr key={x.CustomerId} onClick={() => {setCustomerId(x.CustomerId)}}>
                                    <td><Delete style={{cursor: 'pointer'}} onClick={(e) => removeNewCustomer(e, x.CustomerId)} /></td>
                                    <td>{x.FirstName}</td>
                                    <td>{x.LastName}</td>
                                    <td>{(x.LastOrder && altUtcStringToLocal(x.LastOrder)) || ''}</td>
                                    <td>{x.Notes || ''}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <hr style={{width: '100%'}} />

            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Customer List
                    </h2>
                </Col>
                <Col>

                </Col>
            </Row>

            <Row >
                <Col xs={10} sm lg={3} style={{ padding: '0px' }}>
                    <p style={{ textAlign: 'right', marginTop: 5 }}>
                        Show customers of the last
                    </p>
                </Col>
                <Col xs={10} sm lg={3} style={{ paddingLeft: '5px',paddingReft: '5px', maxWidth: '100px' }}>
                    <Form.Control as="select" value={dateFilterDDL} onChange={(e) => {setDateFilterDDL(e.target.value); reloadOperatorCustomers(e.target.value)}}>
                        <option value={30}>30</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                    </Form.Control>
                </Col>
                <Col xs={10} sm lg={3} style={{ padding: '0px' }}>
                    <p style={{marginTop: 5}}>
                        days
                    </p>
                </Col>
            </Row>

            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px'}}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>First</th>
                                <th>Last</th>
                                <th>Last Order</th>
                                <th>First Visit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerList != null && customerList.map((x) => {
                                    return (
                                        <tr key={x.CustomerId} onClick={() => {setCustomerId(x.CustomerId)}}>
                                            <td>{x.Email}</td>
                                            <td>{x.FirstName}</td>
                                            <td>{x.LastName}</td>
                                            <td>{x.LastOrder && altUtcStringToLocal(x.LastOrder)}</td>
                                            <td>{x.FirstVisit && altUtcStringToLocal(x.FirstVisit)}</td>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )


}