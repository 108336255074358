import React, { useEffect, useState, Fragment, useRef } from 'react'
import { Row, Col, Button, Form, } from 'react-bootstrap'
import userPages from '../../enums/userPages'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { createCombo } from '../../api/sockets/combos/comboRequests'
import CurrencyInput from '../utility/currencyInput'

export const CreateCombo = ({ socket, notify, setCreateCombo, setComboId, setCombo }) => {
    const isSmall = useMediaQuery('(max-width:650px)')
    const isBig = useMediaQuery('(min-width:1200px)')
    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)
    const [discount, setDiscount] = useState(null)
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    const create = (e) => {
        e.preventDefault()
        socket.props.onCreateComboFailure = notify
        socket.props.onCreateComboSuccess = (response) => {
            // Remove when detail request is working I think
            setComboId(response.ComboId)
            setCreateCombo(false)
        }
        const discountValue = parseFloat(discount.replace('$',''))
        const request = {
            Name: name,
            Description: description,
            Discount: discountValue,
        }
        createCombo(request, socket.socket)
    }
    return (
        <Fragment>
            <Row style={{ paddingBottom: '1rem' }}>
                <Col lg={1} />
                <Col>
                    <h4>
                        <ArrowBackIos style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => setCreateCombo(false)} />
                        Create Combo
                    </h4>
                </Col>
            </Row>
            <Form onSubmit={create}>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} sm={4} md={3} lg={3} style={{ marginBottom: '10px', textAlign: 'end' }}>
                        Name
                    </Form.Label>
                    <Col xs={8} sm={8} md={5} lg={4} xl={3}>
                        <Form.Control required value={name || ''} onChange={(e) => setName(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={3} lg={3} style={{ textAlign: 'end' }}>
                        Description
                    </Form.Label>
                    <Col xs={8} md={5} lg={4} xl={3}>
                        <Form.Control as="textarea" required rows={6} value={description || ''} maxLength={500} style={{ resize: 'none' }} onChange={(e) => setDescription(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={4} md={3} lg={3} style={{ textAlign: 'end' }}>
                        Discount
                    </Form.Label>
                    <Col xs={8} md={5} lg={4} xl={3}>
                        <CurrencyInput maskOptions={{prefix: '$'}}   guide={false} required className="form-control" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                    </Col>
                </Form.Group>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={7} xl={6} style={{ textAlign: 'end' }}>
                        <Button type="submit">Create</Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}

