import React, { useState } from 'react'
import { useMediaQuery, makeStyles} from '@material-ui/core'
import { Nav, Navbar } from 'react-bootstrap'
import { Login } from './login';
import { CSSTransition } from 'react-transition-group'
import userType from '../../enums/userTypes'
import '../../App.css'
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles({
    navLink: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    }
})

export const GeneralNav = ({props}) => {
    const classes = useStyles()
    const isBig = useMediaQuery('(min-width:1200px)');
    const isSmall = useMediaQuery('(max-width:525px)');

    const history = useHistory()

    const { setShowLogin, showLogin } = props
    const isSignUp = useRouteMatch('/signup')
    const isResetPW = useRouteMatch('/resetPassword')

    const duration = 300
    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    }    
    const transitionStyles = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '60px'}}>
            <Navbar expand="lg" style={{backgroundColor: 'rgba(255, 255, 255)', height: '60px'}}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" style={{textAlign: 'center'}}>
                    <Nav className="mr-auto" style={{marginLeft: isBig ? '9vw' : isSmall ? '0px' : 'auto', width: '100vw', position: 'absolute', left: '0' }}>
                        <Nav.Link className={classes.navLink} style={{zIndex: 99}} onClick={() => history.push('/')}>HOME</Nav.Link>                        
                        {!isSignUp && !isResetPW  &&  <Nav.Link className={classes.navLink} onClick={() => setShowLogin(!showLogin)}>SIGN IN</Nav.Link>}
                        <Nav.Link className={classes.navLink} onClick={() => {setShowLogin(false); history.push('/signup')}}>SIGN UP</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <CSSTransition in={showLogin} timeout={duration}>
                {state => (
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state],
                        zIndex: 1,
                    }}>
                        <Login props={{setLoggedIn: props.setLoggedIn, userType: userType.User, setShowLogin: props.setShowLogin, showLogin: props.showLogin}} />
                        <div style={{height: '3vh', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0))'}}></div>
                    </div>
                )}
            </CSSTransition>
        </div>
    )
}