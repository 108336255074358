import React, { useState, Fragment, useEffect, useRef } from 'react'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { updateAccount, viewAccountDetail } from '../../api/sockets/account/accountRequests'
import DatePicker from 'react-datepicker'
import MaskedInput from 'react-text-mask'

export const StoreInformation = ({ props, socket, notify }) => {
    const [loading, setLoading] = useState(true)
    const [accountDetails, setAccountDetails] = useState(null)
    const [priorState, setPriorState] = useState(null)
    const [timeJSON, setTimeJSON] = useState(null)

    const mainForm = useRef(null)
    const isSmall = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        if (accountDetails === null && socket !== null) {
            reloadAccountDetails()
        }
        if (socket === null) {
            notify('Error', 'Network Error. Please try again later.') // Reconnect?
        }
    }, [accountDetails])

    const reloadAccountDetails = () => {
        setLoading(true)
        socket.props.notify = notify
        socket.props.onAccountDetailsSuccess = (response) => {
            setAccountDetails(response)
            setPriorState(response)
            parseTimeJSON(response.TimeDataJSON)
        }
        socket.props.setLoading = setLoading
        viewAccountDetail({}, socket)
    }

    const validateAndUpdate = (e) => {
        e && e.preventDefault()

        const request = {
            Name: accountDetails.Name,
            HousePercentage: accountDetails.HousePercentage,
            ActiveAccountKeyId: accountDetails.ActiveAccountKeyId,
            Address1: accountDetails.Address1,
            Address2: accountDetails.Address2,
            State: accountDetails.State,
            City: accountDetails.City,
            ZipCode: accountDetails.ZipCode,
            Timezone: accountDetails.Timezone,
            ObservesDST: accountDetails.ObservesDST,
            CurrencyCode: accountDetails.CurrencyCode,
            TimeDataJSON: JSON.stringify(timeJSON)
        }
        socket.props.onUpdateSuccess = () => {
            setPriorState(accountDetails)
        }
        socket.props.notify = notify
        
        updateAccount(request, socket)
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const parseTimeJSON = (json) => {
        
        const obj = JSON.parse(json || null)
        if (obj){
            if (obj.Hours){
                for (const [k, v] of Object.entries(obj.Hours)){
                    obj.Hours[k] = v ? new Date(v) : null
                }
            }
        }
        setTimeJSON(obj)
    }

    const updateTime = (field, val) => {
        const clone = Object.assign({}, timeJSON)
        if (!clone.Hours){
            clone.Hours = {}
        }
        clone.Hours[field] = val
        setTimeJSON(clone)
        console.log(JSON.stringify(clone))

        // save
        setTimeout(() => {mainForm && mainForm.current && mainForm.current.click() }, 200)
    }

    const updateJSONProp = (field, val, save) => {
        const clone = Object.assign({}, timeJSON)
        clone[field] = val
        setTimeJSON(clone)

        // save
        if (save){
            setTimeout(() => mainForm.current.click(), 200)
            console.log(JSON.stringify(clone))
        }
    }

    return <Fragment>
        {loading
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
            : accountDetails &&
            <div>
                <Row>
                    <Col lg={1}></Col>
                    <Col>
                        <h4>Store Information</h4>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={validateAndUpdate} onKeyDown={(e) => e.key === 'Enter' && e.target.type !== 'textarea' ? e.preventDefault() : ''}>
                    {days.map(x => {
                        const open = `${x}Open`
                        const close = `${x}Close`
                        return (
                            <Form.Group as={Row} key={x}>
                                <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    {x} Open
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <DatePicker
                                        selected={timeJSON && timeJSON.Hours && timeJSON.Hours[open]}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        onChange={(dt) => updateTime(open, dt)}
                                    />
                                </Col>
                                <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                                    {x} Close
                                </Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <DatePicker
                                        selected={timeJSON && timeJSON.Hours && timeJSON.Hours[close]}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        onChange={(dt) => updateTime(close, dt)}
                                    />
                                </Col>
                            </Form.Group>
                        )
                    })}

                    <hr />

                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Store Phone
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control"
                            placeholder="Enter a phone number"
                            guide={false}
                            onChange={(e) => updateJSONProp('StorePhone', e.target.value)}
                            onBlur={(e) => updateJSONProp('StorePhone', e.target.value, true)}
                            type="tel"
                            value={(timeJSON && timeJSON.StorePhone) || ''} 
                        />
                        </Col>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Store Email
                        </Form.Label>
                        <Col xl={2} lg={3} md={3}>                            
                            <Form.Control 
                                value={(timeJSON && timeJSON.StoreEmail) || ''} 
                                maxLength={200} 
                                onChange={(e) => updateJSONProp('StoreEmail', e.target.value)}
                                onBlur={(e) => updateJSONProp('StoreEmail', e.target.value, true)}
                            />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row}>
                        <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                            Store Description
                        </Form.Label>
                        <Col>
                            <Form.Control 
                                as="textarea"
                                maxLength={200} 
                                rows="4"
                                style={{resize: 'none'}}
                                value={(timeJSON && timeJSON.Description) || ''}
                                onChange={(e) => updateJSONProp('Description', e.target.value)}
                                onBlur={(e) => updateJSONProp('Description', e.target.value, true)}
                            />
                        </Col>
                        {!isSmall && <Col></Col>}
                    </Form.Group>
                    
                    {/* TODO : PickupTimes array */}
                    
                    <Button style={{ display: 'none' }} ref={mainForm} type="submit"></Button>
                </Form>
            </div>}
    </Fragment>
}



