import requestType from '../../../enums/requestType'

export const getOperatorCustomerList = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCustomerList,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getOperatorCustomerDetail = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCustomerDetail,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getOperatorOrderList = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorOrderList,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getOperatorOrderDetail = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorOrderDetail,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const acknowledgeOrder = (data, socket) => {
    const request = {
        RequestType: requestType.AcknowledgeOrder,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateOrder = (data, socketInfo) => {
    const request = {
        RequestType: requestType.OperatorEditOrder,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const operatorProductList = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorProductList,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const createOrder = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCreateOrder,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const customerLookUp = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCustomerLookup,
        Data: data
    }
    console.log(request)
    socket.send(JSON.stringify(request))
}

export const operatorCreateCustomer = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCreateCustomer,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const cashOutOrder = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCashOut,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const sendToTerminal = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorSendToTerminal,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const productRecipe = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorProductRecipe,
        Data: data,
    }
    socket.send(JSON.stringify(request))
}

export const cashRecords = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorCashRecords,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateOrderDiscount = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorAddDiscount,
        Data: data,
    }
    socket.send(JSON.stringify(request))
}

export const getOperatorHelp = (data, socket) => {
    const request = {
        RequestType: requestType.OperatorHelp,
        Data: data,
    }
    socket.send(JSON.stringify(request))
}