import React, { Fragment } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { altUtcStringToLocal } from '../../../../utility/dateHelper'


export const OperatorDailyCashList = ({ props }) => {
    const { dailyCashList } = props
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    return (
        <Fragment>
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Today's Cash Transactions
                    </h2>
                </Col>
            </Row>
            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Order Time</th>
                                <th>Due</th>
                                <th>Paid</th>
                                <th>Change</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dailyCashList && dailyCashList.map((x) => {
                                return (
                                    <tr key={x.TransactionId}>
                                        <td>{x.Email}</td>
                                        <td>{altUtcStringToLocal(x.Created)}</td>
                                        <td>{moneyFormat(x.Due)}</td>
                                        <td>{moneyFormat(x.Paid)}</td>
                                        <td>{moneyFormat(x.Change)}</td>
                                    </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>

    )
}