export const handleAddComboCategory = (response, props) => {
    if (response.IsError) {
        props.onAddComboCategoryFailure && props.onAddComboCategoryFailure(response.ErrorMessage)
    }
    else {
        props.onAddComboCategorySuccess && props.onAddComboCategorySuccess(response)
    }
    props.setAddCategoryLoading && props.setAddCategoryLoading(false)
}

export const handleDeleteComboCategory = (response, props) => {
    if (response.IsError) {
        props.onDeleteComboCategoryFailure && props.onDeleteComboCategoryFailure(response.ErrorMessage)
    }
    else {
        props.onDeleteComboCategorySuccess && props.onDeleteComboCategorySuccess()
    }
    props.setLoading && props.setLoading(false)
}

export const handleCreateCombo = (response, props) => {
    if (response.IsError) {
        props.onCreateComboFailure && props.onCreateComboFailure(response.ErrorMessage)
    }
    else {
        props.onCreateComboSuccess && props.onCreateComboSuccess(response)
    }
}

export const handleUpdateCombo = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onUpdateComboFailure && props.onUpdateComboFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateComboSuccess && props.onUpdateComboSuccess()
    }
    props.setLoading && props.setLoading(false)
}

export const handleUpdateComboItems = (response, props) => {
    if (response.IsError) {
        props.onUpdateComboItemsFailure && props.onUpdateComboItemsFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateComboItemsSuccess && props.onUpdateComboItemsSuccess(response)
    }
    props.setLoading && props.setLoading(false)
}

export const handleListCombos = (response, props) => {
    if (response.IsError) {
        props.onListCombosFailure && props.onListCombosFailure(response.ErrorMessage)
    }
    else {
        props.onListCombosSuccess && props.onListCombosSuccess(response)
    }
    props.setLoading && props.setLoading(false)
}

export const handleViewStoreCombo = (response, props) => {
    if (response.IsError) {
        props.onComboFailure && props.onComboFailure(response.ErrorMessage)
    }
    else {
        props.onComboSuccess && props.onComboSuccess(response)
    }
    props.setComboLoading && props.setComboLoading(false)
}

export const handleViewComboDetail = (response, props) => {
    if (response.IsError) {
        props.onViewComboDetailError && props.onViewComboDetailError(response.ErrorMessage)
    }
    else {
        props.onViewComboDetailSuccess && props.onViewComboDetailSuccess(response)
    }
    props.setLoading && props.setLoading(false)
}

export const handleUpdateComboProductCategories = (response, props) => {
    if (response.IsError) {
        props.onUpdateComboProductCategoriesFailure && props.onUpdateComboProductCategoriesFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateComboProductCategoriesSuccess && props.onUpdateComboProductCategoriesSuccess()
    }
    props.setLoading && props.setLoading(false)
}

 
export const handleUpdateComboCategory = (response, props) => {
    if (response.IsError) {
        props.onUpdateComboCategoryFailure && props.onUpdateComboCategoryFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateComboCategorySuccess && props.onUpdateComboCategorySuccess()
    }
    props.setUpdateCategoryLoading && props.setUpdateCategoryLoading(false)
}