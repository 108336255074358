import React, {Fragment, useEffect, useState} from 'react'
import { cashRecords } from '../../../../api/sockets/operator/operatorRequests'
import { OperatorDailyCashList } from './operatorDailyCashList'




export const OperatorRegister = ({props}) => {
    const {socket, notify, setSelectedPage} = props
    const [loading, setLoading] = useState(true)
    const [dailyCashList, setDailyCashList] = useState(null)


    useEffect( () => {
        if(socket !== null){
            reloadDailyCashList()
        }
    }, [socket])


    const reloadDailyCashList = () => {
        setLoading(true)
        socket.props.onCashRecordsFailure = (msg) => {
            notify('Error Getting Cash Records', msg)
        }
        socket.props.setRegisterLoading = setLoading

        socket.props.onCashRecordsSuccess = (data) => {
            setDailyCashList(data.Transactions)
        }
        
        cashRecords({}, socket.socket)
    }



    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <OperatorDailyCashList props ={{
                socket, notify, dailyCashList 
            }}/>
        </div>
    )
}
