import React from 'react'
import { Assessment } from '@material-ui/icons'
import { useMediaQuery, Typography, makeStyles } from '@material-ui/core'
import { Row, Col } from 'react-bootstrap'
import sideLeaf from '../../images/landing/SideLeaf.svg'
import dailySnapshot from '../../images/landing/DailySnapshot.png'
import productCategories from '../../images/landing/ProductCategories.png'
import customerDetail from '../../images/landing/CustomerDetail.png'

const useStyles = makeStyles({
    coverPhoto: {
        width: '100%',
        height: '90vh',
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundColor: '#9fd68d',
        backgroundRepeat: 'repeat',
        backgroundImage: `url(${sideLeaf})`
    },
    title: {
        paddingTop: '20vh', // we may want to do something here for the sign dropdown
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'white'
    },
    paragrapgh: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    },
    infoCard: {
        height: '350px',
        backgroundColor: 'rgba(0,0,0,0.5)',
        boxShadow: '10px 20px 30px rgb(0 0 0 / 38%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    pictureCard: {
        width:'100px',
        height: '350px',
        textAlign: 'center'
    },
    picture: {
        width:'100px',
        height: '100px'
    },
    landingBackground: {
        backgroundColor: "#9fd68d"
    },
    snapshotPhoto: {
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        //backgroundImage: `url(${dailySnapshot})`,
        backgroundPosition: 'center',
        alignItems: 'center', 
        marginBottom: '10px'
    },
    productPhoto: {
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        //backgroundImage: `url(${productCategories})`,
        backgroundPosition: 'center',
        alignItems: 'center', marginBottom: '10px'
    }
})

export const Landing = () => {
    const classes = useStyles()
    const isMd = useMediaQuery('(min-width:768px)')

    // Current Pallete suggestion
    // Greens
    //#082d0f - D
    //#235413 - DM
    //#5e9146 - LM
    //#9fd68d - L

    // Black/White
    //#ffffff - Black
    //#2b2e2b - D Grey
    //#626662 - L Grey
    //#000000 - White

    // Blue
    //#070559 - D
    //#211f73 - DM
    //#302db3 - LM
    //#322ed1 - L

    // Red
    //#6e0505 - D
    //#9e1b1b - DM
    //#ed4545 - LM
    //#f25555 - L

    // TODO: Fix hamburger and sign in overlap issue
    return (
        <div className={classes.landingBackground} >
            <img className={classes.coverPhoto} style={{alignItems: 'center', marginBottom: '10px'}} />
            <Row>
                <Col>
                    <Typography className={classes.title} variant="h1" style={{fontSize: !isMd ? '5rem' : '', textAlign: 'center'}}>
                        Network sMarketer
                    </Typography>
                    <Typography className={classes.subtitle} variant="h4" style={{marginBottom: '10vh'}}>
                        Scalable, Powerful, Personal
                    </Typography>
                </Col>
            </Row>
            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col className={classes.infoCard} md={6} xs={12}>
                    <Typography className={classes.subtitle} variant="h3" style={{padding: '15px 0px'}}>
                        Analytics
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        Get real insights for your business, so that you can make decisions that are right for you. 
                        Customize reports to focus on what is important to you.
                    </Typography>
                </Col>
                <Col className={classes.pictureCard} md={6} xs={12}>
                    <img className={classes.productPhoto} src={dailySnapshot} style={{width: 'auto', maxWidth: '100%', borderRadius: '5px'}} />
                </Col>
            </Row>

            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col className={classes.pictureCard} md={6} xs={12} style={{display: isMd ? '': 'none'}}>
                    <img className={classes.snapshotPhoto} src={productCategories} style={{width: 'auto', maxWidth: '100%', borderRadius: '5px'}} />
                </Col>
                <Col className={classes.infoCard} md={6} xs={12}>
                    <Typography className={classes.subtitle} variant="h3" style={{padding: '15px 0px'}}>
                        Personalize
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        Take control of your menu by customizing your own products and categories.
                    </Typography>
                </Col>
            </Row>
            <Row style={{alignItems: 'center', marginBottom: '10px', display: isMd ? 'none': ''}}>
                <Col className={classes.pictureCard} md={6} xs={12}>
                    <img className={classes.snapshotPhoto} src={productCategories} style={{width: 'auto', maxWidth: '100%', borderRadius: '5px'}} />
                </Col>
            </Row>

            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col className={classes.infoCard} md={6} xs={12}>
                    <Typography className={classes.subtitle} variant="h3" style={{padding: '15px 0px'}}>
                        Power
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        Whether you have 100 or 100,000 customers you can easily manage everything you need.
                    </Typography>
                </Col>
                <Col className={classes.pictureCard} md={6} xs={12}>
                <img className={classes.productPhoto} src={customerDetail} style={{width: 'auto', maxWidth: '100%', borderRadius: '5px'}} />
                </Col>
            </Row>
        </div>
            
    )
}