import React, { useState, Fragment } from "react"
import { Form, Button, Col } from 'react-bootstrap'
import { Input, MenuItem, FormControl, ListItemText, Select, Checkbox, InputLabel, CircularProgress, makeStyles, useMediaQuery } from '@material-ui/core'
import CurrencyInput from '../../../utility/currencyInput'

const useStyles = makeStyles({
    stickyTH: {
        '& th': {
            position: 'sticky',
            top: '-2px',
            backgroundColor: '#a6a4a3',
            zIndex: 1
        }
    },
    formControl: {
        width: 260,
        '@media (max-width: 1000px)': {
            width: 140,
            fontSize: '.75rem'
        },
        color: '#495057',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        alignItems: 'left',
    },
    nounderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    strippedOdd: {
        background: 'lightgrey'
    },
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 15;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 250,
        }
    },
    // Don't realign on focus change
    getContentAnchorEl: null
}


// Exhaustive
export const OperatorProductList = ({ props }) => {
    const { setProductList, setFilteredProducts, setOrderItems, notify, calculateTotal,
        setOrderTotal, maxGrandTotal, orderItems, productList, orderTotal, filteredProducts, loading, cartItemId, setCartItemId } = props

    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:1000px)') //IPad
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    const setSelectedSizeId = (productId, previousSelectionId) => {
        const clone = filteredProducts.map(x => {
            if (x.SelectedSizeId === Number(previousSelectionId)) {
                x.SelectedSizeId = Number(productId)
                x.Quantity = ''
            }
            return x;
        })
        setFilteredProducts(clone)
    }

    const handleQuantityChange = (value, productId) => {
        const clone = productList.map(x => {
            if (x.SelectedSizeId === productId) {
                x.Quantity = value
            }
            return x
        })
        setProductList(clone)
    }

    const addToOrder = (productId) => {
        const product = productList.filter(x => x.SelectedSizeId === productId).map(x => {
            const sizeDetails = x.Sizes.filter(x => x.ProductId === productId)[0]
            let totalPrice = sizeDetails.Price
            x.Addons.filter(y => x.SelectedAddons.includes(y.IngredientId)).forEach(z => {
                totalPrice += z.Price
            })
            let id = cartItemId + 1
            setCartItemId(id)
            return {
                Addons: x.Addons,
                Ingredients: x.Ingredients,
                Name: x.Name,
                Price: totalPrice,
                Quantity: Number(x.Quantity),
                Size: sizeDetails?.SizeDetails,
                ProductId: sizeDetails?.ProductId,
                WithoutIngredients: x.Ingredients.filter(y => {
                    return !x.SelectedIngredients.includes(y.IngredientId)
                }).map(x => x.IngredientId),
                SelectedAddons: x.SelectedAddons,
                CartItemId: id,
                TaxPercent: sizeDetails?.TaxPercent ?? 0,
                BasePrice: sizeDetails.Price
            }
        })[0]

        if (product) {
            if (product.Quantity === '' || product.Quantity === 0) {
                notify('Problem adding item to order', 'Please Enter a quantity to add to order')
                return
            }
            let clonedList
            const clone = Object.assign({}, product)
            let isFound = false
            clonedList = orderItems.map(x => {
                if (isSameProduct(x, clone)) {
                    isFound = true
                    x.Quantity += clone.Quantity
                }
                return x
            })
            let total
            let previousTotal = orderTotal
            if (isFound) {
                total = calculateTotal(clonedList)
                if (total >= maxGrandTotal) {
                    notify('Error', 'There was a problem adding to your order. Please enter a smaller quantity')
                    return
                }
                setOrderItems(clonedList)
                notify('Success', `Added ${product.Name} to your order!`)
                return
            }
            clonedList = orderItems.concat(clone)
            total = calculateTotal(clonedList)
            if (total >= maxGrandTotal) {
                notify('Error', 'There was a problem adding to your order. Please enter a smaller quantity.')
                setOrderTotal(previousTotal)
                return
            }
            setOrderItems(clonedList)
            notify('Success', `Added ${product.Name} to your order!`)
        }
    }

    const handleIngredientSelection = (val, product) => {
        const prodList = productList.map(x => {
            if (x.SelectedSizeId === product.SelectedSizeId) {
                x.SelectedIngredients = val
            }
            return x
        })
        setProductList(prodList)
    }

    const handleAddonSelection = (val, product) => {
        const prodList = productList.map(x => {
            if (x.SelectedSizeId === product.SelectedSizeId) {
                x.SelectedAddons = val
            }
            return x
        })
        setProductList(prodList)
    }

    const isSameProduct = (currentProduct, newProduct) => {
        let sameProduct = true
        if (currentProduct.ProductId !== newProduct.ProductId) {
            return false
        }
        currentProduct.WithoutIngredients.forEach(x => {
            if (!newProduct.WithoutIngredients.includes(x)) {
                sameProduct = false
            }
        })
        currentProduct.SelectedAddons.forEach(x => {
            if (!newProduct.SelectedAddons.includes(x)) {
                sameProduct = false
            }
        })
        newProduct.WithoutIngredients.forEach(x => {
            if (!currentProduct.WithoutIngredients.includes(x)) {
                sameProduct = false
            }
        })

        newProduct.SelectedAddons.forEach(x => {
            if (!currentProduct.SelectedAddons.includes(x)) {
                sameProduct = false
            }
        })

        return sameProduct
    }

    // Used for multiselect ingredient dropdown
    const ingredientRender = (selected, product) => {
        if (product.SelectedIngredients.length) {
            return product.Ingredients.filter(x => selected.includes(x.IngredientId)).map(x => x.Name).join(',')
        }
    }

    const addonRender = (selected, product) => {
        if (product.SelectedAddons.length) {
            return product.Addons.filter(x => selected.includes(x.IngredientId)).map(x => x.Name).join(',')
        }
    }


    return (
        <Fragment>
            <Col xl={12} style={{ height: '40vh', overflowY: 'auto' }}>
                <table className="table table-sm table-bordered">
                    <thead>
                        <tr className={classes.stickyTH} style={{ textAlign: 'center' }}>
                            <th>Name</th>
                            <th>Size Details</th>
                            <th>Quantity</th>
                            <th>Add To Order</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '4rem', overflow: 'scroll' }}>
                        {!loading && filteredProducts && filteredProducts.map((product, i) => {
                            return (
                                <Fragment key={i}>
                                    <tr className={i % 2 !== 0 ? classes.strippedOdd : ''}>
                                        <td style={{ textAlign: 'center', fontWeight: 'bold', textDecoration: 'underline' }}>{product.Name}</td>
                                        <td>
                                            <Form.Control as="select" style={{ marginLeft: 'auto' }} className={classes.formControl} value={product.SelectedSizeId} onChange={(e) => setSelectedSizeId(e.target.value, product.SelectedSizeId)}>
                                                {product.Sizes && product.Sizes.map(s => {
                                                    let addOnPrice = 0
                                                    product.Addons.filter(x => product.SelectedAddons.includes(x.IngredientId)).forEach(x => {
                                                        addOnPrice += x.Price
                                                    })
                                                    const total = addOnPrice + s.Price
                                                    return <option key={s.ProductId} value={s.ProductId}>{`${s.SizeDetails} - $${total.toFixed(2)}`}</option>
                                                })}
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <CurrencyInput
                                                maskOptions={{ allowDecimal: false }}
                                                value={product.Quantity}
                                                className={`form-control ${classes.formControl}`}
                                                style={{ textAlign: 'right', marginLeft: 'auto' }}
                                                onChange={(e) => handleQuantityChange(e.target.value, product.SelectedSizeId)}
                                            />
                                        </td>
                                        <td rowSpan="2" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                            <Button style={{ minWidth: '7.5rem', }} onClick={() => addToOrder(product.SelectedSizeId)}>Add</Button>
                                        </td>
                                    </tr>
                                    <tr className={i % 2 !== 0 ? classes.strippedOdd : ''}>
                                        <td style={{ verticalAlign: 'middle', fontStyle: 'italic' }}>Select Addons and Ingredients</td>
                                        <td style={{ textAlign: 'center' }} >
                                            <FormControl className={classes.formControl} width={'4rem'}>
                                                {<InputLabel style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}> {Boolean(product.Ingredients.length) ? `Select Ingredients` : `No Ingredients Available`}</InputLabel>}
                                                <Select
                                                    multiple
                                                    className={classes.nounderline}
                                                    value={product.SelectedIngredients}
                                                    displayEmpty={true}
                                                    onChange={(e) => handleIngredientSelection(e.target.value, product)}
                                                    input={<Input />}
                                                    renderValue={(selected) => ingredientRender(selected, product)}
                                                    MenuProps={MenuProps}
                                                    style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}
                                                >
                                                    {product.Ingredients.map((x) => (
                                                        <MenuItem key={x.IngredientId} value={x.IngredientId}>
                                                            <Checkbox checked={product.SelectedIngredients.some(i => i === x.IngredientId)} />
                                                            <ListItemText primary={x.Name} style={{ fontSize: isSmall ? '.75rem' : 'inherit' }} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                        <td style={{ textAlign: 'center' }} >
                                            <FormControl className={classes.formControl}>
                                                {<InputLabel style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}> {Boolean(product.Addons.length) ? `Select Addons` : `No Addons Available`}</InputLabel>}
                                                <Select
                                                    multiple
                                                    className={classes.nounderline}
                                                    value={product.SelectedAddons}
                                                    onChange={(e) => handleAddonSelection(e.target.value, product)}
                                                    input={<Input />}
                                                    renderValue={(selected) => addonRender(selected, product)}
                                                    MenuProps={MenuProps}
                                                    style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}
                                                >
                                                    {product.Addons.map((x) => (
                                                        <MenuItem key={x.IngredientId} value={x.IngredientId}>
                                                            <Checkbox checked={product.SelectedAddons.some(i => i === x.IngredientId)} />
                                                            <ListItemText primary={x.Name} secondary={`+ ${moneyFormat(x.Price)}`} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>
                {loading
                    && <CircularProgress style={{ width: '100px', height: '100px', position: 'absolute', left: '45%', top: '40%' }} />
                }
            </Col>
        </Fragment>
    )

}