import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Nav, Navbar } from 'react-bootstrap'
import customerPages from '../../../enums/customerPages'

export const ViewCustomerNav = ({ props }) => {
    const { selectedTab, setSelectedTab, setOrderId } = props

    const isSmall = useMediaQuery('(max-width:990px)');
    const isNotSmall = useMediaQuery('(min-width:990px)');

    const handleOrderHistory = () => {
        setOrderId(null)
        setSelectedTab(customerPages.orderHistory)
    }

    return (
        // TODO: Left Nav when small, blocking notify
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: '99', paddingBottom: '2rem' }}>
           <Navbar style={{width: '85vw'}}>
                <Nav>
                    <Nav.Link
                        style={{
                            fontSize: '1.1rem',
                            maxWidth: '75vw',
                            paddingLeft: isSmall ? '0rem': '2rem',
                            color: selectedTab === customerPages.customerInfo
                                ? 'green'
                                : 'black',
                        }}
                        onClick={() => setSelectedTab(customerPages.customerInfo)}
                    >
                        Profile
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link
                        style={{
                            fontSize: '1.1rem',
                            maxWidth: '75vw',
                            color: selectedTab === customerPages.orderHistory
                                ? 'green'
                                : 'black',
                        }}
                        onClick={() => handleOrderHistory()}
                    >
                        Order History
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link
                        style={{
                            fontSize: '1.1rem',
                            maxWidth: '75vw',
                            color: selectedTab === customerPages.reloadQRCode
                                ? 'green'
                                : 'black',
                        }}
                        onClick={() => setSelectedTab(customerPages.reloadQRCode)}
                    >
                        QR Code
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link
                        style={{
                            fontSize: '1.1rem',
                            maxWidth: '75vw',
                            color: selectedTab === customerPages.customerPaymentInfo
                                ? 'green'
                                : 'black',
                        }}
                        onClick={() => setSelectedTab(customerPages.customerPaymentInfo)}
                    >
                        Payment Information
                    </Nav.Link>
                </Nav>
            </Navbar>
        </div>
    )
}