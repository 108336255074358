import React, { useEffect, useState } from 'react'
import { ViewStore } from '../public/viewStore'
import { Container } from 'react-bootstrap'
import { getTokensSyncNoUpdate, checkSession } from '../../api/auth/session'
import { Login } from '../navigation/login'
import userType from '../../enums/userTypes'
import { connectToAccountWS } from '../../api/sockets/userSocket'

/* eslint-disable react-hooks/exhaustive-deps */
export const KioskHome = ({ storeSocket, setStoreSocket, socketAuthed, setSocketAuthed, isStoreView, isStoreSite }) => {

    const [customerSocket, setCustomerSocket] = useState(null)
    const [validUser, setValidUser] = useState(false)

    const [categories, setCategories] = useState([])
    const [categoryLoading, setCategoryLoading] = useState(true)
    const [storeInfo, setStoreInfo] = useState(null)
    const [storeInfoLoading, setStoreInfoLoading] = useState(true)
    const [socketReconnecting, setSocketReconnecting] = useState(false)
    const [doneLoading, setDoneLoading] = useState(false)

    const storeInfoBundle = {
        categories, setCategories, categoryLoading,
        setCategoryLoading, storeInfo, setStoreInfo, storeInfoLoading, setStoreInfoLoading,
        socketReconnecting, setSocketReconnecting, storeSocket, setStoreSocket,
        isKiosk: true, doneLoading, setDoneLoading
    }

    var goodTokens = getTokensSyncNoUpdate(true)
    const [loggedIn, setLoggedIn] = useState(goodTokens)

    useEffect(() => {
        if (loggedIn && goodTokens && !storeSocket) {
            // Connect to WS
            const socketProps = {
                setSocket: setStoreSocket,
                notify: (a, b) => console.log(b),
                setSocketAuthed,
                ...goodTokens
            }
            setStoreSocket(connectToAccountWS(socketProps))
        }
        else if (goodTokens && !validUser && socketAuthed) {
            // Validate User
            setValidUser(true)
            checkSession()
        }
    }, [storeSocket, validUser, socketAuthed, loggedIn])

    if (!goodTokens && !loggedIn) {
        return <Container style={{ marginTop: '40vh' }}>
                    <Login props={{ showLogin: true, setLoggedIn: () => setLoggedIn(true), userType: userType.User }} />
                </Container>

    }
    else if (validUser) {
        return <ViewStore
            socket={customerSocket}
            setSocket={setCustomerSocket}
            isStoreView={isStoreView}
            isStoreSite={isStoreSite}
            props={storeInfoBundle}
        />
    }
    else {
        return <div></div>
    }
}