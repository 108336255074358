export const baseURL = `https://api.networksmarketer.com`
export const baseWS = `wss://api.networksmarketer.com`
export const baseWeb = `https://www.networksmarketer.com`
export const productImageBase = 'https://www.networksmarketer.com/images/'

export function handleErrors(response) {
    if (response.status === 401 || response.status === 404 || response.status === 503){
     throw Error(response.status)
    }
    if (!response.ok) {
      throw response.json()
    }
    if (response.status === 204){
      return {}
    }
    return response.json()
}