import React, { useState } from 'react';
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Fragment } from 'react'
import { createProduct } from '../../api/sockets/product/productRequests'
import { CircularProgress } from '@material-ui/core'
import CurrencyInput from '../utility/currencyInput'

export const CreateProduct = ({socket, notify, switchToOrder, back}) => {
    const isSmall = useMediaQuery('(max-width:525px)')

    const defaultProduct = {
        Name: '', //string
        Price: null, //decimal
        SizeDetails: '', //string
        TaxPercent: null, //decimal
        Description: '', //string
        Enabled: false, //bool
        Recipe: '', //string
    }

    const [newProduct, setNewProduct] = useState(defaultProduct)
    const [loading, setLoading] = useState(false)

    const updateProp = (x, propName) => {
        const clone = Object.assign({}, newProduct)
        clone[propName] = x
        setNewProduct(clone)
    }

    const validateAndCreate = (e) => {
        e.preventDefault()
        setLoading(true)

        socket.props.onSuccess = (newProductId) => {
            // May want to view our new item instead
            switchToOrder && switchToOrder(newProductId)
            back()
        }

        socket.props.onFailure = (msg) => {
            notify('Creation Error', msg)
        }
        socket.props.setLoading = setLoading
        const request = {
            ...newProduct,
            Price: parseFloat(newProduct.Price.replace('$','')),
        }
        createProduct(request, socket)
    }

    if (loading){
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
    }

    return (
        <Fragment>
            <Form onSubmit={validateAndCreate}>
                <Row>
                    <Col xs={0} md={1}></Col>
                    <Col>
                        <h2>
                            Create Product
                        </h2>
                    </Col>
                </Row>
                <Form.Group as={Row} style={{ alignItems: 'center', textAlign: 'end' }}>
                    <Form.Label column xs={4} sm={2}>
                        Product Name
                    </Form.Label>
                    <Col xs={7} sm={3}>
                        <Form.Control value={newProduct.Name || ''} onChange={(e) => updateProp(e.target.value, 'Name')} required maxLength={200} />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                        Description
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control as="textarea" rows={3} value={newProduct.Description || ''} onChange={(e) => updateProp(e.target.value, 'Description')} maxLength={500} style={{resize: 'none'}} />
                    </Col>
                    <Col xs={0} md={1}></Col>

                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                        Price
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <CurrencyInput
                            maskOptions={{prefix: '$'}}
                            placeholder="0.00"
                            className="form-control"
                            required
                            value={newProduct.Price}
                            onChange={(e) => updateProp(e.target.value, 'Price')}
                            guide={false}
                        />
                    </Col>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                        Tax Percent
                    </Form.Label>
                    <Col xs={3} md={2} lg={1}>
                    <CurrencyInput
                            className="form-control"
                            required
                            value={newProduct.TaxPercent}
                            onChange={(e) => updateProp(e.target.value, 'TaxPercent')}
                            guide={false}
                        />
                    </Col>
                    <Col xs={0} md={1}></Col>

                    <Col xs={0} md={2} lg={3}></Col>
                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                        Size
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control value={newProduct.SizeDetails || ''} onChange={(e) => updateProp(e.target.value, 'SizeDetails')} required maxLength={100} />
                    </Col>
                    <Form.Label column htmlFor="enabledChk" xs={4} md={2} style={{ marginBottom: '10px' }}>
                        Enabled
                    </Form.Label>
                    <Col xs={4} md={2} align="left">
                        <Form.Check id="enabledChk" type="switch" checked={newProduct.Enabled || false} onChange={(e) => updateProp(e.target.checked, 'Enabled')} />
                    </Col>
                    <Col xs={4} md={2}></Col>

                    <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                        Recipe
                    </Form.Label>
                    <Col xs={7} md={3}>
                        <Form.Control as="textarea" rows={3} value={newProduct.Recipe || ''} onChange={(e) => updateProp(e.target.value, 'Recipe')} maxLength={500} style={{resize: 'none'}} />
                    </Col>
                </Form.Group>

                <Row style={{marginTop: isSmall ? '0px' : '30px', marginBottom: '10px' }}>
                    <Col xs={12} sm md={5}>
                        <Row style={{justifyContent: isSmall ? 'center' : 'flex-end', marginBottom: '10px'}}>
                            <Button variant="danger" onClick={back}>Back</Button>
                        </Row>
                    </Col>
                    <Col xs={12} sm>
                        <Row style={{justifyContent: isSmall ? 'center' : 'flex-start', marginBottom: '10px'}}>
                            <Button type="submit">Create Product</Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}