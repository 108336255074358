import React, { useState, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'

export const WebsiteIntegration = ({socket, notify}) => {

    return <Fragment>
        <Row>
            <Col lg={1}></Col>
            <Col>
                <h4>Website Integration</h4>
            </Col>
        </Row>
        <hr />
    </Fragment>
}