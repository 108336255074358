import { CircularProgress } from '@material-ui/core'
import { Code, Redo, Save, Undo } from '@material-ui/icons'
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { stripoDontUseInProduction } from '../../api/account/email'
import { handleErrors } from '../../api/base'
import { baseHTML, baseCSS } from '../../utility/emailTemplateCode'

/* eslint-disable react-hooks/exhaustive-deps */
export const EmailTemplate = ({socket, notify, userId, templateId}) => {

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (!window.Stripo){
            let script = document.createElement('script')
            script.type = 'text/javascript'
            script.onload = loadStripo
            script.src = 'https://plugins.stripo.email/static/latest/stripo.js'
            document.getElementsByTagName('head')[0].appendChild(script)
        }
        else{
            loadStripo()
        }
        return () => {
            window.StripoApi && window.StripoApi.stop()
        }
    }, [])

    const loadStripo = () => {
        // emailId will actually be our UserId
        window.Stripo && window.Stripo.init({
            settingsId: 'EmailSettings',
            previewId: 'EmailPreview',
            html: baseHTML,
            css: baseCSS,
            codeEditorButtonId: 'CodeButton',
            // undoButtonId: 'UndoButton',
            // redoButtonId: 'RedoButton',
            apiRequestData: {
                emailId: userId
            },
            getAuthToken: async (callback) => {
                // TODO : Convert to WebSocket call
                try {
                    const response = await stripoDontUseInProduction()
                    const results = await handleErrors(response)
                    console.log(results)
                    if (results && results.token){
                        callback(results.token)
                    }
                    else{
                        callback(null)
                    }
                }
                catch (err){
                    callback(null)
                }
            }
        })
    }

    const saveTemplate = () => {
        window.StripoApi.compileEmail((error, html, ampHtml, ampErrors) => {
            if (error !== null){
                // show Error
                console.log(error)
            }
            else if (ampErrors){
                console.log(ampErrors)
            }

            let finalHtml = ampHtml || html

            window.StripoApi && window.StripoApi.getTemplate((rawHtml, css) => {
                // Make WS Call here
                console.log('Email Data', {
                    finalHtml,
                    rawHtml,
                    css
                })
                setSaving(true)
                setTimeout(() => setSaving(false), 10000)
            })
        })
    }

    return <Fragment>
        <Row style={{justifyContent: 'space-between'}}>
            <h4>
                {templateId ? 'New' : 'Edit'} Template
            </h4>
            <div>
                <Row style={{paddingRight: '40px'}}>
                    <Col style={{padding: '0px 10px 0px 10px'}}>
                        <Button variant="danger" id="UndoButton">
                            <Undo />
                        </Button>
                    </Col>
                    <Col style={{padding: '0px 10px 0px 10px'}}>
                        <Button variant="primary" id="RedoButton">
                            <Redo />
                        </Button>
                    </Col>
                    <Col style={{padding: '0px 10px 0px 10px'}}>
                        <Button variant="info" id="CodeButton">
                            <Code />
                        </Button>
                    </Col>
                    <Col style={{padding: '0px 10px 0px 10px'}}>
                        <Button variant="success" onClick={saveTemplate}>
                            {saving ? <CircularProgress style={{width: '22px', height: '22px'}} /> : <Save />}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Row>
        <hr />
        <Row>
            <Col lg={4} xl={3} style={{paddingRight: '5px'}}>
                <div id="EmailSettings">

                </div>
            </Col>
            <Col lg={8} xl={9} style={{paddingLeft: '5px'}}>
                <div id="EmailPreview">

                </div>
            </Col>
        </Row>
    </Fragment>
}