const customerPages = {
    storeInfo: 'STORE_INFORMATION',
    cart: 'SHOPPING_CART',
    createCustomer: 'CREATE_CUSTOMER',
    viewCustomer: 'VIEW_CUSTOMER',
    customerInfo: 'CUSTOMER_INFO',
    customerSignIn: 'CUSTOMER_SIGN_IN',
    tipPage: 'SELECT_TIP',
    authorizePayment: 'AUTHORIZE_PAYMENT',
    orderHistory: 'ORDER_HISTORY',
    customerPaymentInfo: 'CUSTOMER_PAYMENT_INFO',
    reloadQRCode: 'RELOAD_QR_CODE',
    forgotPassword: 'FORGOT_PASSWORD',
    addToCartSplash: 'ADD_TO_CART_SPLASH'
}

export default customerPages