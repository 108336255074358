import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Form } from 'react-bootstrap'
import { operatorProductList } from '../../../../api/sockets/operator/operatorRequests'
import { useMediaQuery } from '@material-ui/core'
import { Divider } from '@material-ui/core';
import { OperatorProductList } from "./operatorProductList"
import { OperatorCart } from "./operatorCart"
import { OperatorCombos } from "./operatorCombos"
import { OperatorCheckout } from './operatorCheckout'

export const OperatorCreateOrder = ({ props }) => {
    const { socket, notify, back } = props
    const [loading, setLoading] = useState(false)
    const isSmall = useMediaQuery('(max-width:650px)')
    const isBig = useMediaQuery('(min-width:1200px)')

    // Product List
    const [searchValue, setSearchValue] = useState('')
    const [productList, setProductList] = useState(null)
    const [filteredProducts, setFilteredProducts] = useState(null)
    const [includeDisabled, setIncludeDisabled] = useState(false)
    const [combos, setCombos] = useState(null)


    const [orderItems, setOrderItems] = useState([])
    // TODO: Integrate OrderItems into 1 cart, right now products and combos are seperate
    const [cart, setCart] = useState({ Products: [], Combos: [] })
    const [orderTotal, setOrderTotal] = useState(null)
    const [orderTax, setOrderTax] = useState(0)
    const [cartItemId, setCartItemId] = useState(null)

    const maxGrandTotal = 10000000000000 // Infinity was causing some display issues with large numbers 1e7 etc

    useEffect(() => {
        if (socket !== null && productList === null) {
            reloadProductList(false)
        }
    }, [socket])

    const reloadProductList = (withDisabled) => {
        setLoading(true)
        socket.props.onListFailure = notify
        socket.props.setLoading = setLoading

        socket.props.onListSuccess = (response) => {
            const products = response.Products.map(x => {
                return {
                    ...x,
                    Quantity: '',
                    SelectedSizeId: x.Sizes[0].ProductId,
                    SelectedIngredients: x.Ingredients.map(x => x.IngredientId),
                    SelectedAddons: x.Addons.filter(x => x.DefaultOn).map(x => x.IngredientId)
                }
            })
            setProductList(products)
            setFilteredProducts(products)
            // Only Combos that are complete should be able to get ordered (Each category has at least 1 product in it)
            let combosWithProducts = []
            response.Combos.forEach(x => {
                if (!x.Categories.some(y => y.Products.length === 0)) {
                    combosWithProducts.push(x)
                }
            })
            setCombos(combosWithProducts)
        }

        const request = {
            IncludeDisabled: withDisabled
        }
        operatorProductList(request, socket.socket)
    }

    // TODO: Handle this on the backend.
    // This is a subtotal (pre-tax) Actually
    const calculateTotal = (items, combos) => {
        if (!items) {
            items = orderItems
        }
        if (!combos) {
            combos = cart.Combos
        }

        let total = 0
        items.forEach(x => {
            total += (Number(x.Price) * Number(x.Quantity))
        })
        combos.forEach(x => {
            x.Products.forEach(y => {
                total += (Number(y.Price) * Number(y.Quantity)) 
            })
            total -= x.Discount
            total *= x.Quantity
        })

        if (total <= maxGrandTotal) {
            setOrderTotal(total)
        }
        setOrderTax(calculateTaxTotal(items, combos))
        return total
    }

    const calculateTaxTotal = (items, combos) => {
        let tax = 0
        items.forEach(x => {
            tax += calculateTax(x.Price, x.Quantity, x.TaxPercent)
        })
        combos.forEach(x => {
            x.Products.forEach(y => {
                tax += calculateTax(y.Price - (x.Discount/x.Products.length), y.Quantity, y.TaxPercent)
            })
        })
        return tax
    }

    const calculateTax = (price, quantity, taxPercent) => {
        quantity = Number(quantity)
        if(price > 0){
            return price * quantity * (taxPercent / 100)
        }
        return 0
    }



    const searchProduct = (val) => {
        const products = productList.filter(x => x.Name.toLowerCase().includes(val.toLowerCase()))
        if (products && products.length > 0) {
            setFilteredProducts(products)
        }
    }

    return (
        <Fragment>
            <Form.Group as={Row}>
                <Col xl={12} style={{ marginTop: '1rem' }}>
                    <Row style={{ paddingBottom: '1.5rem', flexDirection: isSmall ? '' : 'row-reverse', marginRight: isBig ? '5rem' : '0rem' }}>
                    </Row>
                    <Row style={{ paddingBottom: '1rem', paddingLeft: '1.5rem' }}>
                        <h4>Products</h4>
                        <Col xl={2} lg={4} md={4} sm={6} xs={6} className="my-auto">
                            <Form.Control size={"sm"} as="select" style={{ textAlign: 'right' }} value={includeDisabled} onChange={(e) => { setIncludeDisabled(e.target.value); reloadProductList(e.target.value) }}>
                                <option value={false}>Active Products</option>
                                <option value={true}>All Products</option>
                            </Form.Control>
                        </Col>

                        <Col xl={{ offset: 4, span: 4 }} md={6} sm={8} xs={12} style={{ marginRight: isBig ? '5rem' : '0rem', paddingLeft: isSmall ? '0' : 'inherit' }}>
                            <Form.Control placeholder="Search for a Product" value={searchValue || ''} onChange={(e) => { setSearchValue(e.target.value); searchProduct(e.target.value) }} />
                        </Col>
                    </Row>
                    {/* TODO: resolve some of these dependencies */}
                    <OperatorProductList props={{
                        setProductList: setProductList,
                        setFilteredProducts: setFilteredProducts,
                        setOrderItems: setOrderItems,
                        setCartItemId: setCartItemId,
                        cartItemId: cartItemId,
                        notify: notify,
                        calculateTotal: calculateTotal,
                        setOrderTotal: setOrderTotal,
                        maxGrandTotal: maxGrandTotal,
                        orderItems: orderItems,
                        productList: productList,
                        orderTotal: orderTotal,
                        filteredProducts: filteredProducts,
                        loading: loading
                    }}
                    />
                </Col>
            </Form.Group>

            <Divider style={{ margin: '1rem' }} />
            {combos && productList &&

                <OperatorCombos
                    socket={socket}
                    combos={combos}
                    products={[...productList]}
                    setCart={setCart}
                    cart={cart}
                    setCartItemId={setCartItemId}
                    cartItemId={cartItemId}
                    notify={notify}
                    calculateTotal={calculateTotal}
                />
            }
            <Divider style={{ margin: '1rem' }} />

            <OperatorCart props={{
                orderItems: orderItems,
                calculateTotal: calculateTotal,
                setOrderItems: setOrderItems,
                cart: cart,
                setCart: setCart,
                products: productList
            }}
            />
            <Divider style={{ margin: '1rem' }} />

            <OperatorCheckout
                notify={notify}
                orderItems={orderItems}
                cart={cart}
                socket={socket}
                back={back}
                orderTotal={orderTotal}
                orderTax={orderTax}
                setCart={setCart}
                setOrderTotal={setOrderTotal}
                setOrderItems={setOrderItems}
                setOrderTax={setOrderTax}
            />
        </Fragment>
    )
}