import React from 'react'
import { LeftNavTab } from './leftNavTab'
import customerPages from '../../enums/customerPages'

export const LeftCustomerNav = ({props, items}) => {

    const navPages = [customerPages.cart, customerPages.createCustomer, customerPages.storeInfo, customerPages.viewCustomer]

    return <div style={{display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        {items && items.map(x => {
            const prodPage = `PRODUCT_CATEGORY_ID_${x.ProductCategoryId}`
            const selectedValue = navPages.includes(props.selectedPage) 
                ? props.selectedPage 
                : [props.selectedPage, props.lastCategory]
            return <LeftNavTab
                text={x.Name}
                key={x.ProductCategoryId}
                selectKey={prodPage}
                selector={(id) => { props.setSelectedPage(id); props.setLastCategory(id); }}
                selected={selectedValue}
            />
        })}
    </div>
}