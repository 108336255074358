import React, { useState, Fragment } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import { addProductCategory } from '../../../api/sockets/account/accountRequests'

export const CreateProductCategory = ({show, setShow, socket, notify, props}) => {

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const submitRequest = (e) => {
        e.preventDefault()
        setLoading(true)
        if (loading){return}
        
        const requestData = {
            Name: name,
            Enabled: true
        }
        
        socket.props.setLoading = setLoading
        socket.props.notify = notify
        socket.props.onSuccess = () => {
            setName('')
            setLoading(false)
            props.reload && props.reload()
            setShow(false)
        }
        socket.props.onFailure = (msg) => {
            setError(msg)
        }

        addProductCategory(requestData, socket)
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header>
                <Modal.Title>Create Product Category</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitRequest}>
                <Modal.Body>
                    {loading
                    ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                        <CircularProgress style={{width: '100px', height: '100px'}} />
                    </div>
                    : <Fragment>
                        {error && <Row style={{justifyContent: 'center'}}>
                            <Form.Label style={{color: 'red', fontWeight: 'bold'}}>
                                {error}
                            </Form.Label>
                        </Row>}
                        <Form.Group as={Row}>
                            <Form.Label column xs={3}>
                                Name
                            </Form.Label>
                            <Col>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                        </Form.Group>
                    </Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!loading && <Button variant="success" type="submit">Submit</Button>}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}