import React, { useState, Fragment, useEffect, useRef } from "react"
import { Button, Col, Row, Form } from 'react-bootstrap'
import { useMediaQuery, ListItemText, List, ListItem, ListItemIcon } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { OperatorComboItem } from './operatorComboItem'

export const OperatorComboDetail = ({ back, products, combo, setCart, cartItemId, setCartItemId, notify, calculateTotal, cart }) => {
    const isSmall = useMediaQuery('(max-width:1000px)') //IPad
    const [comboItem, setComboItem] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')

    // Todo: Handle Next, and Add to cart
    const [openCategories, setOpenCategories] = useState([...combo.Categories])
    const [filteredProducts, setFilteredProducts] = useState(null)
    const [selectedComboItems, setSelectedComboItems] = useState([])

    useEffect(() => {
        if (openCategories && openCategories.length >= 1) {
            setFilteredProducts(openCategories[0].Products)
        }
    },[openCategories])

    const searchProducts = (search) => {
        if (search && openCategories && openCategories.length >= 1) {
            search = search.toLowerCase()
            const matches = openCategories[0].Products.filter(x => x.Name.toLowerCase().includes(search))
            setFilteredProducts(matches)
        }
        else if (openCategories && openCategories.length >= 1) {
            setFilteredProducts(openCategories[0].Products)
        }
    }
    return (
        <Fragment>
            {comboItem && openCategories ?
            // Nasty Exhaustive dependencies
                <OperatorComboItem
                    comboItem={comboItem}
                    setComboItem={setComboItem}
                    setOpenCategories={setOpenCategories}
                    openCategories={openCategories}
                    setSelectedComboItems={setSelectedComboItems}
                    selectedComboItems={selectedComboItems}
                    back={back}
                    setCart={setCart}
                    combo={combo}
                    cart={cart}
                    setCartItemId={setCartItemId}
                    cartItemId={cartItemId}
                    notify={notify}
                    calculateTotal={calculateTotal}
                />
                :
                    <Fragment>
                        <Row>
                            <Col md={{ span: 5, offset: 7 }} style={{marginBottom: '1rem'}}>
                                <Form.Control value={searchTerm} type="search" placeholder={'Search Products'} onChange={(e) => { setSearchTerm(e.target.value); searchProducts(e.target.value) }}></Form.Control>
                            </Col>
                        </Row>
                        {combo && Boolean(openCategories && openCategories.length > 0) && Boolean(filteredProducts)
                            && (
                                <Fragment>
                                    <Row>
                                        <Col md={{ offset: '2', span: '6' }}>
                                            <h5>Select your {openCategories[0].Name}</h5>
                                        </Col>
                                    </Row>
                                    <Row style={{ maxHeight: '25rem', overflowY: 'scroll' }}>
                                        <Col md={10} lg={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            <List role="list">
                                                {filteredProducts.map((x, i) => {
                                                    const product = products.filter(z => x.ProductId === z.SelectedSizeId)[0]
                                                    return (
                                                        <ListItem key={i} dense button onClick={() => setComboItem(product)}>
                                                            <ListItemText primary={x.Name} primaryTypographyProps={{ style: { fontSize: isSmall ? '1rem' : '1.25rem' } }} />
                                                            <ListItemIcon>
                                                                <Button
                                                                    tabIndex={-1}
                                                                    style={{ marginRight: '2rem', minWidth: '7.5rem' }}
                                                                    onClick={() => setComboItem(product)}
                                                                >
                                                                    <ListItemText primary="Add" />
                                                                </Button>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                    </Fragment>
                }
            <ArrowBackIosIcon color="primary" style={{ marginLeft: '3rem', cursor: 'pointer' }} fontSize="large" onClick={back} />
        </Fragment>
    )

}
