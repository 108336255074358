import { CircularProgress, useMediaQuery } from '@material-ui/core'
import React, { useState, Fragment, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap'
import CurrencyInput from '../../utility/currencyInput'
import { updateOrderDiscount } from '../../../api/sockets/operator/operatorRequests'
import operatorPages from '../../../enums/operatorPages'
import operatorDiscountType, { operatorDiscountTypeLookup } from '../../../enums/operatorDiscountType'

export const OperatorAddDiscount = ({ socket, notify, show, setShow, setSelectedPage }) => {
    const isSmall = useMediaQuery('(max-width:768px)')
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    const [loading, setLoading] = useState(false)
    const [discountAmount, setDiscountAmount] = useState('')
    const [discountType, setDiscountType] = useState(operatorDiscountType.CustomDiscount)
    const [discountDetail, setDiscountDetail] = useState('')
    const [badAmount, setBadAmount] = useState(false)
    const [success, setSuccess] = useState(null)

    const handleSubmitDiscount = (e) => {
        e.preventDefault()
        

        if (discountAmount && Number(discountAmount) <= show.Amount){
            setBadAmount(false)
        }
        else{
            setBadAmount(true)
            return
        }

        setLoading(true)
        const requestData = {
            CustomerId: show.CustomerId,
            OrderId: show.OrderId,
            Detail: discountDetail,
            Amount: discountAmount,
            DiscountType: discountType
        }

        socket.props.onUpdateOrderDiscountFailure = (msg) => {
            notify('Update Error', msg)
        }
        socket.props.onUpdateOrderDiscountSuccess = (data) => {
            // Should update the list with the data.NewStatus, but just gonna reload
            setShow(null)
        }
        socket.props.setUpdateLoading = setLoading

        updateOrderDiscount(requestData, socket.socket)
    }

    const hideAndReset = () => {
        setLoading(false)
        const orderDetail = show && `${operatorPages.orderDetail}${show.OrderId}|${show.CustomerId}`
        setShow(false)
        if (success) {
            setSelectedPage(orderDetail)
        }
    }

    return <Modal show={Boolean(show)} onHide={hideAndReset} backdrop="static" size="lg">
        <Modal.Header closeButton>
            <Modal.Title>
                Add Discount
                {show && ` - Order No. ${show.OrderNo}`}
            </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitDiscount}>
            <Modal.Body>
                {loading
                    ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                        <CircularProgress style={{ width: '100px', height: '100px' }} />
                    </div>
                    :
                    <Fragment>
                        <Row style={{ justifyContent: 'center', marginBottom: '50px' }}>
                            <h3>Amount Due: {moneyFormat(show.Amount)}</h3>
                        </Row>
                        <Form.Group as={Row}>
                            <Form.Label column xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: isSmall ? 'center' : 'end' }} >
                                Amount
                            </Form.Label>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <InputGroup style={{ width: '200px', margin: isSmall ? '0px auto' : '0px'  }}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput
                                        placeholder="0.00"
                                        className="form-control"
                                        required
                                        value={discountAmount}
                                        onChange={(e) => setDiscountAmount(e.target.value)}
                                        type="number"
                                    />
                                    {badAmount && (
                                        <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                                            The discount cannot be greater than the amount
                                        </Form.Control.Feedback>
                                    )}
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: isSmall ? 'center' : 'end' }}>
                                Type
                            </Form.Label>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Form.Control 
                                    column 
                                    as="select" 
                                    value={discountType} 
                                    maxLength={1} 
                                    style={{ width: '200px', margin: isSmall ? '0px auto' : '0px'  }} 
                                    onChange={(e) => setDiscountType(e.target.value)}
                                >
                                    {Object.entries(operatorDiscountType).map(x => {
                                        return (
                                            <option value={x[0]}>{operatorDiscountTypeLookup[x[1]]}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column xs={12} sm={12} md={4} lg={4} xl={4} style={{ textAlign: isSmall ? 'center' : 'end' }}>
                                Detail
                            </Form.Label>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Control 
                                    as="textarea"
                                    rows={3}
                                    value={discountDetail}
                                    onChange={(e) => setDiscountDetail(e.target.value)}
                                    maxLength={200}
                                    style={{ resize: 'none' }}
                                />
                            </Col>
                            <Col md={2} lg={2} xl={2}></Col>
                        </Form.Group>
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                {!loading && !success && (
                    <Button type="submit">
                        Submit
                    </Button>
                )}
            </Modal.Footer>
        </Form>
    </Modal>
}