import React, { useState } from 'react'
import { Col, Row, Form, Button, Container } from 'react-bootstrap'
import { useMediaQuery, Typography, CircularProgress } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import { operatorCreateCustomer } from '../../../../api/sockets/operator/operatorRequests'

export const OperatorCreateCustomer = ({ props }) => {
    const isBig = useMediaQuery('(min-width:1200px)')

    const { socket, notify, setShowCreate, setCustomer } = props
    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [appCreated, setAppCreated] = useState(false) // Only false until native
    const [errorMessage, setErrorMessage] = useState('')


    const createCustomer = (e) => {
        e.preventDefault()

        setLoading(true)
        socket.props.onCreateCustomerFailure = (message) => {
            setErrorMessage(message)
        }

        socket.props.setCreateLoading = setLoading

        socket.props.onCreateCustomerSuccess = (response) => {
            notify('Success', 'Your customer is ready to order!')
            const newCustomer = {
                CustomerId: response.CustomerId,
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Phone: phone
            }
            setCustomer(newCustomer)
            setShowCreate(false)
        }

        const request = {
            Email: email,
            FirstName: firstName,
            LastName: lastName,
            Phone: phone,
            AppCreated: appCreated
        }
        operatorCreateCustomer(request, socket.socket)
    }


    return (
        <Container>
            <Form onSubmit={createCustomer}>
                {errorMessage && <Typography style={{ color: 'red', fontSize: '.75rem', marginLeft: '8rem' }}>{errorMessage}</Typography>}
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={3}>First Name</Form.Label>
                    <Col>
                        <Form.Control value={firstName || ''} onChange={(e) => setFirstName(e.target.value)} required />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={3}>Last Name</Form.Label>
                    <Col>
                        <Form.Control value={lastName || ''} onChange={(e) => setLastName(e.target.value)} required />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={3}>Email</Form.Label>
                    <Col>
                        <Form.Control type="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} required />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={3}>Phone</Form.Label>
                    <Col>
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control"
                            placeholder="Enter a phone number"
                            guide={false}
                            onBlur={(e) => { setPhone(e.target.value) }}
                            onChange={(e) => { setPhone(e.target.value) }}
                            required
                        />
                    </Col>
                </Form.Group>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>
                {loading && <CircularProgress style={{marginRight: '1rem'}} /> }
                    <Button type="submit" disabled={loading}>Submit</Button>
                </div>
            </Form>
        </Container>
    )
}