// In this file we will load the combo details which will tell us the 
// categories and products offered in each category.
// From there we will cycle through the categories -> combo item until done

import React, { Fragment, useState, useEffect } from 'react'
import { viewStoreCombo } from '../../../api/sockets/combos/comboRequests'
import { Row } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { StoreProductCard } from '../../products/storeProductCard'
import { StoreComboItemDetail } from './storeComboItemDetail'
import { addCartCombo } from '../../../api/sockets/cart/cartRequests'
import { StoreComboAddSplash } from './storeComboAddSplash'


export const StoreComboDetail = ({socket, notify, comboId, storeId, setSelectedPage, lastCategory, setItemsInCart}) => {

    const [combo, setCombo] = useState(null)
    const [loading, setLoading] = useState(null)
    const [completedCats, setCompletedCats] = useState([])
    const [items, setItems] = useState([])
    const [nextCat, setNextCat] = useState(null)
    const [openProd, setOpenProd] = useState(null)
    const [prefix, setPrefix] = useState('')
    const [added, setAdded] = useState(false)
    const [cartItems, setCartItems] = useState([])

    const isSmall = useMediaQuery('(max-width:525px)')

    useEffect(() => {
        if (socket !== null && socket.socket.readyState === WebSocket.OPEN && comboId){
            reloadCombo(comboId)
        }
    }, [comboId])

    const reloadCombo = (cId) => {
        setLoading(true)

        const requestData = {
            StoreAccountId: storeId,
            ComboId: cId
        }
        
        socket.props.setComboLoading = setLoading
        socket.props.notify = notify
        socket.props.onComboSuccess = (data) => {
            setCombo(data)
            calcNextCat(null, data)
        }
        socket.props.onComboFailure = (msg) => {
            notify('Error', msg)
        }

        viewStoreCombo(requestData, socket.socket)
    }

    const calcNextCat = (overrideCats, overrideCombo) => {
        if (!overrideCats){
            overrideCats = completedCats
        }
        if (!overrideCombo){
            overrideCombo = combo
        }
        
        if (overrideCombo && overrideCombo.Categories){
            setNextCat(overrideCombo.Categories.filter(x => !overrideCats.includes(x.CategoryId))[0])
            
            if (overrideCombo.Categories.length === (overrideCats.length + 1)){
                setPrefix('Last')
            }
            else if (!overrideCats.length){
                setPrefix('First')
            }
            else{
                setPrefix('Next')
            }
        }
    }

    const addItemToCombo = (item, send) => {
        const itemClone = items.map(x => x)
        itemClone.push(item)
        setItems(itemClone)

        const clone = completedCats.map(x => x)
        clone.push(nextCat.CategoryId)
        setCompletedCats(clone)

        calcNextCat(clone)
        setOpenProd(null)

        if (send){
            const requestData = {
                StoreAccountId: storeId,
                ComboId: comboId,
                ComboItems: itemClone,
                Quantity: 1 // TODO : Add a UI for this
            }

            socket.props.onCartFailure = (msg) => {
                notify('Error', msg)
            }
            socket.props.onCartSuccess = (data) => {
                setItemsInCart(data.ItemsInCart)
                localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
                setCartItems(data.ShoppingCart.Items.filter(x => x.CartComboItemId === data.CartComboItemId))
                setAdded(true)
            }

            addCartCombo(requestData, socket.socket)
        }
    }

    return <Fragment>
        {loading
            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
            : openProd 
                ? <StoreComboItemDetail
                    productId={openProd}
                    comboId={comboId}
                    socket={socket}
                    notify={notify}
                    storeId={storeId}
                    setItemsInCart={setItemsInCart}
                    back={() => setOpenProd(null)}
                    isLast={prefix}
                    cartSubmit={addItemToCombo}
                />
                : <Fragment>
                    {nextCat 
                        ? (
                            <Fragment>
                                <Row style={{paddingLeft: isSmall ? '0' : '4rem', paddingRight: isSmall ? '1rem' : '4rem'}}>
                                    <h2>{prefix} Select Your {nextCat.Name}</h2>
                                </Row>
                                <Row style={{paddingLeft: isSmall ? '0' : '4rem', paddingRight: isSmall ? '1rem' : '4rem'}}>
                                    {nextCat.Products && nextCat.Products.map((x, i) => {
                                        return <StoreProductCard key={i} product={x} setFullProduct={setOpenProd} />
                                    })} 
                                </Row>
                            </Fragment>
                        )
                        : completedCats.length
                            ? added 
                                ? <StoreComboAddSplash
                                        lastCategory={lastCategory}
                                        storeId={storeId}
                                        socket={socket}
                                        notify={notify}
                                        setSelectedPage={setSelectedPage}
                                        addedItems={cartItems}
                                    />
                                : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                                    <CircularProgress style={{width: '100px', height: '100px'}} />
                                </div>
                            : <h2>No Categories</h2>
                    }
                </Fragment>
        }
    </Fragment>
}