import React, { useEffect, useState, Fragment, useRef } from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { addComboCategory } from '../../../api/sockets/combos/comboRequests'

export const CreateCategory = ({ socket, show, setShow, comboId, notify, categories }) => {
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)



    const createComboCategory = (e) => {
        e.preventDefault()
        setLoading(true)

        socket.props.setAddCategoryLoading = setLoading
        socket.props.onAddComboCategoryFailure = notify
        socket.props.onAddComboCategorySuccess = (response) => {
            // We will need to load the category again
            const newCategory = {
                ComboCategoryId: response.ComboCategoryId,
                Name: response.Name,
                Products: []
            }
            categories && categories.push(newCategory)
            hide()
        }
        const request = {
            ComboId: comboId,
            Name: name,
        }
        addComboCategory(request, socket.socket)

    }

    const hide = () => {
        setShow(false)
        setName('')
        setError(null)
    }


    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header>
                <Modal.Title>Create Combo Category</Modal.Title>
            </Modal.Header>
            <Form onSubmit={createComboCategory}>
                <Modal.Body>
                    <Fragment>
                        {error && <Row style={{ justifyContent: 'center' }}>
                            <Form.Label style={{ color: 'red', fontWeight: 'bold' }}>
                                {error}
                            </Form.Label>
                        </Row>}
                        <Form.Group as={Row}>
                            <Form.Label column xs={3}>
                                Name
                            </Form.Label>
                            <Col>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                        </Form.Group>
                    </Fragment>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="success" type="submit">Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )


}