import React, { useState, useEffect, Fragment } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import customerPages from '../../enums/customerPages'
import { customerHistoryDetail } from '../../api/sockets/order/orderRequests'
import orderStatus, { orderStatusLookup } from '../../enums/orderStatus'
import { clearCustomerSession } from '../../api/auth/session'
import { useHistory } from 'react-router-dom'

export const OrderSplash = ({ socket, setSelectedPage, orderId, notify, storeId, isKiosk }) => {
    const [orderDetails, setOrderDetails] = useState(null)
    const [orderDetailStatus, setOrderDetailStatus] = useState(orderStatus.Unknown)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (socket !== null && orderId) {
            reloadOrderDetails()
            checkNewOrderStatus()
        }
        if (storeId){
            localStorage.removeItem(`${storeId}_CART`)
        }
    }, [orderId])

    const reloadOrderDetails = () => {
        setLoading(true)
        socket.props.onFailure = notify
        socket.props.setLoading = setLoading

        socket.props.onSuccess = (data) => {
            setOrderDetails(data)
            setOrderDetailStatus(data.Status)
            if (isKiosk) {
                setTimeout(() => logOut(), 10000)
            }
        }
        const request = {
            OrderId: orderId,
            StoreAccountId: storeId,
        }

        // TODO: Maybe Change this name
        customerHistoryDetail(request, socket.socket)
    }

    const checkNewOrderStatus = () => {
        socket.props.onOrderUpdateFailure = notify

        socket.props.onOrderUpdateSuccess = (data) => {
            if (data) {
                setOrderDetailStatus(data.NewStatus)

            }
        }
    }

    const logOut = () => {
        clearCustomerSession(true)
        sessionStorage.removeItem('currentPage')
        // history.push(window.location.pathname)
        // notify('Logged Out', 'Logged Out Successfully')
        setSelectedPage(customerPages.storeInfo)
        window.location.reload()
    }

    // if (isKiosk) {
    //     return (
    //         <Modal backdrop="static" style={{paddingTop:'12rem'}} keyboard={false} show={true}>
    //             <Modal.Header>
    //                 <Modal.Title>Thank you!</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body style={{ paddingBottom: '1.75rem' }}>
    //                 <p>Thanks for your order.  You will now be logged out.</p>
    //             </Modal.Body>
    //         </Modal>
    //     )
    // }
    // else {
    return (
        <Modal size="lg" backdrop="static" keyboard={false} show={true}>
            <Modal.Header>
                {isKiosk
                    ? <Modal.Title>Thank you! You will now be logged out</Modal.Title>
                    : <Modal.Title>Thank you!</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body style={{ paddingBottom: '1.75rem' }}>
                <h5>Order Details</h5>
                {orderDetails &&
                    <Fragment>
                        <Table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Quantity</td>
                                    <td>Price</td>
                                    <td>Tax</td>
                                </tr>
                            </thead>
                            <tbody style={{ paddingBottom: '10rem' }}>
                                {orderDetails.OrderItems &&
                                    orderDetails.OrderItems.map(x => {
                                        return (
                                            <tr key={x.ProductId}>
                                                <td>{x.Name}</td>
                                                <td>{x.Quantity}</td>
                                                <td>{`$${x.Price.toFixed(2)}`}</td>
                                                <td>{`$${x.Tax.toFixed(2)}`}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <h5 style={{ paddingTop: '3rem', textAlign: 'right', paddingRight: '2rem' }}>
                            {`Order Status: ${orderStatusLookup[orderDetailStatus]}`}
                        </h5>
                        <h5 style={{ paddingTop: '.5rem', textAlign: 'right', paddingRight: '2rem' }}>{`Tip: $${orderDetails.TipAmount.toFixed(2)}`}</h5>
                        <h5 style={{ textAlign: 'right', paddingRight: '2rem' }}>{`Total: $${orderDetails.ChargedAmount.toFixed(2)}`}</h5>
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                {!isKiosk && <Button onClick={() => { setSelectedPage(customerPages.storeInfo) }}>Countinue Shopping</Button>}
                <Button variant="danger" onClick={() => logOut()}>Log Out</Button>
            </Modal.Footer>
        </Modal>
    )
    // }
}