import React, { Fragment, useState, useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { updateDefaultPayment } from '../../../api/sockets/customer/customerRequests'

export const CustomerPaymentInfo = ({ props }) => {
    const { socket, notify, storeId, storeInfo } = props

    const isSmall = useMediaQuery('(max-width:768px)')
    const [loading, setLoading] = useState(false)

    // Old Card Info
    const [currentCard, setCurrentCard] = useState(null)

    // New Card Info
    const [cardError, setCardError] = useState('Fill Out Card Informaton')
    const [cardName, setCardName] = useState('')
    const [cardZip, setCardZip] = useState('')
    const [finalCardName, setFinalCardName] = useState('')
    const [finalCardZip, setFinalCardZip] = useState('')

    // This is from cardpoint
    const [newToken, setNewToken] = useState('')


    useEffect(() => {
        const handleCardTokenReceive = (event) => {
            if (typeof (event.data) === 'string') {
                const response = JSON.parse(event.data)

                if (response.validationError) {
                    setCardError(response.validationError)
                }
                else if (response.token) {
                    console.log(response)
                    setNewToken(response.token)
                    setCardError('')
                }
            }
        }

        // Rebind Card Frame
        window.addEventListener('message', handleCardTokenReceive, false)

        // Cleanup old listener
        return () => {
            window.removeEventListener('message', handleCardTokenReceive, false)
        }
    }, [socket])


    const setNewDefaultPayment = () => {
        setLoading(true)
        socket.props.onUpdateDefaultPaymentFailure = (msg) => {
            notify('Update Error', msg)
        }
        socket.props.onUpdateDefaultPaymentSuccess = (data) => {
            notify('Success', 'Payment Information Updated')
            setNewToken('')
            setCurrentCard(data.PaymentMethodDetails)
        }
        socket.props.setLoading = setLoading

        const requestData = {
            StoreAccountId: storeId,
            NameOnCard: finalCardName,
            ZipCode: finalCardZip,
            PaymentToken: newToken
        }
        updateDefaultPayment(requestData, socket.socket)
    }

    const cssQueryString = 'input,select{display:inline-block;font-size:1rem;padding:.375rem .75rem;font-weight:400;line-height:1.5;color:#495057;background-color:#fff;border:1px solid #ced4da;border-radius:.25rem;margin:10px}input:focus,select:focus{color:#495057;background-color:#fff;border-color:#80bdff;box-shadow:0 0 0 .2rem rgb(0 123 255 / 25%);outline:0}label{font-size:1.2rem}.error{border-color: red;background-color: #ffd5d5}'
    let iFrameQueryBuilder = '?useexpiry=true&usecvv=true'
    iFrameQueryBuilder += '&invalidcreditcardevent=true&enhancedresponse=true'
    iFrameQueryBuilder += '&css=' + encodeURIComponent(cssQueryString)


    return (
        <Fragment>
            <Row >
                <Col lg={1}></Col>
                <Col>
                    <h3>Change Payment Method</h3>
                </Col>
                <Col>
                    {/* TODO: will have to set the current info if it exists */}
                    {currentCard && (
                        <h5>Current Card {currentCard}</h5>
                    )}
                </Col>
            </Row>

            {newToken && finalCardName && finalCardZip
                ? (
                    <Fragment>
                        <Row style={{ justifyContent: 'space-evenly', marginTop: '50px' }}>
                            <Button variant="success" onClick={() => setNewDefaultPayment()} style={{ marginBottom: '10px', padding: '8px 14px 8px 14px' }}>Set as New Default Payment</Button>
                            <Button variant="danger" onClick={() => setNewToken('')} style={{ marginBottom: '10px', padding: '8px 14px 8px 14px' }}>Change Card Information</Button>
                        </Row>
                    </Fragment>
                )
                : (
                    <Row style={{ marginTop: '50px' }}>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                        <Col>
                            {Boolean(cardError) && (
                                <Row>
                                    <span style={{ paddingLeft: '20px' }}>{cardError}</span>
                                </Row>
                            )}

                            <Row style={{ height: '60%', marginTop: '20px' }}>
                                <Form style={{ height: '40vh' }}>
                                    <Row>
                                        <Form.Label column style={{ fontSize: '1.2rem', fontFamily: 'Times New Roman', paddingLeft: '8px' }}>
                                            Name On Card
                                            </Form.Label>
                                        <Form.Control style={{ marginLeft: '16px' }} value={cardName}
                                            onChange={(e) => setCardName(e.target.value)}
                                            onBlur={(e) => setFinalCardName(e.target.value)}
                                        />
                                    </Row>
                                    <Row>
                                        <Form.Label column style={{ fontSize: '1.2rem', fontFamily: 'Times New Roman', paddingLeft: '8px' }}>
                                            {storeInfo.Currency === 'USD' ? 'Zip Code' : 'Postal Code'}
                                        </Form.Label>
                                        <Form.Control style={{ marginLeft: '16px' }} value={cardZip}
                                            onChange={(e) => setCardZip(e.target.value)}
                                            onBlur={(e) => setFinalCardZip(e.target.value)}
                                        />
                                    </Row>
                                    <iframe
                                        style={{ height: '250px' }}
                                        id="tokenFrame"
                                        name="tokenFrame"
                                        src={`https://boltgw${!storeInfo.IsProduction ? '-uat' : ''}.cardconnect.com/itoke/ajax-tokenizer.html${iFrameQueryBuilder}`}
                                        frameBorder="0"
                                        scrolling="no"
                                    />
                                </Form>
                            </Row>
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                    </Row>
                )}
        </Fragment>
    )
}