import requestType from '../../../enums/requestType'

export const getReportDashboards = (data, socketInfo) => {
    const request = {
        RequestType: requestType.GetReportDashboards,
        Data: data
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const getDashboardURL = (data, socketInfo) => {
    const request = {
        RequestType: requestType.GetDashboardURL,
        Data: data
    }

    socketInfo.socket.send(JSON.stringify(request))
}