import React, { useEffect, useState } from 'react'
import { ProductCard } from './productCard'
import { Row, Col, Button, Form, Accordion, Card, useAccordionToggle } from 'react-bootstrap'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CircularProgress } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import debounce from 'lodash/debounce'

export const ProductList = ({props}) => {

    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    const [currentKey, setCurrentKey] = useState('')

    useEffect(() => {
        setActiveList(props.list)
        setOGList(props.list)
        setCurrentKey(sessionStorage.getItem('LAST_PROD_CAT') || '')
    }, [props.list])

    const [activeList, setActiveList] = useState(props.list)
    const [ogList, setOGList] = useState(props.list)
    const [search, setSearch] = useState('')

    const debouncedSearch = debounce((text) => { 
        if (!text) {
            setActiveList(ogList)
            return
        }
        const lower = text.toLowerCase()

        const copy = ogList.map(x => {
            const newX = Object.assign({}, x)
            newX.Items = x.Items.map(y => y).filter(y => y.Name.toLowerCase().includes(lower))
            return newX
        })
        setActiveList(copy.filter(x => x.Items.length))
    }, 300)

    const filterItems = (text) => {
        setSearch(text)
        debouncedSearch(text)
    }

    const setCurrentKeyWithMemory = (k) => {
        setCurrentKey(k)
        sessionStorage.setItem('LAST_PROD_CAT', k)
    }

    return (
        <div>
            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h4>
                        Products
                    </h4>
                </Col>
            </Row>
            <Row style={{justifyContent:'flex-end'}}>
                <Col xs={10} sm lg={9} style={{ textAlign: 'end', paddingRight: isSmall ? '0' : '1rem'}}>
                    <Row style={{justifyContent: 'space-between'}}>
                        <Col>
                            <Row>
                                <Form.Label style={{display: 'flex', alignItems: 'center'}}>
                                    Search
                                </Form.Label>
                                <Col>
                                    <Form.Control style={{maxWidth: '250px'}} value={search} onChange={(e) => filterItems(e.target.value)} />
                                </Col>
                            </Row>
                        </Col>
                        <Button style={{marginBottom: '10px'}} onClick={() => props.setCreateProduct(true)}>Create New</Button>
                    </Row>
                </Col>
                <Col xs={10} sm lg={3} style={{paddingRight: isLarge ? '4rem' : isSmall ?  '0' : '1rem'}}>
                    <Form.Control as="select" value={props.filter} onChange={(e) => {props.setFilter(e.target.value); props.reloadList(e.target.value)}}>
                        <option value={0}>Active</option>
                        <option value={1}>Inactive</option>
                        <option value={2}>All</option>
                    </Form.Control>
                </Col>
            </Row>
            <hr />
            {props.loading 
                ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                    <CircularProgress style={{width: '100px', height: '100px'}} />
                </div>
                : (
                    <Accordion defaultActiveKey={currentKey}>
                        {activeList && activeList.map((category, i) => {
                            return <Row style={{paddingLeft: isSmall ? '0' : '4rem', paddingRight: isSmall ? '1rem' : '4rem', marginBottom: '10px'}} key={category.Category}> 
                                <Card style={{width: '100%'}}>
                                    <CustomToggle eventKey={category.Category} setCurrentKey={setCurrentKeyWithMemory} currentKey={currentKey}>
                                        <h3 style={{marginLeft: '10px'}}>
                                            {category.Category}
                                        </h3>
                                    </CustomToggle>                                    
                                    <Accordion.Collapse eventKey={category.Category}>
                                        <Card.Body>
                                            <div style={{
                                                display: 'grid',
                                                gridAutoRows: '1fr',
                                                gridTemplateColumns: `repeat(${
                                                    isXLarge ? '4' : 
                                                    isSmall ? '1' : 
                                                    isLarge ? '3' : '2'
                                                }, 1fr)`,
                                                gridColumnGap: '5px',
                                                gridRowGap: '5px'
                                            }}>
                                            {category.Items.map(item => {
                                                return <ProductCard setShowProductId={props.setShowProductId} product={item} isSmall={isSmall} key={item.ProductId} />
                                            })}
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Row>
                        })}
                    </Accordion>
            )}
        </div>
    )
}

const CustomToggle = ({ children, eventKey, setCurrentKey, currentKey }) => {
      
    const isCurrentEventKey = currentKey === eventKey

    const decoratedOnClick = useAccordionToggle( eventKey, () => {
          setCurrentKey(isCurrentEventKey ? '' : eventKey)
    })
  
  
    return <Card.Header onClick={decoratedOnClick} style={{cursor: 'pointer'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {isCurrentEventKey ? <ExpandLess/> : <ExpandMore />}
            {children}
        </div>
    </Card.Header>
  }