import React, { useState, Fragment } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import { requestAccountAccess } from '../../api/sockets/user/userRequests'



export const RequestAccess = ({show, setShow, socket, notify, props}) => {

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const submitRequest = (e) => {
        e.preventDefault()
        setLoading(true)

        const requestData = {
            OwnerEmail: email
        }
        socket.props.setAccountAccessLoading = setLoading
        socket.props.notify = notify
        socket.props.onSuccess = () => {
            props.reloadAccountAccess && props.reloadAccountAccess()
            setEmail('')
            setShow(false)
        }
        socket.props.onFailure = (msg) => {
            setError(msg || 'Store Email Not Found')
        }

        // Send request via websocket
        requestAccountAccess(requestData, socket)
    }

    return (<Modal show={show} onHide={() => {setEmail(''); setShow(false)}}>
        <Modal.Header closeButton>
            <Modal.Title>Request Access</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitRequest}>
            <Modal.Body>
                {loading
                ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                    <CircularProgress style={{width: '100px', height: '100px'}} />
                </div>
                : <Fragment>
                    {error && <Row style={{justifyContent: 'center'}}>
                        <Form.Label style={{color: 'red', fontWeight: 'bold'}}>
                            {error}
                        </Form.Label>
                    </Row>}
                    <Form.Group as={Row}>
                        <Form.Label column xs={3}>
                            Email
                        </Form.Label>
                        <Col>
                            <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} required type="email" />
                        </Col>
                    </Form.Group>
                </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" disabled={loading} type="submit">Submit Request</Button>
            </Modal.Footer>
        </Form>
    </Modal>)
}
