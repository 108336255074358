import requestType from '../../../enums/requestType'

export const addToCart = (data, socket) => {
    const request = {
        RequestType: requestType.AddToCart,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const restoreCart = (data, socket) => {
    const request = {
        RequestType: requestType.RestoreCart,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getCart = (data, socket) => {
    const request = {
        RequestType: requestType.GetCart,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const startCheckout = (data, socket) => {
    const request = {
        RequestType: requestType.Checkout,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const submitCart = (data, socket) => {
    const request = {
        RequestType: requestType.CartSubmission,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const removeFromCart = (data, socket) => {
    const request = {
        RequestType: requestType.RemoveFromCart,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateCartQuantity = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateCartQuantity,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const applyCoupon = (data, socket) => {
    const request = {
        RequestType: requestType.ApplyCoupon,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const removeCoupon = (data, socket) => {
    const request = {
        RequestType: requestType.RemoveCoupon,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const applyReward = (data, socket) => {
    const request = {
        RequestType: requestType.ApplyReward,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const removeReward = (data, socket) => {
    const request = {
        RequestType: requestType.RemoveReward,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const terminalCancel = (data, socket) => {
    const request = {
        RequestType: requestType.TerminalCancel,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const submitCashOrder = (data, socket) => {
    const request = {
        RequestType: requestType.SubmitCashOrder,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const addCartCombo = (data, socket) => {
    const request = {
        RequestType: requestType.AddCartCombo,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateCartCombo = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateCartCombo,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const removeCartCombo = (data, socket) => {
    const request = {
        RequestType: requestType.RemoveCartCombo,
        Data: data
    }
    socket.send(JSON.stringify(request))
}