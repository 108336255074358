import React, { useEffect, useState, Fragment } from 'react'
import { CreateCombo } from './createCombo'
import { ComboList } from './comboList'
import { ComboDetail } from './comboDetail/comboDetail'
import { EditComboCategory } from './comboDetail/editComboCategory'



export const CombosHome = ({ socket, notify, setSelectedPage, selectedPage }) => {
    // Create
    const [createCombo, setCreateCombo] = useState(false)
    
    // Edit Combo
    const [comboId, setComboId] = useState(null)
    const [productCategories, setProductCategories] = useState(null)
    
    
    // Edit Combo Category Items
    const [comboCategory, setComboCategory] = useState(null) 
    const [productList, setProductList] = useState(null)

    // This really needs to be Selected Page I think
    return (
        <Fragment>
            {createCombo ?
                <CreateCombo
                    socket={socket}
                    notify={notify}
                    setCreateCombo={setCreateCombo}
                    setSelectedPage={setSelectedPage}
                    setComboId={setComboId}
                />
                :

                comboId ?
                    // This is probably wrong
                    comboCategory ?
                        <EditComboCategory
                            socket={socket} 
                            back={() => setComboCategory(null)}
                            productList={productList}
                            setProductList={setProductList}
                            comboCategory={comboCategory}
                            setComboCategory={setComboCategory}
                            notify={notify}
                            productCategories={productCategories}
                         />
                        :
                        <ComboDetail
                            socket={socket}
                            back={() => setComboId(null)}
                            notify={notify}
                            setSelectedPage={setSelectedPage}
                            setComboId={setComboId}
                            comboId={comboId}
                            setComboCategory={setComboCategory}
                            productCategories={productCategories}
                        />
                    :
                    <ComboList
                        socket={socket}
                        setCreateCombo={setCreateCombo}
                        notify={notify}
                        setSelectedPage={setSelectedPage}
                        setComboId={setComboId}
                        productCategories={productCategories}
                        setProductCategories={setProductCategories}
                    />}
        </Fragment>
    )

}