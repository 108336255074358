import React, { useEffect, useState, Fragment } from 'react'
import { getOperatorCustomerDetail } from '../../../api/sockets/operator/operatorRequests'
import { updateCustomerNote } from '../../../api/sockets/account/accountRequests'
import { Form, Row, Col, Button, Table } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/Assignment'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import { altUtcStringToLocal } from '../../../utility/dateHelper'
import { ArrowBackIos } from '@material-ui/icons'


const useStyles = makeStyles({
    clipBoard: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    },
})



export const OperatorCustomerDetail = ({ props }) => {
    const { socket, notify, customerId, back, reloadOperatorCustomers } = props
    const [loading, setLoading] = useState(false)
    const [customerDetail, setCustomerDetail] = useState(null)
    const [note, setNote] = useState("")
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:700px)')
    useEffect(() => {
        if (socket !== null && customerDetail === null) {
            reloadDetail()
        }
    }, [socket, customerDetail])

    const reloadDetail = () => {
        setLoading(true)
        socket.props.onFailure = notify
        socket.props.setLoading = setLoading
        socket.props.onSuccess = (response) => {
            setCustomerDetail(response)
            setNote(response.Note)
        }

        const request = {
            CustomerId: customerId
        }

        getOperatorCustomerDetail(request, socket.socket)
    }

    const sendCustomerNote = () => {

        socket.props.onUpdateFailure = () => {
            notify('Update Error', 'Error updating customer note')
        }

        const request = {
            CustomerId: customerId,
            Note: note
        }

        updateCustomerNote(request, socket.socket)
    }

    const copyToClipBoard = (field) => {
        navigator.clipboard.writeText(customerDetail[field])
        notify('Success', 'Copied to clipboard')
    }


    return (
        <Fragment>
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div> :
                <Fragment>
                    <Row style={{ marginBottom: '30px' }}>
                        <Col xs={0} md={1}></Col>
                        <Col>
                            <h3>
                            <ArrowBackIos style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() =>  {back(); reloadOperatorCustomers();}} />
                            Customer Detail
                            </h3>
                        </Col>
                    </Row>
                    <Form.Group as={Row} style={{ alignItems: 'center', textAlign: 'end' }}>
                        <Form.Label column xs={4} sm={2}>
                            First Name
                        </Form.Label>
                        <Col xs={7} sm={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.FirstName) || ''} />
                        </Col>
                        <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("FirstName")} color="primary" />
                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                            Created
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.Created && altUtcStringToLocal(customerDetail.Created)) || ''} />
                        </Col>
                        <Col xs={0} md={1}></Col>
                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                            Last Name
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.LastName) || ''} />
                        </Col>
                        <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("LastName")} color="primary" />
                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                            Note
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                maxLength={1000}
                                style={{ resize: 'none' }}
                                onBlur={() => sendCustomerNote()}
                            />
                        </Col>
                        <Col xs={0} md={1}></Col>

                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                            Birthdate
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.Birthdate) || ''} />
                        </Col>
                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                            Gender
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.Gender ? 'Male' : 'Female') || ''} />
                        </Col>
                        <Col xs={0} md={1}></Col>

                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                            Is Home Account
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={customerDetail?.IsHomeAccount ? 'Yes' : 'No'} />
                        </Col>
                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                            Phone
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.Phone) || ''} />
                        </Col>
                        <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("Phone")} color="primary" />
                        <Col xs={0} md={1}></Col>

                        <Col xs={4} md={2}>
                            Last Order
                        </Col>
                        <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.LastOrder && altUtcStringToLocal(customerDetail.LastOrder)) || ''} />
                        </Col>
                        <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft: isSmall ? '0' : '1.5rem' }}>
                            Email
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control readOnly value={(customerDetail && customerDetail.Email) || ''} />
                        </Col>
                        <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("Email")} color="primary" />
                    </Form.Group>
                </Fragment>
            }
        </Fragment>
    )


}