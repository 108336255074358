import React, { Fragment, useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Form, Row, Col, Table } from 'react-bootstrap'
import { orderStatusLookup } from '../../enums/orderStatus'
import { altUtcStringToLocal } from '../../utility/dateHelper'
import { customerOrderHistory } from '../../api/sockets/order/orderRequests'


export const CustomerHistoryList = ({ props }) => {
    const { setOrderId, socket, notify, storeId } = props
    const [loading, setLoading] = useState(false)

    // History List
    const [dateFilterDDL, setDateFilterDDL] = useState(30)
    const [orderList, setOrderList] = useState(null)

    useEffect(() => {
        if (socket && socket.socket.readyState === WebSocket.OPEN && !orderList) {
            reloadOrderHistory()
        }
    }, [orderList, socket])

    const reloadOrderHistory = () => {
        setLoading(true)

        let numberOfDays = dateFilterDDL

        socket.props.onFailure = notify
        socket.props.setLoading = setLoading

        socket.props.onSuccess = (results) => {
            setOrderList(results.Orders)
        }

        const request = {
            WithinDays: numberOfDays,
            StoreAccountId: storeId
        }

        customerOrderHistory(request, socket.socket)
    }

    return (
        <Fragment>
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h3>
                        Order History
                    </h3>
                </Col>
            </Row>
            <Row>
                <Col xs={6} sm lg={3} style={{ padding: '0px' }}>
                    <p style={{ textAlign: 'right', paddingTop: '.5rem' }}>
                        Show history of the last
                    </p>
                </Col>
                <Col xs={4} sm lg={3} style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '100px' }}>
                    <Form.Control as="select" value={dateFilterDDL} onChange={(e) => { setDateFilterDDL(e.target.value); reloadOrderHistory(e.target.value) }}>
                        <option value={30}>30</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                    </Form.Control>
                </Col>
                <Col xs={2} sm lg={3} style={{ padding: '0px' }}>
                    <p style={{ paddingTop: '.5rem' }}>
                        days
                    </p>
                </Col>
            </Row>

            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px' }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading
                                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                                </div>
                                : orderList && orderList.map((x) => {
                                    return (
                                        <tr key={x.OrderId} onClick={() => {setOrderId(x.OrderId)}}>
                                            <td>{altUtcStringToLocal(x.OrderDate, 'MM/dd/yyyy')}</td>
                                            <td>{x.Amount && x.Amount.toFixed(2)}</td>
                                            <td>{orderStatusLookup[x.Status]}</td>
                                        </tr>
                                    )
                                }
                                )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}



