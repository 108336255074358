import React, { useState } from 'react'
import { Modal, Button, } from 'react-bootstrap'
import { deleteProductCategory } from '../../../api/sockets/account/accountRequests'

export const DeleteProductCategory = ({ socket, categoryName, productCategoryId, show, onHide, reloadCategories }) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const title = "Delete Product Category?"
    const message = `Would you like to delete ${categoryName || ''}?`

    const confirmDelete = () => {
        if (socket) {
            setLoading(true)
            socket.props.setLoading = setLoading
            socket.props.onDeleteCategoryFailure = (message) => {
                setErrorMessage(message)
                setLoading(false)
            }
            socket.props.onDeleteCategorySuccess = () => {
                reloadCategories()
                onHide && onHide()
            }
            const request = {
                ProductCategoryId: productCategoryId
            }
            deleteProductCategory(request, socket.socket)
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant="danger" disabled={loading} onClick={confirmDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )
}