import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Form, ProgressBar, Button, ButtonGroup } from 'react-bootstrap'
import { baseURL } from '../../api/base'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { clearProductImage } from '../../api/sockets/product/productRequests'

export const ProductImageUploader = ({ productId, existingPath, socket }) => {

    const [error, setError] = useState(null)
    const [fileName, setFileName] = useState('Select Product Image')
    const [uploadPercent, setUploadPercent] = useState(null)
    const [uploadDone, setUploadDone] = useState(true)
    const [filePath, setFilePath] = useState(existingPath)

    const tokenInfo = getTokensSyncNoUpdate()

    useEffect(() => {
        setFilePath(existingPath)
    }, [existingPath])

    const uploadImage = (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }
        setError(null)
        setUploadPercent(0)
        setUploadDone(false)

        setFileName(file.name)
        const oneHundredMB = 104857600
        if (file.size > oneHundredMB) {
            setError('Maximum File Size is 100 MB. Please Try Again.');
            return;
        }

        console.log('Uploading Image')

        let xhr = new XMLHttpRequest()
        if (xhr.upload) {
            xhr.upload.onprogress = (e) => {
                const done = e.position || e.loaded
                const total = e.totalSize || e.total
                setUploadPercent(Math.floor(done / total * 1000) / 10)
            }
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                setUploadDone(true)
                setTimeout(() => setUploadPercent(null), 1000)
                const responseJSON = JSON.parse(xhr.response)

                if (!responseJSON.IsError) {
                    setFilePath(responseJSON.Path)
                }
                console.log(responseJSON)
            }
        }
        let formData = new FormData()
        formData.append('uploadFile', file)

        xhr.open('post', `${baseURL}/content/v1/addproductimage/${productId}`)
        xhr.setRequestHeader('Authorization', `bearer ${tokenInfo.IdToken}`)
        xhr.setRequestHeader('AccessToken', `${tokenInfo.AccessToken}`)
        xhr.send(formData)
    }

    const removeImage = () => {
        if (socket !== null) {
            socket.props.onClearImageFailure = setError
            socket.props.onClearImageSuccess = () => {
                setFileName('Select Product Image')
                setFilePath(null)
            }
            const request = {
                ProductId: productId
            }
            clearProductImage(request, socket.socket)
        }
    }


    return <Fragment>
        <Row>
            <Col xs={0} md={2}></Col>
            <Col>
                <h4>Product Image</h4>
            </Col>
        </Row>
        {error &&
            <Row>
                <Col xs={0} md={2}></Col>
                <Col>
                    <span style={{ color: 'red', fontWeight: 'bold' }}>{error}</span>
                </Col>
            </Row>
        }
        <Form.Group as={Row}>
            <Col xs={0} md={2}></Col>
            <Col>
                {filePath && (
                    <div style={{ marginBottom: '10px' }}>
                        <img src={`https://networksmarketer.com/images/${filePath}`} alt="Product Image Preview" />
                    </div>
                )}
                <ButtonGroup style={{ width: '45%' }}>
                    <Form.File id={`prod-uploader-${productId}`} custom>
                        <Form.File.Input accept=".jpg, .jpeg, .gif, .png" disabled={!uploadDone} onChange={uploadImage} />
                        <Form.File.Label data-browse="Browse">
                            {fileName}
                        </Form.File.Label>
                    </Form.File>
                    {/* May want a confirm deletion here */}
                    <Button variant="danger" size="sm" onClick={() => removeImage()}>Remove</Button>
                </ButtonGroup>
            </Col>
        </Form.Group>
        {uploadPercent !== null && <Row>
            <Col xs={0} md={2}></Col>
            <Col>
                <ProgressBar animated now={uploadPercent} label={`${uploadPercent}%`} variant={uploadDone ? 'success' : 'info'} />
            </Col>
            <Col xs={0} md></Col>
        </Row>}
    </Fragment>
}