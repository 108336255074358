import React, { useState } from 'react'
import { Modal, Button} from 'react-bootstrap'
import { deleteProduct } from '../../../api/sockets/product/productRequests'

export const DeleteProduct = ({ productName, productId, show, socket, onHide, successCallBack }) => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const title = "Are you sure you want to delete?";
    const message = `Would you like to delete ${productName} from your products. Performing this action cannot be undone.`;

    const deleteThisProduct = () => {
        setLoading(true)
        setErrorMessage(null)
        socket.props.setLoading = setLoading
        socket.props.onDeleteProductFailure = (message) => {
            setErrorMessage(message)
        }

        socket.props.onDeleteProductSuccess = () => {
            successCallBack && successCallBack()
        }

        const request = {
            ProductId: productId
        }

        deleteProduct(request, socket.socket)
    }

    const hide = () => {
        setLoading(false)
        setErrorMessage(null)
        onHide && onHide()
    }

    return (
        <Modal show={show} onHide={hide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant="danger" disabled={loading} onClick={deleteThisProduct}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )

}

