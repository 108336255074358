import React, { useState, Fragment } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import orderStatus from '../../../enums/orderStatus'
import { updateOrder } from '../../../api/sockets/operator/operatorRequests'

export const OperatorUpdateOrder = ({socket, notify, show, setShow, setShowCash, setShowReader, setShowDiscount, setShowManualReader}) => {

    const [loading, setLoading] = useState(false)

    const updateOrderHandler = (status) => {
        setLoading(true)
        const requestData = {
            CustomerId: show.CustomerId,
            OrderId: show.OrderId,
            NewStatus: status
        }

        socket.props.onUpdateFailure = (msg) => {
            notify('Update Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            // Should update the list with the data.NewStatus, but just gonna reload
            setShow(null)
        }
        socket.props.setUpdateLoading = setLoading

        updateOrder(requestData, socket)
    }


    return <Modal show={Boolean(show)} onHide={() => setShow(null)}>
        <Modal.Header closeButton>
            <Modal.Title>Update Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading
                ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                    <CircularProgress style={{width: '100px', height: '100px'}} />
                </div>
                : <Row style={{justifyContent: 'space-evenly'}}>
                    {show.Status === orderStatus.AcknowledgedByClub && (
                        <Fragment>
                            <Button 
                                onClick={() => updateOrderHandler(orderStatus.ReadyForPickup)}
                                style={{marginRight: '5px'}}
                            >
                                Ready For Pickup
                            </Button>
                            <Button 
                                onClick={() => updateOrderHandler(orderStatus.Completed)}
                                style={{marginRight: '5px'}}
                            >
                                Completed
                            </Button>
                        </Fragment>
                    )}
                    {show.Status === orderStatus.ReadyForPickup && (
                        <Button onClick={() => updateOrderHandler(orderStatus.Completed)}>Completed</Button>
                    )}
                    {show.Status === orderStatus.AwaitingPayment && (
                        <Fragment>
                            <Button variant="danger" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => updateOrderHandler(orderStatus.CanceledByClub)}>Cancel Order</Button>
                            <Button style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => setShowCash(true)}>Receive Cash</Button>
                            <Button style={{marginBottom: '5px'}} onClick={() => setShowReader(true)}>Pay With Card</Button>
                            <Button style={{marginBottom: '5px'}} onClick={() => setShowManualReader(true)}>Pay With Keyed Card</Button>
                            <Button style={{marginBottom: '5px'}} onClick={() => setShowDiscount(true)}>Add Discount</Button>
                        </Fragment>
                    )}
                    {show.Status === orderStatus.CanceledByClub && (
                        <Fragment>
                            <Button onClick={() => updateOrderHandler(orderStatus.AwaitingPayment)}>Re-Open Order</Button>
                        </Fragment>
                    )}
                </Row>
            }
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
    </Modal>
}