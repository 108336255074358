
export const handleAddToCart = (response, props) => {
    if (response.IsError){
        props.onCartFailure && props.onCartFailure(response.ErrorMessage)
    }
    else{
        props.onCartSuccess && props.onCartSuccess(response)
    }
    props.setCartLoading(false)
}


export const handleCartRestore = (response, props) => {
    if (response.IsError){
        props.onCartRestoreFailure && props.onCartRestoreFailure(response.ErrorMessage)
    }
    else{
        props.onCartRestoreSuccess && props.onCartRestoreSuccess(response)
    }
    props.needCart = false
    props.restoreCartCallback && props.restoreCartCallback()
}

export const handleGetToCart = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartFailure && props.onCartFailure(response.ErrorMessage)
    }
    else{
        props.onCartSuccess && props.onCartSuccess(response)
    }
    props.setCartLoading(false)
}

export const handleStartCheckout = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartFailure && props.onCartFailure(response.ErrorMessage)
    }
    else{
        props.onCartSuccess && props.onCartSuccess(response)
    }
    props.setCartLoading(false)
}

export const handleCardSubmit = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartSubmitFailure && props.onCartSubmitFailure(response.ErrorMessage)
    }
    else{
        props.onCartSubmitSuccess && props.onCartSubmitSuccess(response)
    }
    props.setCartSubmitLoading(false)
}

export const handleRemoveFromCart = (response, props) => {
    if(response.IsError){
        props.onRemoveError && props.onRemoveError(response.ErrorMessage)
    }
    else{
        props.onRemoveSuccess && props.onRemoveSuccess(response)
    }
    props.setCartLoading(false)
}

export const handleUpdateQuantity = (response, props) => {
    if(response.IsError){
        props.onUpdateError && props.onUpdateError(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
    props.setCartLoading(false)
}

export const handleApplyCoupon = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateError && props.onUpdateError(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}

export const handleRemoveCoupon = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateError && props.onUpdateError(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}

export const handleApplyReward = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateError && props.onUpdateError(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}


export const handleRemoveReward = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateError && props.onUpdateError(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}

export const handleTerminalCancel = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCancelError && props.onCancelError(response.ErrorMessage)
    }
    else{
        props.onCancelSuccess && props.onCancelSuccess(response)
    }
}

export const handleAbortKey = (response, props) => {
    console.log(response)
    if (!response.IsError){
        props.setAbortKey && props.setAbortKey(response.AbortKey)
    }
}

export const handleSubmitCash = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartSubmitFailure && props.onCartSubmitFailure(response.ErrorMessage)
    }
    else{
        props.onCartSubmitSuccess && props.onCartSubmitSuccess(response)
    }
    props.setCartSubmitLoading(false)
}

export const handleAddCartCombo = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartFailure && props.onCartFailure(response.ErrorMessage)
    }
    else{
        props.onCartSuccess && props.onCartSuccess(response)
    }
    props.setCartLoading && props.setCartLoading(false)
}

export const handleUpdateCartCombo = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartFailure && props.onCartFailure(response.ErrorMessage)
    }
    else{
        props.onCartSuccess && props.onCartSuccess(response)
    }
    props.setCartLoading && props.setCartLoading(false)
}

export const handleRemoveCartCombo = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCartFailure && props.onCartFailure(response.ErrorMessage)
    }
    else{
        props.onCartSuccess && props.onCartSuccess(response)
    }
    props.setCartLoading && props.setCartLoading(false)
}