import React, { useState, useEffect, Fragment } from 'react'
import { Form, Row, Col, Table } from 'react-bootstrap'

import { AccountOrderList } from '../../api/sockets/order/orderRequests'

import { HistoryList } from './historyList'
import { OrderHistoryDetail } from './historyDetail'
import userPages from '../../enums/userPages'

export const OrderHistory = ({ socket, notify, selectedPage, setSelectedPage }) => {
    const [loading, setLoading] = useState(true)

    // History Detail
    const [showOrderId, setShowOrderId] = useState(null)
    const [customerId, setCustomerId] = useState(null)
    const [backToCustomer, setBackToCustomer] = useState(false)
    
    // History List
    const [dateFilterDDL, setDateFilterDDL] = useState(30)
    const [orderList, setOrderList] = useState(null)

    useEffect(() => {
        if (socket !== null){
            reloadOrderHistory()
        }
        if (selectedPage && selectedPage.includes(userPages.OrderDetail)){
            let detailInfo = selectedPage.replace(userPages.OrderDetail, '').split('|')
            setShowOrderId(detailInfo[0])
            setCustomerId(detailInfo[1])
            setBackToCustomer(Boolean(detailInfo[2]))
        }
        else{
            setCustomerId(null)
            setShowOrderId(null)
            setBackToCustomer(false)
        }
    }, [socket, selectedPage])

    const handleBack = () => {
        if (backToCustomer){
            setSelectedPage(`${userPages.CustomerDetail}${customerId}`)
        }
        else{
            setSelectedPage(`${userPages.OrderHistory}`)
        }
    }

    const reloadOrderHistory = () => {
        setLoading(true)
        let numberOfDays = dateFilterDDL

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onListSuccess = (results) => {
            setOrderList(results)
        }
        const requestData = {
            WithinDays: numberOfDays
        }
        AccountOrderList(requestData, socket)
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {
            showOrderId
                ? <OrderHistoryDetail props={{
                    socket, notify, 
                    orderId: showOrderId,
                    customerId: customerId,
                    back: handleBack,
                    reloadOrderHistory,
                    setSelectedPage
                }} />
                : <HistoryList props={{
                    orderList, loading, reloadOrderHistory,
                    dateFilterDDL, setDateFilterDDL,
                    setShowOrderId, setCustomerId,
                    setSelectedPage
                }} />
        }
        </div>
    )
}