import React, { useState, Fragment } from 'react'
import { Modal, Button, Row, InputGroup, Form } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import CurrencyInput from '../../utility/currencyInput'
import { cashOutOrder } from '../../../api/sockets/operator/operatorRequests'
import operatorPages from '../../../enums/operatorPages'

export const OperatorReceiveCash = ({socket, notify, show, setShow, setSelectedPage}) => {
    
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    const [loading, setLoading] = useState(false)
    const [badAmount, setBadAmount] = useState(false)
    const [recAmount, setRecAmount] = useState('')
    const [success, setSuccess] = useState(null)

    const handleSubmitCash = (e) => {
        e.preventDefault()
        
        if (recAmount && Number(recAmount) >= show.Amount){
            setBadAmount(false)
        }
        else{
            setBadAmount(true)
            return
        }

        setLoading(true)
        const requestData = {
            OrderId: show.OrderId,
            CustomerId: show.CustomerId,
            AmountReceived: Number(recAmount)
        }

        socket.props.onCashSuccess = (data) => {
            setSuccess(data)
        }
        socket.props.onCashFailure = (msg) => {
            notify('Cash Out Error', msg)
        }
        socket.props.setCashLoading = setLoading

        cashOutOrder(requestData, socket.socket)
    }

    const hideAndReset = () => {
        setBadAmount(false)
        setLoading(false)
        setRecAmount('')
        const orderDetail = show && `${operatorPages.orderDetail}${show.OrderId}|${show.CustomerId}`
        setShow(false)
        if (success){
            setSelectedPage(orderDetail)
        }
    }

    return <Modal show={Boolean(show)} onHide={hideAndReset} backdrop="static" size="lg">
        <Modal.Header closeButton>
            <Modal.Title>
                Receive Cash 
                {show && ` - Order No. ${show.OrderNo}`}
            </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitCash}>
            <Modal.Body>
                {loading
                    ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                        <CircularProgress style={{width: '100px', height: '100px'}} />
                    </div>
                    : <Fragment>
                        <Row style={{justifyContent: 'center'}}>
                            <h3>Amount Due: {moneyFormat(show.Amount)}</h3>
                        </Row>
                        <Row style={{marginTop: '70px', justifyContent: 'center'}}>
                            <h4>Amount Received</h4>
                        </Row>
                        {success
                        ? <Fragment>
                            <Row style={{justifyContent: 'center'}}>
                                <h3>{moneyFormat(success.Paid)}</h3>
                            </Row>
                            <Row style={{justifyContent: 'center', marginTop: '40px'}}>
                                <h4>Change Due:</h4>
                            </Row>
                            <Row style={{justifyContent: 'center'}}>
                                <h3>{moneyFormat(success.Change)}</h3>
                            </Row>
                        </Fragment>
                        : (
                            <Row style={{justifyContent: 'center', marginBottom: '50px'}}>
                                <InputGroup style={{width: '200px'}}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput placeholder="0.00" class="form-control" required value={recAmount} onChange={(e) => setRecAmount(e.target.value)} />
                                    {badAmount && (
                                        <Form.Control.Feedback type="invalid" style={{display: 'block'}}>
                                            Amount Received Must Be Greater Than Amount Due
                                        </Form.Control.Feedback>
                                    )}
                                </InputGroup>
                            </Row>
                        )}
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                {!loading && !success && (
                    <Button type="submit">
                        Submit
                    </Button>
                )}
            </Modal.Footer>
        </Form>
    </Modal>
}