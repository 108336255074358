import React, { Fragment, useEffect, useState } from 'react'
import { Col, Form, ProgressBar } from 'react-bootstrap'
import { baseURL } from '../../../api/base'
import { getTokensSyncNoUpdate } from '../../../api/auth/session'

export const ComboImageUpdater = ({ comboId, existingPath, socket }) => {
    const [error, setError] = useState(null)
    const [fileName, setFileName] = useState('Upload Combo Image')
    const [uploadPercent, setUploadPercent] = useState(null)
    const [uploadDone, setUploadDone] = useState(true)
    const [filePath, setFilePath] = useState(existingPath)

    const tokenInfo = getTokensSyncNoUpdate()

    useEffect(() => {
        setFilePath(existingPath)
    }, [existingPath])

    const uploadImage = (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }
        setError(null)
        setUploadPercent(0)
        setUploadDone(false)

        setFileName(file.name)
        const oneHundredMB = 104857600
        if (file.size > oneHundredMB) {
            setError('Maximum File Size is 100 MB. Please Try Again.');
            return;
        }


        let xhr = new XMLHttpRequest()
        if (xhr.upload) {
            xhr.upload.onprogress = (e) => {
                const done = e.position || e.loaded
                const total = e.totalSize || e.total
                setUploadPercent(Math.floor(done / total * 1000) / 10)
            }
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                setUploadDone(true)
                setTimeout(() => setUploadPercent(null), 1000)
                const responseJSON = JSON.parse(xhr.response)

                if (!responseJSON.IsError) {
                    setFilePath(responseJSON.Path)
                }
                console.log(responseJSON)
            }
        }
        let formData = new FormData()
        formData.append('uploadFile', file)

        xhr.open('post', `${baseURL}/content/v1/addcomboImage/${comboId}`)
        xhr.setRequestHeader('Authorization', `bearer ${tokenInfo.IdToken}`)
        xhr.setRequestHeader('AccessToken', `${tokenInfo.AccessToken}`)
        xhr.send(formData)
    }

    const removeImage = () => {
        if (socket != null) {
            socket.props.onClearImageFailure = setError
            socket.props.onClearImageSuccess = () => {
                setFileName('Select Combo Image')
                setFilePath(null)
            }
        }
    }
    return <Fragment>

        <Col xs={8} md={5} lg={4} xl={3}>
            <Form.File id={`prod-uploader-${tokenInfo.HomeAccountId}`} custom>
            {error &&
                    <span style={{ color: 'red', fontWeight: 'bold', position: 'relative', bottom: '1.5rem' }}>{error}</span>
                }
                <Form.File.Input accept=".jpg, .jpeg, .gif, .png" onChange={uploadImage} /> 
                <Form.File.Label data-browse="Browse">
                    {fileName}
                </Form.File.Label>
            </Form.File>
            {uploadPercent !== null &&
                <ProgressBar animated now={uploadPercent} label={`${uploadPercent}%`} variant={uploadDone ? 'success' : 'info'} />
            }
            {filePath && (
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                    <img src={`https://networksmarketer.com/images/${filePath}`} alt="Store Logo" width="300" />
                </div>
            )}
        </Col>
    </Fragment>
}