import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { createOrder, customerLookUp } from '../../../../api/sockets/operator/operatorRequests'
import { useMediaQuery, Typography, makeStyles, FormControl, MenuItem, InputLabel, Select, FormHelperText } from '@material-ui/core'
import { encodeString } from '../../../../utility/stringHelper'
import MaskedInput from 'react-text-mask'
import { OperatorCreateCustomer } from './operatorCreateCustomer'
import { Divider } from '@material-ui/core';
import DatePicker from 'react-datepicker'


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    moneyText: {
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: '1.10rem'
    }
}));


export const OperatorCheckout = ({notify, orderItems, cart, socket, back, orderTotal, setCart, setOrderTotal, setOrderItems, orderTax, setOrderTax}) => {
    const classes = useStyles()
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format
    const isSmall = useMediaQuery('(max-width:650px)')
    const isBig = useMediaQuery('(min-width:1200px)')
    const [loading, setLoading] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    
    // FindCustomer
    const [lookUpErrorMessage, setLookUpErrorMessage] = useState('')
    const [findCustomerLoading, setFindCustomerLoading] = useState(false)
    const [findPhone, setFindPhone] = useState(null)
    const [findEmail, setFindEmail] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [availableCustomers, setAvailableCustomers] = useState(null)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [showFind, setShowFind] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    
    // Checkout Items
    const [couponCode, setCouponCode] = useState(null)
    const [useCustomerRewards, setUseCustomerRewards] = useState(false)
    const [pickUpDate, setPickUpDate] = useState(null)
    const [comments, setComments] = useState('')
    
    
    
    const clearOrderItemFields = (e) => {
        if (e !== undefined) {
            e.preventDefault()
        }
        setOrderItems([])
        setCustomer(null)
        setCouponCode(null)
        setUseCustomerRewards(false)
        setPickUpDate(null)
        setCustomer(null)
        setOrderTotal(null)
        setCart({ Products: [], Combos: [] })
        setOrderTax(0)
    }

    const sendOrder = (e) => {
        e.preventDefault()
        if (customer === null) {
            notify('Select a customer', 'Please select a customer for the order')
            return
        }

        if (orderItems.length === 0 && cart.Combos.length === 0 || (cart.Combos.some(x => x.Quantity === 0 || x.Quantity === ''))) {
            notify('Select a product', 'Invalid quantity')
            return
        }
        setLoading(true)
        socket.props.onFailure = notify
        socket.props.setLoading = setLoading
        socket.props.onOrderSuccess = () => {
            back()
        }
        const utcDate = pickUpDate ? new Date(pickUpDate).toUTCString() : null
        const request = {
            CustomerId: customer.CustomerId,
            OrderItems: getRequestOrderItems(),
            CouponCode: couponCode || '',
            UseCustomerReward: useCustomerRewards,
            Comments: encodeString(comments) || '',
            PickUpTime: utcDate,
            OrderCombos: getRequestComboItems()
        }
        createOrder(request, socket.socket)
    }


    
    const getRequestComboItems = () => {
        return cart.Combos.map(x => {
            return {
                ComboId: x.ComboId,
                Quantity: x.Quantity,
                ComboItems: x.Products.map(y => {
                    return {
                        ProductId: y.SelectedSizeId,
                        Quantity: y.Quantity,
                        AddonIngredientIds: y.SelectedAddons || [],
                        WithoutIngredientIds: y.WithoutIngredientIds || []
                    }
                }) || []
            }
        }) || []
    }

    const getRequestOrderItems = () => {
        return orderItems.map(x => {
            return {
                ProductId: x.ProductId,
                Quantity: Number(x.Quantity),
                AddonIngredientIds: x.SelectedAddons || [],
                WithoutIngredientIds: x.WithoutIngredientIds || []
            }
        }) || []
    }

    const chooseCustomer = () => {
        if (availableCustomers && selectedCustomer) {
            const newCustomer = availableCustomers.filter(x => x.CustomerId === Number(selectedCustomer))[0]
            if (newCustomer) {
                setCustomer(newCustomer)
            }
        }
        setShowFind(false)
        clearFindModalFields()
    }

    const clearFindModalFields = () => {
        setFindEmail('')
        setFindPhone('')
        setLookUpErrorMessage('')
        setAvailableCustomers(null)
        setSelectedCustomer(null)
    }

    const findCustomer = (e) => {
        e.preventDefault()
        setLookUpErrorMessage('')
        setFindCustomerLoading(true)

        socket.props.onLookUpLoading = setFindCustomerLoading

        socket.props.onLookUpFailure = (message) => {
            setLookUpErrorMessage(message)
            setAvailableCustomers(null)
        }

        socket.props.onLookUpSuccess = (response) => {
            if (response.Customers.length) {
                setAvailableCustomers(response.Customers)
                setIsOpen(true) // Dynamically open DDL for convenience
            }
            else {
                setLookUpErrorMessage('Customer Not Found')
            }
        }

        const request = {
            Email: findEmail,
            Phone: findPhone
        }

        customerLookUp(request, socket.socket)
    }



    return (
        <Fragment>
        <Form onSubmit={sendOrder}>
        <Form.Group as={Row}>
            <Col lg={1}></Col>
            <Col md={{offset: 1, span: 11}} lg={11}  >
                <Col>
                    <h4>Order Summary</h4>
                </Col>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={2}>Customer Name</Form.Label>
                    <Col xl={3} lg={4} md={8}>
                        <Form.Control style={{ cursor: 'pointer', pointerEvents: 'none', caretColor: 'transparent' }} value={customer ? `${customer.FirstName} ${customer.LastName}` : ''} readOnly maxLength="100" required />
                        <Button style={{ marginRight: '1rem', marginTop: '1rem' }} variant={"info"} size="sm" onClick={() => setShowFind(true)}>Find A Customer</Button>
                        <Button variant={"info"} size="sm" style={{ marginTop: '1rem' }} onClick={(e) => setShowCreate(true)}>Create A Customer</Button>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xl={2} style={{ textAlign: isBig ? 'end' : 'start', paddingTop: '1rem' }}>Pick up</Form.Label>
                    <Col>
                        <DatePicker selected={pickUpDate || ''} showTimeSelect dateFormat="Pp" onChange={date => setPickUpDate(date)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={2}>Coupon Code</Form.Label>
                    <Col xl={3} lg={4} md={8}>
                        <Form.Control value={couponCode || ''} onChange={(e) => setCouponCode(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start', marginBottom: '1.75rem' }}>
                    <Col xl={3}>
                        <Form.Check value={useCustomerRewards} onChange={(e) => setUseCustomerRewards(!useCustomerRewards)} label="Use Customer Rewards?" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start' }}>
                    <Form.Label column xl={2}>Order Notes</Form.Label>
                    <Col xl={3} md={8} sm={8} xs={12}>
                        <Form.Control as="textarea" rows={3} value={comments || ''} style={{ resize: 'none' }} onChange={(e) => setComments(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ textAlign: isBig ? 'end' : 'start', paddingTop: '1rem' }}>
                    <Col xl={2}></Col>
                    <Form.Label column xl={6} lg={6} style={{ textAlign: 'start' }}>
                        <h5>Sub Total<span style={{marginLeft: '1rem'}} className={classes.moneyText}>{moneyFormat(orderTotal)}</span></h5>
                        <h5 style={{marginLeft: '3.2rem'}}>Tax<span style={{marginLeft: '1.1rem'}} className={classes.moneyText}>{moneyFormat(orderTax)}</span></h5>
                        <Divider style={{ marginRight: '25rem', width: '10rem' }} />
                        <h5 style={{marginLeft: '2rem'}}>Total<span style={{marginLeft: '1.3rem'}} className={classes.moneyText}>{moneyFormat(orderTotal + orderTax)}</span></h5>
                    </Form.Label>
                </Form.Group>
            </Col>
        </Form.Group>
        <Divider style={{ margin: '2rem' }} />
        <Form.Group as={Row}>
            <Col>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', paddingRight: isBig ? '25rem' : '3rem', paddingBottom: '5rem' }}>
                    <Button variant="success" style={{ marginLeft: isSmall ? '1rem' : '4rem' }} onClick={(e) => clearOrderItemFields(e)}>
                        {isSmall ?
                            <span>New</span> :
                            <span>Start a new order</span>}
                    </Button>
                    <Button type="submit" disabled={loading} style={{ marginLeft: isSmall ? '1rem' : '4rem' }}>Submit</Button>
                    <Button variant="danger" onClick={() => back()}>Back</Button>
                </div>
            </Col>
        </Form.Group>
    </Form>


{/* Customer find Modal */}
    <Modal show={showFind} onHide={() => { setShowFind(false); clearFindModalFields(); }}>
        <Modal.Header closeButton>
            <Modal.Title>Find A Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {lookUpErrorMessage && <Typography style={{ color: 'red', fontSize: '.75rem', marginLeft: '6rem' }}>{lookUpErrorMessage}</Typography>}
            <Form onSubmit={findCustomer}>
            
                <Row style={{ alignItems: 'center' }}>
                    <Col md={12} lg={10}>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ paddingTop: '0rem' }} xl={2}>Email</Form.Label>
                            <Col>
                                <Form.Control value={findEmail || ''} placeholder="Search By Email" onChange={(e) => setFindEmail(e.target.value)} onBlur={(e) => setFindEmail(e.target.value)} />
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row}>
                            <Form.Label column xl={2}>Phone</Form.Label>
                            <Col>
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    placeholder="Search By Phone Number"
                                    guide={false}
                                    onBlur={(e) => { setFindPhone(e.target.value) }}
                                    onChange={(e) => { setFindPhone(e.target.value) }}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={12} lg={2}>
                        <Button type="submit" style={{ float: 'right' }} disabled={findCustomerLoading}>Find</Button>
                    </Col>
                </Row>
                <Form.Group as={Row}>
                    <FormControl className={classes.formControl} style={{ marginLeft: 'auto' }}>
                        <Select
                            value={selectedCustomer || ''}
                            onChange={(e) => setSelectedCustomer(e.target.value)}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            open={isOpen}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {availableCustomers ? availableCustomers.map(x => {
                                return <MenuItem key={x.CustomerId} value={x.CustomerId}>{`${x.FirstName} ${x.LastName}`}</MenuItem>

                            }) : <MenuItem value="" disabled>No Customers Available</MenuItem>}
                        </Select>
                        <FormHelperText>Select A Customer</FormHelperText>
                    </FormControl>
                </Form.Group>
                
                <div style={{ display: 'flex', marginRight: '1rem', justifyContent: 'flex-end' }}>
                    
                    <Button variant="success" disabled={!availableCustomers && selectedCustomer} onClick={chooseCustomer}>Go</Button>
                </div>
            </Form>
        </Modal.Body>
    </Modal>
    <Modal show={showCreate} onHide={() => { setShowCreate(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Create A Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <OperatorCreateCustomer props={{
                socket, notify,
                setShowCreate, setCustomer
            }} />
        </Modal.Body>
    </Modal>
    </Fragment>
    )
}