import { CircularProgress } from '@material-ui/core'
import React, { useState, Fragment, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import { viewAccountAccess } from '../../api/sockets/user/userRequests'
import { InviteUser } from '../account/inviteUser'
import { RequestAccess } from './requestAccess'
import accountUserStatus from '../../enums/accountUserStatus'
import { AccessAccountRow } from './accessAccountRow'
import { useHistory } from 'react-router-dom'


export const AccountAccess = ({props, socket, notify}) => {

    const [info, setInfo] = useState(null)
    const [loading, setLoading] = useState(true)
    const [requestAccess, setRequestAccess] = useState(false)
    const [inviteUser, setInviteUser] = useState(false)
    const [allAccounts, setAllAccounts] = useState([])
    const [activeAccounts, setActiveAccounts] = useState([])
    const [pendingAccounts, setPendingAccounts] = useState([])
    const [inactiveAccounts, setInactiveAccounts] = useState([])
    const [myAccount, setMyAccount] = useState(null)
    
    const tokenInfo = getTokensSyncNoUpdate()
    const history = useHistory()

    /* eslint-disable */
    useEffect(() => {
        if (info === null && socket !== null) {
            reloadAccountAccess()
        }
        else if (info !== null && info.Accounts){
            setAllAccounts(info.Accounts)
            parseAccounts(info.Accounts)
        }
    }, [info])
    /* eslint-enable */

    const parseAccounts = (accounts) => {
        const inacts = []
        const acts = []
        const pends = []
        accounts && accounts.forEach(x => {
            if (x.AccountId === (tokenInfo && tokenInfo.HomeAccountId)){
                setMyAccount(x)
                console.log('My Account', x)
            }
            x.AuthorizedUsers && x.AuthorizedUsers.forEach(y => {
                const data = {
                    AccountId: x.AccountId,
                    Name: x.Name,
                    City: x.City,
                    HousePercentage: x.HousePercentage,
                    Address: x.Address1,
                    UserId: y.UserId,
                    PaysHouse: y.PaysHouse,
                    Status: y.Status,
                    Settings: y.Settings,
                    Username: `${y.FirstName} ${y.LastName}`,
                    Email: y.Email
                }
                if (y.Status === accountUserStatus.Inactive){
                    inacts.push(data)
                }
                else if (y.Status === accountUserStatus.Active){
                    acts.push(data)
                }
                else{
                    pends.push(data)
                }
            })
        })
        setInactiveAccounts(inacts)
        setActiveAccounts(acts)
        setPendingAccounts(pends)
    }

    const updateUserStatus = (userId, status, accountId) => {
        const accountClone = allAccounts.map(x => {
            if (x.AccountId === accountId){
                x.AuthorizedUsers = x.AuthorizedUsers.map(y => {
                    if (y.UserId === userId){
                        y.Status = status
                    }
                    return y
                })
            }
            return x
        })
        setAllAccounts(accountClone)
        parseAccounts(accountClone)
    }

    const removeAccountAccessRow = (accountId, userId) => {
        const accountClone = allAccounts.filter(x => x.UserId !== userId && x.AccountId !== accountId);
        setAllAccounts(accountClone)
        parseAccounts(accountClone)
    }

    const reloadAccountAccess = () => {
        setLoading(true)
        socket.props.setInfo = setInfo
        socket.props.notify = notify
        socket.props.setLoading = setLoading
        viewAccountAccess({}, socket)
    }

    const enterOperatorMode = (accountId, accountName) => {
        sessionStorage.setItem('OPERATOR_ACCOUNT_ID', `${accountId}`)
        sessionStorage.setItem('OPERATOR_ACCOUNT_NAME', accountName)
        history.push('/operator')
        // TODO: Stop this reload.  I think we need to use react router properly to unmount using render() instead of component
        // Reload to refresh Operator Nav
        window.location.reload();
    }
    return <Fragment>
        <RequestAccess show={requestAccess} setShow={setRequestAccess} socket={socket} notify={notify} props={{reloadAccountAccess}} />
        <InviteUser show={inviteUser} setShow={setInviteUser} socket={socket} notify={notify} props={{reloadAccountAccess}} />
        {loading 
        ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
        : <Fragment>
            <Row style={{alignItems: 'center'}}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Account Access
                    </h2>
                </Col>
                <Col xs={6} md={4}>
                    <Row style={{justifyContent: 'space-evenly'}}>
                        <Button style={{marginBottom: '10px'}} onClick={() => setRequestAccess(true)}>Request Access</Button>
                        {myAccount && (
                            <Fragment>
                                <Button style={{marginBottom: '10px'}} onClick={() => setInviteUser(true)}>
                                    Invite User
                                </Button>
                                <Button style={{marginBottom: '10px'}} onClick={() => enterOperatorMode(myAccount.AccountId, myAccount.Name)}>
                                    Operate Account
                                </Button>
                            </Fragment>
                        )}
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row style={{marginBottom: '10px', marginTop: '10px'}}>
                <Col xs={0} md={2}></Col>
                <Col>
                    <h5>ACTIVE</h5>
                </Col>
            </Row>
            {activeAccounts.map((x, i) => {
                return <AccessAccountRow obj={x} key={i} tokenInfo={tokenInfo} socket={socket} notify={notify} loading={loading} setLoading={setLoading} updateUserStatus={updateUserStatus} />
            })}
            <hr />
            <Row style={{marginBottom: '10px', marginTop: '10px'}}>
                <Col xs={0} md={2}></Col>
                <Col>
                    <h5>PENDING</h5>
                </Col>
            </Row>
            {pendingAccounts.map((x, i) => {
                return <AccessAccountRow obj={x} key={i} tokenInfo={tokenInfo} socket={socket} notify={notify} loading={loading} setLoading={setLoading} updateUserStatus={updateUserStatus} removeAccountAccessRow={removeAccountAccessRow} />
            })}
            <hr />
            <Row style={{marginBottom: '10px', marginTop: '10px'}}>
                <Col xs={0} md={2}></Col>
                <Col>
                    <h5>INACTIVE</h5>
                </Col>
            </Row>
            {inactiveAccounts.map((x, i) => {
                return <AccessAccountRow obj={x} key={i} tokenInfo={tokenInfo} socket={socket} notify={notify} updateUserStatus={updateUserStatus} />
            })}
        </Fragment>
        }
    </Fragment>
}


