import React, { useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import accountUserStatus, { getAccountUserStatusName } from '../../enums/accountUserStatus'
import { updateAccountUser } from '../../api/sockets/account/accountRequests'
import { useHistory } from 'react-router-dom'
import { cancelAccessRequest } from '../../api/sockets/user/userRequests'


export const AccessAccountRow = ({obj, tokenInfo, socket, notify, updateUserStatus, loading, setLoading, removeAccountAccessRow}) => {
    
    const history = useHistory()

    /* eslint-disable */
    useEffect(() => {
        socket.props.onUpdateSuccess = (response) => {
            updateUserStatus(response.TargetUserId, response.Status, obj.AccountId)
        }

        socket.props.onUpdateFailure = (msg) => {
            notify('Error', msg)
        }
    }, [socket])
    /* eslint-enable */

    const activateRequest = (userId) => {
        const requestData = {
            TargetUserId: userId,
            Status: accountUserStatus.Active,
            PaysHouse: true
        }
        
        updateAccountUser(requestData, socket)
    }

    const inactivateRequest = (userId) => {
        const requestData = {
            TargetUserId: userId,
            Status: accountUserStatus.Inactive,
            PaysHouse: true
        }
        
        updateAccountUser(requestData, socket)
    }

    const enterOperatorMode = (accountId, accountName) => {
        sessionStorage.setItem('OPERATOR_ACCOUNT_ID', `${accountId}`)
        sessionStorage.setItem('OPERATOR_ACCOUNT_NAME', accountName)
        history.push('/operator')
        window.location.reload()
    }

    const cancelUserAccessRequest = (accountId, userId) => {
        if (!loading) {
            socket.props.setCancelAccessLoading = setLoading
            socket.props.onCancelAccessFailure = (msg) => {
                notify('Error Cancelling Request', msg)
            }
            socket.props.onCancelAccessSuccess = () => {
                removeAccountAccessRow(accountId, userId)
            }
            const request = {
                AccountId: accountId
            }
            cancelAccessRequest(request, socket.socket)
        }
    }
    return (
        <Row style={{alignItems: 'center'}}>
            <Col>
                <Row>
                    <Col xs={1} md={3}></Col>
                    <Col>
                        <h5>{obj.Name}</h5>
                    </Col>
                    <Col>
                        <b>{obj.Username || ''}</b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} md={4}></Col>
                    <Col style={{wordBreak: 'break-word'}}>
                        {tokenInfo && tokenInfo.HomeAccountId === obj.AccountId 
                            ? (obj.Email ? `(${obj.Email})` : '') 
                            : obj.City || ''
                        }
                    </Col>
                    <Col>
                        <span>{getAccountUserStatusName(obj.Status)}</span>
                    </Col>
                </Row>
            </Col>
            <Col lg={3}>
                {/* Pending user requested access to MY account */}
                {obj.Status === accountUserStatus.RequestedAccess && tokenInfo && tokenInfo.HomeAccountId === obj.AccountId &&
                    <Row style={{justifyContent: 'center'}}>
                        <Col xs={8} xl={4} style={{paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', marginBottom: '3px'}}>
                            <Button variant="success" onClick={() => activateRequest(obj.UserId)}>Accept</Button>
                        </Col>
                        <Col xs={8} style={{paddingRight: '0px', paddingLeft: '0px', textAlign: 'center'}}>
                            <Button variant="danger" onClick={() => inactivateRequest(obj.UserId)}>Reject</Button>
                        </Col>
                    </Row>
                }
                {/* YOU are the user requesting access to an account */}
                {obj.Status === accountUserStatus.RequestedAccess && tokenInfo && tokenInfo.HomeAccountId !== obj.AccountId &&
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <Button variant="danger" onClick={() => cancelUserAccessRequest(obj.AccountId, obj.UserId)}>Cancel Request</Button>
                        </Col>
                    </Row>
                }
                {/* Active user has access to MY account */}
                {obj.Status === accountUserStatus.Active && tokenInfo && tokenInfo.HomeAccountId === obj.AccountId &&
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <Button variant="danger" onClick={() => inactivateRequest(obj.UserId)}>Deactivate</Button>
                        </Col>
                    </Row>
                }
                {/* YOU are the Active user */}
                {obj.Status === accountUserStatus.Active && tokenInfo && tokenInfo.HomeAccountId !== obj.AccountId &&
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <Button variant="success" onClick={() => enterOperatorMode(obj.AccountId, obj.Name)}>Operate Account</Button>
                        </Col>
                    </Row>
                }
                {/* Inactive user doesnt have access to MY account */}
                {obj.Status === accountUserStatus.Inactive && tokenInfo && tokenInfo.HomeAccountId === obj.AccountId &&
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <Button variant="success" onClick={() => activateRequest(obj.UserId)}>Activate</Button>
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    )
}
