import React, { useEffect, useState, Fragment } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'



export const CartDetail = ({ storeId, setSelectedPage, notify }) => {
    const [customerCart, setCustomerCart] = useState(null)


    useEffect(() => {
        if (customerCart === null) {
            const rawCartData = JSON.parse(localStorage.getItem(`${storeId}_CART`))
            const cart = {
                GrandTotal: rawCartData.GrandTotal,
                NumberOfItems: rawCartData.Items.length
            }
            setCustomerCart(cart)
        }
    },[customerCart])

    return (
        <Fragment>
            {customerCart &&
                <Card style={{ height: '100%', border:'none', backgroundColor: 'inherit' }}>
                    <Card.Body>
                        <Row style={{justifyContent: 'center'}}>
                            <h4 className="text-success">
                                {`${customerCart.NumberOfItems} items in your cart`}
                            </h4>
                        </Row>
                        <Row style={{justifyContent: 'center'}}>
                            <Col>
                                <h4 style={{textAlign: 'center'}}>Grand Total</h4>
                                <h5 style={{textAlign: 'center'}} className="text-danger">
                                    {`$${customerCart.GrandTotal.toFixed(2)}`}
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}></Col>
                            <Col style={{justifyContent: 'flex-end', display: 'flex'}}>
                                {/* <Button size="sm" style={{minWidth: '90px'}}  variant="primary" onClick={() => { setSelectedPage(customerPages.cart) }}>
                                    Edit Cart
                                </Button> */}
                                {/* <Button size="sm" style={{marginLeft:'1rem', minWidth: '90px'}} onClick={() => checkoutClick()} variant="success">
                                    Checkout
                                </Button> */}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
        </Fragment>
    )



}