import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

export const ScrollTabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
        </div>
    )
}

ScrollTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
}