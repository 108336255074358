import { PeopleAlt, ShoppingCart, Settings, Email, Assessment, History, VerifiedUser, AccountTree, VerticalSplit, LocalPlay, Kitchen, CameraAlt, PhoneAndroid, BubbleChart } from '@material-ui/icons'
import React, { Fragment } from 'react'
import userPages from '../../enums/userPages'
import { LeftNavTab } from './leftNavTab'


export const LeftNav = ({HomeAccount, props}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '10vh', maxHeight: '90vh', overflowY: 'auto'}}>
            {HomeAccount 
            ? (
                // We only care if they have an account
                <Fragment>
                    <LeftNavTab
                        Icon={ShoppingCart}
                        text="Products"
                        selectKey={userPages.Products}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={VerticalSplit}
                        text="Product Categories"
                        selectKey={userPages.ProductCategories}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={Kitchen}
                        text="Ingredients"
                        selectKey={userPages.Ingredients}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={PeopleAlt}
                        text="Customers"
                        selectKey={userPages.Customers}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={History}
                        text="Order History"
                        selectKey={userPages.OrderHistory}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={Settings}
                        text="Settings"
                        selectKey={userPages.Settings}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                </Fragment>
            )
            : (
                <Fragment>                    
                    <LeftNavTab
                        Icon={AccountTree}
                        text="Access Account"
                        selectKey={userPages.AccessAccount}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={PeopleAlt}
                        text="Create Account"
                        selectKey={userPages.CreateAccount}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                </Fragment>
            )
            }
            
            {HomeAccount && (
                <Fragment>                    
                    <LeftNavTab
                        Icon={AccountTree}
                        text="Access Account"
                        selectKey={userPages.AccessAccount}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    {/* <LeftNavTab
                        Icon={VerifiedUser}
                        text="Authorized Users"
                        selectKey={userPages.AuthorizedUsers}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    /> */}
                    <LeftNavTab
                        Icon={LocalPlay}
                        text="Coupons"
                        selectKey={userPages.Coupons}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={Assessment}
                        text="Reports"
                        selectKey={userPages.Reports}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={CameraAlt}
                        text="Daily Snapshot"
                        selectKey={userPages.DailySnapshot}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                    />
                    <LeftNavTab
                        Icon={Email}
                        text="Emails"
                        selectKey={userPages.Emails}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                        comingSoon={true}
                    />
                    <LeftNavTab
                        Icon={PhoneAndroid}
                        text="SMS Campaigns"
                        selectKey={userPages.SMSCampaigns}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                        comingSoon={true}
                    />
                    <LeftNavTab
                        Icon={BubbleChart}
                        text="Sales A.I."
                        selectKey={userPages.SalesAI}
                        selector={props.setSelectedPage}
                        selected={props.selectedPage}
                        comingSoon={true}
                    />
                </Fragment>
            )}
        </div>
    )
}