import React, { Fragment, useState, useEffect, useRef } from 'react'
import userPages from '../../enums/userPages'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import { getCouponDetails, updateCoupon } from '../../api/sockets/account/accountRequests'
import { altUtcStringToLocal } from '../../utility/dateHelper'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import couponTypes from '../../enums/couponType'

export const CouponDetail = ({socket, notify, selectedPage, props}) => {

    const couponId = Number(selectedPage.replace(`${userPages.CouponDetail}|`, ''))
    
    const [detail, setDetail] = useState(null)
    const [priorState, setPriorState] = useState({})
    const [loading, setLoading] = useState(true)

    const formSubmiter = useRef(null)

    useEffect(() => {
        if (socket && detail === null && couponId){
            loadDetail()
        }
    }, [])

    const loadDetail = () => {
        const requestData = {
            AccountCouponId: couponId
        }
        socket.props.setDetailLoading = setLoading
        socket.props.onDetailFailure = (msg) => {
            notify('Error', msg)
        }
        socket.props.onDetailSuccess = (data) => {
            data.Start = new Date(altUtcStringToLocal(data.Start))
            if (data.End){
                data.End = new Date(altUtcStringToLocal(data.End))
            }
            setDetail(data)
            setPriorState(data)
        }
        getCouponDetails(requestData, socket)
    }

    const updateProp = (val, field) => {
        const clone = Object.assign({}, detail)
        clone[field] = val
        setDetail(clone)

        if (field === 'Type'){
            setTimeout(() => triggerSave('Type'), 300)
        }
    }

    const triggerSave = (field) => {
        const clone = Object.assign({}, detail)
        if (field === 'Amount'){
            clone.Amount = Number(clone.Amount) || 0
            setDetail(clone)
            if (priorState[field] !== clone[field]){
                setTimeout(() => formSubmiter.current.click(), 300)
                setPriorState(clone)
            }
        }
        else if (priorState[field] !== detail[field]){
            formSubmiter.current.click()
            setPriorState(clone)
        }
    }

    const handleSave = (e) => {
        e.preventDefault()

        const requestData = {
            AccountCouponId: couponId,
            Name: detail.Name,
            Code: detail.Code,
            Type: detail.Type,
            Amount: Number(detail.Amount) || 0,
            Start: (detail.Start && detail.Start.toUTCString()) || null,
            End: (detail.End && detail.End.toUTCString()) || null
        }

        if (requestData.Start === null) {
            notify('Update Error', 'Start Field Is Required')
            return
        }

        console.log(requestData)
        socket.props.onUpdateFailure = (msg) => {
            notify('Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            // Do something maybe?
        }

        updateCoupon(requestData, socket)
    }

    return <Fragment>
        <Row>
            <Col xs={0} md={1}></Col>
            <Col>
                <h2>
                    Coupon Detail
                </h2>
            </Col>
        </Row>
        <hr />
        {loading
        ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
            <CircularProgress style={{width: '100px', height: '100px'}} />
        </div>
        : <Fragment>
            <Form onSubmit={handleSave} style={{width: '100%'}}>
                <Row>
                    <Form.Group as={Row} style={{alignItems: 'center', textAlign: 'end'}}>
                        <Form.Label column xs={4} sm={2} style={{marginBottom: '10px'}}>
                            Name
                        </Form.Label>
                        <Col xs={7} sm={3}>
                            <Form.Control value={detail.Name || ''} onChange={(e) => updateProp(e.target.value, 'Name')} required maxLength={200} onBlur={() => triggerSave('Name')} />
                        </Col>
                        <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                            Code
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control value={detail.Code || ''} onChange={(e) => updateProp(e.target.value, 'Code')} required maxLength={50} onBlur={() => triggerSave('Code')} />
                        </Col>
                        <Col xs={0} md={1}></Col>
                        
                        <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                            Amount
                        </Form.Label>
                        <Col xs={7} md={3}>
                            <Form.Control value={detail.Amount || ''} onChange={(e) => updateProp(e.target.value, 'Amount')} required type="number" maxLength={200} onBlur={() => triggerSave('Amount')} />
                        </Col>
                        <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                            Start
                        </Form.Label>
                        <Col xs={7} md={3} style={{textAlign: 'start'}}>
                            <DatePicker
                                selected={detail.Start}
                                onChange={(date) => updateProp(date, 'Start')}
                                onBlur={() => triggerSave('Start')}
                                // showTimeSelect
                                // timeIntervals={30}
                                // timeCaption="Time"
                                dateFormat="MMM d, yyyy"
                            />
                        </Col>
                        <Col xs={0} md={1}></Col>

                        <Form.Label column xs={4} md={2} style={{marginBottom: '10px'}}>
                            End
                        </Form.Label>
                        <Col xs={7} md={3} style={{textAlign: 'start'}}>
                            <DatePicker
                                selected={detail.End}
                                onChange={(date) => updateProp(date, 'End')}
                                onBlur={() => triggerSave('End')}
                                onCalendarClose={() => triggerSave('End')}
                                // showTimeSelect
                                // timeIntervals={30}
                                // timeCaption="Time"
                                dateFormat="MMM d, yyyy"
                            />
                        </Col>

                        <Col xs={12} style={{marginTop: '15px', marginBottom: '15px'}}>
                            <Row style={{justifyContent: 'space-evenly'}}>
                                <Form.Check type="radio" label="Always Available $" name="couponType" id="CouponTypeStaticDollar" checked={detail.Type === couponTypes.StaticDollars} onChange={() => updateProp(couponTypes.StaticDollars, 'Type')} />
                                <Form.Check type="radio" label="Always Available %" name="couponType" id="CouponTypeStaticPercent" checked={detail.Type === couponTypes.StaticPercent} onChange={() => updateProp(couponTypes.StaticPercent, 'Type')} />

                                <Form.Check type="radio" label="Specific Times $" name="couponType" id="CouponTypeRecurringDollar" disabled  checked={detail.Type === couponTypes.RecurringDollars} onChange={() => updateProp(couponTypes.RecurringDollars, 'Type')} />
                                <Form.Check type="radio" label="Specific Times %" name="couponType" id="CouponTypeRecurringPercent" disabled  checked={detail.Type === couponTypes.RecurringPercent} onChange={() => updateProp(couponTypes.RecurringPercent, 'Type')} />
                            </Row>
                        </Col>
                    </Form.Group>
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <Button variant="danger" onClick={() => props.setSelectedPageWithMemory(userPages.Coupons)}>Back</Button>
                    <Button type="submit" style={{display: 'none'}} ref={formSubmiter}></Button>
                </Row>
            </Form>
        </Fragment>
        }
    </Fragment>
}