import React, { Fragment } from 'react'
import { Form, Row, Col, Table } from 'react-bootstrap'
import userPages from '../../enums/userPages'
import { altUtcStringToLocal } from '../../utility/dateHelper'


export const AccountCustomerList = ({ props }) => {
    const { customerList, reloadCustomerList, dateFilterDDL, setDateFilterDDL, setCustomerId, setSelectedPage } = props

    return (
        <Fragment>
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Customer List
                    </h2>
                </Col>
                <Col>

                </Col>
            </Row>

            <Row style={{alignItems: 'center'}}>
                <Col xs={10} sm lg={3} style={{ padding: '0px', textAlign: 'right' }}>
                    <span>
                        Show customers of the last
                    </span>
                </Col>
                <Col xs={10} sm lg={3} style={{ paddingLeft: '5px',paddingReft: '5px', maxWidth: '100px' }}>
                    <Form.Control as="select" value={dateFilterDDL} onChange={(e) => {setDateFilterDDL(e.target.value); reloadCustomerList(e.target.value)}}>
                        <option value={30}>30</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                    </Form.Control>
                </Col>
                <Col xs={10} sm lg={3} style={{ padding: '0px' }}>
                    <span>
                        days
                    </span>
                </Col>
            </Row>

            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px'}}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Last Order</th>
                                <th>First Visit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerList && customerList.map((x) => {
                                    return (
                                        <tr key={x.CustomerId} onClick={() => {setSelectedPage(`${userPages.CustomerDetail}${x.CustomerId}`)}}>
                                            <td>{x.Email}</td>
                                            <td>{x.FirstName}</td>
                                            <td>{x.LastName}</td>
                                            <td>{x.LastOrder && altUtcStringToLocal(x.LastOrder)}</td>
                                            <td>{x.FirstVisit && altUtcStringToLocal(x.FirstVisit)}</td>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}