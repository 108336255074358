import requestType from '../../../enums/requestType'

export const AccountOrderList = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AccountOrderList,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const AccountOrderDetail = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AccountOrderDetail,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const customerOrderHistory = (data, socket) => {
    const request = {
        RequestType: requestType.CustomerOrderList,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const customerHistoryDetail = (data, socket) => {
    const request = {
        RequestType: requestType.CustomerOrderDetail,
        Data: data
    }
    socket.send(JSON.stringify(request))
}