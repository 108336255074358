const couponTypes = {
    StaticDollars:  0,
    StaticPercent:  1,
    RecurringDollars:  2,
    RecurringPercent:  3
}

export default couponTypes

export const couponTypeAmount = (type, amount) => {
    if (type === couponTypes.StaticPercent || type === couponTypes.RecurringPercent){
        return `${amount}% Off`
    }
    return `$${(amount && amount.toFixed(2)) || '0.00'} Off`
}