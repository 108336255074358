import { emailExistsAPI, registerUserAPI } from './auth'
import { handleErrors } from '../base'
import userType from '../../enums/userTypes'

export const checkEmailExistsAPI = async (email) => {
    const response = await emailExistsAPI(email, userType.User)
    const results = await handleErrors(response)
    return results
}

export const signUpUserAPI = async (email, password, firstName, lastName, phone) => {
    const response = await registerUserAPI(email, password, firstName, lastName, phone);
    const results = await handleErrors(response)
    return results
}