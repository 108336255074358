import React, { Fragment, useState } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { ScrollTabPanel } from '../utility/scrollTabPanel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AccountSettings } from './accountSettings'
import { AccountRewards } from './accountOwner/accountRewards'
import { WebsiteIntegration } from './accountOwner/websiteIntegration'
import { StoreInformation } from './storeInformation'
import { AccountKeys } from './accountOwner/accountKeys'


export const AccountSettingsHome = ({socket, notify}) => {

    const isSmall = useMediaQuery('(max-width:525px)')
    const [tab, setTab] = useState(0)

    const getTabProps = (i) => {
        return {
            id: `scrollable-auto-tab-${i}`,
            'aria-controls': `scrollable-auto-tabpanel-${i}`
        }
    }

    return <Fragment>
        <AppBar position="static" color="default" 
            style={{
                marginLeft: isSmall ? '-4vw' : '',
                width: isSmall ? '87vw' : '',
                // background: 'linear-gradient(transparent, #f5f5f5)',
                boxShadow: '0px 6px 6px 0px rgb(0 0 0 / 8%)'
            }} 
        >
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                style={{outline: 'none'}}
            >                
                <Tab label="General Settings" {...getTabProps(0)} />
                <Tab label="Store Information" {...getTabProps(1)} />
                <Tab label="Customer Rewards" {...getTabProps(2)} />
                <Tab label="Account Keys" {...getTabProps(3)} />
                <Tab label="Website Integration" {...getTabProps(4)} />
            </Tabs>
        </AppBar>
        <ScrollTabPanel value={tab} index={0}>
            <AccountSettings socket={socket} notify={notify} />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={1}>
            <StoreInformation socket={socket} notify={notify} />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={2}>
            <AccountRewards socket={socket} notify={notify} />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={3}>
            <AccountKeys socket={socket} notify={notify} />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={4}>
            <WebsiteIntegration socket={socket} notify={notify} />
        </ScrollTabPanel>
    </Fragment>
}