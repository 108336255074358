import React, { useState, useEffect } from 'react'

import { getOperatorOrderList } from '../../../api/sockets/operator/operatorRequests'
import { OperatorOrderList } from './operatorOrderList'
import { OperatorOrderDetail } from './orderDetail/operatorOrderDetail'
import operatorPages from '../../../enums/operatorPages'
import { OperatorCreateOrder } from './createOrder/operatorCreateOrder'

export const OperatorOrder = ({ socket, notify, orderList, setOrderList, selectedPage, setSelectedPage }) => {
    const [loading, setLoading] = useState(true)
    const [createOrder, setCreateOrder] = useState(false)
    // History Detail
    const pageParts = selectedPage.split('|')
    let showOrderId = null
    let customerId = null
    
    if (pageParts.length > 1) {
        showOrderId = Number(pageParts[0].replace(operatorPages.orderDetail, ''))
        customerId = Number(pageParts[1])
    }
    
    // History List
    const [dateFilterDDL, setDateFilterDDL] = useState(30)
    const [dailySummary, setDailySummary] = useState(null)

    useEffect(() => {
        if (socket !== null){
            reloadOrderHistory()
        }
    }, [socket])

    const reloadOrderHistory = () => {
        setLoading(true)
        let numberOfDays = dateFilterDDL

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onListSuccess = (results) => {
            setOrderList(results.Orders)
            setDailySummary(results.Summary)
        }
        const requestData = {
            WithinDays: numberOfDays
        }
        getOperatorOrderList(requestData, socket.socket)
    }

    
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {
            createOrder 
                ? <OperatorCreateOrder props={{
                    socket, notify,
                    back: () => {setCreateOrder(false); reloadOrderHistory()}
                }} /> 
                :
            showOrderId
                ? <OperatorOrderDetail props={{
                    socket, notify, 
                    orderId: showOrderId,
                    customerId: customerId,
                    reloadOrderHistory,
                    setSelectedPage,
                    setOrderList
                }} />
                : <OperatorOrderList props={{
                    orderList, loading, reloadOrderHistory,
                    dateFilterDDL, setDateFilterDDL,
                    socket, notify,
                    setSelectedPage, setCreateOrder,
                    dailySummary
                }} />
        }
        </div>
    )
}