const operatorDiscountType = {
    CustomDiscount: 0,
    GiftCard: 1,
    PaperCoupon: 2,
    VerbalCoupon: 3
}

export default operatorDiscountType

export const operatorDiscountTypeLookup = {
    0: 'Custom Discount',
    1: 'Gift Card',
    2: 'Paper Coupon',
    3: 'Verbal Coupon'
}