import { CircularProgress } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { startCheckout, submitCart, submitCashOrder, terminalCancel } from '../../api/sockets/cart/cartRequests'
import paymentResponse from '../../enums/paymentResponse'
import { OrderSplash } from '../order/orderSplash'

export const AuthorizePayment = ({socket, notify, storeId, isKiosk, onFile, setItemsInCart, storeInfo, setSelectedPage}) => {

    const [newToken, setNewToken] = useState('')

    const [cardError, setCardError] = useState('Fill Out Card Informaton')
    const [cardName, setCardName] = useState('')
    const [cardZip, setCardZip] = useState('')
    const [finalCardName, setFinalCardName] = useState('')
    const [finalCardZip, setFinalCardZip] = useState('')
    const [loading, setLoading] = useState(false)
    const [comments, setComments] = useState('')
    const [orderSuccess, setOrderSuccess] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const [abortKey, setAbortKey] = useState(null)
    const [saveMethod, setSaveMethod] = useState(false)
    const [continueCheckOut, setContinueCheckOut] = useState(false)
    const [payCash, setPayCash] = useState(false)
    const [useSavedCard, setUseSavedCard] = useState(false)
    let history = useHistory()

    const [onFileInfo, setOnFileInfo] = useState(null)

    const moneyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format

    let cartStorage = localStorage.getItem(`${storeId}_CART`)
    if (cartStorage){
        cartStorage = JSON.parse(cartStorage)
    }
    
    socket.props.setAbortKey = setAbortKey

    useEffect(() => {
        const handleTokenReceive = (event) => {
            if (typeof(event.data) === 'string'){
                const response = JSON.parse(event.data)

                if (response.validationError){
                    setCardError(response.validationError)
                }
                else if (response.token){
                    console.log(response)
                    setNewToken(response.token)
                    setCardError('')
                }
            }
        }

        if (!onFile){
            reloadOnFileInfo()
        }
        else{
            setOnFileInfo(onFile)
        }
        
        // Rebind Card Frame
        window.addEventListener('message', handleTokenReceive, false)
        
        // Cleanup old listener
        return () => {
            window.removeEventListener('message', handleTokenReceive, false)
        }
    }, [onFile, socket])

    const reloadOnFileInfo = () => {
            setLoading(true)

            const cart = JSON.parse(localStorage.getItem(`${storeId}_CART`)) || {}

            const requestData = {
                StoreAccountId: storeId,
                TipPercent: null,
                CustomAmount: cart.Tip || 0
            }
            socket.props.onCartSuccess = (data) => {
                setOnFileInfo({
                    PaymentInfoOnFile: data.PaymentInfoOnFile,
                    OnFileDetails: data.OnFileDetails
                })
                localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            }
            socket.props.onCartFailure = (msg) => {
                notify('Error', msg)
            }
            socket.props.setCartLoading = setLoading
    
            startCheckout(requestData, socket.socket)
    }

    const cssQueryString = 'input,select{display:inline-block;font-size:1rem;padding:.375rem .75rem;font-weight:400;line-height:1.5;color:#495057;background-color:#fff;border:1px solid #ced4da;border-radius:.25rem;margin:10px}input:focus,select:focus{color:#495057;background-color:#fff;border-color:#80bdff;box-shadow:0 0 0 .2rem rgb(0 123 255 / 25%);outline:0}label{font-size:1.2rem}.error{border-color: red;background-color: #ffd5d5}'
    let iFrameQueryBuilder = '?useexpiry=true&usecvv=true'
    iFrameQueryBuilder += '&invalidcreditcardevent=true&enhancedresponse=true&tokenizewheninactive=true'
    iFrameQueryBuilder += '&css=' + encodeURIComponent(cssQueryString)

    const submitCartPayment = (useOnFile, manual) => {
        setLoading(true)
        const requestData = {
            StoreAccountId: storeId,
            UsePaymentOnFile: useOnFile || useSavedCard || false,
            PaymentToken: newToken,
            Comments: comments,
            CardPresent: isKiosk || false,
            NameOnCard: finalCardName,
            Zip: finalCardZip,
            SaveAsDefault: saveMethod || false,
            ManualEnter: manual || false,
            PickupTime: null // TODO
        }

        socket.props.setCartSubmitLoading = setLoading
        socket.props.onCartSubmitSuccess = (data) => {
            if (data.Status === paymentResponse.Approved){
                localStorage.removeItem(`${storeId}_CART`)
                setItemsInCart(0)
                setOrderSuccess(true)
                setOrderId(data.OrderId)

            }
            else if (data.Status === paymentResponse.Retry){
                notify('Error', 'Please Retry Payment')
                setNewToken('')
            }
            else if (data.Status === paymentResponse.Declined){
                notify('Error', 'Payment Declined')
                setNewToken('')
            }
        }
        socket.props.onCartSubmitFailure = (msg) => {
            notify('Error', msg)
            setAbortKey(null)
        }

        submitCart(requestData, socket.socket)
    }

    const abortPayment = () => {
        if (!abortKey){
            return
        }

        const requestData = {
            StoreAccountId: storeId,
            AbortKey: abortKey
        }

        socket.props.onCancelError = (msg) => {
            notify('Error', msg)
        }
        socket.props.onCancelSuccess = () => {
            setAbortKey(null)
        }

        terminalCancel(requestData, socket.socket)
    }

    const submitCashCart = (inPerson) => {
        const requestData = {
            StoreAccountId: storeId,
            Comments: comments,
            InPerson: inPerson
        }
        socket.props.setCartSubmitLoading = setLoading
        socket.props.onCartSubmitSuccess = (data) => {
            if (data.OrderId){
                localStorage.removeItem(`${storeId}_CART`)
                setItemsInCart(0)
                setOrderSuccess(true)
                setOrderId(data.OrderId)
            }
        }
        socket.props.onCartSubmitFailure = (msg) => {
            notify('Error', msg)
            setAbortKey(null)
        }

        submitCashOrder(requestData, socket.socket)
    }

    if (loading){
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
            <div>
                <Row style={{justifyContent: 'center'}}>
                    <CircularProgress style={{width: '100px', height: '100px'}} />
                </Row>
                {abortKey && (
                    <Row style={{marginTop: '60px'}}>
                        <Button variant="danger" onClick={abortPayment}>
                            Cancel Payment
                        </Button>
                    </Row>
                )}
            </div>
        </div>
    }

    if (orderSuccess){
        return <OrderSplash 
                    orderId={orderId}
                    socket={socket}
                    notify={notify}
                    setSelectedPage={setSelectedPage}
                    storeId={storeId}
                    isKiosk={isKiosk}
               />
    }

    return <Fragment>
        <Row style={{height: '80vh'}}>
            <Col xs={1}></Col>
            <Col>
                <Row>
                    <h2>Authorize Payment</h2>
                </Row>
                <Row style={{marginTop: '30px'}}>
                    <Col xs={0} sm={0} md={2} lg={3}></Col>
                    <Col>
                        <h3>Total Amount: {moneyFormat(cartStorage && cartStorage.GrandTotal)}</h3>
                    </Col>
                    <Col xs={0} sm={0} md={2} lg={3}></Col>
                </Row>
                {isKiosk
                ? <Row style={{marginTop: '50px'}}>
                    <Col style={{fontSize: '3rem'}}>
                        <Row style={{justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => submitCashCart(true)}>
                            <Col>
                                Pay With Cash
                            </Col>
                            <Col xs={2} md={3} style={{display: 'flex', alignItems: 'center'}}>
                                <ArrowForwardIos style={{fontSize: '3rem'}} />
                            </Col>
                        </Row>
                        <hr />
                        <Row style={{justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => submitCartPayment()}>
                            <Col>
                                Pay With Card
                            </Col>
                            <Col xs={2} md={3} style={{display: 'flex', alignItems: 'center'}}>
                                <ArrowForwardIos style={{fontSize: '3rem'}} />
                            </Col>
                        </Row>
                        <hr />
                        <Row style={{justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => submitCartPayment(false, true)}>
                            <Col>
                                Pay With Keyed Card
                            </Col>
                            <Col xs={2} md={3} style={{display: 'flex', alignItems: 'center'}}>
                                <ArrowForwardIos style={{fontSize: '3rem'}} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : <Fragment>
                    {!continueCheckOut && onFileInfo && onFileInfo.PaymentInfoOnFile && !(newToken && finalCardName && finalCardZip) && (
                        <Row style={{justifyContent: 'center'}}>
                            <Col xs={0} sm={0} md={2} lg={3}></Col>
                            <Col style={{marginTop: '20px', marginLeft: '20px'}}>
                                <Row>
                                    <h5>{onFileInfo.OnFileDetails}</h5>
                                </Row>
                                <Row>
                                    <Button onClick={() => {setUseSavedCard(true); setContinueCheckOut(true)}}>Use Payment Method On File</Button>
                                </Row>
                            </Col>
                            <Col xs={0} sm={0} md={2} lg={3}></Col>
                        </Row>
                    )}
                    {continueCheckOut
                    ? (
                        <Fragment>
                            <Row>                                
                                <Col xs={0} sm={0} md={2} lg={3}></Col>
                                <Col>
                                    <h4>Paying
                                        { useSavedCard
                                            ? ' With Saved Card ' + onFileInfo.OnFileDetails
                                            : payCash 
                                                ? ' At Store'
                                                : ' With New Card'
                                        }
                                    </h4>
                                </Col>
                                <Col xs={0} sm={0} md={2} lg={3}></Col>
                            </Row>
                            <Row style={{justifyContent: 'space-evenly', marginTop: '50px'}}>
                                <Button variant="success" onClick={() => {payCash ? submitCashCart(false) : submitCartPayment()}} style={{marginBottom: '10px', padding: '8px 14px 8px 14px'}}>
                                    Authorize Payment
                                </Button>
                                <Button variant="danger" onClick={() => {setNewToken(''); setPayCash(false); setUseSavedCard(false); setContinueCheckOut(false)}} style={{marginBottom: '10px', padding: '8px 14px 8px 14px'}}>
                                    Change Payment Information
                                </Button>
                            </Row>
                            <Row style={{marginTop: '40px'}}>
                                <Col sm={0} md={1} lg={3}></Col>
                                <Col>
                                    <Form.Label>
                                    Order Notes (No Price Changing Requests)
                                    </Form.Label>
                                    <Form.Control as="textarea" rows={3} maxLength={500} value={comments} onChange={(e) => setComments(e.target.value)} style={{resize: 'none'}} />
                                </Col>                            
                                <Col sm={0} md={1} lg={3}></Col>
                            </Row>
                        </Fragment>
                    )
                    : (
                        <Fragment>
                            <Row style={{justifyContent: 'center'}}>
                                <Col xs={0} sm={0} md={2} lg={3}></Col>
                                <Col>
                                    <Row style={{marginTop: '20px', marginLeft: '20px'}}>
                                        <Button variant="success" onClick={() => {setPayCash(true); setContinueCheckOut(true)}}>Pay At Store</Button>
                                    </Row>
                                </Col>
                                <Col xs={0} sm={0} md={2} lg={3}></Col>
                            </Row>
                            <Row style={{marginTop: '50px'}}>
                                <Col xs={0} sm={0} md={2} lg={3}></Col>
                                <Col>
                                    <Row>
                                        <h4>Enter New Payment Information</h4>
                                    </Row>
                                    {Boolean(cardError) && (
                                        <Row>
                                            <span style={{paddingLeft: '20px'}}>{cardError}</span>
                                        </Row>
                                    )}
                                    
                                    <Row style={{height: '60%', marginTop: '20px'}}>
                                        <Form style={{height: '40vh'}}>
                                            <Row>
                                                <Form.Label column style={{fontSize: '1.2rem', fontFamily: 'Times New Roman', paddingLeft: '8px'}}>
                                                    Name On Card
                                                </Form.Label>
                                                <Form.Control style={{marginLeft: '16px'}} value={cardName} 
                                                    onChange={(e) => setCardName(e.target.value)} 
                                                    onBlur={(e) => setFinalCardName(e.target.value)} 
                                                />
                                            </Row>
                                            <Row>
                                                <Form.Label column style={{fontSize: '1.2rem', fontFamily: 'Times New Roman', paddingLeft: '8px'}}>
                                                    {storeInfo.Currency === 'USD' ? 'Zip Code' : 'Postal Code'}
                                                </Form.Label>
                                                <Form.Control style={{marginLeft: '16px'}} value={cardZip} 
                                                    onChange={(e) => setCardZip(e.target.value)}  
                                                    onBlur={(e) => setFinalCardZip(e.target.value)} 
                                                />
                                            </Row>
                                            <iframe 
                                                style={{height: '250px'}}
                                                id="tokenFrame" 
                                                name="tokenFrame" 
                                                src={`https://${!storeInfo.IsProduction ? 'varbis-uat' : 'boltgw'}.cardconnect.com/itoke/ajax-tokenizer.html${iFrameQueryBuilder}`} 
                                                frameBorder="0" 
                                                scrolling="no"
                                            />
                                            <Row>
                                                <Form.Check 
                                                    type="switch"
                                                    id="saveMethod"
                                                    label="Set As Default Payment Method"
                                                    style={{marginLeft: '16px', marginBottom: '20px'}} 
                                                    checked={saveMethod}
                                                    onChange={(e) => setSaveMethod(e.target.checked)}
                                                />
                                            </Row>
                                            {newToken && finalCardName && finalCardZip && <Row style={{justifyContent: 'center'}}>
                                                <Button variant="success" onClick={() => setContinueCheckOut(true)}>Continue</Button>
                                            </Row>}
                                        </Form>
                                    </Row>
                                </Col>
                                <Col xs={0} sm={0} md={2} lg={3}></Col>
                            </Row>
                        </Fragment>
                    )}
                    </Fragment>
                }
            </Col>
        </Row>
    </Fragment>
}