import React, { useState, Fragment } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import couponTypes from '../../enums/couponType'
import { createCoupon } from '../../api/sockets/account/accountRequests'


export const CreateCoupon = ({show, setShow, socket, notify, props}) => {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [amount, setAmount] = useState(0)
    const [couponType, setCouponType] = useState(0)
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [days, setDays] = useState(0)

    const handleCreate = (e) => {
        e.preventDefault()
        setLoading(true)
        setMessage('')
        const requestData = {
            Name: name,
            Code: code,
            Type: couponType,
            Amount: amount,
            Start: (start && start.toUTCString()) || null,
            End: (end && end.toUTCString()) || null,
        }
        
        if (requestData.Start === null) {
            setMessage('Start Field Is Required')
            return
        }

        console.log(requestData)

        socket.props.onCreateFailure = (msg) => {
            setMessage(msg)
        }
        socket.props.onCreateSuccess = (data) => {
            props.loadCoupons && props.loadCoupons()
            setName('')
            setCode('')
            setStart(new Date())
            setCouponType(0)
            setShow(false)
        }
        socket.props.setCreateLoading = setLoading

        createCoupon(requestData, socket)
    }

    return <Modal show={show} onHide={() => setShow(false)} backdrop="static" size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Create Coupon</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleCreate}>
            <Modal.Body>
                {loading
                ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                    <CircularProgress style={{width: '100px', height: '100px'}} />
                </div>
                : <Fragment>
                    {message && (
                        <Row style={{justifyContent: 'center'}}>
                            <Form.Label style={{color: 'red', fontWeight: 'bold'}}>
                                {message}
                            </Form.Label>
                        </Row>
                    )}
                    <Form.Group as={Row}>
                        <Form.Label column xs={3} lg={2}>
                            Name
                        </Form.Label>
                        <Col xs sm={7} lg={4} style={{marginBottom: '10px'}}>
                            <Form.Control value={name} onChange={(e) => setName(e.target.value)} required maxLength={200} />
                        </Col>
                        <Form.Label column xs={3} lg={2}>
                            Code
                        </Form.Label>
                        <Col xs sm={7} lg={4} style={{marginBottom: '10px'}}>
                            <Form.Control value={code} onChange={(e) => setCode(e.target.value)} required maxLength={50} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column xs={3} lg={2}>
                            Amount
                        </Form.Label>
                        <Col xs sm={7} lg={4} style={{marginBottom: '10px'}}>
                            <Form.Control value={amount} onChange={(e) => setAmount(e.target.value)} onBlur={(e) => setAmount(Number(e.target.value) || 0)} required />
                        </Col>
                        <Form.Label column xs={3} lg={2}>
                            Start Date
                        </Form.Label>
                        <Col xs sm={7} lg={4} style={{marginBottom: '10px'}}>
                            <DatePicker
                                selected={start}
                                onChange={(date) => setStart(date)}
                                // showTimeSelect
                                // timeIntervals={30}
                                // timeCaption="Time"
                                dateFormat="MMM d, yyyy"
                            />
                        </Col>
                    </Form.Group>                    
                    <Form.Group as={Row}>
                        <Form.Label column xs={3} lg={2}>
                            End Date
                        </Form.Label>
                        <Col xs sm={7} lg={4} style={{marginBottom: '10px'}}>
                            <DatePicker
                                selected={end}
                                onChange={(date) => setEnd(date)}
                                // showTimeSelect
                                // timeIntervals={30}
                                // timeCaption="Time"
                                dateFormat="MMM d, yyyy"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Row style={{justifyContent: 'space-evenly'}}>
                            <Form.Check type="radio" label="Always Available $" name="couponType" id="CouponTypeStaticDollar" checked={couponType === couponTypes.StaticDollars} onChange={() => setCouponType(couponTypes.StaticDollars)} />
                            <Form.Check type="radio" label="Always Available %" name="couponType" id="CouponTypeStaticPercent" checked={couponType === couponTypes.StaticPercent} onChange={() => setCouponType(couponTypes.StaticPercent)} />

                            <Form.Check type="radio" label="Specific Times $" name="couponType" id="CouponTypeRecurringDollar" disabled  checked={couponType === couponTypes.RecurringDollars} onChange={() => setCouponType(couponTypes.RecurringDollars)} />
                            <Form.Check type="radio" label="Specific Times %" name="couponType" id="CouponTypeRecurringPercent" disabled  checked={couponType === couponTypes.RecurringPercent} onChange={() => setCouponType(couponTypes.RecurringPercent)} />
                        </Row>
                    </Form.Group>
                </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                {!loading && <Button variant="success" type="submit">Create Coupon</Button>}
            </Modal.Footer>
        </Form>
    </Modal>
}