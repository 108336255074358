
export const handleInviteUser = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.notify('Error', 'Error Inviting User')
    }
    else if (!response.Success) {
        props.onFailure && props.onFailure()
    }
    else {
        props.onSuccess && props.onSuccess()
    }

    props.setLoading && props.setLoading(false)
}

export const handleListProductCategories = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.notify('Error', 'Error Loading Categories')
    }
    else {
        props.onListCategorySuccess && props.onListCategorySuccess(response.ProductCategories)
    }
    props.setLoading &&  props.setLoading(false)
}

export const handleAddProductCategory = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess()
    }
    props.setLoading && props.setLoading(false)
}

export const handleUpdateProductCategory = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateSuccess && props.onUpdateSuccess(response.ProductCategoryId)
    }
}

export const handleDeleteProductCategory = (response, props) => {
    if (response.IsError) {
        props.onDeleteCategoryFailure && props.onDeleteCategoryFailure(response.ErrorMessage)
    }
    else {
        props.onDeleteCategorySuccess && props.onDeleteCategorySuccess()
    }
    props.setLoading && props.setLoading(false)
}

export const handleUpdateAccountUser = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}

export const handleViewAccountDetail = (response, props) => {
    if (response.IsError) {
        props.notify('Error', 'Error Loading Account Settings. Please try again later')
    }
    else {
        props.onAccountDetailsSuccess && props.onAccountDetailsSuccess(response)
    }
    props.setLoading && props.setLoading(false)
}

export const handleUpdateAccount = (response, props) => {
    if (response.isError) {
        props.notify('Error', 'Error Updating Account Settings. Please try again later')
    }
    else {
        props.onUpdateSuccess && props.onUpdateSuccess()
    }
}

export const handleAccountCustomerList = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else {
        props.onListSuccess && props.onListSuccess(response.Customers)
    }
    props.setLoading(false)
}

export const handleAccountCustomerDetail = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onDetailFailure && props.onDetailFailure(response.ErrorMessage)
    }
    else {
        props.onDetailSuccess && props.onDetailSuccess(response)
    }
    props.setLoading(false)
}

export const handleViewCoupons = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else {
        props.onListSuccess && props.onListSuccess(response)
    }
    props.setListLoading(false)
}

export const handleCreateCoupon = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCreateFailure && props.onCreateFailure(response.ErrorMessage)
    }
    else {
        props.onCreateSuccess && props.onCreateSuccess(response)
    }
    props.setCreateLoading(false)
}

export const handleViewCouponDetails = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onDetailFailure && props.onDetailFailure(response.ErrorMessage)
    }
    else {
        props.onDetailSuccess && props.onDetailSuccess(response)
    }
    props.setDetailLoading(false)
}

export const handleUpdateCoupon = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}

export const handleRefundOrder = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onRefundFailure && props.onRefundFailure(response.ErrorMessage)
    }
    else {
        props.onRefundSuccess && props.onRefundSuccess(response)
    }
}

export const handleRefundOrderItem = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onRefundFailure && props.onRefundFailure(response.ErrorMessage)
    }
    else {
        props.onRefundSuccess && props.onRefundSuccess(response)
    }
}

export const handleViewAccountRewards = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else{
        props.onListSuccess && props.onListSuccess(response)
    }
    props.setListLoading(false)
}

export const handleUpdateAccountRewards = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
    props.setUpdateLoading(false)
}

export const handleOperatorOrderReceived = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onReceiveFailure && props.onReceiveFailure(response.ErrorMessage)
    }
    else {
        props.onReceiveSuccess && props.onReceiveSuccess(response)
    }
}

export const handleUpdateCustomerNote = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
}

export const handleOrderProductCategory = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onOrderFailure && props.onOrderFailure(response.ErrorMessage)
    }
    else {
        props.onOrderSuccess && props.onOrderSuccess(response)
    }
}

export const handleViewIngredientRenames = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onViewIngredientRenamesFailure && props.onViewIngredientRenamesFailure(response.ErrorMessage)
    }
    else{
        props.onViewIngredientRenamesSuccess && props.onViewIngredientRenamesSuccess(response.AccountIngrientRenames) //typo, rename probably
    }
    props.setLoading && props.setLoading(false)
}

// May not need params in some of these success functions, we shall see.
export const handleCreateIngredientRename = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onCreateIngredientRenameFailure && props.onCreateIngredientRenameFailure(response.ErrorMessage)
    }
    else{
        props.onCreateIngredientRenameSuccess && props.onCreateIngredientRenameSuccess(response)
    }
}

export const handleUpdateIngredientRename = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onUpdateIngredientRenameFailure && props.onUpdateIngredientRenameFailure(response.ErrorMessage)
    }
    else{
        props.onUpdateIngredientRenameSuccess && props.onUpdateIngredientRenameSuccess()
    }
}

export const handleDeleteIngredientRename = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onDeleteIngredientRenameFailure && props.onDeleteIngredientRenameFailure(response.ErrorMessage)
    }
    else{
        props.onDeleteIngredientRenameSuccess && props.onDeleteIngredientRenameSuccess() 
    }
}

export const handleAccountHelp = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onHelpFailure && props.onHelpFailure(response.ErrorMessage)
    }
    else{
        props.onHelpSuccess && props.onHelpSuccess(response) 
    }
    props.setLoading && props.setLoading(false)
}

export const handleViewAccountKeys = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else{
        props.onListSuccess && props.onListSuccess(response) 
    }
    props.setListLoading && props.setListLoading(false)
}

export const handleAddAccountKey = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onListAddFailure && props.onListAddFailure(response.ErrorMessage)
    }
    else{
        props.onListAddSuccess && props.onListAddSuccess(response) 
    }
    props.setListLoading && props.setListLoading(false)
}

export const handleEditAccountKey = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onListEditFailure && props.onListEditFailure(response.ErrorMessage)
    }
    else{
        props.onListEditSuccess && props.onListEditSuccess(response) 
    }
    props.setListLoading && props.setListLoading(false)
}

export const handleDeleteAccountKey = (response, props) => {
    console.log(response)
    if(response.IsError){
        props.onListDeleteFailure && props.onListDeleteFailure(response.ErrorMessage)
    }
    else{
        props.onListDeleteSuccess && props.onListDeleteSuccess(response) 
    }
    props.setListLoading && props.setListLoading(false)
}