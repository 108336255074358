import { Fragment, useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './App.css'
import { GeneralNav } from './components/navigation/generalnav';
import { Landing } from './components/public/landing';
import { checkSession, tokensExist } from './api/auth/session'
import { Home } from './components/public/home'
import { SignUp } from './components/public/signup'
import { ViewStore } from './components/public/viewStore';
import { FBLogin } from './components/public/fbLogin';
import { KioskHome } from './components/account/kioskHome';
import { AccountOperator } from './components/account/operator/accountOperator';
import { ForgotPassword } from './components/public/forgotPassword'
import { SVQuickPay } from './components/quickpay/svquickpay';

function App() {
  const publicPaths = ['/', '/signup', '/fblogin', '/resetPassword']
  const quickPays = ['/quickpay', '/quickpaytest']

  const isStoreView = window.location.pathname.includes('/a/') // && !window.location.pathname.includes('kiosk')
  const isStoreSite = !window.location.hostname.includes('networksmarketer') && !window.location.hostname.includes('localhost')
  const isQuickPay = quickPays.includes(window.location.pathname)

  // Session Storage variables
  const operatorAccountId = Number(sessionStorage.getItem('OPERATOR_ACCOUNT_ID'))
  const operatorAccountName = sessionStorage.getItem('OPERATOR_ACCOUNT_NAME')

  if (!publicPaths.includes(window.location.pathname) && !isStoreView && !isStoreSite && !isQuickPay){
     checkSession()
  }

  const hasTokens = tokensExist(false, true)
  // const hasTokens = false;
  const [loggedIn, setLoggedIn] = useState(hasTokens)
  const [mainSocket, setMainSocket] = useState(null)
  const [socketAuthed, setSocketAuthed] = useState(false)
  const [socketReconnecting, setSocketReconnecting] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [customerSocket, setCustomerSocket] = useState(null)

  // Store Info  
  const [categories, setCategories] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(true)
  const [storeInfo, setStoreInfo] = useState(null)
  const [storeInfoLoading, setStoreInfoLoading] = useState(true)
  const [doneLoading, setDoneLoading] = useState(false)

  const storeInfoBundle = { categories, setCategories, categoryLoading,
    setCategoryLoading, storeInfo, setStoreInfo, storeInfoLoading, setStoreInfoLoading,
    socketReconnecting, setSocketReconnecting, doneLoading, setDoneLoading
  }

  const viewStoreProps = {
    socket: customerSocket,
    setSocket: setCustomerSocket,
    isStoreView,
    isStoreSite,
    props: storeInfoBundle
  }

  const kioskProps = {
    storeSocket: mainSocket,
    setStoreSocket: setMainSocket,
    socketAuthed,
    setSocketAuthed,
    isStoreView,
    isStoreSite
  }

  const operatorProps = {
    socket: mainSocket,
    setSocket: setMainSocket,
    accountId: operatorAccountId,
    accountName: operatorAccountName,
    socketAuthed,
    setSocketAuthed,
    socketReconnecting,
    setSocketReconnecting
  }

  return (
    <Fragment>
      <Router>
        
        {/* Not Logged In Nav */}
        {!loggedIn && !isStoreView && !isStoreSite && !isQuickPay && <GeneralNav props={{setLoggedIn, setShowLogin, showLogin}} />}

        {/* Landing Page or Home Dashboard if signed in */}
        <Route path="/" exact 
          component={
            isStoreSite
            ? () => ViewStore(viewStoreProps)
            : loggedIn 
              ? () => Home(mainSocket, setMainSocket, socketAuthed, setSocketAuthed) 
              : Landing
          } 
        /> 
        <Route path="/signup" exact component={SignUp} />
        <Route path="/resetPassword" exact component={ForgotPassword} />
        {/* Back End Signed in */}
        <Route path="/dashboard" exact component={loggedIn ? Home : Landing} /> 
        <Route path="/operator" exact component={operatorAccountId ? () => AccountOperator(operatorProps) : loggedIn ? Home : Landing } />

        {/* Store Routing */}
        <Route path="/a/:storeCode" exact component={() => ViewStore(viewStoreProps)} />
        {/* Hack For FB Unsuccessful Redirect */}
        <Route path="//a//:storeCode*" component={() => ViewStore(viewStoreProps)} /> 
        <Route path="/fblogin" exact component={FBLogin} />

        <Route path="/a/:storeCode/kiosk" exact component={() => KioskHome(kioskProps)} />
        <Route path="/kiosk" exact component={!isStoreSite ? Home : () => KioskHome(kioskProps)} />

        <Route path="/quickpay" exact component={() => SVQuickPay({test: false})} />
        <Route path="/quickpaytest" exact component={() => SVQuickPay({test: true})} />
      </Router>
    </Fragment>
  );
}

export default App;
