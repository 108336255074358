import { userSignInAPI, ssoSignInAPI, qrSignInAPI, requestPWRecoveryAPI,  passwordRecoveryAPI } from './auth'
import { handleErrors } from '../base'
import { startSession, setTokens, setCustomerTokens } from '../../api/auth/session'

export const loginAPI = async (email, password, userType, onSuccess) => {
    const response = await userSignInAPI(email, password, userType)
    const results = await handleErrors(response)

    // Any additional token refreshes go here
    if (results && results.IdToken){
        results.Email = email
        setTokens(results, true)
        startSession()
        onSuccess && onSuccess()
    }
    return results
}

export const ssoLoginAPI = async (code, storeId, onSuccess) => {
    try{
        const response = await ssoSignInAPI(code, storeId)
        const results = await handleErrors(response)

        // Any additional token refreshes go here
        if (results && results.IdToken){
            setCustomerTokens(results, true)
            onSuccess && onSuccess(results)
        }
        return results
    }
    catch(err){}
}

export const emailLoginAPI = async (email, password, userType, isKiosk, storeId, onSuccess) =>{
    const response = await userSignInAPI(email, password, userType, isKiosk, storeId)
    const results = await handleErrors(response)

    if (results && results.IdToken){

        setCustomerTokens(results, true)
        onSuccess && onSuccess(results)
    }
    return results
}

export const qrLoginAPI = async (qrData, storeId, onSuccess) => {
    try{
        const response = await qrSignInAPI(qrData, storeId)
        const results = await handleErrors(response)

        // Any additional token refreshes go here
        if (results && results.IdToken){
            setCustomerTokens(results, true)
            onSuccess && onSuccess(results)
        }
        return results
    }
    catch(err){}
}

export const forgotPWAPI = async (email, userType, onSuccess) => {
    try{
        const response = await requestPWRecoveryAPI(email, userType)
        const results = await handleErrors(response)
        onSuccess && onSuccess(results)
        return results

    }
    catch(err){
        throw err
    }
}

export const confirmForgotPWAPI = async (email, newPassword, code, userType, onSuccess) => {
    try{
         const response = await passwordRecoveryAPI(email, newPassword, code, userType)
         const results = await handleErrors(response)
         onSuccess && onSuccess(results)
         return results
    }
    catch(err){
        throw err
    }
}