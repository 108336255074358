import requestType from '../../../enums/requestType'

export const inviteUser = (data, socketInfo) => {
    const request = {
        RequestType: requestType.InviteUserToAccount,
        Data: data
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const loadProductCategories = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ListProductCategories,
        Data: data
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const addProductCategory = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AddProductCategory,
        Data: data
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const updateProductCategory = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateProductCategory,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const deleteProductCategory = (data, socket) => {
    const request = {
        RequestType: requestType.DeleteProductCategory,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateAccountUser = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateAccountUser,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const viewAccountDetail = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AccountDetails,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateAccount = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateAccount,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const getAccountCustomerList = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AccountCustomerList,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const getAccountCustomerDetail = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AccountCustomerDetail,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const getCouponList = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ViewCoupons,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const createCoupon = (data, socketInfo) => {
    const request = {
        RequestType: requestType.CreateCoupon,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const getCouponDetails = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ViewCouponDetails,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateCoupon = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateCoupon,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const refundOrderItem = (data, socketInfo) => {
    const request = {
        RequestType: requestType.RefundOrderItem,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const refundOrder = (data, socketInfo) => {
    const request = {
        RequestType: requestType.RefundOrder,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const viewRewards = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ViewAccountRewards,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateRewards = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateAccountRewards,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateCustomerNote = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateCustomerNote,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const orderProductCategory = (data, socket) => {
    const request = {
        RequestType: requestType.OrderProductCategory,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const viewIngredientRenames = (data, socket) => {
    const request = {
        RequestType: requestType.ViewIngredientRenames,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const createIngredientRename = (data, socket) => {
    const request = {
        RequestType: requestType.CreateIngredientRename,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateIngredientRename = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateIngredientRename, 
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const deleteIngredientRename = (data, socket) => {
    const request = {
        RequestType: requestType.DeleteIngredientRename,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getAccountHelp = (data, socket) => {
    const request = {
        RequestType: requestType.AccountHelp,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const createAccountKey = (data, socket) => {
    const request = {
        RequestType: requestType.CreateAccountKey,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const editAccountKey = (data, socket) => {
    const request = {
        RequestType: requestType.EditAccountKey,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const deleteAccountKey = (data, socket) => {
    const request = {
        RequestType: requestType.DeleteAccountKey,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getAccountKeys = (data, socket) => {
    const request = {
        RequestType: requestType.ViewAccountKeys,
        Data: data
    }
    socket.send(JSON.stringify(request))
}