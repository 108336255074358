import React, { useState, useEffect, Fragment } from 'react'
import { Form, Row, Col, Table } from 'react-bootstrap'
import { orderStatusLookup } from '../../enums/orderStatus'
import userPages from '../../enums/userPages'
import { altUtcStringToLocal } from '../../utility/dateHelper'


export const HistoryList = ({ props }) => {
    const { orderList, loading, reloadOrderHistory, dateFilterDDL, setDateFilterDDL, setShowOrderId, setCustomerId, setSelectedPage } = props

    const moneyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format

    return (
        <Fragment>
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Order History
                    </h2>
                </Col>
                <Col>

                </Col>
            </Row>

            <Row style={{alignItems: 'center'}}>
                <Col xs={10} sm lg={3} style={{ padding: '0px', textAlign: 'right' }}>
                    <span>
                        Show history of the last
                    </span>
                </Col>
                <Col xs={10} sm lg={3} style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '100px' }}>
                    <Form.Control as="select" value={dateFilterDDL} onChange={(e) => {setDateFilterDDL(e.target.value); reloadOrderHistory(e.target.value)}}>
                        <option value={30}>30</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                    </Form.Control>
                </Col>
                <Col xs={10} sm lg={3} style={{ padding: '0px' }}>
                    <span>
                        days
                    </span>
                </Col>
            </Row>

            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px'}}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Order No</th>
                                <th>Customer Email</th>
                                <th>Order Date</th>
                                <th>Account Order Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList && orderList.map((x, i) => {
                                    return (
                                        <tr key={i} onClick={() => {setSelectedPage(`${userPages.OrderDetail}${x.OrderId}|${x.CustomerId}`)}}>
                                            <td>{x.OrderNo}</td>
                                            <td>{x.CustomerEmail}</td>
                                            <td>{x.OrderDate && altUtcStringToLocal(x.OrderDate)}</td>
                                            <td>{x.OrderDateLocal}</td>
                                            <td>{moneyFormat(x.Amount)}</td>
                                            <td>{x.Status && orderStatusLookup[x.Status]}</td>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}