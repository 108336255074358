const accountUserStatus = {
    Inactive : 0,
    RequestedAccess : 1,
    InvitedToAccount : 2,
    Active : 3
}

export default accountUserStatus

export const getAccountUserStatusName = (val) => {
    switch (val){
        case 0:
            return 'Inactive'
        case 1:
            return 'Requested Access'
        case 2:
            return 'Invited To Account'
        case 3:
            return 'Active'
        default:            
            return 'Unknown'
    }
}