import React, { Fragment, useState } from 'react'
import { Form, Row, Col, Table, Button} from 'react-bootstrap'
import { acknowledgeOrder } from '../../../api/sockets/operator/operatorRequests'
import operatorPages from '../../../enums/operatorPages'
import orderStatus, { orderStatusLookup } from '../../../enums/orderStatus'
import { altUtcStringToLocal } from '../../../utility/dateHelper'
import { OperatorReceiveCash } from './operatorReceiveCash'
import { OperatorUpdateOrder } from './operatorUpdateOrder'
import { OperatorUseTerminal } from './operatorUseTerminal'
import { OperatorAddDiscount } from './operatorAddDiscount'
import { distinctBy } from '../../../utility/arrayHelper'

export const OperatorOrderList = ({ props }) => {
    const { orderList, socket, notify, reloadOrderHistory, setSelectedPage, setCreateOrder, dailySummary } = props

    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    // Popups
    const [showUpdate, setShowUpdate] = useState(null)
    const [showCash, setShowCash] = useState(false)
    const [showReader, setShowReader] = useState(false)
    const [showDiscount, setShowDiscount] = useState(false)
    const [showManualReader, setShowManualReader] = useState(false)

    const updateStatuses = [orderStatus.AcknowledgedByClub, orderStatus.ReadyForPickup, orderStatus.AwaitingPayment, orderStatus.CanceledByClub]

    const handleAcknowledge = (e, orderId, customerId) => {
        e.stopPropagation()

        const requestData = {
            OrderId: orderId,
            CustomerId: customerId
        }

        socket.props.onUpdateFailure = (msg) => {
            notify('Acknowledge Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            reloadOrderHistory()
        }

        acknowledgeOrder(requestData, socket.socket)
    }
    
    const orderClickHandler = (order) => {
        setSelectedPage(`${operatorPages.orderDetail}${order.OrderId}|${order.CustomerId}`)
    }
    return (
        <Fragment>
            {showUpdate && <OperatorUpdateOrder socket={socket} notify={notify} show={showUpdate} setShow={() => {setShowUpdate(null); reloadOrderHistory()}} setShowCash={setShowCash} setShowReader={setShowReader} setShowDiscount={setShowDiscount} setShowManualReader={setShowManualReader} />}
            {showUpdate && showCash && <OperatorReceiveCash socket={socket} notify={notify} show={showCash && showUpdate} setShow={setShowCash} setSelectedPage={setSelectedPage} />}
            {showUpdate && showReader && <OperatorUseTerminal socket={socket} notify={notify} show={showReader && showUpdate} setShow={setShowReader} setSelectedPage={setSelectedPage} manualEnter={false} />}
            {showUpdate && showManualReader && <OperatorUseTerminal socket={socket} notify={notify} show={setShowManualReader && showUpdate} setShow={setShowManualReader} setSelectedPage={setSelectedPage} manualEnter={true} />}
            {showUpdate && showDiscount && <OperatorAddDiscount socket={socket} notify={notify} show={showDiscount && showUpdate} setShow={setShowDiscount} setSelectedPage={setSelectedPage} />}
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Today's Orders
                    </h2>
                </Col>
                <Col style={{display: 'flex', flexDirection: 'row-reverse', paddingRight: '5rem'}}>
                        <Button onClick={() => setCreateOrder(true)}>Create Order</Button>
                </Col>
            </Row>
            <Row style={{paddingTop: '1rem'}}>
                <Col lg={6}></Col>
                <Col style={{textAlign: 'right'}}>
                    <ul style={{listStyle: 'none'}}>
                        <h5>My Daily Totals</h5>
                        <li>
                            Total Sales: {moneyFormat(dailySummary?.MyTotalSales ?? 0)}
                        </li>
                        <li>
                            Total Tips: {moneyFormat(dailySummary?.MyTotalTips ?? 0)}
                        </li>
                        <li>
                            Gross Total: {moneyFormat(dailySummary?.MyGrossTotal ?? 0)}
                        </li>
                    </ul>
                </Col>
                <Col>
                    <ul style={{listStyle: 'none'}}>
                        <h5>Store Daily Totals</h5>
                        <li>
                            Total Sales: {moneyFormat(dailySummary?.TotalSales ?? 0)}
                        </li>
                        <li>
                            Gross Total: {moneyFormat(dailySummary?.GrossTotal ?? 0)}
                        </li>
                    </ul>
                </Col>

            </Row>
            <Row style={{ padding: '0px' }}>
                <Col xs sm lg style={{ paddingTop: '20px'}}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Order No</th>
                                <th>First</th>
                                <th>Last</th>
                                <th>Order Time</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList && distinctBy(orderList, 'OrderId').map(x => x).reverse().map((x, i) => {
                                    return (
                                        <tr key={x.OrderId} onClick={() => {orderClickHandler(x)}}>
                                            <td>{x.OrderNo}</td>
                                            <td>{x.FirstName}</td>
                                            <td>{x.LastName}</td>
                                            <td>{altUtcStringToLocal(x.OrderDate)}</td>
                                            <td>{moneyFormat(x.Amount)}</td>
                                            <td>{orderStatusLookup[x.Status]}</td>
                                            <td>
                                                {x.Status === orderStatus.SentToClub 
                                                    ? <Button variant="success" onClick={(e) => handleAcknowledge(e, x.OrderId, x.CustomerId)}>Acknowledge</Button>
                                                    : updateStatuses.includes(x.Status)
                                                        ? <Button onClick={(e) => {e.stopPropagation(); setShowUpdate(x)}}>Update</Button>
                                                        : ''
                                                }

                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}