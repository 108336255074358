import React, { useState, Fragment } from 'react'
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import customerPages from '../../enums/customerPages'
import { startCheckout } from '../../api/sockets/cart/cartRequests'
import { CircularProgress } from '@material-ui/core'


export const SelectTip = ({socket, notify, setSelectedPage, tipOptions, storeId, setPaymentInfoOnFile}) => {

    const [customAmount, setCustomAmount] = useState('')
    const [showCustom, setShowCustom] = useState(false)
    const [loading, setLoading] = useState(false)

    let storedCart = localStorage.getItem(`${storeId}_CART`)
    storedCart = JSON.parse(storedCart)

    const setTipAmount = (amount) => {
        setLoading(true)
        const requestData = {
            StoreAccountId: storeId,
            TipPercent: null,
            CustomAmount: amount
        }
        socket.props.onCartSuccess = (data) => {
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            setPaymentInfoOnFile({
                PaymentInfoOnFile: data.PaymentInfoOnFile,
                OnFileDetails: data.OnFileDetails
            })
            setTimeout(() => setSelectedPage(customerPages.authorizePayment), 50)
        }
        socket.props.onCartFailure = (msg) => {
            notify('Error', msg)
        }
        // socket.props.setCartLoading = setLoading

        startCheckout(requestData, socket.socket)
    }

    const roundCustom = (e) => {
        const newNumber = Number(e.target.value)
        if (isNaN(newNumber)){
            setCustomAmount('0.00')
        }
        else{
            setCustomAmount(newNumber.toFixed(2))
        }
    }

    if (loading){
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
            <CircularProgress style={{width: '100px', height: '100px'}} />
        </div>
    }

    // TODO : Use Store Currency Type
    const moneyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format

    return <Fragment>
        <Row style={{justifyContent: 'center'}}>
            <h1>{storedCart && moneyFormat(storedCart.GrandTotal)}</h1>
        </Row>
        <Row style={{justifyContent: 'center', marginTop: '50px'}}>
            <h3>Select a Tip</h3>
        </Row>
        <Row style={{height: '50%', alignItems: 'center'}}>
            <Col sm={0} md={1} lg={2} xl={3}></Col>
            <Col sm={12} md={10} lg={8} xl={6}>
                {showCustom
                ? <Fragment>
                    <Row style={{justifyContent: 'center'}}>
                        <Col sm={10} md={8} lg={6}>
                            <InputGroup size="lg">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="number" value={customAmount} placeholder="0.00" onChange={(e) => setCustomAmount(e.target.value)} onBlur={roundCustom} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '60px', justifyContent: 'space-evenly'}}>
                        <Button variant="success" size="lg" onClick={() => setTipAmount(Number(customAmount))}>Submit</Button>
                        <Button variant="danger" size="lg" onClick={() => setShowCustom(false)}>Cancel</Button>
                    </Row>
                </Fragment>
                : <Fragment>
                    <Row>
                        {tipOptions && tipOptions.map((x, i) => {
                            return <Col 
                                key={i} 
                                style={{paddingLeft: '2px', paddingRight: '2px', cursor: 'pointer'}}
                                onClick={() => setTipAmount(x.Amount)}
                            >
                                <div 
                                    style={{
                                        backgroundColor: '#c3c8ce',
                                        color: '#4c7598',
                                        border: '#b5afaf solid 3px'
                                    }}
                                >
                                    <div style={{
                                        textAlign: 'center',
                                        fontSize: '3em'
                                    }}>
                                        {x.Percent}%
                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        fontSize: '1em',
                                        color: 'gray'
                                    }}>
                                        {moneyFormat(x.Amount)}
                                    </div>
                                </div>
                            </Col>
                        })}
                    </Row>
                    <Row>
                        <Col style={{padding: '6px 2px 6px 2px', cursor: 'pointer'}} onClick={() => setShowCustom(true)}>
                            <div 
                                style={{
                                    backgroundColor: '#c3c8ce',
                                    color: '#4c7598',
                                    border: '#b5afaf solid 3px'
                                }}
                            >
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '2.1em',
                                    paddingTop: '10px',
                                    paddingBottom: '10px'
                                }}>
                                    Custom Tip Amount
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{padding: '2px', cursor: 'pointer'}} onClick={() => setTipAmount(0)}>
                            <div 
                                style={{
                                    backgroundColor: '#c3c8ce',
                                    color: '#4c7598',
                                    border: '#b5afaf solid 3px'
                                }}
                            >
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '2.1em',
                                    paddingTop: '10px',
                                    paddingBottom: '10px'
                                }}>
                                    No Tip
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            }
            </Col>
        </Row>
        </Fragment>

}