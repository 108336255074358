
export const handleOperatorCustomerList = (response, props) => {
    if (response.IsError){
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else{
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleOperatorCustomerDetail = (response, props) => {
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleOperatorOrderList = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else{
        props.onListSuccess && props.onListSuccess(response)
    }
    props.setLoading(false)
}

export const handleOperatorOrderDetail = (response, props) => {
    if (response.IsError) {
        props.onDetailFailure && props.onDetailFailure(response.ErrorMessage)
    }
    else {
        props.onDetailSuccess && props.onDetailSuccess(response)
    }
    props.setLoading(false)
}

export const handleAcknowledgeOrder = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
    props.setLoading(false)
}

export const handleOperatorEditOrder = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateFailure && props.onUpdateFailure(response.ErrorMessage)
    }
    else{
        props.onUpdateSuccess && props.onUpdateSuccess(response)
    }
    props.setUpdateLoading(false)
}

export const handleOperatorProductList = (response, props) => {
    if (response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else{
        props.onListSuccess && props.onListSuccess(response)
    }
    props.setLoading(false)
}

export const handleCreateOrder = (response, props) => {
    if (response.IsError){
        props.onFailure && props.onFailure('Error Creating the order',response.ErrorMessage)
    }
    else{
        props.onSuccess && props.onOrderSuccess(response)
    }
    props.setLoading(false)
}

export const handleCustomerLoggedIn = (response, props) => {
    console.log(response)
    if (!response.IsError){
        props.onNewCustomerSuccess && props.onNewCustomerSuccess(response)
    }
}

export const handleOperatorCustomerLookUp = (response, props) => {
    if(response.IsError){
        props.onLookUpFailure && props.onLookUpFailure(response.ErrorMessage)
    }
    else{
        props.onLookUpSuccess && props.onLookUpSuccess(response)
    }
    props.onLookUpLoading && props.onLookUpLoading(false)
}

export const handleOperatorCreateCustomer = (response, props) => {
    if(response.IsError){
        props.onCreateCustomerFailure && props.onCreateCustomerFailure(response.ErrorMessage)
    }
    else{
        props.onCreateCustomerSuccess && props.onCreateCustomerSuccess(response)
    }
    props.setCreateLoading && props.setCreateLoading(false)
}

export const handleCashOut = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCashFailure && props.onCashFailure(response.ErrorMessage)
    }
    else{
        props.onCashSuccess && props.onCashSuccess(response)
    }
    props.setCashLoading && props.setCashLoading(false)
}

export const handleSendToTerminal = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onCardFailure && props.onCardFailure(response.ErrorMessage)
    }
    else{
        props.onCardSuccess && props.onCardSuccess(response)
    }
    props.setCardLoading && props.setCardLoading(false)
}

export const handleOperatorRecipe = (response, props) => {
    if(response.IsError){
        props.onRecipeFailure && props.onRecipeFailure(response.ErrorMessage)
    }
    else {
         props.onRecipeSuccess && props.onRecipeSuccess(response)
    }
}

export const handleOperatorCashRecords = (response, props) => {
     if(response.IsError){
         props.onCashRecordsFailure && props.onCashRecordsFailure(response.ErrorMessage)
     }
     else{
         props.onCashRecordsSuccess && props.onCashRecordsSuccess(response)
     }

     props.setRegisterLoading && props.setRegisterLoading(false)
}

export const handleUpdateOrderDiscount = (response, props) => {
    if(response.IsError){
        props.onUpdateOrderDiscountFailure && props.onUpdateOrderDiscountFailure(response.ErrorMessage)
    }
    else {
         props.onUpdateOrderDiscountSuccess && props.onUpdateOrderDiscountSuccess(response)
    }
    props.setUpdateLoading && props.setUpdateLoading(false)
}

export const handleOperatorHelp = (response, props) => {
    if(response.IsError){
        props.onHelpFailure && props.onHelpFailure(response.ErrorMessage)
    }
    else {
         props.onHelpSuccess && props.onHelpSuccess(response)
    }
    props.setLoading && props.setLoading(false)
}