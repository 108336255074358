
export async function stripoDontUseInProduction(){
    const response = await fetch('https://plugins.stripo.email/api/v1/auth', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            pluginId: '77f2a5f435084542b776ae3751f369f1',
            secretKey: '2127cbbf665e4af191bc2e089fa1606c',
            role: 'ADMIN'
        })
    })
    return response
}