import React, { Fragment, useState } from 'react'
import customerPages from '../../../enums/customerPages'
import { ViewCustomerNav } from './viewCustomerNav'
import { Profile } from './profile'
import { ReloadQRCode } from './reloadQRCode'
import { CustomerHistory } from '../../order/customerHistory'
import { CustomerPaymentInfo } from './paymentInfo'

export const ViewCustomer = ({ props }) => {
    const currentTab = sessionStorage.getItem('currentCustomerTab')
    const {socket, notify, setSelectedPage, storeId, storeInfo } = props
    const [selectedTab, setSelectedTab] = useState(currentTab || customerPages.customerInfo)
    const [orderId, setOrderId] = useState(null)

    const recordTab = (page) => {
        sessionStorage.setItem('currentCustomerTab', page)
        setSelectedTab(page)
    }
    return (
        <Fragment>
            <ViewCustomerNav props={{ selectedTab, setSelectedTab: recordTab, setOrderId }}  />
            {selectedTab === customerPages.customerInfo &&
                <Profile socket={socket} notify={notify} setSelectedPage={setSelectedPage} storeId={storeId} />
            }
            {selectedTab === customerPages.orderHistory &&
                <CustomerHistory socket={socket} notify={notify} storeId={storeId} orderId={orderId} setOrderId={setOrderId} />
            }
            {selectedTab === customerPages.reloadQRCode &&
                <ReloadQRCode socket={socket} notify={notify} storeId={storeId} />
            }
            {selectedTab === customerPages.customerPaymentInfo &&
                <CustomerPaymentInfo props={{socket, notify, storeId, storeInfo}} />
            }
        </Fragment>
    )

}
