import React from 'react'
import qs from 'query-string'

export const FBLogin = (props) => {
    
    const qString = qs.parse(props.location.search)
    const oAuthCode = qString.code
    const oAuthState = qString.state
    debugger

    // Nav BACK to store site
    if (oAuthState){
        // Might need to decode oAuthSate        
        window.location.href = `${oAuthState}?code=${oAuthCode}`
    }
    else{
        window.location.href = window.location.origin
    }

    return <div></div>
}