import React, { useEffect, useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { productImageBase } from '../../../api/base'




export const RelatedProduct = ({ product }) => {
    const defaultImageLocation = '/missingimage.jpg'
    let modifiedLocation = product?.ImageLocation
    if (modifiedLocation) {
        modifiedLocation = `${productImageBase}${modifiedLocation}`
    }
    // Mocked up for related products
    return (
        <Card>
            <Card.Img src={modifiedLocation || defaultImageLocation} />
            <Card.Body>
                <p className="text-info">{product.Name}</p>
                <div style={{ fontSize: '.75rem' }}>
                    <span className="text-danger">{`$${product.Price.toFixed(2)}`}</span>
                </div>
                <Button style={{marginTop: '1rem'}} size={'sm'}>Add to Cart</Button>
            </Card.Body>
        </Card>
    )



}