import React, { useState, useEffect, Fragment } from 'react'
import { CircularProgress } from '@material-ui/core'
import { CustomerHistoryDetail } from './customerHistoryDetail'
import { CustomerHistoryList } from './customerHistoryList'

export const CustomerHistory = ({ socket, notify, storeId, orderId, setOrderId }) => {

    const [customerId, setCustomerId] = useState(null)

    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                {
                    orderId
                        ? <CustomerHistoryDetail props={{
                            socket, notify, orderId, storeId,
                        }} />
                        :
                        <CustomerHistoryList props={{
                            setOrderId, setCustomerId, storeId,
                            socket, notify
                        }} />
                }
            </div>
        </Fragment>
    )




}