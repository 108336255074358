import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { applyCoupon, applyReward, getCart, removeCartCombo, removeCoupon, removeReward, updateCartCombo } from '../../api/sockets/cart/cartRequests'
import { productImageBase } from '../../api/base'
import { getCustomerTokens } from '../../api/auth/session'
import customerPages from '../../enums/customerPages'
import { removeFromCart, updateCartQuantity } from '../../api/sockets/cart/cartRequests'
import { couponTypeAmount } from '../../enums/couponType'
import { Cancel } from '@material-ui/icons'
import { useMediaQuery } from '@material-ui/core'

/* eslint-disable react-hooks/exhaustive-deps */
export const CustomerCart = ({ socket, notify, storeId, setSelectedPage, setTipOptions, doneLoading, setItemsInCart }) => {
    const [cartData, setCartData] = useState(null)
    const [loading, setLoading] = useState(true)
    const defaultImageLocation = '/missingimage.jpg'
    const [newQuantity, setNewQuantity] = useState(null)
    const [cartCopy, setCartCopy] = useState(null)
    const [fullData, setFullData] = useState(null)
    const isSmall = useMediaQuery('(max-width:525px)')

    const [showAddCoupon, setShowAddCoupon] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    // TODO : Use Store Currency Type
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    const reloadCart = () => {
        setLoading(true)
        socket.props.onCartSuccess = (data) => {
            setCartData(data.ShoppingCart)
            setCartCopy(data.ShoppingCart) // May not want this, but just keeping a copy
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            setTipOptions(data.TipOptions)
            setFullData(data)
        }
        socket.props.onCartFailure = (msg) => {
            notify('Error', msg)
        }
        const requestData = {
            StoreAccountId: storeId
        }
        socket.props.setCartLoading = setLoading

        getCart(requestData, socket.socket)
    }

    useEffect(() => {
        if (socket && socket.socket.readyState === WebSocket.OPEN && doneLoading) {
            reloadCart()
        }
    }, [socket, doneLoading])

    const checkoutClick = () => {
        const tokens = getCustomerTokens()
        if (tokens && !tokens.NeedsInformation) {
            setSelectedPage(customerPages.tipPage)
        }
        else if (tokens?.NeedsInformation){
            setSelectedPage(customerPages.viewCustomer)
        }
        else {
            setSelectedPage(customerPages.createCustomer)
        }
    }

    const removeItemClick = (cartItemId) => {
        setLoading(true)
        socket.props.onRemoveSuccess = (data) => {
            setCartData(data.ShoppingCart)
            setCartCopy(data.ShoppingCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            setItemsInCart(data.ShoppingCart.Items.length)
        }
        socket.props.onRemoveError = (msg) => {
            notify('Error', msg)
        }
        const requestData = {
            StoreAccountId: storeId,
            CartItemId: cartItemId
        }
        socket.props.setCartLoading = setLoading

        removeFromCart(requestData, socket.socket)
    }

    const removeComboClick = (cartComboItemId) => {
        setLoading(true)
        socket.props.onCartSuccess = (data) => {
            setCartData(data.ShoppingCart)
            setCartCopy(data.ShoppingCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            setItemsInCart(data.ShoppingCart.Items.length)
        }
        socket.props.onCartFailure = (msg) => {
            notify('Error', msg)
        }
        const requestData = {
            StoreAccountId: storeId,
            CartComboItemId: cartComboItemId
        }
        socket.props.setCartLoading = setLoading

        removeCartCombo(requestData, socket.socket)
    }

    const updateQuantity = (cartItemId, quantity) => {
        if (quantity > 0) {
            socket.props.onUpdateSuccess = (data) => {
                setCartData(data.ShoppingCart)
                localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            }
            socket.props.onUpdateError = (msg) => {
                notify('Error', msg)
            }
            const requestData = {
                StoreAccountId: storeId,
                CartItemId: cartItemId,
                NewQuantity: quantity,
            }
            socket.props.setCartLoading = setLoading
            updateCartQuantity(requestData, socket.socket)
        }
    }

    const updateComboQuantity = (CartComboItemId, quantity) => {
        if (quantity > 0) {
            socket.props.onCartSuccess = (data) => {
                setCartData(data.ShoppingCart)
                localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            }
            socket.props.onCartFailure = (msg) => {
                notify('Error', msg)
            }
            const requestData = {
                StoreAccountId: storeId,
                CartComboItemId: CartComboItemId,
                NewQuantity: quantity,
            }
            socket.props.setCartLoading = setLoading
            updateCartCombo(requestData, socket.socket)
        }
    }

    const handleQuantityChange = (cartItemId, value) => {
        const cartClone = Object.assign({}, cartData)
        setCartCopy(cartClone)
        const items = cartData.Items.map(x => {
            if (x.CartItemId === cartItemId) {
                x.Quantity = value
            }
            return x
        })
        cartClone.Items = items
        setCartData(cartClone)
    }

    const handleComboQuantityChange = (CartComboItemId, value) => {
        const cartClone = Object.assign({}, cartData)
        setCartCopy(cartClone)
        const combos = cartData.CartCombos.map(x => {
            if (x.CartComboItemId === CartComboItemId) {
                x.Quantity = value
            }
            return x
        })
        cartClone.CartCombos = combos
        setCartData(cartClone)
    }

    const handleCouponApply = (e) => {
        e.preventDefault()

        const requestData = {
            StoreAccountId: storeId,
            CouponCode: couponCode
        }

        socket.props.onUpdateError = (msg) => {
            notify('Error', msg)
            setCouponCode('')
        }
        socket.props.onUpdateSuccess = (data) => {
            setCartData(data.ShoppingCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
        }

        applyCoupon(requestData, socket.socket)
    }

    const removeCouponHandler = () => {
        const requestData = {
            StoreAccountId: storeId
        }

        socket.props.onUpdateError = (msg) => {
            notify('Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            setCartData(data.ShoppingCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
            setCouponCode('')
            setShowAddCoupon(false)
        }

        removeCoupon(requestData, socket.socket)
    }

    const removeRewardHandler = () => {
        const requestData = {
            StoreAccountId: storeId
        }

        socket.props.onUpdateError = (msg) => {
            notify('Reward Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            setCartData(data.ShoppingCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
        }

        removeReward(requestData, socket.socket)
    }

    const applyRewardHandler = () => {
        const requestData = {
            StoreAccountId: storeId
        }

        socket.props.onUpdateError = (msg) => {
            notify('Reward Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            setCartData(data.ShoppingCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))
        }

        applyReward(requestData, socket.socket)
    }

    return <Fragment>
        {loading
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
            : <div style={{paddingTop: isSmall ? '0.5rem' : ''}}>
                <Row>
                    <Col>
                        <h2>My Cart</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row style={{ justifyContent: 'center' }}>
                            <Col xs={12} lg={8}>
                                <Row>
                                    <h4>Item Details</h4>
                                </Row>
                                <hr />
                                {cartData && cartData.Items.filter(x => !x.CartComboItemId).map((x, i) => {
                                    let modifiedLocation = defaultImageLocation
                                    if (x.ImageLocation) {
                                        modifiedLocation = `${productImageBase}${x.ImageLocation}`
                                    }
                                    return <Fragment key={i}>
                                        <Row>
                                            <Col xs={6} md={4} lg={4} xl={3} style={{ marginBottom: '10px' }}>
                                                {x.ImageLocation
                                                    ? <img src={modifiedLocation} style={{ width: '6rem' }} alt={x.Name} />
                                                    : (
                                                        <div 
                                                            style={{
                                                                minHeight: '140px', backgroundColor: 'white', 
                                                                padding: '5px', textAlign: 'center',
                                                                borderRadius: '10px', marginTop: '5px',
                                                                display: 'flex', alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <h2>{x.Name}</h2>
                                                        </div>
                                                )}
                                            </Col>
                                            <Col xs={6} lg>
                                                <Row>
                                                    <h5>{x.Name} ({x.SizeDetails})</h5>
                                                </Row>
                                                {Boolean(x.WithoutIngredientIds.length) && (
                                                    <Fragment>
                                                        <Row>
                                                            <span>Without:</span>
                                                        </Row>
                                                        <ul style={{listStyleType: 'circle'}}>
                                                            {x.WithoutIngredientIds.map(y => {
                                                                const match = fullData.Ingredients.filter(z => z.IngredientId === y)[0]
                                                                if (!match){
                                                                    return ''
                                                                }
                                                                return <li>{match.Name}</li>
                                                            })}
                                                        </ul>
                                                    </Fragment>
                                                )}
                                                {Boolean(x.WithAddonIds.length) && (
                                                    <Fragment>
                                                        <Row>
                                                            <span>Addons:</span>
                                                        </Row>
                                                        <ul style={{listStyleType: 'circle'}}>
                                                            {x.WithAddonIds.map(y => {
                                                                const match = fullData.Addons.filter(z => z.AddonId === y)[0]
                                                                if (!match){
                                                                    return ''
                                                                }
                                                                return <li>{match.Name}</li>
                                                            })}
                                                        </ul>
                                                    </Fragment>
                                                )}
                                            </Col>
                                            <Col xs={10} sm={4} lg={4}>
                                                <Row style={{ marginBottom: '10px' }}>
                                                    <Form.Label column style={{textAlign: 'end'}}>
                                                        Quantity
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control type="number" min="1" value={x.Quantity} onChange={(e) => handleQuantityChange(x.CartItemId, e.target.value)} onBlur={(e) => updateQuantity(x.CartItemId, e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ justifyContent: 'center' }}>
                                                    <Button variant="danger" onClick={() => removeItemClick(x.CartItemId)}>Remove Item</Button>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </Fragment>
                                })}
                                {cartData && cartData.CartCombos.map((x, i) => {
                                    const matchItems = cartData.Items.filter(y => y.CartComboItemId === x.CartComboItemId)
                                    return <Fragment key={x.CartComboItemId}>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col>
                                                <h5>{x.Name}</h5>
                                            </Col>
                                            <Col sm={12} md={8} xl={5}>
                                                <Row>
                                                    <Form.Label column style={{textAlign: 'end'}}>
                                                        Quantity
                                                    </Form.Label>
                                                    <Col>
                                                        <Form.Control type="number" min="1" value={x.Quantity} onChange={(e) => handleComboQuantityChange(x.CartComboItemId, e.target.value)} onBlur={(e) => updateComboQuantity(x.CartComboItemId, e.target.value)} />
                                                    </Col>
                                                    <Row style={{ justifyContent: 'center' }}>
                                                        <Button variant="danger" onClick={() => removeComboClick(x.CartComboItemId)}>
                                                            Remove Combo
                                                        </Button>
                                                    </Row>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {matchItems.map((y, j) => {
                                                let modifiedLocation = defaultImageLocation
                                                if (y.ImageLocation) {
                                                    modifiedLocation = `${productImageBase}${y.ImageLocation}`
                                                }
                                                return <Fragment>
                                                    <Row>
                                                        <Col xs={6} md={4} lg={4} xl={3} style={{ marginBottom: '10px' }}>
                                                            {y.ImageLocation
                                                                ? <img src={modifiedLocation} style={{ width: '6rem' }} alt={x.Name} />
                                                                : (
                                                                    <div 
                                                                        style={{
                                                                            minHeight: '140px', backgroundColor: 'white', 
                                                                            padding: '5px', textAlign: 'center',
                                                                            borderRadius: '10px', marginTop: '5px'
                                                                        }}
                                                                    >
                                                                        <h2>{y.Name}</h2>
                                                                    </div>
                                                            )}
                                                        </Col>
                                                        <Col xs={6} lg>
                                                            <Row>
                                                                <h5>{y.Name} ({y.SizeDetails})</h5>
                                                            </Row>
                                                            {Boolean(y.WithoutIngredientIds.length) && (
                                                                <Fragment>
                                                                    <Row>
                                                                        <span>Without:</span>
                                                                    </Row>
                                                                    <ul style={{listStyleType: 'circle'}}>
                                                                        {y.WithoutIngredientIds.map(a => {
                                                                            const match = fullData.Ingredients.filter(z => z.IngredientId === a)[0]
                                                                            if (!match){
                                                                                return ''
                                                                            }
                                                                            return <li>{match.Name}</li>
                                                                        })}
                                                                    </ul>
                                                                </Fragment>
                                                            )}
                                                            {Boolean(y.WithAddonIds.length) && (
                                                                <Fragment>
                                                                    <Row>
                                                                        <span>Addons:</span>
                                                                    </Row>
                                                                    <ul style={{listStyleType: 'circle'}}>
                                                                        {y.WithAddonIds.map(a => {
                                                                            const match = fullData.Addons.filter(z => z.AddonId === a)[0]
                                                                            if (!match){
                                                                                return ''
                                                                            }
                                                                            return <li>{match.Name}</li>
                                                                        })}
                                                                    </ul>
                                                                </Fragment>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {(j + 1) !== matchItems.length && <hr style={{width: '80%'}} />}
                                                </Fragment>
                                            })}
                                        </Row>
                                        <hr />
                                    </Fragment>
                                })}
                            </Col>
                            <Col xs={10} md={6} lg>
                                <Row>
                                    <h4>Order Summary</h4>
                                </Row>
                                <hr />
                                <div
                                    style={{
                                        backgroundColor: '#d4d4d4',
                                        padding: '15px',
                                        borderRadius: '6px'
                                    }}
                                >
                                    <Row>
                                        <span>Items:</span>
                                    </Row>
                                    {cartData && cartData.Items.filter(x => !x.CartComboItemId).map((x, i) => {
                                        return <Row key={i}>
                                            <Col>
                                                <Row>
                                                    <span>{x.Name} ({x.Quantity})</span>
                                                </Row>

                                                <ul style={{ listStyleType: 'none' }}>
                                                    <li>
                                                        Price: {moneyFormat(x.Rate)}
                                                        {x.Quantity > 1
                                                            ? ' each'
                                                            : ''
                                                        }
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    })}
                                    {cartData && cartData.CartCombos.map(x => {
                                        const matchItems = cartData.Items.filter(y => y.CartComboItemId === x.CartComboItemId)
                                        return <Row key={x.CartComboItemId}>
                                            <Col>
                                                <Row>
                                                    <span>{x.Name} ({x.Quantity})</span>
                                                </Row>
                                                <Row style={{borderLeft: 'black 1px dashed', marginBottom: '1rem', marginLeft: '8px'}}>
                                                    {matchItems.map(y => {
                                                        return <Row key={y.CartItemId}>
                                                            <Col>
                                                                <Row>
                                                                    <span>{y.Name}</span>
                                                                </Row>
                                                                <ul style={{ listStyleType: 'none', marginBottom: '0px' }}>
                                                                    <li>
                                                                        Price: {moneyFormat(y.Rate)}
                                                                        {x.Quantity > 1
                                                                            ? ' each'
                                                                            : ''
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row>
                                    })}
                                    {cartData && cartData.CartCombos && Boolean(cartData.CartCombos.length) && (
                                        <Row style={{ justifyContent: 'space-between' }}>
                                            <span>Combo Savings: </span>
                                            <span>{moneyFormat((cartData && cartData.ComboDiscount) || 0)}</span>
                                        </Row>
                                    )}
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <span>Pre-Tax Total: </span>
                                        <span>{moneyFormat((cartData && cartData.PreTaxTotal) || 0)}</span>
                                    </Row>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <span>Tax: </span>
                                        <span>{moneyFormat((cartData && cartData.Tax) || 0)}</span>
                                    </Row>
                                    {cartData && cartData.ApplyReward
                                    ? ''
                                    : cartData && cartData.Coupon
                                        ? <Row style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                                            <span>
                                                <span>{cartData.Coupon.Name} {couponTypeAmount(cartData.Coupon.Type, cartData.Coupon.Amount)}</span>
                                                <Cancel 
                                                    style={{marginLeft: '5px', color: 'red', cursor: 'pointer', fontSize: '1.2rem'}} 
                                                    onClick={removeCouponHandler}
                                                />
                                            </span>
                                            <span>- {moneyFormat(cartData.DiscountAmount)}</span>
                                        </Row>
                                        : showAddCoupon 
                                            ? <Fragment>
                                                <Form onSubmit={handleCouponApply} style={{marginTop: '10px'}}>
                                                    <Row>
                                                        <Col xs={8}>
                                                            <Form.Control value={couponCode} onChange={(e) => setCouponCode(e.target.value)} required />
                                                        </Col>
                                                        <Col>                                                
                                                            <Button type="submit">Apply</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Fragment>
                                            : <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => { if (!cartData || !cartData.GrandTotal) {return} setShowAddCoupon(true)}}>Enter Promo Code</span>
                                    }
                                    {cartData && cartData.ApplyReward && (
                                        <Row style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                                            <span>
                                                <span>Account Reward</span>
                                                <Cancel 
                                                    style={{marginLeft: '5px', color: 'red', cursor: 'pointer', fontSize: '1.2rem'}} 
                                                    onClick={removeRewardHandler}
                                                />
                                            </span>
                                            <span>- {moneyFormat(cartData.DiscountAmount)}</span>
                                        </Row>
                                    )}
                                    {cartData && !cartData.Coupon && !cartData.ApplyReward && fullData && fullData.RewardName && (
                                        <Row>
                                            <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => { if (!cartData || !cartData.GrandTotal) {return} applyRewardHandler()}}>
                                                {fullData.RewardName}
                                            </span>
                                        </Row>
                                    )}
                                    <hr />
                                    <Row style={{ justifyContent: 'space-between', fontWeight: 'bold' }}>
                                        <span>Total: </span>
                                        <span>{moneyFormat((cartData && cartData.GrandTotal) || 0)}</span>
                                    </Row>
                                </div>
                                <Row style={{ justifyContent: 'center', marginTop: '15px', marginBottom: '10px' }}>
                                    <Button style={{ width: '80%', fontWeight: 'bold', letterSpacing: '2px' }} onClick={checkoutClick} disabled={!cartData || !cartData.GrandTotal}>CHECKOUT</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        }
    </Fragment>
}