import React, { useState, Fragment, useEffect, useRef } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { loadProductCategories, orderProductCategory, updateProductCategory } from '../../../api/sockets/account/accountRequests'
import { CreateProductCategory } from './createProductCategory'
import { ArrowDownward, ArrowUpward, Edit, DeleteForever } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DeleteProductCategory } from './deleteProductCategory'
import { deleteProductCategory } from '../../../api/sockets/account/accountRequests'

export const ProductCategories = ({socket, notify}) => {

    const [loading, setLoading] = useState(true)
    const [activeFilterDDL, setActiveFilterDDL] = useState(0)
    const [info, setInfo] = useState(null)
    const [showCreate, setShowCreate] = useState(false)
    const [editRow, setEditRow] = useState(null)
    const [newName, setNewName] = useState('')
    const [updatingIds, setUpdatingIds] = useState([])
    const singleRename = useRef(null)
    const isSmall = useMediaQuery('(max-width:525px)')
    
    // Delete
    const [deleteCategoryId, setDeleteCategoryId] = useState(null)

    const reloadCategories = (showInactiveOverride) => {
        setLoading(true)
        let activeStatus = activeFilterDDL
        if (showInactiveOverride !== undefined){
            activeStatus = showInactiveOverride 
        }

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onListCategorySuccess = (results) => {
            setInfo(results)
        }
        const requestData = {
            ActiveFilter: activeStatus
        }
        loadProductCategories(requestData, socket)
    }

    /* eslint-disable */
    useEffect(() => {
        if (info === null && socket !== null){
            reloadCategories()
        }
    }, [info, socket])
    /* eslint-enable */

    const updateEnabled = (val, i) => {
        let thisId = 0
        let thisObj = null
        const clone = info.map((x, j) => {
            if (i === j){
                x.Enabled = val
                thisId = x.ProductCategoryId
                thisObj = x
            }
            return x
        })
        setInfo(clone)

        socket.props.onUpdateSuccess = (id) => {
            const newIds = updatingIds.map(x => x).filter(x => x !== id)
            setUpdatingIds(newIds)
            reloadCategories()
        }
        socket.props.onUpdateFailure = (msg) => {
            notify('Update Error', msg)
        }

        const idClone = updatingIds.map(x => x)
        idClone.push(thisId)
        setUpdatingIds(idClone)

        updateProductCategory(thisObj, socket)
    }

    const editClick = (x, i) => {
        setEditRow(i)
        setNewName(x.Name)
        setTimeout(() => singleRename.current && singleRename.current.focus(), 100)
    }

    const confirmRename = () => {
        let thisId = 0
        let thisObj = null
        const clone = info.map((x, j) => {
            if (editRow === j){
                x.Name = newName
                thisId = x.ProductCategoryId
                thisObj = x
            }
            return x
        })
        setInfo(clone)
        
        socket.props.onUpdateSuccess = (id) => {
            const newIds = updatingIds.map(x => x).filter(x => x !== id)
            setUpdatingIds(newIds)
        }
        socket.props.onUpdateFailure = (msg) => {
            notify('Update Error', msg)
        }

        const idClone = updatingIds.map(x => x)
        idClone.push(thisId)
        setUpdatingIds(idClone)

        updateProductCategory(thisObj, socket)

        setEditRow(null)
    }

    const moveToIndex = (id, targetIdx) => {
        const requestData = {
            ProductCategoryId: id,
            BelowProductCategoryId: null
        }
        if (targetIdx >= 0){
            requestData.BelowProductCategoryId = info[targetIdx].ProductCategoryId || null
        }
        const thisItem = info.filter(x => x.ProductCategoryId === id)[0]
        
        let newInfo = info.map(x => x)
        newInfo.splice(targetIdx + 1, 0, thisItem)
        newInfo = newInfo.filter((x, i) => i === targetIdx + 1 || x.ProductCategoryId !== id)
        setInfo(newInfo)


        socket.props.onOrderSuccess = (data) => {
            const clone = newInfo.map(x => {
                if (x.ProductCategoryId === data.ProductCategoryId){
                    x.BelowProductCategoryId = data.BelowCategoryId
                }
                else if (x.ProductCategoryId === data.SwapCategoryId){
                    x.BelowProductCategoryId = data.SwapBelowCategoryId
                }
                else if (data.Swap2CategoryId && x.ProductCategoryId === data.Swap2CategoryId){
                    x.BelowProductCategoryId = data.Swap2BelowCategoryId
                }
                return x
            })
            setInfo(clone)
        }
        socket.props.onOrderFailure = (msg) => {
            notify('Order Error', msg)
            reloadCategories()
        }

        console.log(requestData)
        orderProductCategory(requestData, socket.socket)
    }

    return (
        <Fragment>
            <CreateProductCategory socket={socket} notify={notify} show={showCreate} setShow={setShowCreate} props={{reload: reloadCategories}} />
            <Row style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Product Categories
                    </h2>
                </Col>
                <Col>
                    <Row style={{justifyContent: 'space-evenly'}}>
                        <Col xs={10} sm lg={3} style={{padding: '0px', textAlign: 'center'}}>
                            <Button style={{marginBottom: '10px'}} onClick={() => setShowCreate(true)}>Create New</Button>
                        </Col>
                        <Col xs={10} sm lg={3} style={{padding: '0px'}}>
                            <Form.Control as="select" value={activeFilterDDL} onChange={(e) => {setActiveFilterDDL(e.target.value); reloadCategories(e.target.value)}}>
                                <option value={0}>Active</option>
                                <option value={1}>Inactive</option>
                                <option value={2}>All</option>
                            </Form.Control>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            {loading 
            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
            : <div style={{maxHeight: isSmall ? '75vh' : '80vh', overflowY: 'auto'}}>
                {info && info.map((x, i) => {
                    const isLoading = updatingIds.includes(x.ProductCategoryId)
                    if (isLoading){
                        return <Row style={{width: '80%'}}>
                                <Col></Col>
                                <Col>
                                    <CircularProgress style={{width: '40px', height: '40px'}} />
                                </Col>
                                <Col></Col>
                            </Row>
                    }
                    return <Row key={i} style={{marginBottom: '30px'}}>
                        <Col>
                            <Row style={{alignItems: 'center'}}>
                                <Col xs={0} md={3}></Col>
                                <Col>
                                    <Row style={{alignItems: 'center'}}>
                                        {editRow === i 
                                            ? <Col style={{padding: '0px'}}>
                                                <Form.Control value={newName} ref={singleRename} onChange={(e) => setNewName(e.target.value)} onBlur={confirmRename} />
                                            </Col>
                                            : <Fragment>
                                                <Edit color="primary" onClick={() => editClick(x, i)} style={{marginRight: '.5rem', cursor: 'pointer'}} />
                                               {x?.CanDelete && 
                                               <Fragment>
                                                    <DeleteForever color="error" onClick={() => setDeleteCategoryId(x.ProductCategoryId)} style={{marginRight: '30px', cursor: 'pointer'}} />
                                                    {deleteCategoryId === x.ProductCategoryId &&
                                                    <DeleteProductCategory
                                                        socket={socket}
                                                        categoryName={x.Name}
                                                        productCategoryId={x.ProductCategoryId}
                                                        show={Boolean(deleteCategoryId === x.ProductCategoryId)}
                                                        onHide={() => setDeleteCategoryId(null)}
                                                        reloadCategories={reloadCategories}
                                                    />
                                                    }       
                                                </Fragment>}
                                                <h4>{x.Name}</h4>
                                            </Fragment>
                                        }
                                    </Row>
                                    <Row>
                                        <Col xs={1}></Col>
                                        <Col style={{whiteSpace: 'nowrap'}}>
                                            <b>{x.ActiveProducts} Active Products</b>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} lg={4} xl={5}>
                            <Row style={{alignItems: 'center', height: '100%'}}>
                                <Col>
                                    <Form.Check type="switch" id={`CategoryEnabled${i}`} label="Active" checked={x.Enabled} onChange={(e) => updateEnabled(e.target.checked, i)} />
                                </Col>
                                {activeFilterDDL === 0 && (
                                    <Fragment>
                                        <Col style={{textAlign: 'end', cursor: 'pointer'}}>
                                            {i !== 0 && <ArrowUpward onClick={() => moveToIndex(x.ProductCategoryId, i - 2)} />}
                                        </Col>
                                        <Col style={{cursor: 'pointer'}}>
                                            {i !== info.length - 1 && <ArrowDownward onClick={() => moveToIndex(x.ProductCategoryId, i + 1)} />}
                                        </Col>
                                    </Fragment>
                                )}
                            </Row>
                        </Col>
                    </Row>
                })}
            </div>
            }
        </Fragment>
    )
}