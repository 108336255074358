export const handleGetReportDashboards = (response, props) => {
    console.log(response)
    
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }

    props.setLoading && props.setLoading(false)
}

export const handleGetDashboardURL = (response, props) => {
    console.log(response)
    
    if (response.IsError) {
        props.onDetailFailure && props.onDetailFailure(response.ErrorMessage)
    }
    else {
        props.onDetailSuccess && props.onDetailSuccess(response)
    }

    props.setDetailLoading && props.setDetailLoading(false)
}