import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { qrLoginAPI } from '../../api/auth/login'
import Instascan from 'instascan'


export const QRScannerModalNew = ({show, setShow, storeId}) => {

    const [hasCamera, setHasCamera] = useState(false)
    const [error, setError] = useState('')
    const [allCams, setAllCams] = useState([])
    const [activeCam, setActiveCam] = useState(null)
    
    useEffect(() => {
        const vidEl = document.getElementById('qrScanPreview')
        let scanner
        if (vidEl){
            window.URL.createObjectURL = (stream) => {
                vidEl.srcObject = stream
                return stream
            }
            scanner = new Instascan.Scanner({
                video: vidEl,
                scanPeriod: 5
            })

            scanner.addListener('scan', qrWasRead)
        }
        else {
            setError('No VidEl')
        }

        window.scanner = scanner

        Instascan.Camera.getCameras()
            .then((cameras) => {
                if (cameras.length > 0){
                    setAllCams(cameras)
                    setActiveCam(cameras[0])
                    setHasCamera(true)
                    scanner && scanner.start(cameras[0])
                }
                else{
                    setHasCamera(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        return () => {
            window.scanner && window.scanner.removeAllListeners && window.scanner.removeAllListeners()
        }
    }, [])

    const qrWasRead = (data) => {
        window.scanner.stop()
        qrLoginAPI(data, storeId, () => {
            setShow(false)
            window.location.reload()
        })
            .catch(err => {
                console.log(err.toString())
                window.scanner.start()
            })
    }

    const changeScanner = (idx) => {
        setActiveCam(allCams[idx])
        window.scanner.start(allCams[idx])
    }

    return <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Scan QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.2rem'}}>
                {allCams && allCams.map((x, i) => {
                    if (x.id === (activeCam && activeCam.id)){
                        return <div style={{fontWeight: 'bold'}}>
                            {x.name || 'Unknown'}
                        </div>
                    }
                    else{
                        return <div style={{cursor: 'pointer'}} onClick={() => changeScanner(i)}>
                            {x.name || 'Unknown'}
                        </div>
                    }
                })}
            </div>
            {!hasCamera && <h3 style={{textAlign: 'center'}}>No Camera Detected</h3>}
            {error && <h3 style={{textAlign: 'center', color: 'red'}}>{error}</h3>}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <video id="qrScanPreview" height={300} width={300}></video>
            </div>
        </Modal.Body>
    </Modal>
}