import React, { useEffect, useState, Fragment } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { TopNav } from '../../navigation/topnav'
import { LeftOperatorNav } from '../../navigation/leftOperatorNav'
import operatorPages from '../../../enums/operatorPages'
import { connectToAccountWS } from '../../../api/sockets/userSocket'
import { getTokensSyncNoUpdate } from '../../../api/auth/session'
import socketType from '../../../enums/socketType'
import userType from '../../../enums/userTypes'
import requestType from '../../../enums/requestType'
import { Toasty } from '../../public/toast'
import { CircularProgress } from '@material-ui/core'
import { OperatorCustomer } from './operatorCustomer'
import { OperatorOrder } from './operatorOrder'
import { OperatorRegister } from './register/operatorRegister'
import orderStatus from '../../../enums/orderStatus'
import chime from '../../../utility/chime.mp3'
import { HelpPage } from '../helpPage'
import helpType from '../../../enums/helpType'

/* eslint-disable react-hooks/exhaustive-deps */
export const AccountOperator = ({socket, setSocket, socketAuthed, setSocketAuthed, socketReconnecting, setSocketReconnecting, accountId, accountName}) => {
    const tokenInfo = getTokensSyncNoUpdate()
    const isSmall = useMediaQuery('(max-width:525px)')
    const isShort = useMediaQuery('(max-height:500px)')

    const [selectedPage, setSelectedPage] = useState(operatorPages.orders)  
    const [toasts, setToasts] = useState([])

    const [newOrders, setNewOrders] = useState([])
    const [orderList, setOrderList] = useState([])
    const [newCustomers, setNewCustomers] = useState(JSON.parse(localStorage.getItem('OPERATOR_NEW_CUSTOMERS')) || [])
    
    const fullSetOrderList = (list) => {
        sessionStorage.setItem('OPERATOR_ORDERS', JSON.stringify(list))
        setOrderList(list)
        const newOrderIds = list.filter(x => x.Status === orderStatus.SentToClub || x.Status === orderStatus.AwaitingPayment).map(x => x.OrderId)
        setNewOrders(newOrderIds)
    }

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        const time = new Date()
        tsts.push({
            title: title,
            message: msg,
            key: time.toLocaleTimeString()
        })
        setToasts(tsts)
        return tsts
    }

    const receiveOrderHandler = (data) => {
        const orders = JSON.parse(sessionStorage.getItem('OPERATOR_ORDERS')) || []
        
        const existingOrder = orders.filter(x => x.OrderId === data.Order.orderId)[0]
        if (existingOrder){
            return
        }
        orders.push(data.Order)
        fullSetOrderList(orders)

        playTheChime()
        let message = 'New Order Received!'
        if (data.Order.Message){
            message += '\n ' + data.Order.Message
        }
        notify('New Order', message)
    }

    const receiveNewCustomerHandler = (data) => {
        const newCustomers = JSON.parse(localStorage.getItem('OPERATOR_NEW_CUSTOMERS')) || []

        const exists = newCustomers.filter(x => x.CustomerId === data.Customer.CustomerId)[0]
        if (exists){
            return
        }
        newCustomers.unshift(data.Customer)

        updateNewCustomers(newCustomers)

        notify('Customer Received', 'Customer Information Received')
    }

    const updateNewCustomers = (newList) => {
        localStorage.setItem('OPERATOR_NEW_CUSTOMERS', JSON.stringify(newList))

        setNewCustomers(newList)
    }

    const playTheChime = () => {
        try{
            const theChimer = document.getElementById('chimer')
            theChimer && theChimer.play()
        }
        catch(err){ }
    }

    useEffect(() => {
        if (socket === null){
            const socketProps = {
                setSocket,
                notify,
                setSocketAuthed,
                setSocketReconnecting,
                onReceiveSuccess: receiveOrderHandler,
                onNewCustomerSuccess: receiveNewCustomerHandler,
                ...tokenInfo
            }
            setSocket(connectToAccountWS(socketProps, socketType.Account, accountId))
        }
        else if (socket.props.SocketType && socket.props.SocketType !== socketType.Account){
            // Upgrade socket
            const upgradeRequest = {
                RequestType: requestType.Auth,
                Data: {
                    UserType: userType.User,
                    AccessToken: tokenInfo.AccessToken,
                    AccountId: accountId,
                    SocketType: socketType.Account
                }
            }
            socket.socket.send(JSON.stringify(upgradeRequest))
        }
        if (socket){
            socket.props.onReceiveSuccess = receiveOrderHandler
            socket.props.onNewCustomerSuccess = receiveNewCustomerHandler
        }
        const orders = JSON.parse(sessionStorage.getItem('OPERATOR_ORDERS')) || []
        fullSetOrderList(orders)
    }, [socket, socketAuthed])

    const getTopPadding = () => {
        if (isShort){
            return '10vh'
        }
        return '8vh'
    }
    
    return (
        <div style={{overflow: 'hidden'}}>
            <div>
                <TopNav overrideName={accountName} setSelectedPage={setSelectedPage} />
            </div>
            <div style={{display: 'flex'}}>
                <div style={{width: '15vw', height: '100vh', backgroundColor: '#eeeeee'}}> 
                    <LeftOperatorNav props={{setSelectedPage, selectedPage, newOrders, newCustomers}}  />
                </div>
                <div style={{width: '100%', height: '100vh', backgroundColor: '#e4e5e8', paddingTop: getTopPadding(), paddingLeft: isSmall ? '4vw' : '0vw', overflowY: 'auto'}}>
                    {socketAuthed && socket && (
                        <Fragment>
                            {!socketAuthed
                            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                                <CircularProgress style={{width: '100px', height: '100px'}} />
                            </div>
                            :(
                                <Fragment>
                                    {selectedPage === operatorPages.customers && 
                                        <OperatorCustomer
                                            socket={socket}
                                            notify={notify}
                                            newCustomers={newCustomers}
                                            setNewCustomers={updateNewCustomers}
                                        /> 
                                    }
                                    {(selectedPage === operatorPages.orders || selectedPage.includes(operatorPages.orderDetail)) && (
                                        <OperatorOrder 
                                            socket={socket} notify={notify} 
                                            orderList={orderList} setOrderList={fullSetOrderList}
                                            selectedPage={selectedPage} setSelectedPage={setSelectedPage}
                                        /> 
                                    )}
                                    {(selectedPage === operatorPages.help) && (
                                        <HelpPage socket={socket} notify={notify} helpType={helpType.Operator} /> 
                                    )}
                                    {(selectedPage === operatorPages.register) && (
                                        <OperatorRegister props={{
                                            socket, notify,
                                            selectedPage, setSelectedPage}}
                                        /> 
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
            <div style={{position: 'fixed', top: '8vh', right: '20px', zIndex: 20}}>
                <Toasty toasts={toasts} setToasts={setToasts} />
            </div>
            <audio src={chime} id="chimer"></audio>
            {socketReconnecting && (
                <div style={{zIndex: '999', position: 'fixed', top: '0px', left: '0px', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.8)'}}>
                    <div style={{backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', border: '3px solid gray', borderRadius: '20px'}}>
                        <h3>RECONNECTING...</h3>
                    </div>
                </div>
            )}
        </div>
    )
}