import React, { Fragment, useState } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { ScrollTabPanel } from '../utility/scrollTabPanel'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { EmailTemplate } from './emailTemplate'

export const EmailHome = ({socket, notify}) => {
    const isSmall = useMediaQuery('(max-width:525px)')

    const [tab, setTab] = useState(0)

    const getTabProps = (i) => {
        return {
            id: `scrollable-auto-tab-${i}`,
            'aria-controls': `scrollable-auto-tabpanel-${i}`
        }
    }

    return <Fragment>
        <AppBar position="static" color="default" 
            style={{
                marginLeft: isSmall ? '-4vw' : '',
                width: isSmall ? '87vw' : '',
                background: 'linear-gradient(transparent, #f5f5f5)',
                boxShadow: '0px 6px 6px 0px rgb(0 0 0 / 8%)'
            }} 
        >
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                style={{outline: 'none'}}
            >
                <Tab label="Templates" {...getTabProps(0)} />
                <Tab label="Campaigns" {...getTabProps(1)} />
                <Tab label="Customer Lists" {...getTabProps(2)} />
                <Tab label="Stats" {...getTabProps(3)} />
                <Tab label="New Template" {...getTabProps(4)} />
            </Tabs>
        </AppBar>
        <ScrollTabPanel value={tab} index={0}>
            <h4>
                Templates
            </h4>
            <hr />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={1}>
            <h4>
                Campaigns
            </h4>
            <hr />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={2}>
            <h4>
                Customer Lists
            </h4>
            <hr />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={3}>
            <h4>
                Statistics
            </h4>
            <hr />
        </ScrollTabPanel>
        <ScrollTabPanel value={tab} index={4}>
            <EmailTemplate socket={socket} notify={notify} userId={0} />
        </ScrollTabPanel>
    </Fragment>
}