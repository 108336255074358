import React, { useState, Fragment, useEffect, useRef } from "react"
import { Form, Button, Col, Row, Accordion, Card, Container, InputGroup } from 'react-bootstrap'
import { ListItemText, List, ListItem, ListItemIcon } from '@material-ui/core'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { loadProductCategories } from '../../../../api/sockets/account/accountRequests'
import activeFilter from '../../../../enums/activeFilter'
import { OperatorComboDetail } from "./operatorComboDetail"

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center'
    },
    listContainer: {
        minWidth: 250,
        overflow: 'auto',
    },
    listItem: {
        paddingTop: 10,
        '&:hover': {
            color: 'gray'
        },
    },
}))


// May want to make a combo card here, and for each it
export const OperatorCombos = ({ socket, notify, combos, products, setCart, setCartItemId, cartItemId, cart, calculateTotal }) => {
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:650px)')
    const isBig = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    const [combo, setCombo] = useState(null)
    const [combosExpanded, setCombosExpanded] = useState(false)
    
    // Search
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('All')
    const [filteredCombos, setFilteredCombos] = useState(combos)
    const [productCategories, setProductCategories] = useState(null)

    useEffect(() => {
        if (socket && !productCategories) {
            reloadProductCategories()
        }
    }, [socket])

    const reloadProductCategories = () => {
        socket.props.notify = notify
        socket.props.onListCategorySuccess = (categoryList) => {
            setProductCategories(categoryList)
        }

        const request = {
            ActiveFilter: activeFilter.ActiveFilter
        }

        loadProductCategories(request, socket)
    }

    const searchCombos = (search) => {
        if (search) {
            search = search.toLowerCase()
            const matches = combos.filter(x => x.Name.toLowerCase().includes(search))
            setFilteredCombos(matches)
        }
        else {
            filterCombos(selectedCategory)
        }
    }


    // By Product Cat
    const filterCombos = (val) => {
        if(val === 'All') {
            setFilteredCombos(combos)
        }
        else{
            const matches = combos.filter(c => {
               const categoryIds = c.ProductCategories.map(x => x.ProductCategoryId)
               if(categoryIds.includes(Number(val))){
                   return categoryIds
               }
            })
            setFilteredCombos(matches)            
        }
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Accordion>
                        <Card style={{background: 'rgb(228, 229, 232)'}}>
                            <Accordion.Toggle as={Card.Header} style={{cursor: 'pointer'}} onClick={() => setCombosExpanded(!combosExpanded)} eventKey="0">
                                <Card.Header style={{paddingLeft: '0rem'}}>
                                    <h4>Combos {combosExpanded ? <ArrowUpwardIcon fontSize="large" color="primary" /> : <ArrowDownwardIcon fontSize="large" color="primary" />}</h4>
                                </Card.Header>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body style={{ minHeight: '30rem' }}>
                                    {!combos ? <p style={{ color: 'red' }}>No Combos Available at this time.</p>
                                        :
                                        combo ?
                                            <OperatorComboDetail
                                                socket={socket}
                                                back={() => setCombo(null)}
                                                combo={combo}
                                                products={products}
                                                productCategories={productCategories}
                                                setCart={setCart}
                                                cart={cart}
                                                cartItemId={cartItemId}
                                                setCartItemId={setCartItemId}
                                                notify={notify}
                                                calculateTotal={calculateTotal}
                                            />
                                            :
                                            <div>
                                                <Row>
                                                    <Col style={{ paddingBottom: '2rem' }} md={3} sm={4}>
                                                        <Form.Control as="select" value={selectedCategory} onChange={(e) => { setSelectedCategory(e.target.value); filterCombos(e.target.value) }}>
                                                            <option value="All">All</option>
                                                            {productCategories && productCategories.map(x => {
                                                                return <option key={x.ProductCategoryId} value={x.ProductCategoryId}>{x.Name}</option>
                                                            })}
                                                        </Form.Control>
                                                    </Col>
                                                    <Col md={{ span: 5, offset: 4 }}>
                                                        <Form.Control value={searchTerm} type="search" placeholder={'Search Combos'} onChange={(e) => { setSearchTerm(e.target.value); searchCombos(e.target.value) }}></Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row style={{ overflowY: 'scroll' }}>
                                                    <Col md={10} lg={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                        <List className={classes.listContainer} role="list">
                                                            {filteredCombos && filteredCombos.map((x) => {
                                                                return (
                                                                    <ListItem key={x.ComboId} dense button onClick={() => setCombo(x)}>
                                                                        <ListItemText primary={x.Name} primaryTypographyProps={{ style: { fontSize:  isSmall ? '1rem' : '1.25rem' } }} /> {/* May want size details */}
                                                                        <ListItemIcon>
                                                                            <Button
                                                                                onClick={() => setCombo(x)}
                                                                                tabIndex={-1}
                                                                                style={{ marginRight: '2rem', minWidth: isSmall ? '5rem' : '7.5rem' }}
                                                                            >
                                                                                <ListItemText primary="Build" />
                                                                            </Button>
                                                                        </ListItemIcon>
                                                                    </ListItem>
                                                                )
                                                            })}
                                                        </List>
                                                    </Col>
                                                </Row>
                                            </div>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Fragment>
    )
}