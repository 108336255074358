import React, { useState, Fragment } from 'react'
import { Container, Row, Col, Jumbotron, Form, Button, Modal } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import userTypes from '../../enums/userTypes'
import { forgotPWAPI, confirmForgotPWAPI } from '../../api/auth/login'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
    fullHeight: {
        height: '100vh'
    },
    lightJumbo: {
        backgroundColor: 'powderblue'
    },
    portalBlue: {
        backgroundColor: 'rgb(92, 198, 208)'
    }
})

export const ForgotPassword = ({ props }) => {
    const classes = useStyles()
    const isSmall = useMediaQuery('(max-width:525px)')
    const isBig = useMediaQuery('(min-width:1200px)');
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    // Request Code
    const [requestEmail, setRequestEmail] = useState('')
    const [requestEmailMsg, setRequestEmailMsg] = useState('')
    // Enter Code
    const [confirmEmail, setConfirmEmail] = useState('')
    const [recoveryCode, setRecoveryCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [pwMatch, setPWMatch] = useState(null)
    const [pwGood, setPWGood] = useState(null)
    const [hasRecoveryCode, setHasCode] = useState(false)
    const [resetMsg, setResetMsg] = useState('')
    const [resetSuccess, setResetSuccess] = useState(null)

    const sendRecoveryCode = async (e) => {
        e.preventDefault()
        setLoading(true)
        setRequestEmailMsg('')
        let msg
        try {
            const onSuccess = (response) => {
                setHasCode(true)
            }
            await forgotPWAPI(requestEmail, userTypes.User, onSuccess)
        }
        catch (error) {
            try {
                msg = await error
                if (msg) {
                    setRequestEmailMsg(msg)
                }
            }
            catch (err) { }
        }
        setLoading(false)
    }

    const resetPassword = async (e) => {
        e.preventDefault()
        setLoading(true)
        let msg
        try {
            const onSuccess = (response) => {
                setResetSuccess(true)
            }
            await confirmForgotPWAPI(confirmEmail, newPassword, recoveryCode, userTypes.User, onSuccess)
        }
        catch (error) {
            try {
                msg = await error
                if (msg?.status === 400) {
                    msg = 'Under maintenence. Please Try again later'
                    setResetMsg(msg)
                }
                else if (msg) {
                    setResetMsg(msg)
                    setLoading(false)
                }
            }
            catch (err) { }
        }
        setLoading(false)

    }


    const handleRptPWCheck = (pw, confirmPW) => {
        if (pw === undefined) {
            pw = newPassword
        }

        if (confirmPW === undefined) {
            confirmPW = confirmNewPassword
        }

        const valid = pw === confirmPW
        if (newPassword === '' || confirmNewPassword === '') {
            setPWMatch(null)
        }
        else {
            setPWMatch(valid)
        }
    }

    const handlePWCheck = (pw) => {
        const valid = (pw.length >= 8 && pw.match(/[A-Z]/) && pw.match(/[a-z]/) && pw.match(/\d/)) ?? false
        setPWGood(valid)
    }

    const redirect = () => {
        history.goBack()
    }


    return (
        <Container fluid className={`${classes.portalBlue} ${classes.fullHeight}`}>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                :
                <Fragment>
                    <div style={{
                        width: '100%', height: '55px',
                        backgroundRepeat: 'no-repeat', backgroundPositionX: 'center',
                        marginBottom: isSmall ? '15vh' : '25vh'
                    }}>
                    </div>
                    <Row>
                        <Col xl={6} style={{ margin: 'auto' }}>
                            <Jumbotron style={{ margin: 'auto', paddingTop: '1.5rem', minHeight: '25rem' }} className={`${classes.lightJumbo}`}>
                                <h3 style={{ textAlign: 'center', paddingBottom: '1rem' }}>Reset Password</h3>
                                {/* TODO: CSS Transition */}
                                {!hasRecoveryCode &&
                                    <Form onSubmit={sendRecoveryCode}>
                                        <div style={{ paddingBottom: '4rem' }}></div>
                                        {requestEmailMsg && <h5 style={{ color: 'red', fontSize: '1rem', textAlign: 'center' }}>{requestEmailMsg.toString()}</h5>}
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ textAlign: isBig ? 'end' : 'left' }} column xl={4}>Email</Form.Label>
                                            <Col xl={6}>
                                                <Form.Control value={requestEmail} type="text" onChange={(e) => setRequestEmail(e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col xl={8}></Col>
                                            <Col>
                                                <Button disabled={loading} type="submit">Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                                {hasRecoveryCode &&
                                    <Form onSubmit={resetPassword}>
                                        {resetMsg && <h5 style={{ color: 'red', fontSize: '1rem', textAlign: 'center' }}>{resetMsg.toString()}</h5>}
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ textAlign: isBig ? 'end' : 'left' }} column xl={4}>Recovery Code</Form.Label>
                                            <Col xl={6}>
                                                <Form.Control value={recoveryCode} type="text" onChange={(e) => setRecoveryCode(e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ textAlign: isBig ? 'end' : 'left' }} column xl={4}>Confirm Email</Form.Label>
                                            <Col xl={6}>
                                                <Form.Control value={confirmEmail} type="email" onChange={(e) => setConfirmEmail(e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ textAlign: isBig ? 'end' : 'left' }} column xl={4}>New Password
                                    {pwGood !== null && (pwGood
                                                    ? <Tooltip title="Good"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                    : <Tooltip title="Must have 8 characters, an uppercase letter, lowercase letter, and a number"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                )}</Form.Label>
                                            <Col xl={6}>
                                                <Form.Control value={newPassword} type="password" onChange={(e) => { setNewPassword(e.target.value); handlePWCheck(e.target.value); handleRptPWCheck(e.target.value, undefined); }} onBlur={(e) => handlePWCheck(e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label style={{ textAlign: isBig ? 'end' : 'left' }} column xl={4}>Confirm Password
                                    {pwMatch != null && (pwMatch
                                                    ? <Tooltip title="Good"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                    : <Tooltip title="Passwords must match"><BadIcon style={{ color: 'red' }} /></Tooltip>)}
                                            </Form.Label>
                                            <Col xl={6}>
                                                <Form.Control value={confirmNewPassword} type="password" onChange={(e) => { setConfirmNewPassword(e.target.value); handleRptPWCheck(undefined, e.target.value) }} onBlur={(e) => handleRptPWCheck(undefined, e.target.value)}></Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col xl={8}></Col>
                                            <Col>
                                                <Button type="submit" disabled={!pwMatch || loading || !recoveryCode || !confirmEmail || !pwGood || resetSuccess}>Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                            </Jumbotron>
                        </Col>
                    </Row>
                    <Modal
                        show={resetSuccess}
                        onHide={redirect}
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Password Changed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Your password has been changed!
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={redirect}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </Fragment>
            }
        </Container>
    )
}