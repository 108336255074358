import React, { useState, Fragment } from 'react'
import { Container, Row, Col, Jumbotron, Form, Button, Nav } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
// import Helmet from 'react-helmet'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import { Redirect } from 'react-router-dom'
import { checkEmailExistsAPI, signUpUserAPI } from '../../api/auth/signup'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MaskedInput from 'react-text-mask'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
    lightJumbo: {
        backgroundColor: 'powderblue'
    },
    portalBlue: {
        backgroundColor: 'rgb(92, 198, 208)'
    }
})


export const SignUp = () => {

    const classes = useStyles();
    const isSmall = useMediaQuery('(max-width:525px)');
    const [firstName, setfirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [rptPW, setRptPW] = useState('')
    const [message, setMessage] = useState('');
    const [firstNameGood, setfirstNameGood] = useState(null)
    const [lastNameGood, setLastNameGood] = useState(null)
    const [phoneGood, setPhoneGood] = useState(null)
    const [emailGood, setEmailGood] = useState(null)
    const [pwGood, setPWGood] = useState(null)
    const [rptGood, setRptGood] = useState(null)
    const [registered, setRegistered] = useState(false)
    const [loading, setLoading] = useState(false);
    
    const handleFNCheck = (val) => {
        setfirstNameGood(val !== '')
    }

    const handleLNCheck = (val) => {
        setLastNameGood(val !== '')
    }

    const handlePhoneCheck = (val) => {
        setPhoneGood(val && val.length === 14)
    }

    const handleEmailCheck = async () => {
        setLoading(true)
        if (email !== '') {
            let response;
            let msg;
            try {
                response = await checkEmailExistsAPI(email)
            }
            catch (error) {
                let finalError = error
                try { 
                    finalError = await error
                } 
                catch (err) { }
                msg = finalError;
            }
            if (msg) {
                if (msg.message === 'Failed to fetch' || msg === '503') {
                    msg = 'Currently Under Maintenance. Please try again later.'
                }
                setMessage(msg.toString())
                setEmailGood(false)
                setLoading(false)
                return
            }
            setEmailGood(!response.Exists)
        }
        else {
            setEmailGood(false)
        }
        setLoading(false)
    }

    const handlePWCheck = (pw) => {
        const valid = (pw.length >= 8 && pw.match(/[A-Z]/) && pw.match(/[a-z]/) && pw.match(/\d/)) ?? false
            setPWGood(valid)
    }

    const handleRptPWCheck = (pw1, pw2) => {
        if (pw2 === undefined) {
            pw2 = password
        }
        if (pw1 === undefined) {
            pw1 = rptPW
        }
        const valid = pw1 === pw2
        if (password === '' || rptPW === '') {
            setRptGood(null)
        }
        else {
            setRptGood(valid)
        }
    }

    const handleSignUp = async (e) => {
        e.preventDefault()
        if(!loading){
            setLoading(true)
            await signUpUserAPI(email, password, firstName, lastName, phoneNumber)
            setRegistered(true)
            setLoading(false)
        }
    }

    if (registered) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <Container fluid style={{backgroundColor: '#9fd68d', overflowY: 'auto', color: 'white'}}>
            <Row >
                <Col xs={0} md={2} lg={3}></Col>
                <Col>
                    <div className={` ${classes.fullHeight}`}>
                        <div style={{
                            width: '100%', height: '55px',
                            backgroundRepeat: 'no-repeat', backgroundPositionX: 'center',
                            marginBottom: '10vh'
                        }}>
                        </div>
                        <Fragment>
                            <Jumbotron className={`${classes.center} ${classes.lightJumbo} ${classes.column}`} style={{ width: '100%', marginTop: isSmall ? '13vh' : 'inherit', backgroundColor: 'rgba(0,0,0,0.5)', marginBottom: '6vh' }}>
                                <h2 style={{ marginBottom: '20px' }}>Sign Up</h2>
                                {message && <h5 style={{ color: 'red' }}>{message.toString()}</h5>}
                                <Form onSubmit={handleSignUp}>
                                    <Form.Group controlid="firstName">
                                        <Form.Label>First Name
                                            {firstNameGood !== null && (firstNameGood
                                                ? <GoodIcon style={{ color: 'green' }} />
                                                : <Tooltip title="Please enter your first name"><BadIcon style={{ color: 'yellow' }} /></Tooltip>
                                            )}
                                        </Form.Label>
                                        <Form.Control value={firstName} required type="text" placeholder="First Name" onChange={(e) => { setfirstName(e.target.value); handleFNCheck(e.target.value); }} onBlur={(e) => handleFNCheck(e.target.value)}  />
                                    </Form.Group>
                                    <Form.Group controlid="lastName">
                                        <Form.Label>Last Name
                                            {lastNameGood !== null && (lastNameGood
                                                ? <GoodIcon style={{ color: 'green' }} />
                                                : <Tooltip title="Please enter your last name"><BadIcon style={{ color: 'yellow' }} /></Tooltip>
                                            )}
                                        </Form.Label>
                                        <Form.Control value={lastName} required type="text" placeholder="Last Name" onChange={(e) => { setLastName(e.target.value); handleLNCheck(e.target.value); }} onBlur={(e) => handleLNCheck(e.target.value)}  />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Phone Number
                                            {phoneGood !== null && (phoneGood
                                                ? <GoodIcon style={{ color: 'green' }} />
                                                : <Tooltip title="Please enter a valid phone number"><BadIcon style={{ color: 'yellow' }} /></Tooltip>
                                            )}
                                        </Form.Label>
                                        <MaskedInput
                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            placeholder="Phone Number"
                                            guide={false}
                                            onChange={(e) => { setPhoneNumber(e.target.value); handlePhoneCheck(e.target.value) }}
                                            onBlur={(e) => handlePhoneCheck(e.target.value)}
                                            type="tel"
                                            value={phoneNumber} 
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email Address
                                        {emailGood !== null && (emailGood
                                                ? <Tooltip title="Not Taken"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                : <Tooltip title={email ? "This email address is already registered" : "Please enter an email address"}><BadIcon style={{ color: 'yellow' }} /></Tooltip>
                                            )}
                                        </Form.Label>
                                        <Form.Control value={email} required type="email" placeholder="Enter Email" onChange={(e) => { setEmail(e.target.value); handleEmailCheck(); }} onBlur={() => handleEmailCheck()} />
                                    </Form.Group>
                                    <Form.Group controlId="password">
                                        <Form.Label>Password
                                        {pwGood !== null && (pwGood
                                                ? <Tooltip title="Good"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                : <Tooltip title="Must have 8 characters, an uppercase letter, lowercase letter, and a number"><BadIcon style={{ color: 'yellow' }} /></Tooltip>
                                            )}
                                        </Form.Label>
                                        <Form.Control value={password} required type="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value); handlePWCheck(e.target.value); handleRptPWCheck(undefined, e.target.value) }} onBlur={(e) => handlePWCheck(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="rptPassword">
                                        <Form.Label>Repeat Password
                                        {rptGood !== null && (rptGood
                                                ? <Tooltip title="Good"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                : <Tooltip title="Passwords must match"><BadIcon style={{ color: 'yellow' }} /></Tooltip>
                                            )}</Form.Label>
                                        <Form.Control value={rptPW} type="password" required placeholder="Password" onChange={(e) => { setRptPW(e.target.value); handleRptPWCheck(e.target.value) }} onBlur={(e) => handleRptPWCheck(e.target.value) } />
                                    </Form.Group>
                                    <div className={`${classes.center}`}>By signing up you are agreeing to<br /> the following terms of service:</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '10px' }}>
                                        <Nav.Link href="/eula">EULA</Nav.Link>
                                        <Nav.Link href="/privacypolicy">Privacy</Nav.Link>
                                    </div>
                                    <div className={`${classes.center}`}>
                                        <Button variant="primary" type="submit" disabled={!email || !password || !emailGood || !firstName || !lastName || !phoneNumber || !pwGood || !rptPW || !rptGood || !firstNameGood || !lastNameGood || !phoneGood || loading}>
                                            Sign Up
                                    </Button>
                                    </div>
                                </Form>
                            </Jumbotron>
                            <div style={{marginTop: '10vh'}}>&nbsp;</div>
                        </Fragment>
                    </div>
                </Col>
                <Col xs={0} md={2} lg={3}></Col>
            </Row>
        </Container>
    )
}