import React, { useEffect, useState } from 'react'
import { ProductList } from './productList'
import { CreateProduct } from './createProduct'
import { ViewProduct } from './viewProduct'
import activeFilter from '../../enums/activeFilter'
import { listProducts } from '../../api/sockets/product/productRequests'
import userPages from '../../enums/userPages'

/* eslint-disable react-hooks/exhaustive-deps */
export const Product = ({socket, notify, selectedPage, setSelectedPage}) => {
    const [showProductId, setShowProductId] = useState(null)
    const [createProduct, setCreateProduct] = useState(false)
    
    const [list, setList] = useState(null)
    const [filter, setFilter] = useState(activeFilter.Active)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (socket !== null && list === null){
            // load list
            reloadList()
        }

        if (selectedPage && selectedPage.includes(userPages.ProductDetail)){
            setShowProductId(Number(selectedPage.replace(userPages.ProductDetail, '')))
        }
        else{
            setShowProductId(null)
        }
    }, [socket, list, selectedPage])

    const reloadList = (newFilter) => {
        setLoading(true)
        if (newFilter !== undefined){
            setFilter(newFilter)
        }
        else{
            newFilter = filter
        }

        // has filter for tag capabilities but will skip for now
        const requestData = {
            TagNames: [],
            ActiveFilter: newFilter
        }

        socket.props.onListSuccess = (prodList) => {
            let categoryGroups = []
            prodList.forEach(x => {
                if (!x.Categories.length){
                    x.Categories.push('Uncategorized')
                }
                x.Categories.forEach(y => {
                    const existing = categoryGroups.filter(z => z.Category === y)[0]
                    if (existing){
                        existing.Items.push(x)
                    }
                    else{
                        categoryGroups.push({
                            Category: y,
                            Items: [x]
                        })
                    }
                })
            })
            categoryGroups = categoryGroups.sort((a, b) => {
                if (a.Category === 'Uncategorized'){
                    return 1
                }
                return a.Category > b.Category ? 1 : -1
            })
            setList(categoryGroups)
        }
        socket.props.onListFailure = (msg) => {
            notify('Error', 'Error Loading List')
        }
        socket.props.setListLoading = setLoading
        socket.props.notify = notify

        listProducts(requestData, socket)
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {
            createProduct
            ? <CreateProduct socket={socket} notify={notify} back={() => setCreateProduct(false) } switchToOrder={(id) => setSelectedPage(`${userPages.ProductDetail}${id}`)} />
            : showProductId
                ? <ViewProduct props={{
                    socket, notify, 
                    productId: showProductId,
                    back: () => setSelectedPage(userPages.Products),
                    reloadList,
                    setShowProductId: (id) => setSelectedPage(`${userPages.ProductDetail}${id}`)
                }} />
                : <ProductList props={{
                    list, loading, reloadList,
                    filter, setFilter, setCreateProduct,
                    setShowProductId: (id) => setSelectedPage(`${userPages.ProductDetail}${id}`)
                }} />
        }
        </div>
    )
}

