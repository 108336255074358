import React, { Fragment, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { getCustomerTokens } from '../../../api/auth/session'
import { reloadQRCode } from '../../../api/sockets/customer/customerRequests'


export const ReloadQRCode = ({ socket, notify, storeId }) => {
    const { Email, IsSSO } = getCustomerTokens()
    const [customerPassword, setCustomerPassword] = useState(null)
    const isSmall = useMediaQuery('(max-width:768px)')
    const [loading, setLoading] = useState(false)
    const [qrCode, setQRCode] = useState(null)
    
    const processReloadQRCode = (e) => {
        e.preventDefault()
       if(socket !== null && socket.socket.readyState === WebSocket.OPEN) {
           setLoading(true)
           socket.props.onFailure = (msg) => {
               notify('Error', msg)
               setLoading(false)
           }
           socket.props.loading = setLoading
           socket.props.onSuccess = (response) => {
               setQRCode(response.QRCode)
               setLoading(false)
            }
            
            const requestData = {
                Email: IsSSO ? null : Email,
                Password: IsSSO ? null : customerPassword,
                StoreAccountId: storeId
            }
            reloadQRCode(requestData, socket.socket)
        }
    }

    return (
        <Fragment>
                <Row>
                    <Col lg={1}></Col>
                    <Col>
                        <h3>QR Code</h3>
                    </Col>
                </Row>
                <hr />
                {!qrCode &&
                <Form onSubmit={processReloadQRCode}>
                    <Form.Group as={Row}>
                      {!IsSSO &&   <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Please enter your password</Form.Label>}
                        <Col xl={3} lg={4} md={4} sm={5} style={{ paddingBottom: '1rem' }} >
                           {!IsSSO && <Form.Control value={customerPassword || ''} required type="password" maxLength={200} onChange={(e) => setCustomerPassword(e.target.value)}></Form.Control>}
                        </Col>
                        {isSmall ? <Col xl={1}></Col> : null}
                        <Col>
                            <Button type="submit" disabled={loading}>Get QR Code</Button>
                        </Col>
                    </Form.Group>
                </Form>
            }
            {qrCode &&
                <Row>
                    <Col xl={{offset: 4 }} lg={{offset: 3}} md={{offset: 4}} sm={{offset: 3}} xs={{offset: 2}}>
                    <img src={`data:image/png;base64,${qrCode}`} alt="QR Code"></img>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}