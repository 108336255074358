import { decodeHtmlEntity } from '../../../utility/stringHelper'

export const handleAddProduct = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else{
        props.onSuccess && props.onSuccess(response.ProductId)
    }
    props.setLoading(false)
}

export const handleProductList = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else{
        props.onListSuccess && props.onListSuccess(response.ProductOverviews)
    }
    props.setListLoading(false)
}

export const handleUpdateProduct = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onUpdateProductFailure && props.onUpdateProductFailure(response.ErrorMessage)
    }
    else{
        props.onUpdateProductSuccess && props.onUpdateProductSuccess(response.ProductId)
    }
    props.setUpdateLoading(false)
}

export const handleProductDetail = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onProductDetailFailure && props.onProductDetailFailure(response.ErrorMessage)
    }
    else{
        props.onProductDetailSuccess && props.onProductDetailSuccess(response)
    }
    props.setLoading(false)
}

export const handleListIngredients = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onListIngredientsFailure && props.onListIngredientsFailure(response.ErrorMessage)
    }
    else {
        response.Ingredients.forEach(i => i.Name = decodeHtmlEntity(i.Name))
        props.onListIngredientsSuccess && props.onListIngredientsSuccess(response.Ingredients)
    }
    props.setLoading(false)
}

export const handleUpdateProductTags = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onUpdateProductTagsFailure && props.onUpdateProductTagsFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateProductTagsSuccess && props.onUpdateProductTagsSuccess(response.Ingredients)
    }
    props.setLoading && props.setLoading(false)
}

export const handleSearchProductTag = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onSearchTagFailure && props.onSearchTagFailure(response.ErrorMessage)
    }
    else {
        props.onSearchTagSuccess && props.onSearchTagSuccess(response.Tags)
    }
    props.setLoading(false)
}

export const handleCloneProduct = (response, props) => {
    console.log(response)
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }
}

export const handleProductClearImage = (response, props) => {
    if (response.IsError){
        props.onClearImageFailure && props.onClearImageFailure(response.ErrorMessage)
    }
    else{
        props.onClearImageSuccess && props.onClearImageSuccess()
    }
    props.setImageLoading && props.setImageLoading(false)
}

export const handleDailySnapshot = (response, props) => {
    if (response.IsError){
        props.onSnapshotFailure && props.onSnapshotFailure(response.ErrorMessage)
    }
    else{
        props.onSnapshotSuccess && props.onSnapshotSuccess(response)
    }
    props.setLoading && props.setLoading(false)
}

export const handleDeleteProduct = (response, props) => {
    if (response.IsError) {
        props.onDeleteProductFailure && props.onDeleteProductFailure(response.ErrorMessage)
    }
    else {
        props.onDeleteProductSuccess && props.onDeleteProductSuccess()
    }
    props.setLoading && props.setLoading(false)
}