import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { loadProducts } from '../../api/sockets/store/storeRequests'
import { StoreProductCard } from './storeProductCard'
import useMediaQuery from '@material-ui/core/useMediaQuery'

/* eslint-disable react-hooks/exhaustive-deps */
export const StoreProductList = ({socket, notify, categoryPage, storeId, setSelectedPage}) => {

    const [products, setProducts] = useState(null)
    const [combos, setCombos] = useState(null)
    const [loading, setLoading] = useState(false)
    // const [categoryId, setCategoryId] = useState(null)
    const isSmall = useMediaQuery('(max-width:525px)')
    useEffect(() => {
        if (socket !== null && socket.socket.readyState === WebSocket.OPEN && categoryPage){
            const catId = Number(categoryPage.replace('PRODUCT_CATEGORY_ID_', ''))
            reloadProductCategory(catId)
            // setCategoryId(catId)
        }
    }, [categoryPage])

    const reloadProductCategory = (catId) => {
        setLoading(true)
        const requestData = {
            StoreAccountId: storeId,
            ProductCategoryId: catId
        }

        socket.props.setProductLoading = setLoading
        socket.props.notify = notify
        socket.props.onProductSuccess = (data) => {
            setProducts(data.Products)
            setCombos(data.Combos)
        }
        socket.props.onProductFailure = (msg) => {
            notify('Error', msg)
        }

        loadProducts(requestData, socket.socket)
    }
    
    const productSelection = (productId) => {
        setSelectedPage(`PRODUCT_DETAIL_ID_${productId}`)
    }
    const comboSelection = (comboId) => {
        setSelectedPage(`COMBO_DETAIL_ID_${comboId}`)
    }

    return (
        <Fragment>
            {loading
                ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                    <CircularProgress style={{width: '100px', height: '100px'}} />
                </div>
                : <Row style={{paddingLeft: isSmall ? '0' : '4rem', paddingRight: isSmall ? '1rem' : '4rem'}}>        
                    {combos && combos.map(x => {
                        return <StoreProductCard key={x.ComboId} product={x} setFullProduct={comboSelection} />
                    })}
                    {products && products.map((x, i) => {
                        return <StoreProductCard key={i} product={x} setFullProduct={productSelection} />
                    })}      
                </Row>
            }      
        </Fragment>
    )
}