import requestType from '../../../enums/requestType'

export const createAccount = (data, socketInfo) => {
    const request = {
        RequestType: requestType.CreateAccount,
        Data: data
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const viewAccountAccess = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ViewAccountAccess,
        Data: data  
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const requestAccountAccess = (data, socketInfo) => {
    const request = {
        RequestType: requestType.RequestAccountAccess,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const viewUser = (data, socket) => {
    const request = {
         RequestType: requestType.ViewUser,
         Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateUser = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateUser,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const changeUserPassword = (data, socket) => {
    const request = {
        RequestType: requestType.UpdatePassword,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateUpline = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateUpline,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const cancelAccessRequest = (data, socket) => {
    const request = {
        RequestType: requestType.CancelAccessRequest,
        Data: data
    }
    socket.send(JSON.stringify(request))
}