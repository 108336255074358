import requestType from '../../../enums/requestType'
import userType from '../../../enums/userTypes'
import socketType from '../../../enums/socketType'


export const authorizeCustomer = (accessToken, socket) => {
    const request = {
        RequestType: requestType.Auth,
        Data: {
            UserType: userType.Customer,
            AccessToken: accessToken,
            SocketType: socketType.Customer
        }
    }
    socket.send(JSON.stringify(request))
}

export const reloadQRCode = (data, socket) => {
    const request = {
        RequestType: requestType.ReloadQRCode,
        Data: {
            Email: data.Email,
            Password: data.Password,
            StoreAccountId: data.StoreAccountId
        }
    }
    socket.send(JSON.stringify(request))
}
export const viewCustomer = (data, socket) => {
    const request = {
        RequestType: requestType.ViewCustomer,
        Data: data 
    }
   socket.send(JSON.stringify(request))
}

export const updateCustomer = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateCustomer,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const changeCustomerEmail = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateCustomerEmail,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateDefaultPayment = (data, socket) => {
    const request = {
        RequestType: requestType.CustomerSetDefaultPayment,
        Data: data
    }
    socket.send(JSON.stringify(request))
}