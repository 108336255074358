
export const handleAccountOrderList = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onListFailure && props.onListFailure(response.ErrorMessage)
    }
    else{
        props.onListSuccess && props.onListSuccess(response.OrderItems)
    }
    props.setLoading(false)
}

export const handleAccountOrderDetail = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.onDetailFailure && props.onDetailFailure(response.ErrorMessage)
    }
    else{
        props.onDetailSuccess && props.onDetailSuccess(response)
    }
    props.setLoading(false)
}

export const handleCustomerHistory = (response, props) => {
    if (response.IsError){
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else{
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleCustomerHistoryDetail = (response, props) => {
    if (response.IsError){
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else{
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}