import { restoreCart } from "../cart/cartRequests"

export const handleAuth = (response, props) => {
    console.log(response)

    const storedCart = localStorage.getItem(`${props.storeId}_CART`)
    if (storedCart) {
        const restoreData = {
            StoreAccountId: props.storeId,
            ShoppingCart: JSON.parse(storedCart)
        }
        props.restoreCartCallback = () => props.setDoneLoading(true)
        restoreCart(restoreData, props.thisSocket)
    }
    else {
        props.setDoneLoading(true)
    }
}

export const handleReloadQRCode = (response, props) => {
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleViewCustomer = (response, props) => {
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleUpdateCustomer = (response, props) => {
    if (response.IsError) {
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess()
    }
    props.setLoading(false)
}

export const handleChangeCustomerEmail = (response, props) => {
    if (response.IsError) {
        props.onEmailChangeFailure && props.onEmailChangeFailure(response.ErrorMessage)
    }
    else {
        props.onEmailChangeSuccess && props.onEmailChangeSuccess()
    }
    props.setLoading(false)
}

export const handleCustomerOrderUpdated = (response, props) => {
    if (response.IsError) {
        props.onOrderUpdateFailure && props.onOrderUpdateFailure(response.ErrorMessage)
    }
    else {
        props.onOrderUpdateSuccess && props.onOrderUpdateSuccess(response)
    }
    props.setLoading(false)
}

export const handleUpdateDefaultPayment = (response, props) => {
    if (response.IsError) {
        props.onUpdateDefaultPaymentFailure && props.onUpdateDefaultPaymentFailure(response.ErrorMessage)
    }
    else {
        props.onUpdateDefaultPaymentSuccess && props.onUpdateDefaultPaymentSuccess(response)
    }
    props.setLoading(false)
}