import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'
import React, { useState } from 'react'
import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import { handleErrors } from '../../api/base'
import { sendReceiptAPI, testQuickPayAPI, quickPayAPI } from '../../api/quickpay/quickpay'
import { Toasty } from '../public/toast'

export const SVQuickPay = ({test}) => {
    const [toasts, setToasts] = useState([])

    const [reg, setReg] = useState(0)
    const [stu, setStu] = useState(0)
    const [stuD, setStuD] = useState(0)
    const [base, setBase] = useState(0)
    const [tickFee, setTickFee] = useState(0)
    const [ccFees, setCCFees] = useState(0)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [orderId, setOrderId] = useState(0)
    const [email, setEmail] = useState('')
    const [sending, setSending] = useState(false)

    const ccFlat = 0.05, ccPerc = 2.7, flatTickFee = 0.95, perTickFee = 0
    const regPrice = 9, stuPrice = 7, stuDonPrice = 5

    const isMd = useMediaQuery('(min-width:769px)')

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        const time = new Date()
        tsts.push({
            title: title,
            message: msg,
            key: time.toLocaleTimeString()
        })
        setToasts(tsts)
        return tsts
    }

    const calcTickets = (regular, student, studentDon) => {
        let ovrReg = reg, ovrStu = stu, ovrStuD = stuD
        if (typeof(regular) === 'number'){
            ovrReg = regular
            setReg(ovrReg)
        }
        if (typeof(student) === 'number'){
            ovrStu = student
            setStu(ovrStu)
        }
        if (typeof(studentDon) === 'number'){
            ovrStuD = studentDon
            setStuD(ovrStuD)
        }

        const baseNum = safeNum(safeNum(ovrReg * regPrice) + safeNum(ovrStu * stuPrice) + safeNum(ovrStuD * stuDonPrice))
        setBase(baseNum)

        const feeNum = safeNum(flatTickFee)
        setTickFee(feeNum)

        const combined = safeNum(baseNum + feeNum)
        const withPerc = safeNum(combined * (1 + (ccPerc / 100)))
        const final = safeNum(withPerc + ccFlat, 2)
        if (baseNum === 0){
            setTotal(0)
            setCCFees(0)
            setTickFee(0)
        }
        else{
            setTotal(final)
            setCCFees(safeNum(final - combined))
        }
    }

    const payNow = () => {
        if (!total){
            return
        }
        const request = {
            ManualEnter: false,
            Total: total,
            TicketMeta: {
                CCFlat: ccFlat,
                CCPerc: ccPerc,
                TicketFlat: flatTickFee,
                PerTickFee: perTickFee,
                TimeZone: -5,
                ObservesDST: true,
                Tickets: []
            }
        }

        if (reg){
            request.TicketMeta.Tickets.push({
                Name: 'General Admission',
                Quantity: reg,
                AmountEach: regPrice
            })
        }
        if (stu){
            request.TicketMeta.Tickets.push({
                Name: 'Student And Senior Admission',
                Quantity: stu,
                AmountEach: stuPrice
            })
        }
        if (stuD){
            request.TicketMeta.Tickets.push({
                Name: 'Student With Donation Admission',
                Quantity: stuD,
                AmountEach: stuDonPrice
            })
        }

        console.log(request)
        setLoading(true)
        const payAPI = test ? testQuickPayAPI : quickPayAPI
        payAPI(request)
            .then(handleErrors)
            .then(data => {
                console.log(data)
                if (data.Message === 'SUCCESS'){
                    reset()
                    notify('Success', 'Payment Approved')
                    setOrderId(data.OrderId)
                }
                else{
                    notify('Error', data.Message)
                }
            })
            .catch(err => err)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setLoading(false)
            })
    }

    const reset = () => {
        setBase(0)
        setReg(0)
        setStu(0)
        setStuD(0)
        setTickFee(0)
        setCCFees(0)
        setTotal(0)
    }

    const sendReceipt = (e) => {
        e.preventDefault()

        const request = {
            CustomerEmail: email,
            From: null,
            FromName: 'Southview HS Theater'
        }
        setSending(true)
        sendReceiptAPI(request, orderId)
            .then(handleErrors)
            .then(data => {
                console.log(data)
                if (data === 'Success'){
                    setOrderId(0)
                    notify('Sent', 'Email Has Been Sent to ' + email)
                    setEmail('')
                }
            })
            .catch(err => err)
            .then(msg => {
                if (msg){
                    notify('Error', msg.toString())
                }
                setSending(false)
            })
    }

    const safeNum = (n, d = 12) => Number(n.toFixed(d))

    const checkoutStyle = {width: '30vw'}

    return (
        <div>
            <Modal show={Boolean(orderId)} onHide={() => {setOrderId(0); setEmail('')}} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Payment Success - Order No. {orderId}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={sendReceipt}>
                    <Modal.Body>
                        <h4 style={{marginBottom: '20px'}}>Send Receipt To:</h4>
                        <Form.Group as={Row}>
                            <Form.Label column xs={4}>Email Address</Form.Label>
                            <Col>
                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} required type="email" />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" type="submit" disabled={sending}>
                            {sending ? 'Sending...' : 'Send'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor: '#e4e5e8', overflowY: 'auto'}}>
                <Row>
                    <h1 style={{fontSize: '4rem'}}>Southview HS Theater</h1>
                </Row>
                <div style={{width: isMd ? '70vw' : '90vw'}}>
                    <TicketOption label="General Admission" pricePerTicket={regPrice} priceUpdater={(x) => calcTickets(x, undefined, undefined)} getter={reg} setter={setReg} />
                    <TicketOption label="Student And Senior Admission" pricePerTicket={stuPrice} priceUpdater={(x) => calcTickets(undefined, x, undefined)} getter={stu} setter={setStu} />
                    <TicketOption label="Student With Donation Admission" pricePerTicket={stuDonPrice} priceUpdater={(x) => calcTickets(undefined, undefined, x)} getter={stuD} setter={setStuD} />
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px'}}>
                        <div style={checkoutStyle}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4>Subtotal</h4>
                                <h4>${base.toFixed(2)}</h4>
                            </div>
                        </div>
                        <div style={checkoutStyle}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4>Ticket Fees</h4>
                                <h4>${tickFee.toFixed(2)}</h4>
                            </div>
                        </div>
                        <div style={checkoutStyle}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4>Credit Card Fees</h4>
                                <h4>${ccFees.toFixed(2)}</h4>
                            </div>
                        </div>
                        <hr style={checkoutStyle} />
                        <div style={checkoutStyle}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4>Total</h4>
                                <h4>${total.toFixed(2)}</h4>
                            </div>
                        </div>
                        <div style={{width: '30vw', display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            {loading
                                ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                                        <CircularProgress style={{ width: '100px', height: '100px' }} />
                                    </div>)
                                : (
                                    <Button size="lg" disabled={!total} onClick={payNow}>
                                        Pay Now
                                    </Button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{position: 'fixed', top: '8vh', right: '20px'}}>
                <Toasty toasts={toasts} setToasts={setToasts} />
            </div>
        </div>
    )
}


const TicketOption = ({label, pricePerTicket, priceUpdater, getter, setter}) => {

    const updateTickets = (adj) => {
        let current = getter
        current += adj
        if (current <= 0){
            current = 0
        }

        setter(current)
        priceUpdater && priceUpdater(current)
    }

    return (
        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', marginTop: '10px'}}>
            <Col md={6}>
                <h2 style={{textAlign: 'center'}}>{label}</h2>
            </Col>
            <Col md={2}>
                <h2>${pricePerTicket.toFixed(2)}</h2>
            </Col>
            <Col md={4}>
                <Row style={{alignItems: 'center'}}>
                    <AddCircleOutline onClick={() => updateTickets(1)} style={{fontSize: '2rem', cursor: 'pointer'}} />
                    <Form.Control disabled style={{width: '80px', textAlign: 'center', margin: '10px', fontWeight: 'bold'}} value={getter} />
                    <RemoveCircleOutline onClick={() => updateTickets(-1)} style={{fontSize: '2rem', cursor: 'pointer'}} />
                </Row>
            </Col>
        </Row>
    )
}