import React, { useEffect, useState, Fragment } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { LeftNav } from '../navigation/leftnav'
import { TopNav } from "../navigation/topnav";
import { connectToAccountWS } from '../../api/sockets/userSocket'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import userPages from '../../enums/userPages'
import { CreateAccount } from '../user/createAccount'
import { Toasty } from '../public/toast'
import { AccountAccess } from '../user/accountAccess'
import { ProductCategories } from '../account/productCategory/productCategories'
import { AccountCustomer } from '../customer/accountCustomer'
import { EmailHome } from '../email/emailHome'
import { UserProfile } from '../user/userProfile'

import { OrderHistory } from '../order/history'
import { Coupons } from '../account/coupons';
import { CouponDetail } from '../account/couponDetail'
import { AccountSettingsHome } from '../user/accoutSettingsHome'
import { ViewReports } from '../account/viewReports'
import { ViewDailySnapshotReport } from '../account/viewDailySnapshotReport'
import { Ingredients } from '../ingredients/ingredients'
import { ProductHome } from '../products/productHome'
import { HelpPage } from '../account/helpPage';
import helpType from '../../enums/helpType';

export const Home = (socket, setSocket, socketAuthed, setSocketAuthed) => {

    const isSmall = useMediaQuery('(max-width:525px)')
    const isShort = useMediaQuery('(max-height:500px)')
    const tokenInfo = getTokensSyncNoUpdate()

    const accountSelectedPageLocation = 'ACCOUNT_SELECTED_PAGE'

    const storedPage = sessionStorage.getItem(accountSelectedPageLocation)

    // TODO : Convert to Enum
    const availablePages = tokenInfo.HomeAccountId 
        ? [userPages.Products, userPages.Customers, userPages.OrderHistory, userPages.Settings, userPages.AuthorizedUsers, userPages.Reports, userPages.Emails, userPages.Ingredients]
        : [userPages.AccessAccount, userPages.CreateAccount, userPages.Settings]

    const [selectedPage, setSelectedPage] = useState(storedPage || availablePages[0])    
    const [toasts, setToasts] = useState([])

    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        const time = new Date()
        tsts.push({
            title: title,
            message: msg,
            key: time.toLocaleTimeString()
        })
        setToasts(tsts)
        return tsts
    }

    /* eslint-disable */
    useEffect(() => {
        if (socket === null){
            const socketProps = {
                setSocket, 
                notify,
                setSocketAuthed,
                ...tokenInfo
            }
            setSocket(connectToAccountWS(socketProps))
        }
    }, [socket, tokenInfo])
    /* eslint-enable */

    const getTopPadding = () => {
        if (isShort){
            return '10vh'
        }
        if (selectedPage === userPages.Emails){
            return '6vh'
        }
        return '8vh'
    }

    const setSelectedPageWithMemory = (newPage) => {
        sessionStorage.setItem(accountSelectedPageLocation, newPage)
        setSelectedPage(newPage)
    }
    
    return (
        <div style={{overflow: 'hidden'}}>
            <div>
                <TopNav setSelectedPage={setSelectedPageWithMemory} socket={socket} />
            </div>
            <div style={{display: 'flex'}}>
                 <div style={{width: '15vw', height: '100vh', backgroundColor: '#eeeeee'}}> 
                    <LeftNav HomeAccount={tokenInfo.HomeAccountId} props={{setSelectedPage: setSelectedPageWithMemory, selectedPage}}  />
                </div>
                <div style={{width: '100%', height: '100vh', backgroundColor: '#e4e5e8', paddingTop: getTopPadding(), paddingLeft: isSmall ? '4vw' : '0vw', overflowY: 'auto'}}>
                    {socketAuthed && socket && <Fragment>

                        {(selectedPage === userPages.Products  || selectedPage.includes(userPages.ProductDetail) || selectedPage.includes(userPages.Combos)) && <ProductHome socket={socket} notify={notify} selectedPage={selectedPage} setSelectedPage={setSelectedPageWithMemory} /> }
                        {selectedPage === userPages.ProductCategories && <ProductCategories socket={socket} notify={notify} />}
                        {(selectedPage === userPages.Customers || selectedPage.includes(userPages.CustomerDetail)) && <AccountCustomer socket={socket} notify={notify} setSelectedPage={setSelectedPageWithMemory} selectedPage={selectedPage} />}
                        {(selectedPage === userPages.OrderHistory || selectedPage.includes(userPages.OrderDetail)) && <OrderHistory socket={socket} notify={notify} setSelectedPage={setSelectedPageWithMemory} selectedPage={selectedPage} /> }
                        {selectedPage === userPages.Settings && <AccountSettingsHome socket={socket} notify={notify} />}
                        {selectedPage === userPages.AccessAccount && <AccountAccess socket={socket} notify={notify} />}
                        {selectedPage === userPages.Coupons && <Coupons socket={socket} notify={notify} props={{setSelectedPageWithMemory}} />}
                        {selectedPage.includes(userPages.CouponDetail) && <CouponDetail socket={socket} notify={notify} selectedPage={selectedPage} props={{setSelectedPageWithMemory}} />}
                        {selectedPage === userPages.Ingredients && <Ingredients socket={socket} notify={notify}  />}
                        {selectedPage === userPages.CreateAccount && <CreateAccount socket={socket} notify={notify} setSelectedPage={setSelectedPageWithMemory} />}
                        {selectedPage === userPages.Reports && <ViewReports socket={socket} notify={notify} />}
                        {selectedPage === userPages.DailySnapshot && <ViewDailySnapshotReport socket={socket} notify={notify} />}
                        {selectedPage === userPages.Emails && <EmailHome socket={socket} notify={notify} />}
                        {selectedPage === userPages.Profile && <UserProfile socket={socket} notify={notify} />}
                        {selectedPage === userPages.Help && <HelpPage socket={socket} notify={notify} helpType={helpType.Account} />}
                    </Fragment>}
                </div>
            </div>
            <div style={{position: 'fixed', top: '8vh', right: '20px'}}>
                <Toasty toasts={toasts} setToasts={setToasts} />
            </div>
        </div>
    )
}