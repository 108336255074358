
export const handleViewStoreCategories = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.notify('Error Loading Categories')
    }
    else{
        props.setCategories(response.StoreCategories)
    }
    props.setCategoryLoading(false)
}

export const handleViewStoreInformation = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.notify('Error Loading Store Information')
    }
    else{
        props.setStoreInfo(response)
    }
    props.setStoreInfoLoading(false)
}

export const handleViewStoreProducts = (response, props) => {
    if (response.IsError){
        props.onProductFailure && props.onProductFailure(response.ErrorMessage)
    }
    else{
        props.onProductSuccess && props.onProductSuccess(response)
    }
    props.setProductLoading(false)
}


export const handleViewStoreProductDetail = (response, props) => {
    if (response.IsError){
        props.onDetailFailure && props.onDetailFailure(response.ErrorMessage)
    }
    else{
        props.onDetailSuccess && props.onDetailSuccess(response)
    }
    props.setDetailLoading(false)
}