import React, { useState, Fragment, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Nav } from 'react-bootstrap'
import { useMediaQuery } from '@material-ui/core'
import {  RenamedIngredients } from './renamedIngredients'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { ScrollTabPanel } from '../utility/scrollTabPanel'


export const Ingredients = ({ socket, notify, setSelectedPage }) => {
    const isSmall = useMediaQuery('(max-width:525px)')

    const [tab, setTab] = useState(0)

    const getTabProps = (i) => {
        return {
            id: `scrollable-auto-tab-${i}`,
            'aria-controls': `scrollable-auto-tabpanel-${i}`
        }
    }

    return (
        <Fragment>
            <AppBar position="static" color="default"
                style={{
                    marginLeft: isSmall ? '-4vw' : '',
                    width: isSmall ? '87vw' : '',
                    background: 'linear-gradient(transparent, #f5f5f5)',
                    boxShadow: '0px 6px 6px 0px rgb(0 0 0 / 8%)'
                }}
            >
                <Tabs
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ outline: 'none' }}
                >
                    <Tab label="Rename Ingredients" {...getTabProps(0)} />
                </Tabs>
            </AppBar>
            <ScrollTabPanel value={tab} index={0}>
                <RenamedIngredients 
                    socket={socket} 
                    notify={notify} 
                />
            </ScrollTabPanel>
        </Fragment>
    )
}