import { setHomeAccountId } from '../../auth/session'

export const handleAuth = (response, props) => {
    // No Error Load Data
    console.log(response)
} 

export const handleCreateAccount = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.notify('Creation Error', response.ErrorMessage)
    }
    else{
        props.createAccountSuccess && props.createAccountSuccess(response) 
    }
    props.setLoading && props.setLoading(false)
}

export const handleViewAccountAccess = (response, props) => {
    console.log(response)
    if (response.IsError){
        props.notify('Error', 'Error Viewing Associations')
    }
    else{
        props.setInfo(response)
    }
    props.setLoading(false)
}

export const handleRequestAccountAccess = (response, props) => {
    console.log(response)
    if (response.IsError){        
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else if (!response.Success){
        props.onFailure && props.onFailure()
    }
    else{
        props.onSuccess && props.onSuccess()
    }
   props.setAccountAccessLoading &&  props.setAccountAccessLoading(false)
}

export const handleViewUser = (response, props) => {
    if (response.IsError){
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleUpdateUser = (response, props) => {
    if(response.IsError){
        props.onFailure && props.onFailure(response.ErrorMessage)
    }
    else {
        props.onSuccess && props.onSuccess(response)
    }
    props.setLoading(false)
}

export const handleUserPasswordChange = (response, props) => {
    if(response.IsError){
        props.onPasswordChangeFailure && props.onPasswordChangeFailure(response.ErrorMessage)
    }
    else {
        props.onPasswordChangeSuccess && props.onPasswordChangeSuccess(response)
    }
    props.setLoading(false)
}

export const handleUpdateUpline = (response, props) => {
    if(response.IsError){
        props.onUplineFailure && props.onUplineFailure(response.ErrorMessage)
    }
    else{
        props.onUplineSuccess && props.onUplineSuccess(response)
    }
    props.setLoading(false)
}

export const handleCancelRequestAccess = (response, props) => {
    if (response.IsError) {
        props.onCancelAccessFailure && props.onCancelAccessFailure(response.ErrorMessage)
    }
    else {
        props.onCancelAccessSuccess && props.onCancelAccessSuccess()
    }
    props.setCancelAccessLoading && props.setCancelAccessLoading(false)
}