import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, Accordion, useAccordionToggle, Card } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import { getAccountHelp } from '../../api/sockets/account/accountRequests'
import { getOperatorHelp } from '../../api/sockets/operator/operatorRequests'
import { default as helpTypeEnum } from '../../enums/helpType'
import { baseURL } from '../../api/base'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const HelpPage = ({socket, notify, helpType}) => {

    const isSmall = useMediaQuery('(max-width:650px)')
    const isLarge = useMediaQuery('(min-width:1200px)')
    const isXLarge = useMediaQuery('(min-width:1500px)')

    const [pages, setPages] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeVideo, setActiveVideo] = useState(null)

    useEffect(() => {
        
        if (socket && socket.socket.readyState === WebSocket.OPEN){
            socket.props.notify = notify
            socket.props.setLoading = setLoading
            socket.props.onHelpSuccess = (results) => {
                setPages(results.HelpItems)
            }
            socket.props.onHelpFailure = (msg) => {
                notify('Error', msg)
            }
            
            if (helpType === helpTypeEnum.Account){
                getAccountHelp({}, socket.socket)
            }
            else if (helpType === helpTypeEnum.Operator){
                getOperatorHelp({}, socket.socket)
            }
        }
    }, [socket])

    return <Fragment>
        <Row>
            <Col xs={0} md={1}></Col>
            <Col>
                <h2>
                    Help
                </h2>
            </Col>
        </Row>
        <hr />
        {loading
            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
            : (
                <Accordion defaultActiveKey={activeVideo}>
                    {pages && pages.map(x => {
                        return (
                            <Row style={{paddingLeft: isSmall ? '0' : '4rem', paddingRight: isSmall ? '1rem' : '4rem', marginBottom: '10px'}} key={x.VideoId}> 
                                <Card style={{width: '100%'}}>
                                    <CustomToggle eventKey={x.VideoId} setCurrentKey={setActiveVideo} currentKey={activeVideo}>
                                        <h3 style={{marginLeft: '10px'}}>
                                            {x.Title}
                                        </h3>
                                    </CustomToggle>
                                    <Accordion.Collapse eventKey={x.VideoId}>
                                        <Card.Body>
                                            {activeVideo === x.VideoId && (
                                                <div>
                                                    <video controlsList='nodownload' width={isLarge ? 600 : 400} controls={true}>
                                                        <source src={`${baseURL}/content/v1/playvideo/1/${x.VideoId}`} type="video/mp4" />
                                                    </video>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Row>
                        )
                    })}
                </Accordion>
            )
        }
    </Fragment>
}



const CustomToggle = ({ children, eventKey, setCurrentKey, currentKey }) => {
      
    const isCurrentEventKey = currentKey === eventKey

    const decoratedOnClick = useAccordionToggle( eventKey, () => {
          setCurrentKey(isCurrentEventKey ? '' : eventKey)
    })
  
  
    return <Card.Header onClick={decoratedOnClick} style={{cursor: 'pointer'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            {isCurrentEventKey ? <ExpandLess/> : <ExpandMore />}
            {children}
        </div>
    </Card.Header>
  }