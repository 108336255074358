import React, { useState, Fragment, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Nav } from 'react-bootstrap'
import { makeStyles } from '@material-ui/styles'
import GoodIcon from '@material-ui/icons/Done'
import BadIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { emailLoginAPI } from '../../api/auth/login'
import { signUpCustomerAPI, checkCustomerEmailExistsAPI } from '../../api/customer/customer'
import fbIcon from '../../images/fbicon.png'
import userTypes from '../../enums/userTypes'
import customerPages from '../../enums/customerPages'
import { authorizeCustomer } from '../../api/sockets/customer/customerRequests'
import { QRScannerModalNew } from './qrScannerModalNew'
import MaskedInput from 'react-text-mask'

const useStyles = makeStyles({
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column'
    },
    fullHeight: {
        height: '100vh'
    },
    big: {
        height: '40%',
        width: '80%'
    },
    outline: {
        border: '.2rem solid #ececec',
        borderRadius: '8px'
    },
})

export const CreateCustomer = ({ id, props }) => {
    const classes = useStyles()
    const storeId = id
    const { setSelectedPage, socket } = props
    const appCreated = false
    const [firstName, setfirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [signUpEmail, setSignUpEmail] = useState('')
    const [signUpPassword, setSignUpPassword] = useState('')
    const [rptPW, setRptPW] = useState('')
    const [message, setMessage] = useState('');
    const [firstNameGood, setfirstNameGood] = useState(null)
    const [lastNameGood, setLastNameGood] = useState(null)
    const [phoneGood, setPhoneGood] = useState(null)
    const [signUpEmailGood, setsignUpEmailGood] = useState(null)
    const [pwGood, setPWGood] = useState(null)
    const [rptGood, setRptGood] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showQRScanner, setShowQRScanner] = useState(false)

    const [signInEmail, setSignInEmail] = useState('')
    const [signInPassword, setSignInPassword] = useState('')
    const [signInMessage, setSignInMessage] = useState('')

    const handleFNCheck = () => {
        setfirstNameGood(firstName.length)
    }

    const handleLNCheck = () => {
        setLastNameGood(lastName.length)
    }

    const handlePhoneCheck = (phone) => {
        setPhoneGood(phone.length === 14)
    }

    useEffect(() => {

    }, [])

    const handleEmailCheck = async () => {
        setLoading(true)
        if (signUpEmail !== '') {
            let response;
            let msg;
            try {
                response = await checkCustomerEmailExistsAPI(signUpEmail)
            }
            catch (error) {
                let finalError = error
                try {
                    finalError = await error
                }
                catch (err) { }
                msg = finalError;
            }
            if (msg) {
                if (msg.message === 'Failed to fetch' || msg === '503') {
                    msg = 'Currently Under Maintenance. Please try again later.'
                }
                setMessage(msg.toString())
                setsignUpEmailGood(null)
                setLoading(false)
                return
            }
            setsignUpEmailGood(!response.Exists)
        }
        else {
            setsignUpEmailGood(null)
        }
        setLoading(false)
    }

    const handlePWCheck = (pw) => {
        const valid = (pw.length >= 8 && pw.match(/[A-Z]/) && pw.match(/[a-z]/) && pw.match(/\d/)) ?? false
        if (pw === '') {
            setPWGood(null)
        }
        else {
            setPWGood(valid)
        }
    }

    const handleRptPWCheck = (pw1, pw2) => {
        if (pw2 === undefined) {
            pw2 = signUpPassword
        }
        if (pw1 === undefined) {
            pw1 = rptPW
        }
        const valid = pw1 === pw2
        if (signUpPassword === '' || rptPW === '') {
            setRptGood(null)
        }
        else {
            setRptGood(valid)
        }
    }

    const handleSignUp = async (e) => {
        e.preventDefault()
        setMessage('')
        let msg
        if (!loading) {
            setLoading(true)
            try {
                const results = await signUpCustomerAPI(signUpEmail, signUpPassword, firstName, lastName, phoneNumber, storeId, appCreated)
                if (!results.isError) {
                    authorizeCustomer(results.AccessToken, socket.socket)
                    socket.AccessToken = results.AccessToken
                    setSelectedPage(customerPages.storeInfo)
                }
                else {
                    msg = 'An error occured while processing your sign up. Please try again later.'
                    setMessage(msg)
                }
            }
            catch (error) {
                let finalError = error
                try {
                    finalError = await error
                }
                catch (err) { }
                msg = finalError
            }
            if (msg) {
                msg = 'An error occured while processing your sign up. Please try again later.'
                setMessage(msg)
            }
            setLoading(false)
        }
    }

    const handleEmailSignIn = async (e) => {
        setSignInMessage('')
        e.preventDefault()
        setLoading(true)
        setMessage('')
        try {
            const results = await emailLoginAPI(signInEmail, signInPassword, userTypes.Customer, props.isKiosk, storeId)
            if (!results.isError) {
                authorizeCustomer(results.AccessToken, socket.socket)
                socket.AccessToken = results.AccessToken
                setSelectedPage(customerPages.cart)
            }
        }
        catch (error) {
            let finalError = error
            try {
                finalError = await error
            }
            catch (err) { }
            setSignInMessage(finalError)
        }
        setLoading(false)
    }

    const handleSignInWithFB = () => {
        let currentSite = `${window.location.origin}`
        const pathPart = window.location.pathname
        if (pathPart !== '/'){
            currentSite += pathPart
        }
        const facebookSSOLink = `https://networksmarketercustomers.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://www.networksmarketer.com/fblogin&response_type=CODE&client_id=6dnsftdp2tcunh52dfidlka6o6&scope=aws.cognito.signin.user.admin%20email%2%20openid%20phone%20profile&state=${currentSite}`
        window.location.href = facebookSSOLink
    }
    return (
        // TODO: Style this for full screen, split this into 2 components
        <Container fluid>
            {showQRScanner && <QRScannerModalNew show={showQRScanner} setShow={setShowQRScanner} />}
            <Row style={{ marginBottom: '40px' }}>
                <Col xs={0} md={1} lg={3}></Col>
                <Col xs={12} md={8} lg={6} xl={5}>
                    <div>
                        <h2 style={{ marginBottom: '20px' }}>Sign In</h2>
                    </div>
                    <Row>
                        <Col xs={0} md={1} lg={2}></Col>
                        <Col>
                            <Form onSubmit={handleEmailSignIn}>
                                <Form.Row>
                                    <Form.Label column>
                                        {signInMessage && <h5 style={{ color: 'red' }}>{signInMessage.toString()}</h5>}
                                        Email
                                    </Form.Label>
                                    <Form.Control value={signInEmail} onChange={(e) => setSignInEmail(e.target.value)} required type="email" />
                                </Form.Row>
                                <Form.Row style={{ paddingBottom: '1rem' }}>
                                    <Form.Label column>
                                        Password
                                    </Form.Label>
                                    <Form.Control value={signInPassword} onChange={(e) => setSignInPassword(e.target.value)} required type="password" />
                                </Form.Row>
                                <div className="d-flex flex-row-reverse">
                                    <a href="" onClick={(e) => {e.preventDefault(); setSelectedPage(customerPages.forgotPassword)}} style={{ fontSize: '.75rem', alignItems: 'center', textAlign: 'end' }}>Forgot Password?</a>
                                </div>
                                <Row style={{ justifyContent: 'center' }}>
                                    <Button type="submit" disabled={loading}>Sign In</Button>
                                </Row>
                            </Form>
                            <Row className="strike">
                                <span>OR</span>
                            </Row>
                            {props.isKiosk
                                ? <Row style={{ justifyContent: 'center' }}>
                                    <Button onClick={() => setShowQRScanner(true)}>
                                        <PhotoCamera style={{ marginRight: '10px' }} />
                                    Scan QR Code
                                </Button>
                                </Row>
                                : <Row style={{ justifyContent: 'center', marginBottom: '10px' }}>
                                    <Button onClick={handleSignInWithFB}>
                                        <img src={fbIcon} style={{ marginRight: '10px' }} alt="facebook logo" />
                                    Sign In With Facebook
                                </Button>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Row style={{ paddingBottom: '20px' }}>
                <Col xs={0} md={1} lg={3}></Col>
                <Col xs={12} md={8} lg={6} xl={5}>
                    <div>
                        <Fragment>
                            <h2 style={{ marginBottom: '20px' }}>Sign Up Today!</h2>
                           {message && <p style={{ color: 'red', textAlign: 'center' }}>{message.toString()}</p>}
                            <Row>
                                <Col xs={0} md={1} lg={2}></Col>
                                <Col>
                                    <Form onSubmit={handleSignUp}>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group controlid="firstName">
                                                    <Form.Label>First Name
                                                    {firstNameGood !== null && (firstNameGood
                                                            ? <GoodIcon style={{ color: 'green' }} />
                                                            : <Tooltip title="Please enter your first name"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control value={firstName} type="text" placeholder="First Name" onChange={(e) => { setfirstName(e.target.value) }} onBlur={handleFNCheck} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlid="lastName">
                                                    <Form.Label>Last Name
                                                    {lastNameGood !== null && (lastNameGood
                                                            ? <GoodIcon style={{ color: 'green' }} />
                                                            : <Tooltip title="Please enter your last name"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control value={lastName} type="text" placeholder="Last Name" onChange={(e) => { setLastName(e.target.value) }} onBlur={handleLNCheck} />
                                                </Form.Group>
                                            </Col>

                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Phone Number
                                                    {phoneGood !== null && (phoneGood
                                                            ? <GoodIcon style={{ color: 'green' }} />
                                                            : <Tooltip title="Please enter a valid phone number"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                        )}
                                                    </Form.Label>
                                                    <MaskedInput
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        className="form-control"
                                                        placeholder="Enter a phone number"
                                                        guide={false}
                                                        onBlur={(e) => { setPhoneNumber(e.target.value); handlePhoneCheck(e.target.value); }}
                                                        onChange={(e) => { setPhoneNumber(e.target.value); handlePhoneCheck(e.target.value); }}
                                                        required
                                                        type="tel"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group controlId="email">
                                                    <Form.Label>Email Address
                                                {signUpEmailGood !== null && (signUpEmailGood
                                                            ? <Tooltip title="Not Taken"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                            : <Tooltip title="This email address is already registered"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control value={signUpEmail} required={true} type="email" placeholder="Enter Email" onChange={(e) => { setSignUpEmail(e.target.value) }} onBlur={handleEmailCheck} />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Group controlId="password">
                                            <Form.Label>Password
                                                {pwGood !== null && (pwGood
                                                    ? <Tooltip title="Good"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                    : <Tooltip title="Must have 8 characters, an uppercase letter, lowercase letter, and a number"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                )}
                                            </Form.Label>
                                            <Form.Control value={signUpPassword} type="password" placeholder="Password" onChange={(e) => { setSignUpPassword(e.target.value); handlePWCheck(e.target.value); handleRptPWCheck(undefined, e.target.value) }} />
                                        </Form.Group>
                                        <Form.Group controlId="rptPassword">
                                            <Form.Label>Repeat Password
                                                {rptGood !== null && (rptGood
                                                    ? <Tooltip title="Good"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                                    : <Tooltip title="Passwords must match"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                                )}</Form.Label>
                                            <Form.Control value={rptPW} type="password" placeholder="Password" onChange={(e) => { setRptPW(e.target.value); handleRptPWCheck(e.target.value) }} />
                                        </Form.Group>
                                        <div className={`${classes.center}`}>By signing up you are agreeing to the following terms of service:</div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '10px' }}>
                                            <Nav.Link href="/eula">EULA</Nav.Link>
                                            <Nav.Link href="/privacypolicy">Privacy</Nav.Link>
                                        </div>
                                        <div className={`${classes.center}`} style={{ marginBottom: '1rem' }}>
                                            <Button variant="primary" type="submit" disabled={!signUpEmail || !signUpPassword || !signUpEmailGood || !firstName || !lastName || !phoneNumber || !pwGood || !rptPW || !rptGood || !firstNameGood || !lastNameGood || !phoneGood || loading}>
                                                Sign Up
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </Fragment>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}