import { baseWS } from '../base'
import requestType from '../../enums/requestType'
import userType from '../../enums/userTypes'
import socketType from '../../enums/socketType'

import { handleAuth, handleCreateAccount, handleViewAccountAccess,
     handleRequestAccountAccess, handleViewUser, handleUpdateUser, handleUserPasswordChange, handleUpdateUpline, handleCancelRequestAccess } from './user/userResponses'
     
import { handleInviteUser, handleListProductCategories, handleAddProductCategory,
        handleUpdateProductCategory, handleUpdateAccountUser, handleUpdateAccount,
        handleViewAccountDetail, handleAccountCustomerList, handleAccountCustomerDetail,
        handleViewCoupons, handleCreateCoupon, handleViewCouponDetails, handleUpdateCoupon, 
        handleRefundOrderItem, handleRefundOrder, handleViewAccountRewards, handleUpdateAccountRewards,
        handleOperatorOrderReceived, handleUpdateCustomerNote, handleOrderProductCategory, handleCreateIngredientRename,
        handleViewIngredientRenames, handleUpdateIngredientRename, handleDeleteIngredientRename, handleAccountHelp, handleDeleteProductCategory,
        handleViewAccountKeys, handleEditAccountKey, handleDeleteAccountKey, handleAddAccountKey
    } from './account/accountResponses'

import {handleOperatorCustomerDetail, handleOperatorCustomerList, 
        handleOperatorOrderDetail, handleOperatorOrderList, handleAcknowledgeOrder,
        handleOperatorEditOrder, handleOperatorProductList, handleCreateOrder, handleCustomerLoggedIn,
        handleOperatorCustomerLookUp, handleOperatorCreateCustomer, handleCashOut, handleSendToTerminal,
        handleOperatorCashRecords, handleUpdateOrderDiscount, handleOperatorRecipe,
        handleOperatorHelp
    } from './operator/operatorResponses'

import { handleAddProduct, handleProductList, handleUpdateProduct,
     handleProductDetail, handleListIngredients, handleUpdateProductTags, handleSearchProductTag,
     handleCloneProduct, handleProductClearImage, handleDailySnapshot, handleDeleteProduct } from './product/productResponses'

import { handleAddComboCategory, handleDeleteComboCategory, handleCreateCombo, handleUpdateCombo,
     handleUpdateComboItems, handleListCombos, handleViewStoreCombo, handleViewComboDetail, handleUpdateComboProductCategories,
     handleUpdateComboCategory } from './combos/comboResponses'

import { handleAccountOrderList, handleAccountOrderDetail } from './order/orderResponses'
import { handleGetDashboardURL, handleGetReportDashboards } from './reports/reportResponses'
import { handleAbortKey } from './cart/cartResponses'

const controller = `${baseWS}/store/v1`


export const connectToAccountWS = (props, ovrdeSocketType, ovrdeAccountId) => {
    const token = props.AccessToken
    if (!token){
        return false
    }

    let socket = new WebSocket(controller)

    const request = {
        RequestType: requestType.Auth,
        Data: {
            UserType: props.HomeAccountId ? userType.AccountOwner : userType.User,
            AccessToken: props.AccessToken,
            AccountId: ovrdeAccountId || props.HomeAccountId,
            SocketType: ovrdeSocketType || socketType.User,
        }
    }
    props.thisSocket = socket
    socket.onopen = (e) => {
        socket.send(JSON.stringify(request))
    }
    socket.onmessage = (e) => handleReceive(e, props)

    socket.onclose = (e) => {
        console.log('Socket Disconnected. Reconnecting now...', e.reason)
        props.setSocketAuthed(false)
        clearInterval(props.heartBeat)
        setTimeout(() => {            
            props.setSocket(connectToAccountWS(props))
        }, 1000)
    }

    return { socket, props }
}
// TODO: I think some of these need to be in the accountSocket.js file
const handleReceive = (event, props) => {
    try{
        const response = JSON.parse(event.data)
        switch (response.RequestType){
            case requestType.HeartBeat:
                return  
            case requestType.Auth:
                if (response.IsError){
                    props.notify('Error', 'Error Connecting!')
                }
                else{
                    props.SocketType = response.SocketType
                    props.UserType = response.UserType
                    
                    props.setSocketAuthed(true)
                    props.heartBeat = setInterval(() => {
                        beatHeart(props)
                    }, 10000)

                    handleAuth(response, props)
                }
                break
            case requestType.CreateAccount:
                handleCreateAccount(response, props)
                break
            case requestType.ViewAccountAccess:
                handleViewAccountAccess(response, props)
                break
            case requestType.RequestAccountAccess:
                handleRequestAccountAccess(response, props)
                break
            case requestType.InviteUserToAccount:
                handleInviteUser(response, props)
                break
            case requestType.ListProductCategories:
                handleListProductCategories(response, props)
                break
            case requestType.AddProductCategory:
                handleAddProductCategory(response, props)
                break
            case requestType.UpdateProductCategory:
                handleUpdateProductCategory(response, props)
                break
            case requestType.DeleteProductCategory: 
                handleDeleteProductCategory(response, props)
                break
            case requestType.UpdateAccountUser:
                handleUpdateAccountUser(response, props)
                break
            case requestType.AddProduct:
                handleAddProduct(response, props)
                break
            case requestType.ListProducts:
                handleProductList(response, props)
                break
            case requestType.UpdateProduct:
                handleUpdateProduct(response, props)
                break
            case requestType.ProductDetails:
                handleProductDetail(response, props)
                break
            case requestType.AccountDetails:
                handleViewAccountDetail(response, props)
                break
            case requestType.UpdateAccount:
                handleUpdateAccount(response, props)
                break
            case requestType.AccountOrderList:
                handleAccountOrderList(response, props)
                break
            case requestType.AccountOrderDetail:
                handleAccountOrderDetail(response, props)
                break
            case requestType.ViewUser:
                handleViewUser(response, props)
                break
            case requestType.UpdateUser:
                handleUpdateUser(response, props)
                break
            case requestType.AccountCustomerList:
                handleAccountCustomerList(response, props)
                break
            case requestType.AccountCustomerDetail:
                handleAccountCustomerDetail(response, props)
                break
            case requestType.OperatorCustomerList:
                handleOperatorCustomerList(response, props)
                break
            case requestType.OperatorCustomerDetail:
                handleOperatorCustomerDetail(response, props)
                break
            case requestType.ViewCoupons:
                handleViewCoupons(response, props)
                break
            case requestType.CreateCoupon:
                handleCreateCoupon(response, props)
                break
            case requestType.ViewCouponDetails:
                handleViewCouponDetails(response, props)
                break
            case requestType.UpdateCoupon:
                handleUpdateCoupon(response, props)
                break
            case requestType.ListIngredients:
                handleListIngredients(response, props)
                break
            case requestType.RefundOrder:
                handleRefundOrder(response, props)
                break
            case requestType.RefundOrderItem:
                handleRefundOrderItem(response, props)
                break
            case requestType.OperatorOrderList:
                handleOperatorOrderList(response, props)
                break
            case requestType.OperatorOrderDetail:
                handleOperatorOrderDetail(response, props)
                break
            case requestType.ViewAccountRewards:
                handleViewAccountRewards(response, props)
                break
            case requestType.UpdateAccountRewards:
                handleUpdateAccountRewards(response, props)
                break
            case requestType.OperatorOrderReceived:
                handleOperatorOrderReceived(response, props)
                break
            case requestType.AcknowledgeOrder:
                handleAcknowledgeOrder(response, props)
                break
            case requestType.OperatorEditOrder:
                handleOperatorEditOrder(response, props)
                break
            case requestType.OperatorProductList:
                handleOperatorProductList(response, props)
                break
            case requestType.ProductTagSearch:
                handleSearchProductTag(response, props)
                break
            case requestType.UpdateProductTags:
                handleUpdateProductTags(response, props)
                break
            case requestType.OperatorCreateOrder:
                handleCreateOrder(response, props)
                break
            case requestType.UpdatePassword:
                handleUserPasswordChange(response, props)
                break
            case requestType.GetReportDashboards:
                handleGetReportDashboards(response, props)
                break
            case requestType.GetDashboardURL:
                handleGetDashboardURL(response, props)
                break
            case requestType.UpdateUpline:
                handleUpdateUpline(response, props)
                break
            case requestType.CloneProduct:
                handleCloneProduct(response, props)
                break
            case requestType.OperatorCustomerLoggedIn:
                handleCustomerLoggedIn(response, props)
                break
            case requestType.OperatorCustomerLookup:
                handleOperatorCustomerLookUp(response, props)
                break
            case requestType.OperatorCreateCustomer:
                handleOperatorCreateCustomer(response, props)
                break
            case requestType.OperatorCashOut:
                handleCashOut(response, props)
                break
            case requestType.UpdateCustomerNote:
                handleUpdateCustomerNote(response, props)
                break
            case requestType.OperatorCashOut:
                handleCashOut(response, props)
                break
            case requestType.TerminalAbortKey:
                handleAbortKey(response, props)
                break
            case requestType.OperatorSendToTerminal:
                handleSendToTerminal(response, props)
                break
            case requestType.ProductClearImage:
                handleProductClearImage(response, props)
                break
            case requestType.OperatorProductRecipe:
                handleOperatorRecipe(response, props)
                break
            case requestType.OperatorCashRecords:
                handleOperatorCashRecords(response, props)
                break
            case requestType.OperatorAddDiscount:
                handleUpdateOrderDiscount(response, props)
                break
            case requestType.OrderProductCategory:
                handleOrderProductCategory(response, props)
                break
            case requestType.CreateIngredientRename:
                handleCreateIngredientRename(response, props)
                break
            case requestType.ViewIngredientRenames:
                handleViewIngredientRenames(response, props)
                break
            case requestType.UpdateIngredientRename:
                handleUpdateIngredientRename(response, props)
                break
            case requestType.DeleteIngredientRename:
                handleDeleteIngredientRename(response, props)
                break
            case requestType.AddComboCategory:
                handleAddComboCategory(response, props)
                break
            case requestType.DeleteComboCategory:
                handleDeleteComboCategory(response, props)
                break
            case requestType.CreateCombo:
                handleCreateCombo(response, props)
                break
            case requestType.UpdateCombo:
                handleUpdateCombo(response, props)
                break
            case requestType.UpdateComboItems:
                handleUpdateComboItems(response, props)
                break
            case requestType.ListCombos:
                handleListCombos(response, props)
                break
            case requestType.ViewStoreCombo:
                handleViewStoreCombo(response, props)
                break
            case requestType.ViewComboDetail:
                handleViewComboDetail(response, props)
                break
            case requestType.DailySnapshot:
                handleDailySnapshot(response, props)
                break
            case requestType.UpdateComboProductCategories:
                handleUpdateComboProductCategories(response, props)
                break
            case requestType.UpdateComboCategory:
                handleUpdateComboCategory(response, props)
                break
            case requestType.AccountHelp:
                handleAccountHelp(response, props)
                break
            case requestType.OperatorHelp:
                handleOperatorHelp(response, props)
                break
            case requestType.CancelAccessRequest:
                handleCancelRequestAccess(response, props)
                break
            case requestType.DeleteProduct:
                handleDeleteProduct(response, props)
                break
            case requestType.ViewAccountKeys:
                handleViewAccountKeys(response, props)
                break
            case requestType.CreateAccountKey:
                handleAddAccountKey(response, props)
                break
            case requestType.EditAccountKey:
                handleEditAccountKey(response, props)
                break
            case requestType.DeleteAccountKey:
                handleDeleteAccountKey(response, props)
                break
            default:
                break
        }
    }
    catch (err){
        console.log(err.toString())
    }
}

const beatHeart = (props) => {
    if (!props.thisSocket || props.thisSocket.readyState !== WebSocket.OPEN){
        clearInterval(props.heartBeat)
    }
    else{
        props.thisSocket.send(JSON.stringify({
            RequestType: requestType.HeartBeat,
            Data: {}
        }))
    }
}