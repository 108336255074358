import React, { Fragment, useState } from "react"
import { Form, Button, Col, Row } from 'react-bootstrap'
import { makeStyles, useMediaQuery, ListItemText, Input, FormControl, Select, MenuItem, Checkbox, InputLabel } from '@material-ui/core'

const useStyles = makeStyles({
    formControl: {
        width: 260,
        color: '#495057',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        alignItems: 'left',
    },
    nounderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 15;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 250,
        }
    },
    // Don't realign on focus change
    getContentAnchorEl: null
}

// Exhaustive. . .
export const OperatorComboItem = ({ comboItem, setComboItem, openCategories, setOpenCategories, back, setSelectedComboItems,
                                        selectedComboItems, setCart, combo, cartItemId, setCartItemId, notify, calculateTotal, cart }) => {
    const isSmall = useMediaQuery('(max-width:650px)')
    const classes = useStyles()
    const isLast = Boolean(openCategories && openCategories.length === 1)  // Tried passing isLast, but wouldn't update


    // Used for multiselect ingredient dropdown
    const ingredientRender = (selected, product) => {
        if (product.SelectedIngredients.length) {
            return product.Ingredients.filter(x => selected.includes(x.IngredientId)).map(x => x.Name).join(',')
        }
    }

    const addonRender = (selected, product) => {
        if (product.SelectedAddons.length) {
            return product.Addons.filter(x => selected.includes(x.IngredientId)).map(x => x.Name).join(',')
        }
    }

    const updateProp = (field, val) => {
        setComboItem(prevState => {
            return {
                ...prevState,
                [field]: val
            }
        })
    }
    // WithoutIngredientIds and Size, Price 
    const getComboItemClone = () => {
        const clone = Object.assign({}, comboItem)
        const ingredientIds =  comboItem.Ingredients.map(x => x.IngredientId) || []
        clone.WithoutIngredients = ingredientIds.filter(x => !comboItem.SelectedIngredients.includes(x)) || []
        clone.Quantity = 1
        let sizeDetails = comboItem.Sizes.filter(x => x.ProductId === clone.SelectedSizeId)[0]
        let price = sizeDetails.Price
        const addOns = clone.Addons.filter(x => clone.SelectedAddons.includes(x.IngredientId))
        addOns && addOns.forEach(x => {
            price += x.Price
        })
        // TODO: Add Tax Here
        clone.Size = sizeDetails.SizeDetails
        clone.Price = price
        clone.TaxPercent = sizeDetails.TaxPercent
        clone.BasePrice = sizeDetails.Price
        return clone
    }

    const handleNextAdd = () => {
        if (isLast) {
            const clone = getComboItemClone()
            const products = [...selectedComboItems, clone]
            const itemId = cartItemId + 1
            setCartItemId(itemId)
            const newCombo = {
                ComboId: combo.ComboId,
                Discount: combo?.Discount ?? 0,
                Products: products,
                CartItemId: itemId, 
                Name: combo.Name,
                Quantity: 1
            }
            const cartClone = Object.assign({}, cart)
            cartClone.Combos.push(newCombo)
            setCart(cartClone)
            calculateTotal(null, cartClone.Combos)
            setSelectedComboItems(prevState => [...prevState, clone])
            notify('Success', `${combo.Name} added to the order`)
            back()
        }
        else {
            const clone = getComboItemClone()
            setSelectedComboItems(prevState => [...prevState, clone])
            setOpenCategories([...openCategories].splice(1, openCategories.length - 1))
            setComboItem(null)
        }
    }

    return (
        <Fragment>
            {/* TODO: No Products message. */}
            {comboItem && openCategories &&
                <Fragment>
                    <Row style={{ marginBottom: '1.5rem' }}>
                        <Col md={{ offset: '2', span: '4' }}>
                            <h5>{comboItem.Name}</h5>
                        </Col>
                    </Row>
                    <Form.Group as={Row} style={{ paddingBottom: '1.5rem' }}>
                        <Form.Label column md={4} style={{ textAlign: isSmall ? 'left' : 'right', marginLeft: isSmall ? '3rem' : 0 }}>Size Details</Form.Label>
                        <Col md={3}>
                            <Form.Control as="select" style={{ margin: isSmall ? 'auto' : 'inherit' }} className={classes.formControl} value={comboItem.SelectedSizeId} onChange={(e) => updateProp('SelectedSizeId', e.target.value)}>
                                {comboItem.Sizes && comboItem.Sizes.map(s => {
                                    let addOnPrice = 0
                                    comboItem.Addons.filter(x => comboItem.SelectedAddons.includes(x.IngredientId)).forEach(x => {
                                        addOnPrice += x.Price
                                    })
                                    const total = addOnPrice + s.Price
                                    return <option key={s.ProductId} value={s.ProductId}>{`${s.SizeDetails} - $${total.toFixed(2)}`}</option>
                                })}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} style={{ paddingBottom: '1.5rem' }}>
                        <Form.Label column md={4} style={{ textAlign: isSmall ? 'left' : 'right', marginLeft: isSmall ? '3rem' : 0 }}>Ingredients</Form.Label>
                        {/* May want to move this to a form.Control for consistency */}
                        <FormControl className={classes.formControl} style={{ marginLeft: isSmall ? 'auto' : '1rem', marginRight: isSmall ? 'auto' : 0 }} >
                            {<InputLabel> {Boolean(comboItem.Ingredients.length) ? `Select Ingredients` : `No Ingredients Available`}</InputLabel>}
                            <Select
                                multiple
                                className={classes.nounderline}
                                value={comboItem.SelectedIngredients}
                                displayEmpty={true}
                                onChange={(e) => updateProp('SelectedIngredients', e.target.value)}
                                input={<Input />}
                                renderValue={(selected) => ingredientRender(selected, comboItem)}
                                MenuProps={MenuProps}
                                style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}
                            >
                                {comboItem.Ingredients.map((x) => (
                                    <MenuItem key={x.IngredientId} value={x.IngredientId}>
                                        <Checkbox checked={comboItem.SelectedIngredients.some(i => i === x.IngredientId)} />
                                        <ListItemText primary={x.Name}
                                            style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column md={4} style={{ textAlign: isSmall ? 'left' : 'right', marginLeft: isSmall ? '3rem' : 0 }}>Addons</Form.Label>
                        <FormControl className={classes.formControl} style={{ marginLeft: isSmall ? 'auto' : '1rem', marginRight: isSmall ? 'auto' : 0 }}>
                            {<InputLabel style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}> {Boolean(comboItem.Addons.length) ? `Select Addons` : `No Addons Available`}</InputLabel>}
                            <Select
                                multiple
                                className={classes.nounderline}
                                value={comboItem.SelectedAddons}
                                onChange={(e) => updateProp('SelectedAddons', e.target.value)}
                                input={<Input />}
                                renderValue={(selected) => addonRender(selected, comboItem)}
                                MenuProps={MenuProps}
                                style={{ fontSize: isSmall ? '.75rem' : 'inherit' }}
                            >
                                {comboItem.Addons.map((x) => (
                                    <MenuItem key={x.IngredientId} value={x.IngredientId}>
                                        <Checkbox checked={comboItem.SelectedAddons.some(i => i === x.IngredientId)} />
                                        <ListItemText primary={x.Name} secondary={`+ $${x.Price.toFixed(2)}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Row>
                        <Col md={6} style={{ textAlign: 'end' }}>
                            <Button onClick={() => handleNextAdd()}>{isLast ? 'Add To Cart' : 'Next'}</Button>
                        </Col>
                    </Row>
                </Fragment>
            }
        </Fragment>
    )
}