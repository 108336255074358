import { CircularProgress } from '@material-ui/core'
import React, { useState, Fragment, useEffect } from 'react'
import { Modal, Button, Row } from 'react-bootstrap'
import { terminalCancel } from '../../../api/sockets/cart/cartRequests'
import { sendToTerminal } from '../../../api/sockets/operator/operatorRequests'
import operatorPages from '../../../enums/operatorPages'
import paymentResponse from '../../../enums/paymentResponse'

export const OperatorUseTerminal = ({socket, notify, show, setShow, setSelectedPage, manualEnter}) => {

    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

    const [loading, setLoading] = useState(true)
    const [abortKey, setAbortKey] = useState(null)
    const [error, setError] = useState(null)
    const [inProcess, setInProcess] = useState(false)
    const [success, setSuccess] = useState(null)
    
    const hideAndReset = () => {
        setLoading(false)
        setAbortKey(null)
        setInProcess(false)
        if (success){
            const orderDetail = show && `${operatorPages.orderDetail}${show.OrderId}|${show.CustomerId}`
            setSelectedPage(orderDetail)
        }
        setShow(false)
        setSuccess(null)
        setError(null)
    }

    useEffect(() => {
        if (socket){
            socket.props.setAbortKey = setAbortKey
        }
        if (show && socket && !inProcess){
            startTerminal(manualEnter)
        }
    }, [])

    const startTerminal = (manual) => {
        if (inProcess || !show){
            return
        }
        setLoading(true)
        setInProcess(true)
        setError(null)
        socket.props.setCardLoading = setLoading
        socket.props.onCardSuccess = (data) => {
            if (data.Status === paymentResponse.Approved){
                setSuccess(data)
                setError(null)
            }
            else if (data.Status === paymentResponse.Retry){
                setError('Please Retry Payment')
            }
            else if (data.Status === paymentResponse.Declined){
                setError('Payment Declined')
            }
            setInProcess(false)
            setAbortKey(null)
        }
        socket.props.onCardFailure = (msg) => {
            setError(msg)
            setInProcess(false)
            setAbortKey(null)
        }
        const requestData = {
            OrderId: show.OrderId,
            CustomerId: show.CustomerId,
            ManualEnter: manual || false
        }
        sendToTerminal(requestData, socket.socket)
    }

    const abortPayment = () => {
        if (!abortKey){
            return
        }

        const requestData = {
            AbortKey: abortKey
        }

        socket.props.onCancelError = (msg) => {
            notify('Error', msg)
        }
        socket.props.onCancelSuccess = () => {
            // setAbortKey(null)
            // Could use for tip cancel then card cancel
        }

        terminalCancel(requestData, socket.socket)
    }

    return <Modal show={Boolean(show)} onHide={hideAndReset} backdrop="static" size="lg">
        <Modal.Header closeButton={error || success}>
            <Modal.Title>
                Pay With Card
                {show && ` - Order No. ${show.OrderNo}`}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row style={{justifyContent: 'center', marginBottom: '50px'}}>
                <h3>Amount Due: {moneyFormat(show.Amount)}</h3>
            </Row>
            {loading
                ? <Fragment>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', marginBottom: '30px'}}>
                        <CircularProgress style={{width: '100px', height: '100px'}} />
                    </div>
                    {abortKey && (
                        <Row style={{justifyContent: 'center'}}>
                            <Button variant="danger" onClick={abortPayment}>Cancel</Button>
                        </Row>
                    )}
                </Fragment>
                : <Fragment>
                    {error && (
                        <Fragment>
                            <Row style={{justifyContent: 'center', marginBottom: '30px'}}>
                                <h5>{error}</h5>
                            </Row>
                            <Row style={{justifyContent: 'center'}}>
                                <Button onClick={() => startTerminal()}>
                                    Retry
                                </Button>
                            </Row>
                        </Fragment>
                    )}
                    {success && (
                        <Row style={{justifyContent: 'center'}}>
                            <h4>Payment Approved!</h4>
                        </Row>
                    )}
                </Fragment>
            }

        </Modal.Body>
        <Modal.Footer></Modal.Footer>
    </Modal>
}