import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { TopCustomerNav } from '../navigation/topCustomerNav'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getCustomerTokens } from '../../api/auth/session'
import { connectToCustomerWS } from '../../api/sockets/customerSocket'
import { accountCodeCheckAPI } from '../../api/auth/auth'
import { handleErrors } from '../../api/base'
import { LeftCustomerNav } from '../navigation/leftCustomerNav'
import { loadCategories, loadStoreInformation } from '../../api/sockets/store/storeRequests'
import customerPages from '../../enums/customerPages'
import { CreateCustomer } from './createCustomer'
import { StoreProductList } from '../products/storeProductList'
import { StoreProductDetail } from '../products/storeProductDetail'
import { Toasty } from './toast'
import qs from 'query-string'
import { ssoLoginAPI } from '../../api/auth/login'
import { authorizeCustomer } from '../../api/sockets/customer/customerRequests'
import { CustomerCart } from '../customer/customerCart'
import { SelectTip } from '../customer/selectTip'
import { AuthorizePayment } from '../customer/authorizePayment'
import { ViewCustomer } from '../customer/viewCustomer/viewCustomer'
import { ForgotPassword } from '../customer/forgotPassword'
import { Helmet } from 'react-helmet'
import { Fragment } from 'react'
import { StoreLanding } from './storeLanding'
import { AddToCartSplash } from '../customer/cartSplash/addToCartSplash'
import { StoreComboDetail } from '../combos/store/storeComboDetail'

/* eslint-disable react-hooks/exhaustive-deps */
export const ViewStore = ({socket, setSocket, isStoreView, isStoreSite, props}) => {

    const currentPage = sessionStorage.getItem('currentPage')
    let { storeCode } = useParams()
    const isSmall = useMediaQuery('(max-width:525px)')
    let history = useHistory()
    const tokenInfo = getCustomerTokens()
    const [toasts, setToasts] = useState([])
    const [selectedPage, setSelectedPage] = useState(currentPage || customerPages.storeInfo)
    const showLeftNav = useMediaQuery('(min-width:900px)')
    const notShowLeftNav = useMediaQuery('(max-width:900px)')
    const [lastCategory, setLastCategory] = useState('')
    const [itemsInCart, setItemsInCart] = useState(0)
    // const [login, setLoggedIn] = useState(false)
    const [tipOptions, setTipOptions] = useState([])
    const [paymentInfoOnFile, setPaymentInfoOnFile] = useState(null)
    const [isNavbarExpanded, setIsNavBarExpanded] = useState(false)
    
    let thisStoreInfo = null
    if (storeCode){
        const storedCode = localStorage.getItem(storeCode)
        if (storedCode){
            thisStoreInfo = JSON.parse(storedCode)
        }
    }
    else if (isStoreSite){
        const storedCode = localStorage.getItem('STORE_ROOT')
        if (storedCode){
            thisStoreInfo = JSON.parse(storedCode)
        }
    }

    const [storeId, setStoreId] = useState(thisStoreInfo && thisStoreInfo.StoreAccountId)

    
    const notify = (title, msg) => {
        let tsts = toasts.map(x => x)
        const time = new Date()
        tsts.push({
            title: title,
            message: msg,
            key: time.toLocaleTimeString()
        })
        setToasts(tsts)
        return tsts
    }

    useEffect(() => {
        // async function has to go inside useEffect
        const validateStoreCode = async () => {
            let whichStore = thisStoreInfo
            let errorMsg = null
            try{            
                if (!whichStore){
                    console.log(JSON.stringify({time: new Date(), msg: 'Making AJAX request'}))
                    if (isStoreSite){
                        const response = await accountCodeCheckAPI(window.location.hostname, null)
                        whichStore = await handleErrors(response)
    
                        if (storeCode && whichStore.AccountCode && storeCode !== whichStore.AccountCode){
                            history.push('/')
                        }
                        else if (whichStore.StoreAccountId){
                            setStoreId(whichStore.StoreAccountId)
                            if (storeCode){
                                localStorage.setItem(whichStore.AccountCode, JSON.stringify(whichStore))
                            }
                            else{
                                localStorage.setItem('STORE_ROOT', JSON.stringify(whichStore))
                            }
                        }
                        else{
                            throw Error('Error Loading Store')
                        }
                    }
                    else{
                        // Requesting from Network sMarketer so any account
                        const response = await accountCodeCheckAPI(null, storeCode)
                        whichStore = await handleErrors(response)
                        
                        if (whichStore.StoreAccountId){
                            setStoreId(whichStore.StoreAccountId)
                            localStorage.setItem(whichStore.AccountCode, JSON.stringify(whichStore))
                        }
                        else{
                            throw Error('Error Loading Store')
                        }
                    }
                }
            }
            catch (error){
                let finalError = error
                try{
                    finalError = await error
                }
                catch(err){}
                errorMsg = finalError
                console.log(errorMsg)
                notify('Error', errorMsg.toString())
                return
            }
    
            // Now connect to the server
            if (socket === null){
                const socketProps = {
                    setSocket,
                    notify,
                    storeId: (whichStore && whichStore.StoreAccountId) || 0,
                    selectedCategory: null,
                    setCategories: props.setCategories,
                    setCategoryLoading: props.setCategoryLoading,
                    setStoreInfo: props.setStoreInfo,
                    setStoreInfoLoading: props.setStoreInfoLoading,
                    setSocketReconnecting: props.setSocketReconnecting,
                    setDoneLoading: props.setDoneLoading,
                    ...tokenInfo
                }
                const onConnectCallback = async (sock, sockProps) => {
                    if (socketProps.storeId){
                        loadCategories({
                            StoreAccountId: socketProps.storeId
                        }, sock)                    
                        loadStoreInformation({
                            StoreAccountId: socketProps.storeId
                        }, sock)


                        const qString = qs.parse(window.location.search)
                        const oAuthCode = qString.code
                        if (oAuthCode && oAuthCode !== 'undefined'){
                            sessionStorage.setItem('currentPage', customerPages.cart)
                            // Get Tokens
                            await ssoLoginAPI(oAuthCode, socketProps.storeId, (loginResult) => {
                                authorizeCustomer(loginResult.AccessToken, sock)
                                sockProps.AccessToken = loginResult.AccessToken
                                // Just to trigger component re-renders
                                setStoreId(whichStore.StoreAccountId)
                                if (window.location.search){
                                    history.push(window.location.pathname)
                                }
                            })
                        }
                        else if (oAuthCode === 'undefined') { // FB Bad Login
                            sessionStorage.setItem('currentPage', customerPages.createCustomer)
                            // FB redirect on unsucess puts host//a//StoreCode?Code=undefined in URL
                            history.push(`/a/${storeCode}`)
                        }
                    }
                }
                console.log(JSON.stringify({time: new Date(), msg: 'WebServer Connect'}))
                setSocket(connectToCustomerWS(socketProps, onConnectCallback))
            }
        }
        // End Async Function

        if (socket === null){
            validateStoreCode()
        }
        if (storeId){
            let cart = localStorage.getItem(`${storeId}_CART`)
            if (cart){
                cart = JSON.parse(cart)
                setItemsInCart(cart.Items.length)
            }
        }
        
    }, [storeId, socket])

    

    const recordPage = (page) => {
        sessionStorage.setItem('currentPage', page)
        setSelectedPage(page)
    }

    return (
        <div style={{overflow: 'hidden'}}>
            {/* TODO : UNSAFE_component unmount - migrate to react-helmet-async */}
            {props.storeInfo && props.storeInfo.Name && <Helmet>
                    <title>{props.storeInfo.Name}</title>
                </Helmet>
            }
            <div>
                <TopCustomerNav 
                    id={storeId} 
                    props={{
                        selectedPage, 
                        setSelectedPage: recordPage, 
                        categories: props.categories,
                        showLeftNav, notShowLeftNav,
                        storeInfo: props.storeInfo,
                        setLastCategory, lastCategory,
                        itemsInCart, isKiosk: props.isKiosk,
                        setItemsInCart,
                        isNavBarExpanded: isNavbarExpanded,
                        setIsNavBarExpanded: setIsNavBarExpanded
                    }} 
                />
            </div>
            <div style={{display: 'flex'}}>
                {showLeftNav && 
                    <div style={{width: '15vw', height: '100vh', backgroundColor: '#eeeeee'}}> 
                        <LeftCustomerNav
                            props={{selectedPage, setSelectedPage: recordPage, socket, setLastCategory, lastCategory}} 
                            items={props.categories}
                            loading={props.categoryLoading}
                        />
                    </div>
                }
                <div 
                    style={{
                        width: '100%',
                        height: '100vh',
                        backgroundColor: '#e4e5e8',
                        paddingTop: '8vh',
                        paddingLeft: isSmall ? '4vw' : '0vw',
                        overflowY: 'auto', 
                        paddingBottom: isSmall ? '8vh' : '4vh'
                    }}
                >
                    {props.storeInfo && props.categories && <Fragment>
                        {/* Only load the center pane when we are done with everything else */}
                        {selectedPage === customerPages.storeInfo &&
                            <StoreLanding
                                socket={socket}
                                notify={notify}
                                info={props.storeInfo}
                            />
                        }
                        {selectedPage === customerPages.createCustomer && 
                            <CreateCustomer 
                                id={socket && socket.props.storeId} 
                                props={{setSelectedPage: recordPage, isStoreView, socket, isKiosk: props.isKiosk}} 
                            />
                        }
                        {selectedPage === customerPages.viewCustomer && 
                            <ViewCustomer
                                props={{
                                    socket, notify, setSelectedPage,
                                    storeId, storeInfo: props.storeInfo
                                }}
                            />
                        }
                        {selectedPage === customerPages.forgotPassword &&
                            <ForgotPassword
                                setSelectedPage={setSelectedPage}
                            />
                            
                        }
                        {selectedPage.includes('PRODUCT_CATEGORY_ID_') &&
                            <StoreProductList
                                socket={socket}
                                notify={notify}
                                categoryPage={selectedPage}
                                storeId={storeId}
                                setSelectedPage={recordPage}
                            />
                        }   
                        {selectedPage.includes('COMBO_DETAIL_ID_') && 
                            <StoreComboDetail 
                                socket={socket} 
                                comboId={Number(selectedPage.replace('COMBO_DETAIL_ID_', ''))} 
                                storeId={storeId} 
                                notify={notify} 
                                setSelectedPage={recordPage} 
                                lastCategory={lastCategory} 
                                setItemsInCart={setItemsInCart}
                            />
                        }
                        {selectedPage.includes('PRODUCT_DETAIL_ID_') && 
                            <StoreProductDetail 
                                socket={socket} 
                                productId={Number(selectedPage.replace('PRODUCT_DETAIL_ID_', ''))} 
                                storeId={storeId} 
                                notify={notify} 
                                setSelectedPage={recordPage} 
                                lastCategory={lastCategory} 
                                setItemsInCart={setItemsInCart}
                            />
                        }
                        {selectedPage === customerPages.cart && 
                            <CustomerCart
                                socket={socket}
                                notify={notify}
                                storeId={storeId}
                                setSelectedPage={setSelectedPage}
                                setTipOptions={setTipOptions}
                                doneLoading={props.doneLoading}
                                setItemsInCart={setItemsInCart}
                            />
                        }
                        {selectedPage === customerPages.tipPage &&
                            <SelectTip
                                socket={socket}
                                notify={notify}
                                setSelectedPage={recordPage}
                                tipOptions={tipOptions}
                                storeId={storeId}
                                setPaymentInfoOnFile={setPaymentInfoOnFile}
                            />
                        }
                        {selectedPage === customerPages.authorizePayment &&
                            <AuthorizePayment
                                socket={socket}
                                notify={notify}
                                storeId={storeId}
                                setSelectedPage={recordPage}
                                onFile={paymentInfoOnFile}
                                isKiosk={props.isKiosk}
                                setItemsInCart={setItemsInCart}
                                storeInfo={props.storeInfo}
                            />
                        }
                        {selectedPage === customerPages.addToCartSplash && 
                            <AddToCartSplash
                                socket={socket}
                                notify={notify}
                                lastCategory={lastCategory}
                                storeId={storeId}
                                setSelectedPage={recordPage}
                                setIsNavBarExpanded={setIsNavBarExpanded}
                            />
                        }
                    </Fragment>
                    }
                </div>
            </div>            
            <div style={{position: 'fixed', top: '8vh', right: '20px'}}>
                <Toasty toasts={toasts} setToasts={setToasts} />
            </div>
            {props.socketReconnecting && (
                <div style={{zIndex: '999', position: 'fixed', top: '0px', left: '0px', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.8)'}}>
                    <div style={{backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', border: '3px solid gray', borderRadius: '20px'}}>
                        <h3>RECONNECTING...</h3>
                    </div>
                </div>
            )}
        </div>
    )
}