import React, { useState, Fragment, useRef, useEffect } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import { updateRewards, viewRewards } from '../../../api/sockets/account/accountRequests'

export const AccountRewards = ({socket, notify}) => {
    
    const isSmall = useMediaQuery('(max-width:768px)')

    const submitRef = useRef(null)
    const [details, setDetails] = useState(null)
    const [priorState, setPriorState] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (details === null && socket){
            loadDetails()
        }
    }, [details])

    const loadDetails = () => {
        setLoading(true)

        socket.props.onListFailure = (msg) => {
            notify('Loading Error', msg)
        }
        socket.props.onListSuccess = (data) => {
            setDetails(data)
            setPriorState(data)
        }
        socket.props.setListLoading = setLoading

        viewRewards({}, socket)
    }

    const updateAccountRewards = (e) => {
        e.preventDefault()

        const requestData = {
            PointsRequired: details.PointsRequired,
            DollarsOff: details.DollarsOff,
            Enabled: details.Enabled
        }

        const clone = Object.assign({}, details)
        setPriorState(clone)

        socket.props.onUpdateFailure = (msg) => {
            notify('Update Error', msg)
        }
        socket.props.onUpdateSuccess = (data) => {
            // Do something maybe
        }
        socket.props.setUpdateLoading = setLoading

        updateRewards(requestData, socket)
    }

    const updateProp = (val, field) => {
        const clone = Object.assign({}, details)
        clone[field] = val
        setDetails(clone)
    }

    const saveDetails = (val, field) => {
        if (details[field] !== priorState[field]){
            submitRef.current.click()
        }
    }

    return <Fragment>        
        <Row>
            <Col lg={1}></Col>
            <Col>
                <h4>
                    Customer Rewards
                </h4>
            </Col>
        </Row>
        <hr />
        {loading
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
            : <Form onSubmit={updateAccountRewards} onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : ''}>
                <Form.Group as={Row}>
                    <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                        Points Required
                    </Form.Label>
                    <Col xl={2} lg={3} md={3}>
                        <Form.Control required type="number" value={details.PointsRequired} maxLength={20} onChange={(e) => updateProp(e.target.value, 'PointsRequired')} onBlur={(e) => saveDetails(e.target.value, 'PointsRequired')} />
                    </Col>
                    <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                        Dollars Off
                    </Form.Label>
                    <Col xl={2} lg={3} md={3}>
                        <Form.Control required type="number" required value={details.DollarsOff} maxLength={20} onChange={(e) => updateProp(e.target.value, 'DollarsOff')} onBlur={(e) => { saveDetails(e.target.value, 'DollarsOff') }} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>
                        Active
                    </Form.Label>
                    <Col xl={2} lg={3} md={3} style={{display: 'flex', alignItems: 'center'}}>
                        <Form.Check type="switch" id="RewardsEnabled" checked={details.Enabled} maxLength={20} onChange={(e) => updateProp(e.target.checked, 'Enabled')} onBlur={(e) => saveDetails(e.target.checked, 'Enabled')} />
                    </Col>
                </Form.Group>
                <Row>
                    <Button type="submit" ref={submitRef} style={{display: 'none'}}>Submit</Button>
                </Row>
            </Form>
        }
    </Fragment>
}