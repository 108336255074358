import requestType from '../../../enums/requestType'

export const loadCategories = (data, socket) => {
    const request = {
        RequestType: requestType.ViewStoreCategories,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const loadProducts = (data, socket) => {
    const request = {
        RequestType: requestType.ViewStoreProducts,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const loadStoreInformation = (data, socket) => {
    const request = {
        RequestType: requestType.ViewStoreInformation,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const loadProductDetail = (data, socket) => {
    const request = {
        RequestType: requestType.ViewStoreProductDetail,
        Data: data
    }
    socket.send(JSON.stringify(request))
}