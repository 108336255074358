import requestType from '../../../enums/requestType'

export const addComboCategory = (data, socket) => {
    const request = {
        RequestType: requestType.AddComboCategory,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const deleteComboCategory = (data, socket) => {
    const request = {
        RequestType: requestType.DeleteComboCategory,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const createCombo = (data, socket) => {
    const request = {
        RequestType: requestType.CreateCombo,
        Data: data 
    }
    socket.send(JSON.stringify(request))
    console.log(request)
}

export const updateCombo = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateCombo,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateComboItems = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateComboItems,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const listCombos = (data, socket) => {
    const request = {
        RequestType: requestType.ListCombos,
        Data: data
    }
    socket.send(JSON.stringify(request))
}


export const viewStoreCombo = (data, socket) => {
    const request = {
        RequestType: requestType.ViewStoreCombo,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const viewComboDetail = (data, socket) => {
    const request = {
        RequestType: requestType.ViewComboDetail,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateComboProductCategories = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateComboProductCategories,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const updateComboCategory = (data, socket) => {
    const request = {
        RequestType: requestType.UpdateComboCategory,
        Data: data
    }
    socket.send(JSON.stringify(request))
}
