import React, { useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Nav, Navbar, Button } from 'react-bootstrap'
import { clearSession, getTokensSyncNoUpdate } from '../../api/auth/session'
import userPages from '../../enums/userPages'
import longLogo from '../../images/nsLong419.png'

export const TopNav = ({overrideName, setSelectedPage, socket}) => {

    const isSmall = useMediaQuery('(max-width:990px)');
    const tokenInfo = getTokensSyncNoUpdate()
    return (
        <div style={{display: 'flex', position: 'absolute', flexDirection: 'column', top: '0px', zIndex: '99'}}>
            <Navbar expand="lg" style={{backgroundColor: 'rgba(255, 255, 255, 0.95)', width: '100vw'}}>
                {!isSmall && <div style={{ width: '15vw', textAlign: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', top: '5px', height: '4vh'}}>
                    {overrideName 
                        ? <h5 style={{textAlign: 'left', display: 'flex', height: '100%', alignItems: 'center', whiteSpace: 'nowrap', marginTop: '5px'}}>
                            {overrideName}
                        </h5>
                        : <img src={longLogo} style={{height: '120%'}} />
                    }
                    {/* <Button onClick={() => socket && socket.socket.close()}>Disconnect</Button> */}
                </div>}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {isSmall && <Nav><Nav.Link style={{fontSize: '1.1rem'}} onClick={() => setSelectedPage(userPages.Profile)}>{tokenInfo.Email}</Nav.Link></Nav> }
                <Navbar.Collapse id="basic-navbar-nav" style={{textAlign: 'center'}}>
                    <Nav className="ml-auto">
                        {!isSmall && <Nav.Link style={{fontSize: '1.1rem'}} onClick={() => setSelectedPage(userPages.Profile)}>{tokenInfo.Email}</Nav.Link>  }
                        <Nav.Link style={{fontSize: '1.1rem'}} onClick={() => setSelectedPage(userPages.Help)}>Help</Nav.Link>
                        <Nav.Link style={{fontSize: '1.1rem'}} onClick={() => clearSession(true)}>Log Out</Nav.Link>                    
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}