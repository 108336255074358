import { registerCustomerAPI, emailExistsAPI } from '../auth/auth'
import { setCustomerTokens } from '../auth/session';
import { handleErrors } from '../base'
import userType from '../../enums/userTypes'


export const signUpCustomerAPI = async (email, password, firstName, lastName, phone, homeId, appCreated) => {
    const response = await registerCustomerAPI(email, password, firstName, lastName, phone, homeId, appCreated);
    const results = await handleErrors(response)
    if (results && results.IdToken) {
        results.Email = email
        setCustomerTokens(results, true)
    }
    return results
}

export const checkCustomerEmailExistsAPI = async (email) => {
    const response = await emailExistsAPI(email, userType.Customer)
    const results = await handleErrors(response)
    return results
}