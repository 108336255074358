import React, { useState, Fragment, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import { Form, Col, Row } from 'react-bootstrap'
import { getDashboardURL, getReportDashboards } from '../../api/sockets/reports/reportRequests'
import { getTokensSyncNoUpdate } from '../../api/auth/session'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"


export const ViewReports = ({socket, notify}) => {

    const [loading, setLoading] = useState(true)
    const [dashList, setDashList] = useState(null)
    const [selectedBoard, setSelectedBoard] = useState(null)
    const [dashLoaded, setDashLoaded] = useState(false)

    let today = new Date()
    today.setHours(0,0,0,0)
    const [startDate, setStartDate] = useState(today)
    let todayMax = new Date()
    todayMax.setHours(23, 59, 59, 999)
    const [endDate, setEndDate] = useState(todayMax)
    
    const tokenInfo = getTokensSyncNoUpdate()

    const globalParams = {
        AccountId: [tokenInfo.HomeAccountId],
        CustomStart: today,
        CustomEnd: todayMax
    }

    useEffect(() => {
        if (!dashList && socket){
            window.dashboard = null
            loadDashboards()
        }
    }, [])

    const loadDashboards = () => {
        socket.props.setLoading = null
        socket.props.onSuccess = (data) => {
            setDashList(data.Dashboards)
            loadSingleDash(data.Dashboards[0])
        }
        socket.props.onFailure = (msg) => {
            notify('Error', 'Error Loading Reports')
        }

        getReportDashboards({}, socket)
    }

    const loadSingleDash = (dash) => {
        if (typeof(dash) === 'string'){
            dash = dashList.filter(x => x.DashboardId === dash)[0]
        }

        if (!dash || dash === selectedBoard){
            return
        }

        setSelectedBoard(dash)

        setLoading(true)

        // Once the dash is loaded we can just switch
        if (!dashLoaded){
            const requestData = {
                DashboardId: dash.DashboardId
            }

            socket.props.onDetailSuccess = (data) => {
                setLoading(false)
                setTimeout(() => loadBoard(data.DashboardURL), 200)
                setDashLoaded(true)
            }
            socket.props.onDetailFailure = (msg) => {
                notify('Error', msg)
            }
            socket.props.setDetailLoading = setLoading

            getDashboardURL(requestData, socket)
        }
        else{
            switchToBoard(dash)
        }
    }

    const switchToBoard = (dash) => {
        if (window.dashboard){

            delete globalParams.CustomStart
            delete globalParams.CustomEnd

            const options = {
                dashboardId: dash.DashboardId,
                parameters: globalParams
            }
            window.dashboard.navigateToDashboard(options)
            // Kinda Hacky, would like to fire on some sort of event
            setTimeout(() => {
                updateBoardWithNewDates()
            }, 2000)
        }
        setLoading(false)
    }

    const loadBoard = (embedURL) => {
        const options = {
            url: embedURL,
            container: document.getElementById('dashboardTarget'),
            parameters: globalParams,
            printEnabled: true
        }
        window.dashboard = embedDashboard(options)
        window.dashboard.on('load', (x) => console.log('Dashboard Loaded', x))
        window.dashboard.on('selectedSheetChange', (x) => console.log('Sheet Changed'))
        window.dashboard.on('error', (x) => {
            notify('Error', 'Error Loading Report')
            console.log('Dashboard Error', x)
        })
    }

    const updateQuickDate = (daysAgo) => {
        let thisDate = new Date()
        setEndDate(thisDate)

        let thisDate2 = new Date()
        thisDate2.setDate(thisDate2.getDate() + daysAgo)
        setStartDate(thisDate2)

        updateBoardWithNewDates(thisDate2, thisDate)
    }



    const updateBoardWithNewDates = (startOvr, endOvr) => {
        if (!startOvr){
            startOvr = startDate
        }
        if (!endOvr){
            endOvr = endDate
        }
        
        // Zero start
        let zeroStart = new Date(startOvr)
        zeroStart.setHours(0, 0, 0, 0)

        // Max end
        let maxEnd = new Date(endOvr)
        maxEnd.setHours(23, 59, 59, 999)

        globalParams.CustomStart = zeroStart
        globalParams.CustomEnd = maxEnd

        window.dashboard && window.dashboard.setParameters(globalParams)
    }

    return <Fragment>
        <Row>
            <Col>
                <h2>
                    Reporting
                </h2>
            </Col>
            <Col xs={6} lg={4} xl={3}>
                <Form.Control as="select" value={(selectedBoard && selectedBoard.DashboardId) || ''} onChange={(e) => loadSingleDash(e.target.value)}>
                    <option value="">-- Select Report --</option>
                    {dashList && dashList.map((x, i) => {
                        return <option value={x.DashboardId} key={i}>
                            {x.Name}
                        </option>
                    })}
                </Form.Control>
            </Col>
        </Row>
        <hr />
        <Row style={{justifyContent: 'space-between', marginRight: '30px', marginLeft: '30px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => {setStartDate(date); updateBoardWithNewDates(date, null)}}
                    dateFormat="MMM d, yyyy"
                />
                <span style={{marginLeft: '10px', marginRight: '10px'}}>
                    To
                </span>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => {setEndDate(date); updateBoardWithNewDates(null, date)}}
                    dateFormat="MMM d, yyyy"
                />
            </div>
            <div style={{display: 'flex', fontWeight: 'bold', alignItems: 'center'}}>
                <div 
                    style={{marginRight: '10px', cursor: 'pointer'}}
                    onClick={() => updateQuickDate(0)}
                >
                    1 Day
                </div>
                <div 
                    style={{marginRight: '10px', cursor: 'pointer'}}
                    onClick={() => updateQuickDate(-6)}
                >
                    1 Week
                </div>
                <div 
                    style={{cursor: 'pointer'}}
                    onClick={() => updateQuickDate(-30)}
                >
                    1 Month
                </div>
            </div>
        </Row>
        <Row style={{marginTop: '5px', height: '85%'}}>
            <Col>
                <Row style={{height: '100%'}}>
                    {loading 
                    ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%' }}>
                            <CircularProgress style={{ width: '100px', height: '100px' }} />
                        </div>
                    )
                    : (
                        <div id="dashboardTarget" style={{width: '100%'}}></div>
                    )}
                </Row>
            </Col>
        </Row>
    </Fragment>
}
