import requestType from "../../../enums/requestType"


export const createProduct = (data, socketInfo) => {
    const request = {
        RequestType: requestType.AddProduct,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const listProducts = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ListProducts,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateProduct = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateProduct,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const productDetail = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ProductDetails,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateProductItemCategories = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateProductItemCategories,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const listIngredients = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ListIngredients,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateProductIngredients = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateProductIngredients,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateProductAddons = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateProductAddons,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const searchProductTags = (data, socketInfo) => {
    const request = {
        RequestType: requestType.ProductTagSearch,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const updateProductTags = (data, socketInfo) => {
    const request = {
        RequestType: requestType.UpdateProductTags,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const cloneProduct = (data, socketInfo) => {
    const request = {
        RequestType: requestType.CloneProduct,
        Data: data
    }
    socketInfo.socket.send(JSON.stringify(request))
}

export const clearProductImage = (data, socket) => {
    const request = {
        RequestType: requestType.ProductClearImage,
        Data: data
    }
    socket.send(JSON.stringify(request))
}

export const getDailySnapshot = (socketInfo) => {
    const request = {
        RequestType: requestType.DailySnapshot,
        Data: {}
    }

    socketInfo.socket.send(JSON.stringify(request))
}

export const deleteProduct = (data, socket) => {
    const request = {
        RequestType: requestType.DeleteProduct,
        Data: data
    }
    socket.send(JSON.stringify(request))
}