import { OpenInNew } from '@material-ui/icons'
import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useMediaQuery } from '@material-ui/core'

export const StoreLanding = ({socket, notify, info}) => {
    const isSmall = useMediaQuery('(max-width:525px)')

    let timeInfo = JSON.parse(info.TimeData || null) || {}
    if (!timeInfo.Hours){
        timeInfo.Hours = {}
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const formatTime = (str) => {
        const timeDate = new Date(str)
        let hours = timeDate.getHours()
        let meridiem = 'AM'
        if (hours > 12){
            hours -= 12
            meridiem = 'PM'
        }
        else if (hours === 0){
            hours = 12
        }
        return `${hours}:${timeDate.getMinutes().toString().padStart(2, '0')} ${meridiem}`
    }

    const getMapsURL = () => {
        let baseURL = 'https://www.google.com/maps/dir/?api=1&destination='
        const location = `${info.Address1} ${info.Address2} ${info.City} ${info.State} ${info.ZipCode}`
        return baseURL + encodeURIComponent(location)
    }

    const formatPhone = (number) => {
        if (number.length !== 11 && number.length !== 10){
            return number
        }
        let final = ''
        let parenStart = 0
        if (number.length === 11){
            final += number[parenStart++] + ' '          
        }
        final += '(' + number.substring(parenStart, parenStart += 3) + ') '
        final += number.substring(parenStart, parenStart += 3) + '-'
        final += number.substring(parenStart, parenStart += 4)

        return final
    }

    window.formatPhone = formatPhone
    return (
        <Container>
            <Row style={{justifyContent: 'center'}}>
                <h1>{info.Name}</h1>
            </Row>
            <Row style={{alignItems: 'center'}}>
                {info.Logo && <div>
                    <img src={`https://networksmarketer.com/images/${info.Logo}`} height="300" style={{borderRadius: '10px'}} />
                </div>}
                <div 
                    style={{
                        whiteSpace: 'pre-line',
                        fontSize: '1.2rem',
                        marginLeft: '20px'
                    }}
                >
                    {timeInfo.Description || ''}
                </div>
            </Row>
            <hr />
            <Row>
                <Col sm={'auto'} xs={12}>
                    <Row>
                        <h4>Store Hours</h4>
                    </Row>
                    {days.map(x => {
                        const open = timeInfo.Hours[`${x}Open`]
                        const close = timeInfo.Hours[`${x}Close`]
                        return (
                            <Row style={{fontSize:  isSmall ? '1rem' : '1.2rem'}} key={x}>
                            <Col xs={4}>
                                    {x}
                                </Col>
                                <Col>
                                    {!open && !close
                                        ? 'Closed'
                                        : open && close
                                            ? `${formatTime(open)} - ${formatTime(close)}`
                                            : open
                                                ? `Opens at ${formatTime(open)}`
                                                : `Open until ${formatTime(close)}`
                                    }
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
                <Col>
               {isSmall &&  <hr /> }
                    <Row>
                        <h4>Location</h4>
                        <a href={getMapsURL()} target="_blank">
                            <OpenInNew style={{marginLeft: '20px'}} />
                        </a>
                    </Row>
                    <Row style={{fontSize: isSmall ? '1rem' : '1.2rem'}}>
                        <Col>
                            {info.Address1} {info.Address2}
                        </Col>
                    </Row>
                    <Row style={{fontSize: isSmall ? '1rem' : '1.2rem'}}>
                        <Col>
                            {info.City} {info.State} {info.ZipCode}
                        </Col>
                    </Row>
                    {(Boolean(timeInfo.StorePhone) || Boolean(timeInfo.StoreEmail)) && 
                        <Fragment>
                            <hr />
                            <Row>
                                <h4>Contact Us</h4>
                            </Row>
                        </Fragment>
                    }
                    {timeInfo.StorePhone && (
                        <Row style={{fontSize: isSmall ? '1rem' : '1.2rem'}}>
                            <Col>
                                {formatPhone(timeInfo.StorePhone)}
                            </Col>
                        </Row>
                    )}
                    {timeInfo.StoreEmail && (
                        <Row style={{fontSize: isSmall ? '1rem' : '1.2rem'}}>
                            <Col>
                                <a href={`mailto:${timeInfo.StoreEmail}`}>{timeInfo.StoreEmail}</a>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Container>
    )
}