import React, { useState, useEffect } from 'react'

import { getAccountCustomerList } from '../../api/sockets/account/accountRequests'

import { AccountCustomerList } from './accountCustomerList'
import { AccountCustomerDetail } from './accountCustomerDetail'
import userPages from '../../enums/userPages'

export const AccountCustomer = ({ socket, notify, setSelectedPage, selectedPage }) => {
    const [loading, setLoading] = useState(true)

    // Customer Detail
    const [customerId, setCustomerId] = useState(null)
    
    // Customer List
    const [dateFilterDDL, setDateFilterDDL] = useState(30)
    const [customerList, setCustomerList] = useState(null)

    useEffect(() => {
        if (socket !== null){
            reloadCustomerList()
        }
        if (selectedPage && selectedPage.includes(userPages.CustomerDetail)){
            setCustomerId(Number(selectedPage.replace(userPages.CustomerDetail, '')))
        }
        else{
            setCustomerId(null)
        }
    }, [socket, selectedPage])

    const reloadCustomerList = () => {
        setLoading(true)
        let numberOfDays = dateFilterDDL

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onListSuccess = (results) => {
            setCustomerList(results)
        }
        const requestData = {
            WithinDays: numberOfDays
        }
        getAccountCustomerList(requestData, socket)
    }
    
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {
            customerId
                ? <AccountCustomerDetail props={{
                    socket, notify, 
                    customerId: customerId,
                    back: () => setCustomerId(null),
                    reloadCustomerList,
                    setSelectedPage
                }} />
                : <AccountCustomerList props={{
                    customerList, reloadCustomerList,
                    dateFilterDDL, setDateFilterDDL,
                    setCustomerId, setSelectedPage
                }} />
        }
        </div>
    )
}